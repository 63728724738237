import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {reducer} from "./core/authentication.reducer";
import {EffectsModule} from "@ngrx/effects";
import {AuthenticationEffects} from "./core/authentication.effects";
import {LoginFormComponent} from './forms/login-form/login-form.component';
import {SignupFormComponent} from './forms/signup-form/signup-form.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {ForgotPasswordFormComponent} from './forms/forgot-password-form/forgot-password-form.component';
import {UpdateProfileFormComponent} from './forms/update-profile-form/update-profile-form.component';


@NgModule({
  declarations: [
    LoginFormComponent,
    SignupFormComponent,
    ForgotPasswordFormComponent,
    UpdateProfileFormComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature(
      [AuthenticationEffects]
    ),
    FlexLayoutModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class AuthenticationModule {}
