import {address, phoneNumber} from '../../shared/constants/validation.constants';

interface UnauthenticatedCartScreenTranslations {
  cartHeading: string,
  myCartHeading: string,
  total: string,
  productDeliveryPolicyPart1: string,
  productDeliveryPolicyPart2: string,
  productDeliveryPolicyPart3: string,
  noData: string,
  guestFormHeading: string,
  fullName: string,
  email: string,
  shippingAddressDetailsLabel: string,
  paymentCardDetailsLabel: string,
  location: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  phoneCode: string,
  phone: string,
  nameOnCard: string,
  numberLabel: string,
  expiryLabel: string,
  cvcLabel: string,
  addCoupon: string,
  next: string,
  confirmOrder: string,
  goToCatalogPage: string,
  back: string,
  message: string,
  acceptText: string,
  rejectText: string,
  successMessage: string,
  maxQuantityMessage: string,
  minQuantityMessage: string,
  productDeleteSuccessMessage: string,
  errorMessageMap: object
}

const en: UnauthenticatedCartScreenTranslations = {
  cartHeading: 'Your Order',
  myCartHeading: 'My Cart',
  total: 'Total Amount',
  productDeliveryPolicyPart1:
    `※One-time purchase teas are shipped within 3 business days. First package of the tea subscription is shipped out within 3 business days. We cannot accept returns after shipping the package unless the product is defective. For cancellation and return, please reach out to order.japan@load-road.com. Please refer to`,
  productDeliveryPolicyPart2: `this document`,
  productDeliveryPolicyPart3: `for details of the subscription contract and cancellation.`,
  noData: `Your Cart is empty! \n Select something from our Selections`,
  guestFormHeading: 'Enter your purchase details',
  fullName: 'Name',
  email: 'Email',
  shippingAddressDetailsLabel: 'Shipping Address Details',
  location: 'Location',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  phoneCode: 'Code',
  phone: 'Phone',
  paymentCardDetailsLabel: 'Payment Card Details',
  nameOnCard: 'Name On Card',
  numberLabel: 'Card Number',
  expiryLabel: 'MM/YY',
  cvcLabel: 'CVV',
  addCoupon: 'ADD COUPON',
  next: 'Next',
  confirmOrder: 'Confirm Order',
  goToCatalogPage: 'Go To Shop Page',
  back: 'Back',
  message: 'Do you want to clear your cart?',
  acceptText: 'No',
  rejectText: 'Yes',
  successMessage: 'Cart Cleared Successfully',
  maxQuantityMessage: 'Can not add more quantity. Maximum Limit Reached.',
  minQuantityMessage: 'Can not remove more quantity. Minimum Limit Reached.',
  productDeleteSuccessMessage: 'Item Removed Successfully.',
  errorMessageMap: {
    fullName: {
      required: 'This field is required.',
      pattern: 'Numbers and space at start not allowed.'
    },
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email Id.'
    },
    country: {
      required: 'This field is required.',
    },
    zip: {
      required: 'This field is required.',
      minlength: `Must be atleast ${address.zipCode.length.min} digits.`,
      maxlength: `Must be atleast ${address.zipCode.length.max} digits.`,
      pattern: 'Zip Code Can only contain Numbers and Single (-) in between.'
    },
    state: {
      required: 'This field is required.',
      whitespace: 'Only spaces not allowed.',
    },
    city: {
      required: 'This field is required.',
      whitespace: 'Only spaces not allowed.',
    },
    addressLine1: {
      required: 'This field is required.',
      minlength: `Please enter at least ${address.addressLine1.length.min} characters`,
      whitespace: `Only spaces not allowed.`,
    },
    addressLine2: {
      minlength: `Please enter at least ${address.addressLine2.length.min} characters`
    },
    phone: {
      required: 'This field is required.',
      minlength: `Must be atleast ${phoneNumber.length.min} digits.`,
      maxlength: `Must be atleast ${phoneNumber.length.max} digits.`
    },
    nameOnCard: {
      required: 'This field is required.',
      pattern: 'Name should be alphabetical only.'
    }
  }
}

const ja: UnauthenticatedCartScreenTranslations = {
  cartHeading: 'ご注文',
  myCartHeading: 'カート',
  total: '合計',
  productDeliveryPolicyPart1: `※単品購入分の発送は、決済確認後3営業日以内といたします。サブスクリプションの場合、初回発送は決済確認後3営業日以内とし、2回目以降の発送は更新日から3営業日以内といたします。
商品発送後のお客様都合での返品・返却等はお受けいたしかねます。商品に不良があった場合は良品と交換いたしますので、<span class="ff-lang-ja-text-en">order.japan@load-road.com</span>までご連絡ください。
サブスクリプションの契約内容及び解約方法については`,
  productDeliveryPolicyPart2: `こちら`,
  productDeliveryPolicyPart3: `を参照ください。`,
  noData: `カートが空です`,
  guestFormHeading: 'お届け先・お支払い情報',
  fullName: 'お名前',
  email: 'メールアドレス',
  shippingAddressDetailsLabel: 'お届け先を選択',
  location: '原産国/地域',
  addressLine1: '番地以降',
  addressLine2: 'マンション名等',
  city: '市区町村',
  state: '都道府県',
  zip: '郵便番号',
  country: '国',
  phoneCode: '電話コード',
  phone: '電話番号',
  paymentCardDetailsLabel: 'お支払い情報',
  nameOnCard: 'カード名義',
  numberLabel: 'カード番号',
  expiryLabel: 'MM/YY',
  cvcLabel: 'セキュリティ番号',
  addCoupon: 'クーポンを適用',
  next: '次に進む',
  confirmOrder: '注文を確定',
  goToCatalogPage: 'ホームに戻る',
  back: '戻る',
  message: 'カートを空にしますか？',
  acceptText: 'いいえ',
  rejectText: 'はい',
  successMessage: 'カートが空になりました',
  maxQuantityMessage: '注文できる個数の上限です',
  minQuantityMessage: '最小注文数です',
  productDeleteSuccessMessage: '商品を削除しました',
  errorMessageMap: {
    fullName: {
      required: '入力が必須です',
      pattern: '先頭に数字とスペースは使用できません。'
    },
    email: {
      required: '入力が必須です',
      pattern: 'Eメールが無効です'
    },
    country: {
      required: '入力が必須です',
    },
    zip: {
      required: '入力が必須です',
      minlength: `${address.zipCode.length.min}桁以上で入力してください`,
      maxlength: `${address.zipCode.length.max}桁以上で入力してください`,
      pattern: '郵便番号は半角数字と半角ハイフン(-)で入力してください'
    },
    state: {
      required: '入力が必須です',
      whitespace: '文字を入力してください',
    },
    city: {
      required: '入力が必須です',
      whitespace: '文字を入力してください',
    },
    addressLine1: {
      required: '入力が必須です',
      minlength: `${address.addressLine1.length.min}文字以上で入力してください`,
      whitespace: '文字を入力してください',
    },
    addressLine2: {
      minlength: `${address.addressLine1.length.min}文字以上で入力してください`
    },
    phone: {
      required: '入力が必須です',
      minlength: `${phoneNumber.length.min}桁以上で入力してください`,
      maxlength: `${phoneNumber.length.min}桁以下で入力してください`
    },
    nameOnCard: {
      required: '入力が必須です',
      pattern: '名前には日本語かアルファベットを使用してください'
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
