interface UnauthenticatedCartNonEditableRowTranslations {
  quantity: string,
  removeItem: string
}

const en: UnauthenticatedCartNonEditableRowTranslations = {
  quantity: `Quantity`,
  removeItem: `Remove Item`
}

const ja: UnauthenticatedCartNonEditableRowTranslations = {
  quantity: `数量`,
  removeItem: `商品を削除`
}

export const Translations = {
  1: en,
  2: ja
}
