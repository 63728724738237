<div
  class="container"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="1.5rem"
>
  <div
    class="footer-text"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxLayoutGap="1rem"
  >
    <div
      (click)="privacyClickHandler()"
      class="button"
    >{{translations[language].privacy}}</div>
    <div class="separator">|</div>
    <div
      (click)="termsClickHandler()"
      class="button"
    >{{translations[language].terms}}</div>
    <div class="separator">|</div>
    <div
      (click)="noticeClickHandler()"
      class="button"
    >{{translations[language].notice}}</div>
  </div>
  <div
    *ngIf="!(isMobileView$ | async)"
    class="language-selector-section"
  >
    <div
      [matMenuTriggerFor]="menu"
      class="language-selector"
      fxLayoutAlign="center center"
    >
      <div
        fxLayout="row"
        fxLayoutGap="0.75rem"
      >
        <app-icon
          class="language-icon"
          icon="language-globe"
        ></app-icon>
        <div class="selected-language">
          {{selectedLanguageText}}
        </div>
      </div>
    </div>
    <mat-menu #menu="matMenu" class="header-language-list" xPosition="before">
      <button
        (click)="setSelectedLanguage(eachLanguage.key)"
        *ngFor="let eachLanguage of languagesList"
        [class.selected]="eachLanguage.key === language"
        class="header-language-item"
        mat-menu-item
      >
        {{eachLanguage.value}}
      </button>
    </mat-menu>
  </div>
</div>
