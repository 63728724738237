<app-subscription-detail-loader
  *ngIf="loading; else subscriptionDetailsTemplate"
  [translations]="translations[language]"></app-subscription-detail-loader>
<ng-template #subscriptionDetailsTemplate>
  <style
    id="subscription-details-dialog-styling"
  >
    .cdk-overlay-pane.dialog {
      width: unset !important;
    }
  </style>
  <div
    class="container"
    ngClass.gt-lg="gt-lg"
    ngClass.lg="lg"
    ngClass.md="md"
    ngClass.sm="sm"
    ngClass.xs="xs"
  >
    <div class="content"
         fxLayout="column">
      <div
        class="each-section basic-information"
        fxFlex.lg="80"
        fxFlex.md="80"
        fxFlex.sm="80"
        fxFlex.xs="100"
      >
        <div class="subscription-code">
          <div class="label"
               fxFlex="35"
               fxFlex.md="45"
               fxFlex.xs="45">{{ translations[language].subscriptionLabel }} #
          </div>
          <div class="value ff-lang-ja-text-en"
               fxFlex="65"
               fxFlex.md="55"
               fxFlex.xs="55">
            {{ subscriptionDetails?.subscriptionCode }}
          </div>
        </div>
        <div class="name">
          <div class="label"
               fxFlex="35"
               fxFlex.md="45"
               fxFlex.xs="45"> {{translations[language].name}}</div>
          <div class="value ff-lang-ja-text-en"
               fxFlex="65"
               fxFlex.md="55"
               fxFlex.xs="55">{{ subscriptionDetails?.item?.name }}</div>
        </div>
        <div class="nickname">
          <div class="label"
               fxFlex="35"
               fxFlex.md="45"
               fxFlex.xs="45">
            {{ translations[language].subscriptionLabel }}
          </div>
          <div class="value"
               fxFlex="65"
               fxFlex.md="55"
               fxFlex.xs="55">
            {{ subscriptionDetails?.subscriptionPlan }}
          </div>
        </div>
        <div class="price">
          <div class="label"
               fxFlex="35"
               fxFlex.md="45"
               fxFlex.xs="45">
            {{translations[language].price}}
          </div>
          <div class="value ff-lang-ja-text-en"
               fxFlex="65"
               fxFlex.md="55"
               fxFlex.xs="55">
            {{ subscriptionDetails?.total | currency: subscriptionDetails?.currencySymbol : subscriptionDetails?.currencySymbol : '1.0-2' }}
          </div>
        </div>
        <div class="subscription-status">
          <div class="label"
               fxFlex="35"
               fxFlex.md="45"
               fxFlex.xs="45">
            {{translations[language].subscriptionStatus}}
          </div>
          <div class="label primary-status-text"
               fxFlex="65"
               fxFlex.md="55"
               fxFlex.xs="55"
               fxLayoutAlign="start"
               [ngClass]="subscriptionDetails?.isActive === true ? 'active' : 'cancelled'"
          >
            {{subscriptionDetails?.status}}
          </div>
        </div>
        <div *ngIf="subscriptionDetails?.status !== 'Cancelled'"
             class="next-payment-date">
          <div class="label"
               fxFlex="35"
               fxFlex.md="45"
               fxFlex.xs="45">
            {{ translations[language].nextPaymentDate }}
          </div>
          <div class="value ff-lang-ja-text-en"
               fxFlex="65"
               fxFlex.md="55"
               fxFlex.xs="55">
            {{subscriptionDetails?.nextPaymentDate | date:'longDate'}}
          </div>
        </div>
      </div>
      <div
        class="space"
        fxLayout.lg="row"
        fxLayout.md="row"
        fxLayout.sm="row"
        fxLayout.xs="column"
        fxLayoutGap.lg="20px"
        fxLayoutGap.md="20px"
      >
        <div
          class="each-section shipping-address"
          fxFlex.lg="50"
          fxFlex.md="50"
          fxFlex.sm="100"
          fxFlex.xs="100"
        >
          <div class="heading"> {{ translations[language].shippingAddress }}</div>
          <app-shipping-address-selection-card
            [address]="addressDetails"
          ><!--          // *** "Selected" Text/Button disabled- To be reviewed later *** //-->
          </app-shipping-address-selection-card>
        </div>
        <div
          class="each-section payment-details"
          fxFlex.lg="50"
          fxFlex.md="50"
          fxFlex.sm="100"
          fxFlex.xs="100"
        >
          <div class="heading"> {{ translations[language].paymentDetails }}</div>
          <app-payment-option-card
            [paymentOption]="paymentDetails"
            [defaultLabel]="translations[language].defaultLabel"
            [makeDefaultButtonDisabled]="true"
            [makeDefaultButtonLabel]="translations[language].selectedLabel"
            [makeDefaultButtonVisible]="false"
            [deleteButtonVisible]="false"
            [expiryDateVisible]="false"
          ><!--          // *** "Selected" Text/Button disabled- To be reviewed later *** //-->
          </app-payment-option-card>
        </div>
      </div>
      <div
        class="space"
        fxLayout="column"
      >
        <div
          class="row"
          fxLayout="column"
        >
          <div fxFlex="100" class="value"><span [innerHtml]="translations[language].contactUsDescription"></span></div>
          <app-secondary-button
            fxLayoutAlign="center center"
            class="value contact-us-button"
            (handleClick)="handleContactUs(subscriptionDetails?.subscriptionCode)"
            [text]="translations[language].contactUsLabel"
          ></app-secondary-button>

          <div
            *ngIf="subscriptionDetails?.isActive"
            class="text-center cancel"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <div fxFlex="100"
            >
              <app-primary-clickable-text
                (handleClick)="handleCancelSubscription(subscriptionDetails._id)"
                [text]="translations[language].cancelSubscriptionLabel"
              ></app-primary-clickable-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

