import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {gendersList, selectedLanguage} from "../../../shared/core/shared.selectors";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {CommonService} from "../../../shared/services/common.service";
import {Translations} from "../../../translations/authentication/forms/update-profile-form.translation";
import * as fromRoot from "../../../../state/app.state";
import {FilterModel} from "../../../models/filter.model";
import {FetchGenderListRequest} from '../../../shared/core/shared.actions';
import {LoginFormComponent} from "../login-form/login-form.component";
import {UpdateAccountRequest} from "../../core/authentication.actions";
import * as Validations from "../../../shared/constants/validation.constants";

@Component({
  selector: 'app-update-profile-form',
  templateUrl: './update-profile-form.component.html',
  styleUrls: ['./update-profile-form.component.css']
})
export class UpdateProfileFormComponent implements OnInit {
  language: number = 1;
  validations = Validations;
  translations = Translations;
  errorMessages = {};
  errorMessageMap = {};

  gendersList: FilterModel[] = [];

  form = this.fb.group({
    age: new FormControl('', {
      validators:
        [
          Validators.required,
          Validators.min(this.validations.user.age.min),
          Validators.max(this.validations.user.age.max)
        ],
    }),
    gender: new FormControl('', [Validators.required]),
  });

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(data => {
    this.language = data;
    this.errorMessageMap = this.translations[this.language].errorMessageMap;
  });

  gendersList$: Observable<FilterModel[]> = this.store.pipe(select(gendersList))

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.store.dispatch(FetchGenderListRequest());
  }

  ngOnInit(): void {
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  restrictZero(event: any) {
    if (event.target.value.length === 0 && event.key === '0') {
      event.preventDefault();
    }
  }

  updateAccount() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }
    const {age, gender} = this.form.getRawValue();
    const payload = {
      age: Number(age),
      gender
    };

    this.store.dispatch(UpdateAccountRequest({payload}));
  }

  openLoginDialog() {
    this.commonService.closeDialog();
    this.commonService.openDialog({data: {component: LoginFormComponent}})
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    // this.gendersListSubscription?.unsubscribe();
  }
}
