import * as TeaDetailsAction from './tea-details.actions';
import {createReducer, on} from '@ngrx/store';
import {TeaDetailsPageState} from '../../models/tea-details.model';
import {ResetSuccessState} from "./tea-details.actions";

// State for this feature (User)

const initialState: TeaDetailsPageState = {
  teaDetails: {
    loading: false,
    success: null,
    appearanceDescription: '',
    aromaDescription: '',
    availableQuantity: 0,
    brewConfigurations: [],
    brewTasteDescription: '',
    country: '',
    experience: [],
    id: '',
    images: [],
    isAvailableInCountry: true,
    name: '',
    pairings: [],
    pricingPlans: [],
    region: '',
    shortDescription: '',
    storyInfo: {
      description: '',
      imageUrl: '',
      title: ''
    },
    vendor: {
      logoUrl: '',
      name: ''
    },
    quantityDescription:'',
    key: ''
  },
  reviewSummary: {
    loading: false,
    success: null,
    averageRatingKey: 0,
    averageRatingValue: '',
    ratingSummary: [],
    topSevenTags: [],
    totalReviews: 0,
  },
  userReviews: {
    loading: false,
    success: null,
    isNext: false,
    isPrevious: false,
    reviews: [],
    totalCount: 0
  },
};

export const reducer = createReducer(
  initialState,

  // Fetch tea Details
  on(TeaDetailsAction.FetchTeaDetailsRequest, (state) => {
    return {
      ...state,
      teaDetails: {
        ...initialState.teaDetails,
        success: null,
        loading: true
      }
    };
  }),

  on(TeaDetailsAction.FetchTeaDetailsSuccess, (state, props) => {
    return {
      ...state,
      teaDetails: {
        ...props.teaDetails,
        success: true,
        loading: false
      }
    };
  }),

  on(TeaDetailsAction.FetchTeaDetailsFailure, (state, props) => {
    return {
      ...state,
      teaDetails: {
        ...initialState.teaDetails,
        success: false,
        loading: false
      }
    };
  }),
  //FetchTeaReviewSummary
  on(TeaDetailsAction.FetchTeaReviewSummaryRequest, (state, props) => {
    return {
      ...state,
      reviewSummary: {
        ...initialState.reviewSummary,
        success: null,
        loading: true
      }
    };
  }),

  on(TeaDetailsAction.FetchTeaReviewSummarySuccess, (state, props: any) => {
    return {
      ...state,
      reviewSummary: {
        ...props.userReviewsSummary,
        success: true,
        loading: false
      }
    };
  }),

  on(TeaDetailsAction.FetchTeaReviewSummaryFail, (state, props) => {
    return {
      ...state,
      reviewSummary: {
        ...initialState.reviewSummary,
        success: false,
        loading: false
      }
    };
  }),
  //Fetch Tea Reviews
  on(TeaDetailsAction.FetchTeaReviewRequest, (state, props) => {
    return {
      ...state,
      userReviews: {
        ...initialState.userReviews,
        success: null,
        loading: true
      },
    };
  }),

  on(TeaDetailsAction.FetchTeaReviewSuccess, (state, props) => {
    return {
      ...state,
      userReviews: {
        ...props.userReviews,
        success: true,
        loading: false
      },
    };
  }),

  on(TeaDetailsAction.FetchTeaReviewFail, (state, props) => {
    return {
      ...state,
      userReviews: {
        ...initialState.userReviews,
        success: false,
        loading: false
      },
    };
  }),

  // NOTIFY USER
  on(TeaDetailsAction.NotifyUserRequest, (state) => state),
  on(TeaDetailsAction.NotifyUserSuccess, (state) => state),
  on(TeaDetailsAction.NotifyUserFailure, (state) => state),

  // Reset Success State
  on(TeaDetailsAction.ResetSuccessState, (state) => ({
    ...state,
    teaDetails: {
      ...state.teaDetails,
      success: initialState.teaDetails.success
    },
    userReviews: {
      ...state.userReviews,
      success: initialState.userReviews.success
    },
    reviewSummary: {
      ...state.reviewSummary,
      success: initialState.reviewSummary.success
    }
  })),

  //clear State
  on(TeaDetailsAction.ClearState, (state) => initialState),


);
