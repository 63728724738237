import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from '../../shared/services/common.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-cart-wrapper',
  templateUrl: './cart-wrapper.component.html',
  styleUrls: ['./cart-wrapper.component.css']
})
export class CartWrapperComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = this.commonService.isAuthenticated();

  isAuthenticatedSubscription: Subscription = this.commonService.triggerAuthenticationStatusCheck.subscribe(() => {
    this.isAuthenticated = this.commonService.isAuthenticated();
  })

  constructor(
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.isAuthenticatedSubscription?.unsubscribe();
  }
}
