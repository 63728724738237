interface SubscriptionsPageScreenTranslation {
  noData: string
  goToShopPage: string,
}

const en: SubscriptionsPageScreenTranslation = {
  noData: `No Subscriptions`,
  goToShopPage: 'Go To Shop Page'
}

const ja: SubscriptionsPageScreenTranslation = {
  noData: `サブスクリプションはありません`,
  goToShopPage: 'ホームに戻る',
}

export const Translations = {
  1: en,
  2: ja
}
