<div
  class="container"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
  fxFlex="100"
  fxLayoutAlign="center stretch"
  fxLayoutAlign.xs="center center"
  fxLayout.xs="column"
>
  <div class="foreground"
       fxFlex="36"
       fxFlex.sm="50"
       fxFlex.xs="100"
       fxLayoutAlign="center center">
    <ng-content></ng-content>
  </div>

  <div
    class="background"
    fxFlex="64"
    fxFlex.sm="50"
    fxFlex.xs="100"
    fxLayoutAlign="center center"
  >
    <div
      class="image-section"
      fxLayoutAlign="end center"
      fxLayoutAlign.xs="center center">
      <img
        alt="tea-cup-background-image"
        class="image"
        src="{{backgroundImageURL}}"
      >
    </div>
  </div>

</div>


