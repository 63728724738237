<div class="address-section" fxLayout="column" fxLayoutGap="2.5rem">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="shipping-address-heading"
  >
    <h1 class="heading">{{ translations.sectionHeading }}</h1>
    <a (click)="openAddDialog()"
       class="mat-icon-link">
      <mat-icon class="medium">add</mat-icon>
    </a>
  </div>
  <app-address-list-loader
    *ngIf="loading; else addressCarousel"></app-address-list-loader>
  <ng-template #addressCarousel>
    <app-carousel
      [arrowSize]="'sm'"
      [slidesData]="addressList"
      fxFlexAlign="center"
      ngClass.xs="xs"
      style="width: 80%"
    >
      <ng-template let-address>
        <app-address-card
          (handleDeleteButtonClick)="openDeleteDialog($event)"
          (handleEditButtonClick)="onEdit($event)"
          (handleMakeDefaultButtonClick)="onMakeDefault($event)"
          [address]="address"
          [defaultLabel]="translations.defaultLabel"
          [makeDefaultButtonLabel]="translations.makeDefaultButtonLabel"
        ></app-address-card>
      </ng-template>
    </app-carousel>
  </ng-template>
</div>
