import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {v1URL} from '../../shared/constants/urls.constants';
import {CommonService} from '../../shared/services/common.service';
import {LoginRequestModel} from "../../models/login-request.model";

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  constructor(private commonServices: CommonService) {
  }

  login(payload: LoginRequestModel): Observable<any> {
    const urlConfig = v1URL.user.login
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  logout(): Observable<any> {
    const urlConfig = v1URL.user.logout
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  resetPasswordRequest(payload): Observable<any> {
    const urlConfig = v1URL.user.password.reset.request
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  createAccount(payload): Observable<any> {
    const urlConfig = v1URL.user.signUp
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  updateAccount(payload): Observable<any> {
    const urlConfig = v1URL.user.profile.update
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchProfile(): Observable<any> {
    const urlConfig = v1URL.user.profile.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }
}
