interface TeaDetailsLeftPanelTranslation {
  addToCart: string,
  loginToAddToCart: string,
  pricingUnavailable: string,
  outOfStock: string
}

const en: TeaDetailsLeftPanelTranslation = {
  addToCart: `ADD TO CART`,
  loginToAddToCart: `Please login to add items to cart.`,
  pricingUnavailable: 'Unavailable',
  outOfStock: 'Out Of Stock'
}

const ja: TeaDetailsLeftPanelTranslation = {
  addToCart: `カートに追加`,
  loginToAddToCart: `カートにアイテムを追加するには、ログインしてください`,
  pricingUnavailable: '利用することができませ',
  outOfStock: '在庫切れ'
}

export const Translations = {
  1: en,
  2: ja
}
