<div
  class="story-main-section"
  fxFlex="0 0 100%"
  fxLayout="column"
  fxLayoutGap="3rem"
  ngClass.xs="xs"
  ngClass.sm="sm"
>
  <div class="story-heading-section" fxFlex="100" fxLayoutAlign="center center">
    {{ StoryInfoTranslation[language].heading }}
  </div>
  <div class="story-image-section" fxLayoutAlign="center center">
    <img *ngIf="teaStory.imageUrl" [alt]="teaStory.imageUrl" [src]="teaStory.imageUrl"/>
  </div>
  <div
    class="story-description-section"
  >{{teaStory?.description}}</div>
</div>
