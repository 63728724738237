interface TeaDetailsBasicInfoTranslation {
  vendor: string,
  location: string,
  startBrew: string,
  quantityDescription:string,
  quantityDescriptionText:string,
}

const en: TeaDetailsBasicInfoTranslation = {
  vendor: `Vendor`,
  location: `Location`,
  startBrew: `Start Brew`,
  quantityDescription:`Volume and Quantity`,
  quantityDescriptionText:`Loose Leaf tea`,
}

const ja: TeaDetailsBasicInfoTranslation = {
  vendor: `販売者`,
  location: `原産国/地域`,
  startBrew: `抽出を開始`,
  quantityDescription:`内容量`,
  quantityDescriptionText:`リーフティー`,
}

export const Translations = {
  1: en,
  2: ja
}
