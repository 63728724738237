import {Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Observable, Subscription} from "rxjs";
import {
  DisableScrolling,
  EnableScrolling,
  FetchCountriesListRequest,
  SetIsMobileSearchOpen,
  SetIsMobileSidenavOpen,
  SetIsMobileView
} from "../../core/shared.actions";
import {select, Store} from "@ngrx/store";
import * as fromRoot from 'src/app/state/app.state';
import {
  disableScrolling,
  isMobileSearchOpen,
  isMobileSidenavOpen,
  isMobileView,
  selectedCountry,
  selectedLanguage
} from "../../core/shared.selectors";
import {CommonService} from "../../services/common.service";
import {LazyLoaderService} from "../../services/lazy-loader.service";
import {Router} from "@angular/router";
import {FetchCartCountRequest} from "../../../cart-details/core/cart.actions";

@Component({
  selector: 'app-global-wrapper',
  templateUrl: './global-wrapper.component.html',
  styleUrls: ['./global-wrapper.component.css']
})
export class GlobalWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('mobileDialogAnchor', {static: true, read: ViewContainerRef}) mobileDialogAnchor: any;

  isMobileView$: Observable<boolean> = this.store.pipe(select(isMobileView));

  isMobileSidenavOpen = false;
  isAuthenticated = false;
  isMobileDialogOpen = false;
  isMobileSearchOpen: boolean = false;
  mobileDialogBackgroundColor = '#D9D9D9';
  selectedCountry: string = '';
  language: number = 1;

  isSearchSectionOpenSubscription: Subscription = this.store.pipe(select(isMobileSearchOpen)).subscribe((data) => {
    this.isMobileSearchOpen = data;

    if (this.isMobileSearchOpen) {
      this.store.dispatch(DisableScrolling());
    } else {
      this.store.dispatch(EnableScrolling());
    }
  });

  breakpointObserverSubscription: Subscription = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .subscribe((result) => {
      if (result.matches) {
        this.store.dispatch(SetIsMobileView({isMobileView: true}));
      } else {
        this.store.dispatch(SetIsMobileView({isMobileView: false}));
        this.store.dispatch(SetIsMobileSearchOpen({isMobileSearchOpen: false}));
        this.store.dispatch(SetIsMobileSidenavOpen({isMobileSidenavOpen: false}));
      }
      this.commonService.closeDialog();
    });

  disableScrollingSubscription: Subscription = this.store.pipe(select(disableScrolling)).subscribe((disableScrolling) => {
    if (disableScrolling) {
      document.getElementsByTagName("body")[0].classList.add('disable-scroll');
    } else {
      document.getElementsByTagName("body")[0].classList.remove('disable-scroll');
    }
  });

  isMobileSidenavOpenSubscription: Subscription = this.store.pipe(select(isMobileSidenavOpen)).subscribe((data) => {
    this.isMobileSidenavOpen = data;
    this.isAuthenticated = this.commonService.isAuthenticated();

    if (this.isMobileSidenavOpen) {
      this.store.dispatch(DisableScrolling());
    } else {
      this.store.dispatch(EnableScrolling());
    }
  });


  openMobileDialogSubscription: Subscription = this.commonService.openMobileDialog.subscribe((params) => {
    // Close Any existing dialog
    this.mobileDialogAnchor.clear();

    // Open required dialog
    this.isMobileDialogOpen = true;
    const {component, backgroundColor, ...data} = params.data;

    if (backgroundColor) {
      this.mobileDialogBackgroundColor = backgroundColor;
    } else {
      this.mobileDialogBackgroundColor = '#D9D9D9';
    }

    const componentRef = this.mobileDialogAnchor.createComponent(component);
    componentRef.instance.data = data;
    this.store.dispatch(DisableScrolling());
  });

  closeMobileDialogSubscription: Subscription = this.commonService.closeMobileDialog.subscribe(() => {
    this.mobileDialogAnchor.clear();
    this.store.dispatch(EnableScrolling());
    this.isMobileDialogOpen = false;
  });

  selectedCountrySubscription: Subscription = this.store.pipe(select(selectedCountry)).subscribe((data) => {
    if (!this.selectedCountry) {
      this.selectedCountry = data;
    } else if (this.selectedCountry !== data) {
      this.selectedCountry = data;
      this.router.navigate([]).then(() => {
        if (this.commonService.isAuthenticated()) {
          this.store.dispatch(FetchCartCountRequest({}));
        }
        this.store.dispatch(FetchCountriesListRequest());
      });
    }

  });

  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(data => {
    this.language = data;
    const body = document.getElementsByTagName('body')[0];
    const langMap = {
      1: 'en',
      2: 'ja'
    }

    for (let i = 0; i < body.classList?.length; i++) {
      if (body.classList[i]?.includes("lang-")) {
        body.classList.remove(body.classList[i])
        break;
      }
    }

    body.classList.add(`lang-${langMap[this.language]}`);
  });

  constructor(
    private store: Store<fromRoot.State>,
    private breakpointObserver: BreakpointObserver,
    public commonService: CommonService,
    private lazyLoaderService: LazyLoaderService,
    private router: Router
  ) {
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    // );
    //
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/cart-details/cart-details.module').then(m => m.CartDetailsModule)
    // );
    //
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/package-details/package-details.module').then(m => m.PackageDetailsModule)
    // );
    //
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/tea-details/tea-details.module').then(m => m.TeaDetailsModule)
    // );
    //
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/user-details/user-details.module').then(m => m.UserDetailsModule)
    // );
    //
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/delete-account/delete-account.module').then(m => m.DeleteAccountModule)
    // );
    //
    // this.lazyLoaderService.loadModule(
    //   () => import('src/app/modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    // );


    this.store.dispatch(FetchCountriesListRequest());
  }

  ngOnInit(): void {

  }

  toggleSearchSection() {
    this.store.dispatch(SetIsMobileSearchOpen({isMobileSearchOpen: !this.isMobileSearchOpen}))
  }

  ngOnDestroy() {
    this.isSearchSectionOpenSubscription?.unsubscribe();
    this.breakpointObserverSubscription?.unsubscribe();
    this.isMobileSidenavOpenSubscription?.unsubscribe();
    this.openMobileDialogSubscription?.unsubscribe();
    this.closeMobileDialogSubscription?.unsubscribe();
    this.disableScrollingSubscription?.unsubscribe();
    this.selectedCountrySubscription?.unsubscribe();
  }
}
