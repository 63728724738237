import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-subscription-card-loader',
  templateUrl: './subscription-card-loader.component.html',
  styleUrls: ['./subscription-card-loader.component.css']
})
export class SubscriptionCardLoaderComponent implements OnInit {
  @Input() translations: any;
  constructor() { }

  ngOnInit(): void {
  }

}
