interface SubscriptionCardTranslation {
  subscriptionsCodeLabel: string;
  subscriptionLabel: string;
  totalAmount: string;
  subscriptionsDetailsLabel: string;
}

const en: SubscriptionCardTranslation = {
  subscriptionsCodeLabel: `Subscription #`,
  subscriptionLabel: `Subscription`,
  totalAmount: `Total`,
  subscriptionsDetailsLabel: ` SUBSCRIPTION DETAILS`,
};

const ja: SubscriptionCardTranslation = {
  subscriptionsCodeLabel: `サブスクリプション番号`,
  subscriptionLabel: `サブスクリプション`,
  totalAmount: `注文総額`,
  subscriptionsDetailsLabel: `注文詳細`,
};

export const Translations = {
  1: en,
  2: ja
};
