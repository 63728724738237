import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-account-form-loader',
  templateUrl: './delete-account-form-loader.component.html',
  styleUrls: ['./delete-account-form-loader.component.css']
})
export class DeleteAccountFormLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
