import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription, tap} from 'rxjs';
import {selectedLanguage,} from 'src/app/modules/shared/core/shared.selectors';
import {
  Translations as StoryInfoTranslation
} from '../../../translations/tea-details-page/tea-details-story-info.translation';
import {storyInfo} from '../../core/tea-details.selectors';

@Component({
  selector: 'app-tea-story',
  templateUrl: './tea-story.component.html',
  styleUrls: ['./tea-story.component.css'],
})
export class TeaStoryComponent implements OnInit, OnDestroy {
  language: number = 1;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });
  teaStory;
  teaStorySubscription: Subscription = this.store
    .select(storyInfo)
    .pipe(
      tap((tea) => {
        this.teaStory = JSON.parse(JSON.stringify(tea));
        if (this.teaStory.description) {
          this.teaStory.description = this.teaStory.description.replaceAll('\\n', '\n').replaceAll('\\"', '"');
        }
      })
    )
    .subscribe();
  StoryInfoTranslation: any = StoryInfoTranslation;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.teaStorySubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
  }
}
