
  <div
    class="container"
    fxLayout="column"
    ngClass.xs="xs"
  >
    <div class="heading">
      {{translations.heading}}
    </div>
    <form
      [formGroup]="paymentOptionForm"
      fxLayout="column"
      fxLayoutAlign="space-around none"
    >
      <div
        class="form-fields"
        fxLayout="column"
      >
        <app-text-input
          id="card_holder_name"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['cardHolderName']"
          [label]="translations.nameLabel"
          [parentFormGroup]="paymentOptionForm"
          class="input-control"
          controlName="cardHolderName"
          maxLength="20"
          [isSlim]="true"
        ></app-text-input>
        <div
          class="input-control payment-gateway-input-control"
        >
          <div
            class="input-box-label"
          >
            <span class="star">*</span> {{translations.numberLabel}}
          </div>
          <div
            class="stripe-control"
            fxLayoutAlign="none center"
            id="card-number"
          >
          </div>
          <div class="stripe-element-error" *ngIf="cardNumberError">
            {{ cardNumberError }}
          </div>
        </div>

        <div
          fxLayout.lg="row"
          fxLayout.md="row"
          fxLayout.sm="row"
          fxLayout.xs="column"
          fxLayoutGap.gt-sm="1.5rem"
        >
          <div
            class="input-control payment-gateway-input-control"
            fxFlex="50"
            fxFlex.xs="100"
          >
            <div
              class="input-box-label"
            >
              <span class="star">*</span> {{translations.expiryLabel}}
            </div>
            <div
              class="stripe-control"
              fxLayoutAlign="none center"
              id="card-expiry"
            >
            </div>
            <div class="stripe-element-error" *ngIf="cardExpiryError">
              {{ cardExpiryError }}
            </div>
          </div>
          <div
            class="input-control payment-gateway-input-control"
            fxFlex="50"
            fxFlex.xs="100"
          >
            <div
              class="input-box-label"
            >
              <span class="star">*</span>{{translations.cvcLabel}}
            </div>
            <div
              class="stripe-control"
              fxLayoutAlign="none center"
              id="card-cvv"
            >
            </div>
            <div class="stripe-element-error" *ngIf="cardCvcError">
              {{ cardCvcError }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-actions">
        <app-primary-button
          id="add_payment"
          (handleClick)="addPaymentOption()"
          [isLoading]="loading"
          [text]="paymentOptionDetails ? translations.updateButton : translations.addButton"
          class="save-button"
          [isSlim]="true"
        >
        </app-primary-button>
      </div>
    </form>
  </div>


