<div
  class="container"
>
  <div class="location">
    {{address.location}}
  </div>
  <div class="secondary-text address-lines">
    <div
      *ngIf="address?.recipientName"
      class="shipping-name"
    >
      {{ address?.recipientName }}
    </div>
    <div class="address-lines">
      {{ address?.addressLine1}}, {{ address?.addressLine2}}.
    </div>
    <div class="city-state">
      {{ address?.city}}, {{ address?.state}}.
    </div>
    <div class="country-zip">
      {{ address?.countryName}} - {{ address?.zip}}
    </div>
  </div>
</div>
