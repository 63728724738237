import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {v1URL} from "../../shared/constants/urls.constants";
import {CommonService} from "../../shared/services/common.service";

@Injectable({
  providedIn: 'root'
})
export class DeleteAccountService {

  deleteAccountSuccess: Subject<any> = new Subject();

  constructor(private commonService: CommonService) {
  }

  deleteAccount(): Observable<any> {
    const urlConfig = v1URL.user.delete
    return this.commonService.callAPI(urlConfig.method, urlConfig.url, {});
  }
}
