<div
  class="container"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
  fxLayout="column"
  fxLayoutAlign="space-around none"
>
  <div class="heading">{{translations[language].heading}}</div>
  <div
    *ngIf="!addressList.length"
    class="no-data"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div>{{translations[language].noData}}</div>
    <app-primary-clickable-text
      (handleClick)="addAddress()"
      [text]="translations[language].addAddress"
    ></app-primary-clickable-text>
  </div>
  <div
    *ngIf="addressList.length"
    fxLayout="row wrap"
    fxLayoutAlign.lt-lg="column"
    fxLayoutAlign="center center"
    fxLayout.lt-lg="column center"
  >
    <app-shipping-address-selection-card
      (click)="selectAddress(eachAddress['_id'])"
      *ngFor="let eachAddress of addressList"
      [address]="eachAddress"
      [class.selected]="selectedAddressId === eachAddress['_id']"
      class="shipping-address-card"
      [fxFlex]="addressList?.length === 1 ? 50 : 25 "
      fxFlex.lt-lg="75"
    ></app-shipping-address-selection-card>
  </div>
  <div
    class="action-cta"
    fxLayoutAlign="center center"
  >
    <app-primary-button
      id="confirm"
      (handleClick)="setShippingAddress()"
      [text]="translations[language].confirmButton"
      fxFlex="45rem"
      fxFlex.lt-md="40rem"
    ></app-primary-button>
  </div>
</div>

