export const email = {
  regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$',
  length: {
    max: 100
  }
};

export const password = {
  regex: new RegExp('^(?=.*[A-Z])(?=.*[!@#$%^&*()\\\\\/\\[\\]\\-_=+{}|?>.<,:;~`\'])(?=.*[0-9])(?=.*[a-z]).{8,20}$'),
  length: {
    min: 8,
    max: 20
  }
}

export const user = {
  fullName: {
    length: {
      min: 1,
      max: 25
    }
  },
  age: {
    length: {
      max: 2
    },
    min: 13,
    max: 99
  },
}

export const address = {
  location: {
    length: {
      min: 1,
      max: 35
    }
  },
  recipientName: {
    length: {
      min: 1,
      max: 20
    }
  },
  addressLine1: {
    length: {
      min: 1,
      max: 35
    }
  },
  addressLine2: {
    length: {
      min: 1,
      max: 35
    }
  },
  zipCode: {
    length: {
      min: 5,
      max: 10
    }
  },
  city: {
    length: {
      min: 1,
      max: 20
    }
  },
  state: {
    length: {
      min: 1,
      max: 20
    }
  },
};

export const phoneNumber = {
  length: {
    min: 8,
    max: 18
  }
};

