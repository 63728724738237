import * as DeleteAccountActions from './delete-account.actions';
import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, map, mergeMap, Observable, of, tap} from "rxjs";
import {Action, Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {CommonService} from "../../shared/services/common.service";
import {DeleteAccountService} from "./delete-account.service";

@Injectable({
  providedIn: 'root'
})
export class DeleteAccountEffects {

  deleteAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAccountActions.DeleteAccountRequest),
      map((action) => {
        return action;
      }),
      mergeMap((action) =>
        this.deleteAccountService.deleteAccount().pipe(
          map((response: any) => {
            const {data, message} = response;
            return DeleteAccountActions.DeleteAccountSuccess({message});
          }),
          catchError((error) => {
            return of(DeleteAccountActions.DeleteAccountFailure());
          }),
          tap((action) => {
            if (action.type === DeleteAccountActions.DeleteAccountSuccess.type) {
              this.commonService.closeDialog();
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
              this.route.navigate(
                ['/delete-account'],
                {queryParams: {accountDeleted: 1}}
              )
            } else if (action.type === DeleteAccountActions.DeleteAccountFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  constructor(private route: Router,
              private commonService: CommonService,
              private store: Store,
              private actions$: Actions,
              private deleteAccountService: DeleteAccountService) {
  }
}
