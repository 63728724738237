import * as AuthenticationActions from './authentication.actions';
import {createReducer, on} from '@ngrx/store';
import {ProfileModel} from "../../models/profile.model";

// State for this feature (User)
export interface AuthenticationState {
  profileData: {
    loading: boolean,
    profile: ProfileModel
  };
  logout: {
    loading: boolean
  };
  updateAccount: {
    loading: boolean
  };
  isForgotPasswordSuccessful: {
    isForgotPasswordSuccessful: boolean,
    emailId: string
  }
}

const initialState: AuthenticationState = {
  profileData: {
    loading: false,
    profile: {
      role: null,
      image: null,
      _id: null,
      fullName: null,
      email: null,
      gender: null,
      age: null,
      unitSystem: 1,
      notifyUser: null
    },
  },
  logout: {
    loading: false
  },
  updateAccount: {
    loading: false
  },
  isForgotPasswordSuccessful: {
    isForgotPasswordSuccessful: false,
    emailId: ''
  }
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(AuthenticationActions.ClearState, (state) => initialState),

  // LOGIN
  on(AuthenticationActions.LoginRequest, (state) => ({
    ...state,
    profileData: {
      ...state.profileData,
      loading: true
    }
  })),
  on(AuthenticationActions.LoginSuccess, (state, props) => ({
    ...state,
    profileData: {
      loading: false,
      profile: props.profile
    }
  })),
  on(AuthenticationActions.LoginFailure, (state) => ({
    ...state,
    profileData: initialState.profileData
  })),

  // CREATE ACCOUNT
  on(AuthenticationActions.CreateAccountRequest, (state) => ({
    ...state,
    profileData: {
      ...state.profileData,
      loading: true
    }
  })),
  on(AuthenticationActions.CreateAccountSuccess, (state, props) => ({
    ...state,
    profileData: {
      loading: false,
      profile: props.profile
    }
  })),
  on(AuthenticationActions.CreateAccountFailure, (state) => ({
    ...state,
    profileData: initialState.profileData
  })),

  // LOGOUT
  on(AuthenticationActions.LogoutRequest, (state) => ({
    ...state,
    logout: {
      loading: true
    }
  })),
  on(AuthenticationActions.LogoutSuccess, (state, props) => ({
    ...state,
    logout: {
      loading: false
    }
  })),
  on(AuthenticationActions.LogoutFailure, (state) => ({
    ...state,
    logout: {
      loading: false
    }
  })),

  // FETCH PROFILE
  on(AuthenticationActions.FetchProfileRequest, (state) => ({
    ...state,
    profileData: {
      loading: true,
      profile: initialState.profileData.profile
    }
  })),
  on(AuthenticationActions.FetchProfileSuccess, (state, props) => ({
    ...state,
    profileData: {
      loading: false,
      profile: props.profile
    }
  })),
  on(AuthenticationActions.FetchProfileFailure, (state) => ({
    ...state,
    profileData: {
      ...state.profileData,
      loading: false
    }
  })),

  // UPDATE PROFILE
  on(AuthenticationActions.UpdateAccountRequest, (state) => ({
    ...state,
    profileData: {
      ...state.profileData,
      loading: true
    },
    updateAccount: {
      loading: true
    }
  })),
  on(AuthenticationActions.UpdateAccountSuccess, (state, props) => ({
    ...state,
    profileData: {
      loading: false,
      profile: props.profile
    },
    updateAccount: {
      loading: false
    }
  })),
  on(AuthenticationActions.UpdateAccountFailure, (state) => ({
    ...state,
    profileData: {
      ...state.profileData,
      loading: false
    },
    updateAccount: {
      loading: false
    }
  })),

  // Set profile email
  on(AuthenticationActions.SetProfileEmail, (state, props) => ({
    ...state,
    profileData: {
      loading: false,
      profile: {
        ...state.profileData.profile,
        email: props.email
      }
    }
  })),

// Set Forgot Password Status
  on(AuthenticationActions.SetForgotPasswordStatus, (state, props) => ({
    ...state,
    isForgotPasswordSuccessful: {
      isForgotPasswordSuccessful: props.isForgotPasswordSuccessful,
      emailId: props.emailId
    }
  }))
);
