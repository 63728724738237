<app-half-content-half-image>
  <div
    class="delete-account-form-container"
    ngClass.xs="xs">
    <div
      *ngIf="!isAccountDeleted"
      class="delete-account-form"
    >
      <app-delete-account-form></app-delete-account-form>
    </div>
    <div
      *ngIf="isAccountDeleted"
      class="delete-account-success"
      fxLayout="column"
      fxLayoutAlign.xs="center center"
    >
      <div class="message">
        {{ translations[language].successHeading }}
      </div>
    </div>
  </div>
</app-half-content-half-image>



