<div *ngIf="cartData?.loading && cartData?.success === null; else showData"></div>
<ng-template #showData>
  <div
    class="cart-page-container"
    fxLayout="column"
    ngClass.lt-sm="xs"
    ngClass.sm="sm"
    ngClass.md="md"
    *ngIf="cartData.success !== null && cartData?.items?.length; else noData"
  >
    <div
      fxFlex="100"
      fxLayout="row"
      fxLayout.lt-md="column"
    >
      <div
        class="left"
        fxFlex="75"
        fxFlex.lt-md="100"
      >
        <div
          class="heading"
          fxLayoutAlign.lt-md="center center"
        >
          {{translations[language].left.heading}}
        </div>
        <div *ngIf="cartData?.items?.length">
          <div class="section items-list-section">
            <app-authenticated-cart-editable-row
              *ngFor="let eachCartItem of cartData?.items"
              [currencySymbol]="cartData.currencySymbol"
              [data]="eachCartItem"
            ></app-authenticated-cart-editable-row>
          </div>

          <div *ngIf="cartData.isDiscountApplied"
               class="coupons-section">
            <div class="label discount-coupon-label">
              {{translations[language].left.discountCoupon}}
            </div>
            <div class="coupon-details">
              <div class="coupon-code">
                {{cartData?.discountCouponDetails?.couponCode}}
              </div>
            </div>
          </div>
          <div
            class="section totals-section"
          >
            <div class="total">
        <span
          class="cart-total-label"
          fxFlex="50"
        >
          {{translations[language].left.total}}
        </span>
              <span
                class="cart-total-value"
                fxFlex="50"
              >
          <span
            [class.strike-text]="cartData.isDiscountApplied"
            class="actual-total"
          >
            {{cartData.total | currency: cartData.currencySymbol:cartData.currencySymbol:'1.0'}}
          </span>
          <span
            *ngIf="cartData.isDiscountApplied"
            class="discounted-value discounted-total"
          >
            {{cartData.afterDiscount.total | currency: cartData.currencySymbol:cartData.currencySymbol:'1.0'}}
          </span>
        </span>
            </div>
          </div>

        </div>
      </div>
      <div
        class="right"
        fxFlex="25"
        fxFlex.lt-md="100"
      >
        <div
          class="heading"
          fxLayoutAlign.lt-md="center center"
        >
          {{translations[language].right.heading}}
        </div>
        <div
          *ngIf="!cartData?.items?.length"
          class="purchase-disabled no-data"
          fxLayoutAlign="center center"
        >
          {{translations[language].right.purchaseDisabled}}
        </div>
        <div
          *ngIf="cartData?.items?.length"
        >
          <div class="section name-section">
            <div class="label name-label">
              {{translations[language].right.name}}
            </div>
            <div class="value name-value">
              {{profileData?.fullName}}
            </div>
          </div>
          <div class="section email-section">
            <div class="label email-label">
              {{translations[language].right.emailAddress}}
            </div>
            <div class="value email-value">
              {{profileData?.email}}
            </div>
          </div>
          <div class="section shipping-address-section">
            <div class="label shipping-address-label">
              {{translations[language].right.shippingAddress}}
            </div>
            <div
              *ngIf="addressList?.length  && !(cartData?.address | keyvalue)?.length"
              fxLayoutAlign="none center"
            >
              <app-primary-clickable-text
                (handleClick)="openAddressSelectionDialog()"
                [text]="translations[language].right.selectAddress"
                class="button"
              ></app-primary-clickable-text>
            </div>
            <div
              *ngIf="!addressList?.length && !(cartData?.address | keyvalue)?.length"
              fxLayoutAlign="none center"
            >
              <app-primary-clickable-text
                (handleClick)="addAddress()"
                [text]="translations[language].right.addAddress"
                class="button"
              ></app-primary-clickable-text>
            </div>
            <div
              *ngIf="(cartData?.address | keyvalue)?.length"
              class="value shipping-address-value"
            >
              <div class="location">
                {{cartData?.address?.location}}
              </div>
              <div class="secondary-text">
                <div
                  *ngIf="cartData?.address?.recipientName"
                  class="shipping-name"
                >
                  {{ cartData?.address?.recipientName}}
                </div>
                <div class="address-lines">
                  {{ cartData?.address?.addressLine1}}, {{ cartData?.address?.addressLine2}}.
                </div>
                <div class="city-state">
                  {{ cartData?.address?.city}}, {{ cartData?.address?.state}}.
                </div>
                <div class="country-zip">
                  {{ cartData?.address?.countryName}} - {{ cartData?.address?.zip}}
                </div>
              </div>
              <div class="change-button">
                <app-primary-clickable-text
                  (handleClick)="openAddressSelectionDialog(cartData?.address?._id)"
                  [text]="translations[language].right.changeButton"
                  class="button"
                ></app-primary-clickable-text>
              </div>
            </div>
          </div>
          <div class="section payment-details-section">
            <div class="label payment-details-label">
              {{translations[language].right.paymentDetails}}
            </div>
            <div
              *ngIf="cardsList?.length  && !(cartData?.paymentOption | keyvalue)?.length"
              fxLayoutAlign="none center"
            >
              <app-primary-clickable-text
                (handleClick)="openCardSelectionDialog()"
                [text]="translations[language].right.selectCard"
                class="button"
              ></app-primary-clickable-text>
            </div>
            <div
              *ngIf="!cardsList?.length"
              fxLayoutAlign="none center"
            >
              <app-primary-clickable-text
                (handleClick)="addCard()"
                [text]="translations[language].right.addCard"
                class="button"
              ></app-primary-clickable-text>
            </div>
            <div
              *ngIf="(cartData?.paymentOption | keyvalue)?.length"
              class="card-details"
            >
              <div class="paymentOption-holder-name">
                {{cartData?.paymentOption?.details?.name}}
              </div>
              <div class="card">
                <div class="secondary-text card-number">
                  XXXX-XXXX-XXXX-{{cartData?.paymentOption?.details?.last4Digits}}
                </div>
                <div *ngIf="!cartData?.paymentOption?.isValid" class="expired">
                  | <span class="expiredLabel"> {{ translations[language].right.expiredLabel }} </span>
                </div>
              </div>
              <div class="change-button">
                <app-primary-clickable-text
                  (handleClick)="openCardSelectionDialog(cartData?.paymentOption?._id)"
                  [text]="translations[language].right.changeButton"
                  class="button"
                ></app-primary-clickable-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-delivery-policy"
         *ngIf="cartData?.items?.length">
      <span [innerHtml]="translations[language].right.productDeliveryPolicyPart1 "></span>
      <span
        (click)="openDocument()"
        class="textbutton"
      >
          {{translations[language].right.productDeliveryPolicyPart2}}
          </span>
      {{translations[language].right.productDeliveryPolicyPart3}}
    </div>
    <div class="section"
         fxLayoutAlign="center center">
      <app-secondary-button
        id="placeorder"
        [isDisabled]="!cartData?.items?.length"
        (handleClick)="placeOrder()"
        [text]="translations[language].right.placeOrder"
        class="button"
        fxFlex="48rem"
        fxFlex.lt-md="40rem"
      ></app-secondary-button>
    </div>
  </div>
</ng-template>
<ng-template #noData>
  <div
    fxLayoutAlign="center center"
    fxLayout="column"
    ngClass.xs="xs"
  >
    <div
      class="empty-cart-heading"
    >
      {{translations[language].left.heading}}
    </div>
    <div class="empty-cart-text">
      {{translations[language].noData}}
    </div>
    <div class="back-to-home-btn">
      <app-secondary-button
        id="back_to_home"
        (handleClick)="backToHome()"
        fxFlex="48rem"
        fxFlex.xs="42rem"
        text="{{translations[language].goToCatalogPage}}"
      >
      </app-secondary-button>
    </div>
  </div>
</ng-template>

