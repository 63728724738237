import { Injectable,EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { v1URL } from '../../shared/constants/urls.constants';
import { CommonService } from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class TeaDetailsService {
  userReviewsCurrentPage:BehaviorSubject<number>=new BehaviorSubject(1)
  UnauthenticatedBrewNotificationInfo = new EventEmitter();
  notify = new EventEmitter();

  fetchProductDetails(payload): Observable<any> {
    const urlConfig = v1URL.product.fetch;
    return this.commonServices.callAPI(
      urlConfig.method,
      urlConfig.url,
      payload
    );
  }

  fetchProductReviewsSummary(payload): Observable<any> {
    const urlConfig = v1URL.productReview;
    return this.commonServices.callAPI(
      urlConfig.method,
      urlConfig.url,
      payload
    );
  }

  setNextUserReviewPage() {
    const currentPage: number = this.userReviewsCurrentPage.value
    this.userReviewsCurrentPage.next(currentPage + 1)
  }

  notifyUser(payload): Observable<any> {
    const urlConfig = v1URL.brew.coldCarafe.notify
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  constructor(private commonServices: CommonService) {}
}
