<div class="container">
  <div
    class="language-selector-section sidenav-item"
  >
    <div
      [matMenuTriggerFor]="menu"
      class="language-selector"
      fxLayoutAlign="start center"
    >
      {{selectedLanguageText}}
    </div>
    <mat-menu #menu="matMenu" class="header-language-list" yPosition="below">
      <button
        (click)="setSelectedLanguage(eachLanguage.key)"
        *ngFor="let eachLanguage of languagesList"
        [class.selected]="eachLanguage.key == language"
        class="header-language-item"
        mat-menu-item
      >
        {{eachLanguage.value}}
      </button>
    </mat-menu>
  </div>
  <ng-container
    *ngFor="let eachOption of options"
  >
    <div
      (click)="eachOption.handleClick()"
      *ngIf="eachOption.isVisible"
      fxLayoutAlign="start center"
      class="sidenav-item">
      {{translations[language][eachOption.key]}}
    </div>
  </ng-container>
</div>
