export interface MobileSidenavTranslations {
  selections: string;
  cart: string;
  accountInformation: string;
  myOrders: string;
  mySubscriptions: string;
  teploTeapot: string;
  teploCarafe: string;
  teploForBusiness: string;
  blog: string;
  support: string;
  logout: string;
  login: string;
}

const en: MobileSidenavTranslations = {
  selections: 'Selections',
  cart: 'Cart',
  accountInformation: 'Account Information',
  myOrders: 'My Orders',
  mySubscriptions: 'My Subscriptions',
  teploTeapot: 'Teplo Teapot',
  teploCarafe: 'Teplo Carafe',
  teploForBusiness: 'Teplo for business',
  blog: 'Blog',
  support: 'Support',
  logout: 'Logout',
  login: 'SIGNUP / LOGIN',
};

const ja: MobileSidenavTranslations = {
  selections: `オンラインショップ`,
  cart: `カート`,
  accountInformation: `アカウント情報`,
  myOrders: `注文履歴`,
  mySubscriptions: `サブスクリプション`,
  teploTeapot: 'teploティーポット',
  teploCarafe: 'teplo水出しカラフェ',
  teploForBusiness: '法人サービス',
  blog: 'ブログ',
  support: 'サポート',
  logout: `ログアウト`,
  login: `ログイン/新規登録`,
};

export const Translations = {
  1: en,
  2: ja
};
