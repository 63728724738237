<div
  class="container confirm-dialog"
  fxLayoutAlign="space-between none"
  fxLayout="column"
>

  <div class="heading">
    {{translations.heading}}
  </div>
  <div
    class="action"
    fxLayout="column"
  >
    <div>
      <app-primary-button
        id="yes"
        fxFlex="100"
        (handleClick)="onConfirm()"
        [isLoading]="loading"
        class="confirm-button"
        [isSlim]="true"
        text="{{translations.confirmButtonLabel}}"
      >
      </app-primary-button>
    </div>
    <div>
      <app-primary-clickable-text
        id="cancel"
        fxFlex="100"
        (handleClick)="onCancel()"
        [text]="translations.cancelButtonLabel"
      >
      </app-primary-clickable-text>
    </div>
  </div>
</div>

