<div class="pairing-main-div" fxLayout="column" fxLayoutGap="4rem">
  <div class="pairing-heading-section">
    {{ translations[language].heading }}
  </div>
  <div
    class="pairing-heading-content"
    fxLayout="row wrap"
    fxLayoutGap="5rem"
  >
    <div
      *ngFor="let pairing of pairing"
      fxLayout="column"
      fxLayoutAlign="start start"
    >
      <div
        class="pairing-image-section"
        [class.mobile-view]="isMobileView"
        fxLayoutAlign="center center"
      >
        <img
          [class.mobile-view]="isMobileView"
          [src]="pairing.cdnImageUrl"
          alt=""
          class="pairing-image"/>
      </div>
      <div class="pairing-name-section">
        {{ pairing?.displayName | titlecase}}
      </div>
    </div>
  </div>
  <!------------------------------------------------------>
  <!--  Selection functionality is disabled temporarily -->
  <!------------------------------------------------------>
  <!--  <div-->
  <!--    class="pairing-heading-content"-->
  <!--    fxLayout="row wrap"-->
  <!--    fxLayoutGap="5rem"-->
  <!--  >-->
  <!--    <div-->
  <!--      *ngFor="let pairing of pairing"-->
  <!--      fxLayout="column"-->
  <!--      fxLayoutAlign="start start"-->
  <!--    >-->
  <!--      <div-->
  <!--        [class.active]="getSelectedRows(pairing)"-->
  <!--        class="pairing-image-section"-->
  <!--        [class.mobile-view]="isMobileView"-->
  <!--        fxLayoutAlign="center center"-->
  <!--      >-->
  <!--        <img-->
  <!--          (click)="onChangeSelectedParing(pairing)"-->
  <!--          [class.mobile-view]="isMobileView"-->
  <!--          [src]="pairing.imageUrl"-->
  <!--          alt=""-->
  <!--          class="pairing-image"/>-->
  <!--      </div>-->
  <!--      <div class="pairing-name-section">-->
  <!--        {{ pairing.displayName}}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!----------------------------------------------->
  <!--  Pairing details are disabled temporarily -->
  <!----------------------------------------------->
  <!--  <div class="paring-details">-->
  <!--    {{selectedPairing ? selectedPairing?.description : pairing[0]?.description}}-->
  <!--  </div>-->
</div>
