import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../../shared/services/common.service";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {v4 as uuidv4} from "uuid";
import {Translations} from "../../../translations/authentication/forms/login-form.translation";
import {LoginRequest, SetForgotPasswordStatus} from "../../core/authentication.actions";
import {ClientDeviceModel} from "../../../models/login-request.model";
import {ForgotPasswordFormComponent} from "../forgot-password-form/forgot-password-form.component";
import {SignupFormComponent} from "../signup-form/signup-form.component";
import * as Validations from "../../../shared/constants/validation.constants";
import * as CartActions from "../../../cart-details/core/cart.actions";
import * as $ from "jquery";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  @Input() data;

  guestPurchase: boolean = false;
  language: number = 1;
  uuid: string = uuidv4();
  validations = Validations;
  translations: any = Translations;
  errorMessages: any = {};
  errorMessageMap: any = {};
  os: string = this.commonService.OSName();
  model: string = this.commonService.browserDetails().browserName;
  version: string = this.commonService.browserDetails().browserVersion;
  clientDevice: ClientDeviceModel = {
    uuid: this.uuid,
    os: this.os,
    version: this.version,
    model: this.model,
    appVersion: '0.0.0'
  };

  form: FormGroup = this.fb.group({
    email: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.pattern(this.validations.email.regex)
        ]
      }
    ),
    password: new FormControl('', {
      validators: [
        Validators.required
      ]
    }),
  });

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
    this.errorMessageMap = this.translations[this.language].errorMessageMap;
  });


  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.guestPurchase = this.data.guestPurchase;
  }

  login(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const email: string = this.form.getRawValue().email;
    const password: string = this.form.getRawValue().password;

    const payload = {
      email,
      password,
      clientDevice: this.clientDevice
    };

    this.store.dispatch(LoginRequest({payload}));
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  openCreateTeploAccountDialog() {
    this.commonService.closeDialog();
    this.commonService.openDialog({data: {component: SignupFormComponent}});
  }

  enableGuestPurchaseDetailsForm() {
    this.store.dispatch(CartActions.EnableGuestPurchaseForm({enableGuestPurchaseForm: true}));
    this.commonService.closeDialog();
    const anchorElement = $('#client_area')
    anchorElement.animate({scrollTop: anchorElement.offset()?.top - 100}, 500);
  }

  openForgotPasswordDialog() {
    this.commonService.closeDialog();
    const isForgotPasswordSuccessful = {
      isForgotPasswordSuccessful: false,
      emailId: ''
    }
    this.store.dispatch(SetForgotPasswordStatus(isForgotPasswordSuccessful));
    this.commonService.openDialog({data: {component: ForgotPasswordFormComponent}});
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }
}
