interface PackageDetailsScreenTranslation {
  noData: string;
  vendor: string;
  contactUsButton: string;
  contactUsText: string;
  addToCart: string;
  loginToAddToCart: string;
  pricingUnavailable: string;
  outOfStock: string;
  errorMessage: string
}

const en: PackageDetailsScreenTranslation = {
  noData: `Information for selected package is not available currently`,
  vendor: `Vendor`,
  contactUsButton: `CONTACT US`,
  contactUsText: `* If you have any questions about the order contact us at order.japan@load-road.com`,
  addToCart: `ADD TO CART`,
  loginToAddToCart: `Please login to add items to cart.`,
  pricingUnavailable: 'Unavailable',
  outOfStock: 'Out Of Stock',
  errorMessage: 'Please login or Signup if you like to subscribe for our teas-packages'
};

const ja: PackageDetailsScreenTranslation = {
  noData: `選択された商品の詳細情報は現在準備中です`,
  vendor: `ブランド`,
  contactUsButton: `お問い合わせ`,
  contactUsText: `* お問い合わせはorder.japan@load-road.comまでお気軽にご連絡ください`,
  addToCart: `カートに追加`,
  loginToAddToCart: `カートにアイテムを追加するには、ログインしてください`,
  pricingUnavailable: '利用することができませ',
  outOfStock: '在庫切れ',
  errorMessage: 'お茶のパッケージを購読したい場合は、ログインまたはサインアップしてください'
};

export const Translations = {
  1: en,
  2: ja,
};
