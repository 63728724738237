import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-subscription-list-loader',
  templateUrl: './subscription-list-loader.component.html',
  styleUrls: ['./subscription-list-loader.component.css']
})
export class SubscriptionListLoaderComponent implements OnInit {
  @Input() translations: any;
  itemList: number[] = [1, 2, 3, 4, 5, 6, 7, 8];

  constructor() { }

  ngOnInit(): void {
  }

}
