<div
  class="container"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div class="heading">{{translations[language].heading}}</div>
  <div
    *ngIf="!cardsList.length"
    class="no-data"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div>{{translations[language].noData}}</div>
    <app-primary-clickable-text
      (handleClick)="addCard()"
      [text]="translations[language].addAddress"
    ></app-primary-clickable-text>
  </div>
  <div
    *ngIf="cardsList.length"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxLayout.lt-lg="column center"
  >
    <div
      (click)="selectCard(eachCard['_id'])"
      *ngFor="let eachCard of cardsList"
      [class.selected]="selectedCardId === eachCard['_id']"
      class="card"
      fxFlex="25"
      fxFlex.lt-lg="25"
      fxFlexAlign.lt-lg="center"
    >
      <div class="card-holder-name" fxLayoutAlign="start">{{eachCard?.details?.name}}</div>
      <div
        fxLayout="row"
        fxLayoutGap="1rem"
      >
        <div class="secondary-text card-number">XXXX-XXXX-XXXX-{{eachCard?.details?.last4Digits}}</div>
        <div *ngIf="!eachCard?.isValid" class="expiredLabel"> {{ translations[language].expiredLabel }} </div>
      </div>
    </div>
  </div>
  <div
    fxLayoutAlign="center center"
  >
    <app-primary-button
      id="confirm"
      (handleClick)="setCard()"
      [text]="translations[language].confirmButton"
      fxFlex="45rem"
      fxFlex.lt-md="40rem"
    ></app-primary-button>
  </div>
</div>

