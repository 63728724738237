<div
  class="container"
  fxLayoutAlign="center center"
  ngClass.xs="xs"
>
  <div
    class="add-update-address-form account-form"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="heading">
      {{ translations[address ? 'editHeading' : 'addHeading'] }}
    </div>
    <form
      [formGroup]="form"
      fxLayout="column"
      fxLayoutAlign="space-around none"
    >
      <div class="form-fields">
        <app-text-input
          id="loaction_name"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['location']"
          [focus]="focus['location']"
          [label]="translations.nameLabel"
          [parentFormGroup]="form"
          class="input-control"
          controlName="location"
          [isSlim]="true"
          [maxLength]="validations.address.location.length.max"
        >
        </app-text-input>
        <app-text-input
          id="shipping_name"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['recipientName']"
          [focus]="focus['recipientName']"
          [label]="translations.recipientNameLabel"
          [parentFormGroup]="form"
          class="input-control"
          controlName="recipientName"
          [isSlim]="true"
          [maxLength]="validations.address.recipientName.length.max"
        >
        </app-text-input>
        <app-single-select
          id="country"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['country']"
          [focus]="focus['country']"
          [label]="translations.countryLabel"
          [options]="countryList"
          [parentFormGroup]="form"
          class="input-control"
          [isSlim]="true"
          controlName="country"
        ></app-single-select>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap.gt-sm="1.5rem"
        >
          <app-text-input
            id="zip"
            (checkForErrors)="checkForErrors($event)"
            [controlName]="'zip'"
            [error]="errorMessages['zip']"
            [focus]="focus['zip']"
            [label]="translations.zipLabel"
            [parentFormGroup]="form"
            class="input-control"
            [isSlim]="true"
            [maxLength]="validations.address.zipCode.length.max"
          >
          </app-text-input>
          <app-text-input
            id="state"
            (checkForErrors)="checkForErrors($event)"
            [controlName]="'state'"
            [error]="errorMessages['state']"
            [focus]="focus['state']"
            [label]="translations.stateLabel"
            [parentFormGroup]="form"
            class="input-control"
            [maxLength]="validations.address.state.length.max"
            [isSlim]="true"
            inputRestriction="noSpecialChars"
          >
          </app-text-input>
          <app-text-input
            id="city"
            (checkForErrors)="checkForErrors($event)"
            [controlName]="'city'"
            [error]="errorMessages['city']"
            [focus]="focus['city']"
            [label]="translations.cityLabel"
            [parentFormGroup]="form"
            class="input-control"
            [maxLength]="validations.address.city.length.max"
            [isSlim]="true"
            inputRestriction="noSpecialChars"
          >
          </app-text-input>
        </div>
        <app-text-input
          id="addressline1"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['addressLine1']"
          [focus]="focus['addressLine1']"
          [label]="translations.addressLine1Label"
          [parentFormGroup]="form"
          class="input-control"
          controlName="addressLine1"
          [maxLength]="validations.address.addressLine1.length.max"
          [isSlim]="true"
        >
        </app-text-input>
        <app-text-input
          id="addressline2"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['addressLine2']"
          [focus]="focus['addressLine2']"
          [label]="translations.addressLine2Label"
          [parentFormGroup]="form"
          class="input-control"
          controlName="addressLine2"
          [maxLength]="validations.address.addressLine2.length.max"
          [isSlim]="true"
        >
        </app-text-input>
        <div
          fxLayout="row"
          fxLayoutGap="1.5rem"
        >
          <app-text-input
            id="code"
            *ngIf="language === constants.languages.english"
            (checkForErrors)="checkForErrors($event)"
            [error]="errorMessages['phoneCode']"
            [focus]="focus['phoneCode']"
            [label]="translations.phoneCodeLabel"
            [parentFormGroup]="form"
            class="input-control"
            controlName="phoneCode"
            fxFlex="30"
            [isSlim]="true"
          ></app-text-input>
          <app-number-input
            id="phone_number"
            (checkForErrors)="checkForErrors($event)"
            [controlName]="'phoneNumber'"
            [error]="errorMessages['phoneNumber']"
            [focus]="focus['phoneNumber']"
            [label]="translations.phoneNumberLabel"
            [parentFormGroup]="form"
            class="phoneNumber input-control"
            fxFlex="100"
            [maxLength]="validations.phoneNumber.length.max"
            [isSlim]="true"
          >
          </app-number-input>
        </div>
      </div>
      <div class="form-actions">
        <app-primary-button
          id="update_address"
          (handleClick)="updateAddress()"
          [isLoading]="loading"
          [text]="
            translations[
              address ? 'updateAddressButton' : 'addAddressButton'
            ]
          "
          class="save-button"
          [isSlim]="true"
        >
        </app-primary-button>
      </div>
    </form>
  </div>
</div>
