import {createReducer, on} from '@ngrx/store';
import * as ResetPasswordActions from "./reset-password.actions";

// State for this feature (User)
export interface ResetPasswordState {
  resetPasswordSuccessful: boolean;
}

const initialState: ResetPasswordState = {
  resetPasswordSuccessful: false,
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(ResetPasswordActions.ClearState, (state) => state),

  // PASSWORD RESET SUCCESSFUL
  on(ResetPasswordActions.ResetPasswordSetRequest, (state) => state),
  on(ResetPasswordActions.ResetPasswordSetSuccess, (state) => state),
  on(ResetPasswordActions.ResetPasswordSetFailure, (state) => state),
);
