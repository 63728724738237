/* NgRx */
import {createAction, props} from '@ngrx/store';
import {FilterModel} from "../../models/filter.model";
import {FilterCountryModel} from "../../models/filter-country.model";


/******** CLEAR STATE ***********/
const CLEAR_STATE = '[shared] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** SET LANGUAGE ***********/
const SET_LANGUAGE = '[shared] set language';
export const SetLanguage = createAction(SET_LANGUAGE, props<{ selectedLanguage: number }>());

/******** SET COUNTRY ***********/
const SET_COUNTRY = '[shared] set country';
export const SetCountry = createAction(SET_COUNTRY, props<{ selectedCountry: string }>());

/******** SET IS MOBILE VIEW ***********/
const SET_IS_MOBILE_VIEW = '[shared] set is mobile view';
export const SetIsMobileView = createAction(SET_IS_MOBILE_VIEW, props<any>());

/******** SET IS MOBILE SIDENAV OPEN ***********/
const SET_IS_MOBILE_SIDENAV_OPEN = '[shared] set is mobile sidenav open';
export const SetIsMobileSidenavOpen = createAction(SET_IS_MOBILE_SIDENAV_OPEN, props<{ isMobileSidenavOpen: boolean }>());

/******** SET IS MOBILE SEARCH OPEN ***********/
const SET_IS_MOBILE_SEARCH_OPEN = '[shared] set is mobile search open';
export const SetIsMobileSearchOpen = createAction(SET_IS_MOBILE_SEARCH_OPEN, props<{ isMobileSearchOpen: boolean }>());

/******** LOADING ***********/
const START_LOADING = '[shared] start loading';
export const StartLoading = createAction(START_LOADING);

const STOP_LOADING = '[shared] stop loading';
export const StopLoading = createAction(STOP_LOADING);

const CLEAR_LOADING = '[shared] clear loading';
export const ClearLoading = createAction(CLEAR_LOADING);

/******** SCROLLING ***********/
const DISABLE_SCROLLING = '[shared] disable scrolling';
export const DisableScrolling = createAction(DISABLE_SCROLLING);

const ENABLE_SCROLLING = '[shared] enable scrolling';
export const EnableScrolling = createAction(ENABLE_SCROLLING);


/******** FETCH COUNTRIES LIST ***********/
const FETCH_COUNTRIES_LIST_REQUEST = '[shared] fetch countries list requested';
export const FetchCountriesListRequest = createAction(
  FETCH_COUNTRIES_LIST_REQUEST
);

const FETCH_COUNTRIES_LIST_SUCCESS = '[shared] fetch countries list successful';
export const FetchCountriesListSuccess = createAction(
  FETCH_COUNTRIES_LIST_SUCCESS,
  props<{ countriesList: FilterCountryModel[] }>()
);

const FETCH_COUNTRIES_LIST_FAILURE = '[shared] fetch countries list failure';
export const FetchCountriesListFailure = createAction(
  FETCH_COUNTRIES_LIST_FAILURE
);

/******** FETCH GENDER LIST ***********/
const FETCH_GENDER_LIST_REQUEST = '[shared] fetch gender list requested';
export const FetchGenderListRequest = createAction(
  FETCH_GENDER_LIST_REQUEST
);

const FETCH_GENDER_LIST_SUCCESS = '[shared] fetch gender list successful';
export const FetchGenderListSuccess = createAction(
  FETCH_GENDER_LIST_SUCCESS,
  props<{ gendersList: FilterModel[] }>()
);

const FETCH_GENDER_LIST_FAILURE = '[shared] fetch gender list failure';
export const FetchGenderListFailure = createAction(
  FETCH_GENDER_LIST_FAILURE
);

/******** ENABLE MAINTENANCE OVERLAY ***********/
const ENABLE_MAINTENANCE_OVERLAY = '[shared] enable maintenance overlay';
export const isAppInMaintenanceMode = createAction(ENABLE_MAINTENANCE_OVERLAY, props<any>());
