/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TeaDetailsPageState} from '../../models/tea-details.model';

// Selector functions
const teaDetailsData = createFeatureSelector<TeaDetailsPageState>('teaDetails');

// Create Selectors
export const teaDetails = createSelector(teaDetailsData, (state) => ({
  teaDetails: state.teaDetails,
  reviewSummary: state.reviewSummary,
  userReviews: state.userReviews
}));

export const reviewSummary = createSelector(
  teaDetailsData,
  (state) => state.reviewSummary
);

export const userReviews = createSelector(
  teaDetailsData,
  (state) => state.userReviews
);

export const storyInfo = createSelector(
  teaDetailsData,
  (state) => state.teaDetails.storyInfo
);

export const teaExperience = createSelector(
  teaDetails,
  (state) => state.teaDetails.experience
)

export const teaPairing = createSelector(teaDetails,
  (state) => state.teaDetails.pairings)

export const getExternalBrewDetails = createSelector(
  teaDetails,
  (state) => ({
    name: state?.teaDetails?.name || '',
    brewConfigurations: state?.teaDetails?.brewConfigurations || []
  })
);
