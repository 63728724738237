/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserDetailsState} from './user-details.reducer';

// Selector functions
const userDetailsData =
  createFeatureSelector<UserDetailsState>('userDetails');

export const getCountriesList = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.countriesList
);

export const getPhoneCodesList = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.phoneCodesList
);

// Create Selectors
export const getAddressList = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.addressListData
);

export const getPaymentOptionList = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.paymentOptionListData
);

export const ordersList = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.ordersListData
);

export const getSubscriptions = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.subscriptionsListData
);

export const selectedOrderData = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.selectedOrderData
);

export const getUserSubscriptionDetails = createSelector(
  userDetailsData,
  (state: UserDetailsState) => state.subscriptionDetailsData
);

export const getLoadingFlags = createSelector(
  userDetailsData,
  (state) => ({
    saveAddress: state.saveAddress,
    removeAddress: state.removeAddress,
    makeDefaultAddress: state.makeDefaultAddress,
    addPaymentOption: state.addPaymentOption,
    removePaymentOption: state.removePaymentOption,
    makeDefaultPaymentOption: state.makeDefaultPaymentOption
  })
);
