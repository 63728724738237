<div
  class="container"
>
  <div
    class="cart-row"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="1rem"
    [ngClass.lt-sm]="'grey-background'"

  >
    <div
      class="image-box"
      fxFlex="30"
    >
      <div
        class="image-container"
        fxFlex="100"
      >
        <img
          [src]="data?.images[0]"
          alt=""
          class="image">
      </div>
    </div>
    <div
      class="details-box"
      fxFlex="70"
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-around start"
      fxLayoutAlign.lt-sm="space-around strech"
      fxLayoutGap="1rem"
    >
      <div
        class="item-details"
        fxLayout="column"
        fxFlex="50"
        fxFlex.lt-sm="100"
        fxLayoutGap="0.6rem"
        fxLayoutAlign="space-evenly start"
      >
        <div class="name ff-lang-ja-text-en">{{data?.name}}</div>
        <div class="price">
          <div
            fxFlex="50"
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <div
              class="ff-lang-ja-text-en"
              [class.strike-text]="data.isDiscountApplied">
              {{data?.total | currency: currencySymbol:currencySymbol:'1.0'}}
            </div>
            <div *ngIf="data?.isDiscountApplied"
                 class="discounted-value discounted-total ff-lang-ja-text-en">
              {{data?.afterDiscount?.total | currency: currencySymbol:currencySymbol:'1.0'}}
            </div>
          </div>
        </div>
        <div class="purchase-type">{{data?.purchaseType}}</div>
      </div>
      <div
        class="quantity-editor"
        fxFlex="50"
        fxFlex.lt-sm="100"
        fxLayout="row"
      >
        <div
          class="quantity-row"
          fxLayout="row"
          fxFlex="60"
          fxFlex.lt-sm="75"
          fxLayoutAlign="start center"
        >
          <div class="quantity-label">
            {{translations[language].quantity}} :
          </div>
          <div
            class="value"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="0.5rem"
          >
            <div class="quantity-counter-element">
              <div class="quantity ff-lang-ja-text-en">{{data.quantity}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

