<div
	class="container"
	ngClass.lg="lg"
	ngClass.md="md"
	ngClass.sm="sm"
	ngClass.xs="xs"
>
	<div
		fxFlex="100"
		fxLayout="column"
		fxLayoutAlign="center center"
		fxLayoutGap="20px"
	>
		<div class="image-container" fxLayoutAlign="start center">
      <img alt="" class="image" src="./assets/images/svg/Carafe.svg" />
		</div>
		<div class="message-line1">
			{{translations[language].labelText1}}
		</div>
		<div class="message-line2">
			{{translations[language].labelText2}}
		</div>
		<form
			[formGroup]="UnauthenticatedBrewNotificationInfoForm"
			fxLayout="column"
			fxLayoutAlign="space-around none"
		>
			<div class="input-field">
				<app-text-input
          id="email"
					(checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['email']"
					[controlName]="'email'"
					[parentFormGroup]="UnauthenticatedBrewNotificationInfoForm"
					class="username"
					placeholder="{{translations[language].placeholderText}}"
					maxLength=100
				>
				</app-text-input>
				<div
					class="actions-section"
					fxLayout="row"
					fxLayoutAlign="space-around center"
				>
					<app-primary-button
            buttonId="start_unauthenticated_cold_brew"
						(handleClick)="handleStartBrewClick()"
						[text]="translations[language].submit"
						class="button start-unauthenticated-cold-brew"
            [isSlim]="true"
					></app-primary-button>
				</div>
			</div>
		</form>
	</div>
</div>

