interface DeleteAccountPageTranslation {
  heading: string,
  submitButtonLabel: string,
  dialogConfirmationMessage: string,
  dialogConfirmButtonLabel: string,
  dialogCancelButtonLabel: string,
  backToHomePage: string,
  successHeading: string
}

const en: DeleteAccountPageTranslation = {
  heading: 'Delete Account',
  submitButtonLabel: 'Delete your account',
  dialogConfirmationMessage: 'Are you sure, you want to delete your account?',
  dialogConfirmButtonLabel: 'Confirm',
  dialogCancelButtonLabel: 'Cancel',
  backToHomePage: 'Back to home',
  successHeading: 'Your account has been deleted successfully.'
}

const ja: DeleteAccountPageTranslation = {
  heading: 'アカウントを消去',
  submitButtonLabel: 'アカウントを削除',
  dialogConfirmationMessage: '本当にアカウントを削除しますか？',
  dialogConfirmButtonLabel: 'お知らせ',
  dialogCancelButtonLabel: 'キャンセル',
  backToHomePage: 'ホームに戻る',
  successHeading: 'アカウントを削除しました'
}

export const Translations = {
  1: en,
  2: ja
}
