import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../translations/cart-details/unauthenticated-cart-editable-row.translations";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../state/app.state";
import {CartDetailsService} from "../../../services/cart-details.service";
import {CommonService} from "../../../../shared/services/common.service";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-unauthenticated-cart-editable-row',
  templateUrl: './unauthenticated-cart-editable-row.component.html',
  styleUrls: ['./unauthenticated-cart-editable-row.component.css']
})
export class UnauthenticatedCartEditableRowComponent implements OnInit, OnDestroy {
  language: number = 1;
  @Input() data: any;
  @Input() currencySymbol: string;
  translations: any = Translations;
  cdnUrl = environment.cdnURL;
  images: any;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private store: Store<fromRoot.State>,
    private cartDetailsService: CartDetailsService,
    private commonService: CommonService,
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {
    this.images = this.data?.cdnImages?.map((imageUrl) => {
      if (imageUrl) {
        return this.cdnUrl + '/' + imageUrl;
      }
    });
  }

  increaseQuantity(productId, pricingPlanId, quantity) {
    this.cartDetailsService.productQuantityIncrement.next({productId, pricingPlanId, quantity})

  }

  decreaseQuantity(productId, pricingPlanId, quantity) {
    this.cartDetailsService.productQuantityDecrement.next({productId, pricingPlanId, quantity})
  }

  removeItem(productId, pricingPlanId, quantity) {
    this.cartDetailsService.productDelete.next({productId, pricingPlanId, quantity})
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

  notAllowedToAddItem() {
    this.commonService.notification(this.translations[this.language].notAllowedToAddItem, 'danger')
  }

  notAllowedToRemoveItem() {
    this.commonService.notification(this.translations[this.language].notAllowedToRemoveItem, 'danger')
  }

}
