import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromRoot from '../../../../../state/app.state';
import * as AuthenticationActions from "../../../../authentication/core/authentication.actions";

@Component({
  selector: 'app-user-details-container',
  templateUrl: './user-details-container.component.html',
  styleUrls: ['./user-details-container.component.css']
})
export class UserDetailsContainerComponent implements OnInit {
  @Input() language: number;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(AuthenticationActions.FetchProfileRequest());
  }

}
