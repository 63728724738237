import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {v1URL} from '../../shared/constants/urls.constants';
import {CommonService} from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root',
})

export class UserDetailsService {
  constructor(private commonServices: CommonService) {
  }

  fetchAddressList(): Observable<any> {
    const urlConfig = v1URL.address.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  addAddress(payload): Observable<any> {
    const urlConfig = v1URL.address.add;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  updateAddress(payload): Observable<any> {
    const urlConfig = v1URL.address.update;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  removeAddress(payload): Observable<any> {
    const urlConfig = v1URL.address.remove
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchPaymentOptionList(): Observable<any> {
    const urlConfig = v1URL.paymentOption.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  addPaymentOption(payload): Observable<any> {
    const urlConfig = v1URL.paymentOption.add;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  makeDefaultPaymentOption(payload): Observable<any> {
    const urlConfig = v1URL.paymentOption.makeDefault;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  removePaymentOption(payload): Observable<any> {
    const urlConfig = v1URL.paymentOption.remove
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  getOrders(payload): Observable<any> {
    const urlConfig = v1URL.order.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchUserSubscriptions(payload): Observable<any> {
    const urlConfig = v1URL.subscription.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  getOrdersDetails(payload): Observable<any> {
    const urlConfig = v1URL.order.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchUserSubscriptionDetails(payload): Observable<any> {
    const urlConfig = v1URL.subscription.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  cancelUserSubscription(payload): Observable<any> {
    const urlsConfig = v1URL.subscription.cancel
    return this.commonServices.callAPI(urlsConfig.method, urlsConfig.url, payload);
  }
}
