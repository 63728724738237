import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {UserDetailsService} from './user-details.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../../shared/services/common.service';
import {mergeMap, Observable, of, tap} from "rxjs";
import * as UserDetailsActions from '../../user-details/core/user-details.actions';
import {
  FetchOrdersDetailsFailure,
  FetchOrdersDetailsRequest,
  FetchOrdersDetailsSuccess
} from '../../user-details/core/user-details.actions';
import {catchError, map} from "rxjs/operators";
import {SharedService} from "../../shared/core/shared.service";
import {FetchOrdersListFailure, FetchOrdersListRequest, FetchOrdersListSuccess} from "./user-details.actions"


@Injectable()
export class UserDetailsEffects {
  fetchCountriesList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.FetchCountriesListRequest),
      map((action) => {
        return action;
      }),
      mergeMap((action) =>
        this.sharedService.getCountries().pipe(
          map((response) => {
            const countries = response.data;

            let countriesList = [];
            let phoneCodesList = [];

            countries.forEach((eachCountry) => {
              const phoneCode = eachCountry.phoneCode;
              const countryName = eachCountry.value;
              const countryId = eachCountry.key;
              countriesList = [
                ...countriesList,
                {
                  key: phoneCode + '_' + countryId,
                  value: countryName,
                },
              ];

              phoneCodesList = [
                ...phoneCodesList,
                {
                  key: phoneCode + '_' + countryId,
                  value: phoneCode,
                },
              ];
            });

            return UserDetailsActions.FetchCountriesListSuccess({
              countriesList,
              phoneCodesList,
            });
          }),
          catchError((error) => {
            return of(UserDetailsActions.FetchCountriesListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.FetchCountriesListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === UserDetailsActions.FetchCountriesListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchAddressList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.FetchAddressListRequest),
      map((action) => {
          return action;
        }
      ),
      mergeMap((action) =>
        this.userDetailsService.fetchAddressList().pipe(
          map((response) => {
            const {data, message} = response;
            return UserDetailsActions.FetchAddressListSuccess({addressList: data, message});
          }),
          catchError(err => {
            return of(UserDetailsActions.FetchAddressListFailure({message: err.message}));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.FetchAddressListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === UserDetailsActions.FetchAddressListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  addAddress$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.SaveAddressRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.addAddress(payload).pipe(
          map((response) => {
            const {data, message} = response;

            this.commonService.notification(message, 'success');
            return UserDetailsActions.SaveAddressSuccess({
              message,
              addressList: data
            });
          }),
          catchError((error) => {
            return of(UserDetailsActions.SaveAddressFailure(error.message));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.SaveAddressSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (action.type === UserDetailsActions.SaveAddressFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  updateAddress$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.UpdateAddressRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.updateAddress(payload).pipe(
          map((response) => {
            const {data, message} = response;

            this.commonService.notification(message, 'success');

            return UserDetailsActions.UpdateAddressSuccess({
              message,
              addressList: data,
            });
          }),
          catchError((error) => {
            return of(UserDetailsActions.UpdateAddressFailure(error.message));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.UpdateAddressSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (action.type === UserDetailsActions.UpdateAddressFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  makeDefaultAddress$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.MakeDefaultAddressRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.updateAddress(payload).pipe(
          map((response) => {
            const {data, message} = response;

            this.commonService.notification(message, 'success');

            return UserDetailsActions.MakeDefaultAddressSuccess({
              message,
              addressList: data,
              addressId: payload.addressId
            });
          }),
          catchError((error) => {
            return of(UserDetailsActions.MakeDefaultAddressFailure({
              addressId: payload.addressId,
              message: error.message
            }));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.MakeDefaultAddressSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === UserDetailsActions.MakeDefaultAddressFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  removeAddress$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.RemoveAddressRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.removeAddress(payload).pipe(
          map((response) => {
            const {data, message} = response;
            this.commonService.notification(message, 'success');
            return UserDetailsActions.RemoveAddressSuccess({
              message,
              addressList: data,
            });
          }),
          catchError((error) => {
            return of(
              UserDetailsActions.RemoveAddressFailure(error.message)
            );
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.RemoveAddressSuccess.type) {
              this.commonService.closeDialog();
            }
          })
        )
      )
    )
  );


  fetchPaymentOptionList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.FetchPaymentOptionListRequest),
      map((action) => {
          return action;
        }
      ),
      mergeMap((action) =>
        this.userDetailsService.fetchPaymentOptionList().pipe(
          map((response) => {
            const {data, message} = response;
            return UserDetailsActions.FetchPaymentOptionListSuccess({paymentOptionList: data, message});
          }),
          catchError(err => {
            return of(UserDetailsActions.FetchPaymentOptionListFailure({message: err.message}));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.FetchPaymentOptionListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === UserDetailsActions.FetchPaymentOptionListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  addPaymentOption$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.SavePaymentOptionRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.addPaymentOption(payload).pipe(
          map((response) => {
            const {data, message} = response;

            this.commonService.notification(message, 'success');
            return UserDetailsActions.SavePaymentOptionSuccess({
              message,
              paymentOptionList: data
            });
          }),
          catchError((error) => {
            return of(UserDetailsActions.SavePaymentOptionFailure(error.message));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.SavePaymentOptionSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (action.type === UserDetailsActions.SavePaymentOptionFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  makePaymentOption$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.PaymentOptionMakeDefaultRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.makeDefaultPaymentOption(payload).pipe(
          map((response) => {
            const {data, message} = response;

            this.commonService.notification(message, 'success');

            return UserDetailsActions.PaymentOptionMakeDefaultSuccess({
              message,
              paymentOptionList: data,
              paymentOptionId: payload.paymentOptionId
            });
          }),
          catchError((error) => {
            return of(UserDetailsActions.PaymentOptionMakeDefaultFailure({
              paymentOptionId: payload.paymentOptionId,
              message: error.message
            }));
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.PaymentOptionMakeDefaultSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === UserDetailsActions.PaymentOptionMakeDefaultFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  removePaymentOption$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.RemovePaymentOptionRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.removePaymentOption(payload).pipe(
          map((response) => {
            const {data, message} = response;
            this.commonService.notification(message, 'success');
            return UserDetailsActions.RemovePaymentOptionSuccess({
              message,
              paymentOptionList: data,
            });
          }),
          catchError((error) => {
            return of(
              UserDetailsActions.RemovePaymentOptionFailure(error.message)
            );
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.RemovePaymentOptionSuccess.type) {
              this.commonService.closeDialog();
            }
          })
        )
      )
    )
  );
  // @ts-ignore
  ordersList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchOrdersListRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.getOrders(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const ordersList = data.orderList
            return FetchOrdersListSuccess({ordersList});
          }),
          catchError((error) => {
            return of(FetchOrdersListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === FetchOrdersListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === FetchOrdersListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchUserSubscriptions$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.FetchUserSubscriptionsRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.fetchUserSubscriptions(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const subscriptionsData = {
              subscriptionsList: data.subscriptions,
              hasMore: data.subscriptions.has_more
            }

            return UserDetailsActions.FetchUserSubscriptionsSuccess(subscriptionsData);
          }),
          catchError((error) => {
            return of(
              UserDetailsActions.FetchUserSubscriptionsFailure(error.message)
            );
          }),
          tap((action) => {
            this.commonService.closeDialog();
            if (action.type === UserDetailsActions.FetchUserSubscriptionsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === UserDetailsActions.FetchUserSubscriptionsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  ordersDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchOrdersDetailsRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.getOrdersDetails(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return FetchOrdersDetailsSuccess({orderDetails: data});
          }),
          catchError((error) => {
            return of(FetchOrdersDetailsFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === FetchOrdersDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchOrdersDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchUserSubscriptionDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.FetchUserSubscriptionDetailsRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.fetchUserSubscriptionDetails(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return UserDetailsActions.FetchUserSubscriptionDetailsSuccess({subscriptionDetails: data});
          }),
          catchError((error) => {
            return of(
              UserDetailsActions.FetchUserSubscriptionDetailsFailure(error.message)
            );
          }),
          tap((action) => {
            if (action.type === UserDetailsActions.FetchUserSubscriptionDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === UserDetailsActions.FetchUserSubscriptionDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
              this.commonService.closeDialog();
              UserDetailsActions.FetchUserSubscriptionDetailsSuccess(null);
            }
          })
        )
      )
    )
  );

  cancelUserSubscription$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserDetailsActions.CancelUserSubscriptionRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.userDetailsService.cancelUserSubscription(payload).pipe(
          map((response: any) => {
            const {data} = response;

            return UserDetailsActions.CancelUserSubscriptionSuccess({data, message: response.message});
          }),
          catchError((error) => {
            return of(
              UserDetailsActions.CancelUserSubscriptionFailure(error.message)
            );
          }),
          tap((action) => {
            this.commonService.notification(action.message, 'success');
            this.commonService.closeDialog();
            if (
              action.type === UserDetailsActions.CancelUserSubscriptionSuccess.type
            ) {
              // Code to execute on API Success Action dispatch

              this.store.dispatch(
                UserDetailsActions.FetchUserSubscriptionsRequest({payload : {limit : 100}})
              );
            } else if (
              action.type === UserDetailsActions.CancelUserSubscriptionFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private userDetailsService: UserDetailsService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {
  }
}
