<div
  class="user-details-container"
  ngClass.xs="xs">
  <app-user-details-screen [language]="language"></app-user-details-screen>
  <div
    class="router-content"
    ngClass.xs="xs">
    <router-outlet></router-outlet>
  </div>
</div>

