import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {countriesList, selectedCountry, selectedLanguage} from "../../../core/shared.selectors";
import {Subscription} from "rxjs";
import {FilterCountryModel} from "../../../../models/filter-country.model";
import {CommonService} from "../../../services/common.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Translations} from 'src/app/modules/translations/shared/components/headers/desktop-header.translations'
import {LoginFormComponent} from "../../../../authentication/forms/login-form/login-form.component";
import {LazyLoaderService} from "../../../services/lazy-loader.service";
import {languages} from "../../../constants/common.constants";
import {cartCount} from "../../../../cart-details/core/cart.selectors";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SetCountry} from "../../../core/shared.actions";
import {
  SetProductFilters,
  SetVendorFilters
} from "../../../../landing-page/core/landing-page.actions";

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.css']
})
export class DesktopHeaderComponent implements OnInit, OnDestroy {
  cartCount: string;
  cartIcon: string;
  searchIconClicked: boolean;
  translations = Translations;

  countriesList: FilterCountryModel[] = [];
  subHeaderOptions: any[] = [];

  selectedCountryCode: string;
  selectedCountryText: string;
  language: number;

  cartCountSubscription: Subscription;

  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe((data) => this.language = data);

  countriesListSubscription: Subscription = this.store.pipe(select(countriesList)).subscribe((data) => {
    this.countriesList = data;
    if (this.selectedCountryCode) {
      this.selectedCountryText = this.getSelectedCountryName();
    }
  });

  selectedCountryCodeSubscription: Subscription = this.store.pipe(select(selectedCountry)).subscribe((data) => {
    this.selectedCountryCode = data;
    this.selectedCountryText = this.getSelectedCountryName();
  })

  form: FormGroup = this.fb.group({
    searchText: new FormControl('')
  });

  paramsSubscription: Subscription = this.activatedRoute.queryParams.subscribe((params) => {
    if (params.search && this.form) {
      this.form.patchValue({
        searchText: params.search
      });
    }
  });


  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private lazyLoaderService: LazyLoaderService,
    private fb: FormBuilder,
  ) {
    this.lazyLoaderService.loadModule(
      () => import('src/app/modules/cart-details/cart-details.module').then(m => m.CartDetailsModule),
      () => {
        this.cartCountSubscription = this.store.pipe(select(cartCount)).subscribe((count) => {
          this.cartCount = count === 0 ? '' : count.toString();
          this.cartIcon = this.cartCount ? 'cart_filled' : 'cart_empty'
        })
      }
    );
  }

  ngOnInit(): void {
    this.subHeaderOptions = [
      {
        key: 'teapot',
        handleClick: () => {
          if (Number(this.language) === languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/')
          }
          if (Number(this.language) === languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/')
          }
        }
      },
      {
        key: 'coldWaterCarafe',
        handleClick: () => {
          if (Number(this.language) === languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/carafe')
          }
          if (Number(this.language) === languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/carafe')
          }
        }
      },
      {
        key: 'teaLeaf',
        handleClick: () => {
          this.store.dispatch(SetProductFilters({productFilters: []}));
          this.store.dispatch(SetVendorFilters({vendorFilters: ''}));
          this.router.navigate(['/'], {fragment: 'tea-leaves'});
        }
      },
      {
        key: 'corporateService',
        handleClick: () => {
          if (this.language == languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/business/')
          }
          if (this.language == languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/business/')
          }
        }
      },
      {
        key: 'blog',
        handleClick: () => {
          if (this.language == languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/blogs/')
          }
          if (this.language == languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/blogs/')
          }
        }
      },
      {
        key: 'support',
        handleClick: () => {
          if (this.language == languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/support/')
          }
          if (this.language == languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/support/')
          }
        }
      }
    ];
  }

  setSelectedCountry(countryCode: string) {
    this.store.dispatch(SetCountry({selectedCountry: countryCode}));

    let queryParams = {};

    this.activatedRoute.queryParams.subscribe((params) => {
      queryParams = params;
    }).unsubscribe();

    const route = new URL(window.location.href).pathname;
    const fragment = this.activatedRoute.snapshot.fragment;

    this.router.navigateByUrl('/dummy-url',{skipLocationChange:true}).then(()=>{
      this.router.navigate([`/${route}`], {
        queryParams,
        ...(fragment ?{fragment} : {})
      })
    })
  }

  getSelectedCountryName() {
    return (this.countriesList.filter((o) => o.key === this.selectedCountryCode))[0]?.value || '';
  }

  handleCartClick() {
    this.router.navigate(['/cart']);
  }

  handleProfileClick() {
    if (this.commonService.isAuthenticated()) {
      // If user is logged in, then redirect to the /user page
      this.router.navigate(['/user']);
    } else {
      // If the user is not authenticated, lazy load the Authentication module.
      // After that open the generic dialog box with the LoginForm component passed to it.
      this.commonService.openDialog({data: {component: LoginFormComponent}})
    }
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  search() {
    const formData = this.form.getRawValue();
    const searchText = formData?.searchText;
    const currentUrl = this.router.url.split('?')[0];

    let options: any = {
      fragment: 'tea-leaves',
      queryParams: {
        search: searchText || ''
      }
    };

    if (currentUrl === '/') {
      this.router.navigate([], options);
    } else {
      this.router.navigate(['/'], options);
    }
  }

  close() {
    this.searchIconClicked = false;
    this.form.reset();
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.selectedCountryCodeSubscription?.unsubscribe();
    this.countriesListSubscription?.unsubscribe();
    this.cartCountSubscription?.unsubscribe();
    this.paramsSubscription?.unsubscribe();
  }
}
