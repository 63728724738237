import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,} from '@angular/core';
import {Translations} from '../../../translations/tea-details-page/brew-processing-info.translation';

import {ThemePalette} from '@angular/material/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {selectedLanguage} from 'src/app/modules/shared/core/shared.selectors';

@Component({
  selector: 'app-brew-processing-info',
  templateUrl: './brew-processing-info.component.html',
  styleUrls: ['./brew-processing-info.component.css'],
})
export class BrewProcessingInfoComponent
  implements OnInit, OnChanges, OnDestroy {
  language: number;
  @Input() brewDuration: number;
  @Output() handleClick = new EventEmitter();
  translations: any = Translations;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value: number;
  counter: number;
  interval = 1000;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.counter = this.brewDuration;
  }

  handleValueChange(event) {
    this.value = event;
    if (this.value === 0) {
      setTimeout(() => this.handleNextClick(), 1000);
    }
  }

  handleNextClick() {
    this.handleClick.emit(3);
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }
}
