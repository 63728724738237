import {Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-desktop-basic-dialog',
  templateUrl: './desktop-basic-dialog.component.html',
  styleUrls: ['./desktop-basic-dialog.component.css']
})
export class DesktopBasicDialogComponent implements OnInit {
  @ViewChild('anchor', {static: true, read: ViewContainerRef}) anchor: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DesktopBasicDialogComponent>) {
  }

  ngOnInit(): void {
    this.anchor.clear();
    const {component, ...data} = this.data;
    const componentRef = this.anchor.createComponent(component);
    componentRef.instance.data = data;
  }

  close() {
    this.dialogRef.close();
  }
}
