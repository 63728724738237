import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/shared/pages/page-not-found.translation";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  cdnUrl: string = environment.cdnURL;
  backgroundImageURL: string = `${this.cdnUrl}/shop.teplotea.com/reset-password/background.png`;
  translations: any = Translations;
  language: number = 1;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit(): void {
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
