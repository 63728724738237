export interface DesktopHeaderTranslations {
  searchPlaceholder: string;
  teapot: string;
  coldWaterCarafe: string;
  teaLeaf: string;
  corporateService: string;
  blog: string;
  support: string;
}

const en: DesktopHeaderTranslations = {
  searchPlaceholder: 'Search...',
  teapot: 'Teapot',
  coldWaterCarafe: 'Cold water carafe',
  teaLeaf: 'Tea leaf',
  corporateService: 'Corporate service',
  blog: 'Blogs',
  support: 'Support'
};

const ja: DesktopHeaderTranslations = {
  searchPlaceholder: '検索',
  teapot: 'teploティーポット',
  coldWaterCarafe: 'teplo水出しカラフェ',
  teaLeaf: '茶葉',
  corporateService: '法人サービス',
  blog: 'ブログ',
  support: 'サポート'
};

export const Translations = {
  1: en,
  2: ja
};
