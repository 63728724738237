import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/user-details/logout-confirm-dialog.translation";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {getLoadingFlags} from "../../../authentication/core/authentication.selectors";

@Component({
  selector: 'app-logout-confirm-dialog',
  templateUrl: './logout-confirm-dialog.component.html',
  styleUrls: ['./logout-confirm-dialog.component.css']
})
export class LogoutConfirmDialogComponent implements OnInit, OnDestroy {
  language: number = 1;
  translations: any = Translations;
  loading: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.loading = loadingFlags.logout?.loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

  onConfirm() {
    this.commonService.logout();
  }

  onCancel() {
    this.commonService.closeDialog();
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
  }

}
