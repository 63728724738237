import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {CommonService} from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuardService implements CanActivate {

  constructor(
    private commonService: CommonService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.queryParams?.token || route.queryParams?.passwordReset) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
