<div
  [class]="className"
>
  <div
    [class]="'arrow-container' + (showNavigationArrows ? '' : ' hidden')"
    fxFlex="10"
    fxFlex.xs="15"
    fxLayoutAlign="center center"
  >
    <img
      #prev
      alt=""
      class="carousel-arrow {{arrowSize}}-arrow carousel-arrow-prev"
      src="/assets/images/svg/prev-slide.svg"
    />
  </div>
  <div
    [fxFlex.xs]="showNavigationArrows ? '70' : '100'"
    [fxFlex]="showNavigationArrows ? '80' : '100'"
    class="grabbable"
  >
    <swiper [config]="sliderOptions">
      <ng-template
        *ngFor="let slide of slidesData"
        swiperSlide
      >
        <ng-container
          *ngTemplateOutlet="slideTemplate.first; context: { $implicit: slide }"
        >
        </ng-container>
      </ng-template>
    </swiper>
  </div>
  <div
    [class]="'arrow-container' + (showNavigationArrows ? '' : ' hidden')"
    fxFlex="10"
    fxFlex.xs="15"
    fxLayoutAlign="center center"
  >
    <img
      #next
      alt=""
      class="carousel-arrow {{arrowSize}}-arrow carousel-arrow-next"
      src="/assets/images/svg/next-slide.svg"
    />
  </div>
</div>

