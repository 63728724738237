  <div
    class="cards-container"
    fxLayout="row wrap"
  >
    <div
      *ngFor="let eachItem of itemList"
      fxFlex.gt-lg="0 0 calc(20% - 22.5rem/5)"
      fxFlex.lg="0 0 calc(25% - 13.5rem/4)"
      fxFlex.md="0 0 calc(33.3333% - 9rem/3)"
      fxFlex.sm="0 0 calc(50% - 4.5rem/2)"
      fxFlex.xs="100"
    >
      <div
        class="order-card"
        fxLayout="column"
        ngClass.sm="sm"
      >
        <div
          fxLayoutAlign="center center"
        >
          <app-shimmer-loader
            fxFlex="13.1rem"
            [theme]="{
              'background-color': '#e5e5e5',
              'height': '2rem',
              'margin-bottom': '1.5rem'
            }"
          ></app-shimmer-loader>
        </div>
        <div
          *ngFor="let eachInfo of[1, 2, 3 ,4]"
          class="info-row"
          fxFlex="100"
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <app-shimmer-loader
            [theme]="{
                'background-color': '#e5e5e5',
                'height': '1.3rem',
                'width': '10rem',
                'margin-bottom': '0.2rem'
              }"
          ></app-shimmer-loader>
          <app-shimmer-loader
            [theme]="{
                'background-color': '#e5e5e5',
                'height': '1.3rem',
                'width': '10rem',
                'margin-bottom': '0.2rem'
              }"
          ></app-shimmer-loader>
        </div>
        <div class="image-panel">
          <app-shimmer-loader
            class="image"
            [theme]="{
                  'background-color': '#e5e5e5',
                  'height': '21.5rem',
                  'margin-top': '0.75rem',
                  'margin-bottom': '0'
                }"
          ></app-shimmer-loader>
        </div>
      </div>
    </div>
  </div>


