import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders-list-loader',
  templateUrl: './orders-list-loader.component.html',
  styleUrls: ['./orders-list-loader.component.css']
})
export class OrdersListLoaderComponent implements OnInit {
  itemList: number[] = [1, 2, 3, 4, 5, 6, 7, 8];
  constructor() { }

  ngOnInit(): void {
  }

}
