<app-mobile-header
  *ngIf="isMobileView$ | async; else desktopHeader"
  class="header mobile-header"
></app-mobile-header>

<ng-template #desktopHeader>
  <app-desktop-header class="header"></app-desktop-header>
  <!--  <div class="spacer"></div>-->
</ng-template>

<div *ngIf="isMobileView$ | async">
<!--  <div class="spacer mobile"></div>-->
  <app-mobile-sidenav
    *ngIf="isMobileSidenavOpen"
    [isAuthenticated]="isAuthenticated"
    class="mobile-sidenav"
  ></app-mobile-sidenav>
  <div
    *ngIf="isMobileSearchOpen"
    (click)="toggleSearchSection()"
    class="search-section-backdrop"
  >
  </div>
</div>



<div
  [class.hidden]="!isMobileDialogOpen"
  [ngStyle]="{
    background: mobileDialogBackgroundColor
  }"
  class="mobile-dialog"
>
  <template #mobileDialogAnchor></template>
  <app-icon
    (click)="commonService.closeMobileDialog.next()"
    class="close"
    icon="close-with-background"
  ></app-icon>
</div>

<div
  id="client_area"
  class="client-area"
  [class.mobile-view]="isMobileView$ | async"
>
  <div id="router_outlet" class="router-outlet">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
