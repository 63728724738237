import * as UserDetailsActions from './user-details.actions';
import {
  FetchOrdersDetailsFailure,
  FetchOrdersDetailsRequest,
  FetchOrdersDetailsSuccess,
  FetchOrdersListFailure,
  FetchOrdersListRequest,
  FetchOrdersListSuccess
} from './user-details.actions';
import {createReducer, on} from '@ngrx/store';
import {UserAddressModel} from "../../models/user-address.model";
import {UserPaymentOptionModel} from "../../models/user-payment-option.model";
import {FilterCountryModel} from "../../models/filter-country.model";
import {FilterModel} from "../../models/filter.model";

// State for this feature (User)
export interface UserDetailsState {
  addressListData: {
    loading: boolean,
    addressList: UserAddressModel[]
  },
  paymentOptionListData: {
    loading: boolean,
    paymentOptionList: UserPaymentOptionModel[]
  },
  subscriptionsListData: {
    loading: boolean,
    subscriptionsList: any[],
    hasMoreSubscriptions: boolean
  },
  subscriptionDetailsData: {
    loading: boolean,
    subscriptionDetails: null
  },
  countriesList: FilterCountryModel[],
  phoneCodesList: FilterModel[],
  ordersListData: {
    loading: boolean,
    ordersList: any[]
  };
  selectedOrderData: {
    orderDetails: any,
    loading: boolean
  };
  saveAddress: {
    loading: boolean
  };
  removeAddress: {
    loading: boolean
  };
  makeDefaultAddress: {
    addressId: string,
    loading: boolean
  };
  addPaymentOption: {
    loading: boolean
  };
  removePaymentOption: {
    loading: boolean
  };
  makeDefaultPaymentOption: {
    paymentOptionId: string,
    loading: boolean
  };
}

const initialState: UserDetailsState = {
  addressListData: {
    loading: false,
    addressList: []
  },
  paymentOptionListData: {
    loading: false,
    paymentOptionList: []
  },
  countriesList: [],
  phoneCodesList: [],
  subscriptionsListData: {
    loading: false,
    subscriptionsList: [],
    hasMoreSubscriptions: false
  },
  subscriptionDetailsData: {
    loading: false,
    subscriptionDetails: null
  },
  ordersListData: {
    loading: false,
    ordersList: []
  },
  selectedOrderData: {
    orderDetails: {
      code: null,
      date: null,
      amount: 0,
      isDiscountApplied: false,
      currency: null,
      shippingAddress: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zip: null,
        county: null,
      },
      card: {
        name: null,
        last4Digits: null
      },
      items: null,
      taxData: {
        amount: null
      },
      contactUsText: null,
      discountDetails: {
        couponCode: null
      },
      trackingDetails: {
        id: null,
        status: null
      }
    },
    loading: false
  },
  saveAddress: {
    loading: false
  },
  removeAddress: {
    loading: false
  },
  makeDefaultAddress: {
    addressId: '',
    loading: false
  },
  addPaymentOption: {
    loading: false
  },
  removePaymentOption: {
    loading: false
  },
  makeDefaultPaymentOption: {
    paymentOptionId: '',
    loading: false
  }
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(UserDetailsActions.ClearState, (state) => initialState),

  // FETCH COUNTRIES LIST
  on(UserDetailsActions.FetchCountriesListRequest, (state) => state),
  on(UserDetailsActions.FetchCountriesListSuccess, (state, props) => ({
    ...state,
    countriesList: props.countriesList,
    phoneCodesList: props.phoneCodesList,
  })),
  on(UserDetailsActions.FetchCountriesListFailure, (state) => state),

  // FETCH ADDRESS LIST
  on(UserDetailsActions.FetchAddressListRequest, (state) => ({
    ...state,
    addressListData: {
      addressList: [],
      loading: true
    }
  })),
  on(UserDetailsActions.FetchAddressListSuccess, (state, props) => ({
    ...state,
    addressListData: {
      addressList: props.addressList,
      loading: false,
    }
  })),
  on(UserDetailsActions.FetchAddressListFailure, (state) => ({
    ...state,
    addressListData: {
      ...state.addressListData,
      loading: false
    }
  })),

  // SAVE ADDRESS
  on(UserDetailsActions.SaveAddressRequest, (state) => ({
    ...state,
    saveAddress: {
      loading: true
    }
  })),
  on(UserDetailsActions.SaveAddressSuccess, (state, props) => ({
    ...state,
    addressListData: {
      addressList: props.addressList,
      loading: false,
    },
    saveAddress: {
      loading: false
    }
  })),
  on(UserDetailsActions.SaveAddressFailure, (state) => ({
    ...state,
    saveAddress: {
      loading: false
    }
  })),

  // UPDATE ADDRESS
  on(UserDetailsActions.UpdateAddressRequest, (state) => ({
    ...state,
    saveAddress: {
      loading: true
    }
  })),

  on(UserDetailsActions.UpdateAddressSuccess, (state, props) => ({
    ...state,
    addressListData: {
      addressList: props.addressList,
      loading: false,
    },
    saveAddress: {
      loading: false
    }
  })),

  on(UserDetailsActions.UpdateAddressFailure, (state) => ({
    ...state,
    saveAddress: {
      loading: false
    }
  })),

  // MAKE DEFAULT ADDRESS
  on(UserDetailsActions.MakeDefaultAddressRequest, (state, props) => ({
    ...state,
    makeDefaultAddress: {
      addressId: props.payload.addressId,
      loading: true
    }
  })),
  on(UserDetailsActions.MakeDefaultAddressSuccess, (state, props) => ({
    ...state,
    addressListData: {
      addressList: props.addressList,
      loading: false,
    },
    makeDefaultAddress: {
      addressId: props.addressId,
      loading: false
    }
  })),
  on(UserDetailsActions.MakeDefaultAddressFailure, (state, props) => ({
    ...state,
    makeDefaultAddress: {
      addressId: props.addressId,
      loading: false
    }
  })),

  // REMOVE ADDRESS
  on(UserDetailsActions.RemoveAddressRequest, (state) => ({
    ...state,
    removeAddress: {
      loading: true
    }
  })),
  on(UserDetailsActions.RemoveAddressSuccess, (state, props) => ({
    ...state,
    addressListData: {
      addressList: props.addressList,
      loading: false,
    },
    removeAddress: {
      loading: false
    }
  })),
  on(UserDetailsActions.RemoveAddressFailure, (state) => ({
    ...state,
    removeAddress: {
      loading: false
    }
  })),

  // FETCH PAYMENT OPTION LIST
  on(UserDetailsActions.FetchPaymentOptionListRequest, (state) => ({
    ...state,
    paymentOptionListData: {
      paymentOptionList: [],
      loading: true
    }
  })),
  on(UserDetailsActions.FetchPaymentOptionListSuccess, (state, props) => ({
    ...state,
    paymentOptionListData: {
      paymentOptionList: props.paymentOptionList,
      loading: false
    }
  })),
  on(UserDetailsActions.FetchPaymentOptionListFailure, (state) => ({
    ...state,
    paymentOptionListData: {
      ...state.paymentOptionListData,
      loading: false
    }
  })),

  // SAVE PAYMENT OPTION
  on(UserDetailsActions.SavePaymentOptionRequest, (state) => ({
    ...state,
    addPaymentOption: {
      loading: true
    }
  })),

  on(UserDetailsActions.SavePaymentOptionSuccess, (state, props) => ({
    ...state,
    paymentOptionListData: {
      paymentOptionList: props.paymentOptionList,
      loading: false
    },
    addPaymentOption: {
      loading: false
    }
  })),

  on(UserDetailsActions.SavePaymentOptionFailure, (state) => ({
    ...state,
    addPaymentOption: {
      loading: false
    }
  })),

  // MAKE DEFAULT PAYMENT OPTION
  on(UserDetailsActions.PaymentOptionMakeDefaultRequest, (state, props) => ({
    ...state,
    makeDefaultPaymentOption: {
      paymentOptionId: props.payload.paymentOptionId,
      loading: true
    }
  })),
  on(UserDetailsActions.PaymentOptionMakeDefaultSuccess, (state, props) => ({
    ...state,
    paymentOptionListData: {
      paymentOptionList: props.paymentOptionList,
      loading: false
    },
    makeDefaultPaymentOption: {
      paymentOptionId: props.paymentOptionId,
      loading: false
    }
  })),
  on(UserDetailsActions.PaymentOptionMakeDefaultFailure, (state, props) => ({
    ...state,
    makeDefaultPaymentOption: {
      paymentOptionId: props.paymentOptionId,
      loading: false
    }
  })),

  // REMOVE PAYMENT OPTION
  on(UserDetailsActions.RemovePaymentOptionRequest, (state) => ({
    ...state,
    removePaymentOption: {
      loading: true
    }
  })),
  on(UserDetailsActions.RemovePaymentOptionSuccess, (state, props) => ({
    ...state,
    paymentOptionListData: {
      paymentOptionList: props.paymentOptionList,
      loading: false
    },
    removePaymentOption: {
      loading: false
    }
  })),
  on(UserDetailsActions.RemovePaymentOptionFailure, (state) => ({
    ...state,
    removePaymentOption: {
      loading: false
    }
  })),

  // SET LOADING FLAG
  on(UserDetailsActions.SetAddPaymentOptionLoadingRequest, (state, props) => ({
    ...state,
    addPaymentOption: {
      loading: props.loading
    }
  })),

  // FETCH SUBSCRIPTION LIST
  on(UserDetailsActions.FetchUserSubscriptionsRequest, (state) => ({
    ...state,
    subscriptionsListData: {
      ...state.subscriptionsListData,
      loading: true
    },
  })),
  on(UserDetailsActions.FetchUserSubscriptionsSuccess, (state, props) => ({
    ...state,
    subscriptionsListData: {
      subscriptionsList: props.subscriptionsList,
      hasMoreSubscriptions: props.hasMore,
      loading: false
    }
  })),
  on(UserDetailsActions.FetchUserSubscriptionsFailure, (state) => ({
    ...state,
    subscriptionsListData: {
      ...state.subscriptionsListData,
      loading: false
    },
  })),

  // FETCH ORDERS LIST
  on(FetchOrdersListRequest, (state) => ({
    ...state,
    ordersListData: {
      ordersList: [],
      loading: true
    },
  })),
  on(FetchOrdersListSuccess, (state, props) => ({
    ...state,
    ordersListData: {
      ordersList: props.ordersList,
      loading: false
    },
  })),
  on(FetchOrdersListFailure, (state) => ({
    ...state,
    ordersListData: {
      ordersList: state.ordersListData.ordersList,
      loading: false
    },
  })),

  // FETCH ORDERS DETAILS
  on(FetchOrdersDetailsRequest, (state) => ({
    ...state,
    selectedOrderData: {
      orderDetails: initialState.selectedOrderData.orderDetails,
      loading: true
    }
  })),
  on(FetchOrdersDetailsSuccess, (state, props) => ({
    ...state,
    selectedOrderData: {
      orderDetails: props.orderDetails,
      loading: false
    }
  })),
  on(FetchOrdersDetailsFailure, (state) => ({
    ...state,
    selectedOrderData: {
      ...state.selectedOrderData,
      loading: false
    }
  })),

  // FETCH SUBSCRIPTION DETAILS
  on(UserDetailsActions.FetchUserSubscriptionDetailsRequest, (state) => ({
    ...state,
    subscriptionDetailsData: {
      loading: true,
      subscriptionDetails: null
    },
  })),
  on(UserDetailsActions.FetchUserSubscriptionDetailsSuccess, (state, props) => ({
    ...state,
    subscriptionDetailsData: {
      loading: false,
      subscriptionDetails: props.subscriptionDetails
    },
  })),
  on(UserDetailsActions.FetchUserSubscriptionDetailsFailure, (state) => ({
    ...state,
    subscriptionDetailsData: {
      ...state.subscriptionDetailsData,
      loading: false
    },
  })),

  // CANCEL USER SUBSCRIPTION
  on(UserDetailsActions.CancelUserSubscriptionRequest, (state) => state),
  on(UserDetailsActions.CancelUserSubscriptionSuccess, (state) => state),
  on(UserDetailsActions.CancelUserSubscriptionFailure, (state) => state),
);
