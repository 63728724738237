import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ResetPasswordService} from './reset-password.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../../shared/services/common.service';
import {Observable, of} from "rxjs";
import * as ResetPasswordActions from './reset-password.actions'
import {catchError, map, mergeMap, tap} from "rxjs/operators";


@Injectable()
export class ResetPasswordEffects {

  resetPasswordSet$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ResetPasswordActions.ResetPasswordSetRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.resetPasswordService.resetPasswordSet(payload).pipe(
          map((response: any) => {
            return ResetPasswordActions.ResetPasswordSetSuccess({message: response.message});
          }),
          catchError((error) => {
            return of(ResetPasswordActions.ResetPasswordSetFailure(error));
          }),
          tap((action) => {
            if (action.type === ResetPasswordActions.ResetPasswordSetSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.resetPasswordService.resetPasswordSuccess.next(true);
              this.commonService.notification(action.message, 'success');
              this.router.navigate(
                ['/resetpassword'],
                {queryParams: {passwordReset: 1}}
              ).then(() => {
                this.commonService.removeAuthenticationToken()
              })
            } else if (action.type === ResetPasswordActions.ResetPasswordSetFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private resetPasswordService: ResetPasswordService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService,
  ) {
  }
}
