import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-half-content-half-image',
  templateUrl: './half-content-half-image.component.html',
  styleUrls: ['./half-content-half-image.component.css']
})
export class HalfContentHalfImageComponent implements OnInit {
  cdnUrl: string = environment.cdnURL;
  backgroundImageURL: string;

  constructor() { }

  ngOnInit(): void {
    this.backgroundImageURL = `${this.cdnUrl}/shop.teplotea.com/reset-password/background.png`;
  }

}
