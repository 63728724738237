import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResetPasswordScreenComponent} from './reset-password-screen/reset-password-screen.component';
import {StoreModule} from "@ngrx/store";
import {reducer} from "./core/reset-password.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ResetPasswordEffects} from "./core/reset-password.effects";
import {ResetPasswordRoutingModule} from "./reset-password-routing.module";
import {SharedModule} from "../shared/shared.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {ResetPasswordFormComponent} from "./components/reset-password-form/reset-password-form.component";


@NgModule({
  declarations: [
    ResetPasswordScreenComponent,
    ResetPasswordFormComponent,
  ],
  imports: [
    CommonModule,
    ResetPasswordRoutingModule,
    StoreModule.forFeature('resetPassword', reducer),
    EffectsModule.forFeature(
      [ResetPasswordEffects]
    ),
    FlexLayoutModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class ResetPasswordModule {
}
