import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../../state/app.state";
import {CommonService} from "../../../../../shared/services/common.service";
import {Translations} from "../../../../../translations/user-details/personal-information/payment-option-form.translation";
import {environment} from "../../../../../../../environments/environment";
import {PaymentOptionService} from "../../../services/payment-option.service";
import {getLoadingFlags} from "../../../../core/user-details.selectors";
import * as UserDetailsActions from '../../../../core/user-details.actions';
declare let Stripe: any;

@Component({
  selector: 'app-add-payment-option-form',
  templateUrl: './add-payment-option-form.component.html',
  styleUrls: ['./add-payment-option-form.component.css']
})
export class AddPaymentOptionFormComponent implements OnInit, OnDestroy {
  @Input() paymentOptionId: string;
  paymentOptionDetails: any;
  language: number = 1;
  translations: any;
  loading: boolean = false;
  cardNumberError: string = '';
  cardExpiryError: string = '';
  cardCvcError: string = '';

  // Stripe integration for generate token
  stripe = Stripe(environment.stripePublishableKey);
  elements = this.stripe.elements();

  stripInputStyleGuide = {
    base: {
      color: '#000000',
      fontSize: '16px',
      height: '45px',
      backgroundColor: '#FFFFFF',
      padding: '0 10px'
    },
    invalid: {
      iconColor: '#FF0000',
      color: '#000000',
    }
  }
  cardNumberElement = this.elements.create('cardNumber', {showIcon: true, style: this.stripInputStyleGuide});
  cardExpiryElement = this.elements.create('cardExpiry', {style: this.stripInputStyleGuide});
  cardCVCElement = this.elements.create('cardCvc', {style: this.stripInputStyleGuide});

  paymentOptionForm: FormGroup;
  errorMessages = {};
  errorMessageMap;

  // Get language
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe((selectedLanguage) => {
    this.language = selectedLanguage;
  });

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.loading = loadingFlags.addPaymentOption?.loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private fb: FormBuilder,
              private paymentOptionService: PaymentOptionService) { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
    this.errorMessageMap = this.translations.errorMessageMap;

    this.paymentOptionForm = this.fb.group({
      cardHolderName: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(this.commonService.alphabetsRegex)
        ]
      })
    });

    this.cardNumberElement.mount('#card-number');
    this.cardExpiryElement.mount('#card-expiry');
    this.cardCVCElement.mount('#card-cvv');

    // Custom errors for stripe elements
    let _self = this;
    this.cardNumberElement.addEventListener('change', function(event) {
      _self.cardNumberError = _self.getStripeErrorMessage(event, _self.errorMessageMap.cardNumber);
    });

    this.cardExpiryElement.addEventListener('change', function(event) {
      _self.cardExpiryError = _self.getStripeErrorMessage(event, _self.errorMessageMap.cardExpiry);
    });

    this.cardCVCElement.addEventListener('change', function(event) {
      _self.cardCvcError = _self.getStripeErrorMessage(event, _self.errorMessageMap.cardCvc);
    });
  }

  getStripeErrorMessage(event, errorMessagesMap){
    if (event.error) {
      return errorMessagesMap[event.error.code] || event.error.message;
    }
    return '';
  }

  async addPaymentOption() {
    if (this.paymentOptionForm.invalid) {
      this.checkForErrors();
      return;
    }
    const {cardHolderName} = this.paymentOptionForm.getRawValue();
    this.store.dispatch(UserDetailsActions.SetAddPaymentOptionLoadingRequest({loading: true}));
    const {token, error} = await this.stripe.createToken(this.cardNumberElement, {card: {name: cardHolderName}});
    if (error) {
      this.store.dispatch(UserDetailsActions.SetAddPaymentOptionLoadingRequest({loading: false}));
      this.commonService.notification(error.message, 'danger');
    } else {
      this.paymentOptionService.savePaymentOption.emit({
        token: token.id,
        returnAll: true,
        type: 1
      });
    }
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.paymentOptionForm, this.errorMessageMap, currentField))
    };
  }


  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
  }

}
