import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserAddressModel} from "../../../../models/user-address.model";
import {select, Store} from "@ngrx/store";
import * as fromRoot from '../../../../../state/app.state';
import {Subscription} from "rxjs";
import * as UserDetailsSelectors from '../../../core/user-details.selectors';
import {Translations} from "../../../../translations/user-details/personal-information/address-section.translation";
import {Translations as DialogTranslations} from '../../../../translations/user-details/personal-information/delete-address-confirm-dialog.translation';
import {CommonService} from "../../../../shared/services/common.service";
import {AddUpdateAddressContainerComponent} from "../add-update-address/add-update-address-container/add-update-address-container.component";
import {AddressService} from "../../services/address.service";
import {DeleteAddressConfirmDialogComponent} from "../delete-address-confirm-dialog/delete-address-confirm-dialog.component";
import {getLoadingFlags} from "../../../core/user-details.selectors";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-address-section',
  templateUrl: './address-section.component.html',
  styleUrls: ['./address-section.component.css']
})
export class AddressSectionComponent implements OnInit, OnDestroy {
  @Input() language: number = 1;
  addressList: UserAddressModel[];
  translations: any;
  loading: boolean = false;
  cellsToShow = 3;

  // Get address list
  addressListSubscription: Subscription = this.store.pipe(select(UserDetailsSelectors.getAddressList)).subscribe((addressListData) => {
    this.addressList = addressListData.addressList;
    this.loading = addressListData.loading;
  });

  // Open add address dialog
  routeQueryParams$: Subscription = this.route.queryParams.subscribe(params => {
    if (params['addAddress']) {
      this.openAddDialog();
    }
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private addressService: AddressService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

  openAddDialog(){
    this.commonService.openDialog({
      data: {
        component: AddUpdateAddressContainerComponent
      }
    })
  }

  onMakeDefault(event){
    this.addressService.makeDefaultAddress.emit({
      payload: {
        addressId: event._id,
        isDefault: true,
        returnAll: true
      }
    });
  }

  onEdit(event){
    this.commonService.openDialog({
      data: {
        component: AddUpdateAddressContainerComponent,
        address: event
      }
    })
  }

  openDeleteDialog(event){
    this.commonService.openDialog({
      data: {
        component: DeleteAddressConfirmDialogComponent,
        address: event
      }
    })
  }

  ngOnDestroy() {
    this.addressListSubscription?.unsubscribe();
    this.routeQueryParams$?.unsubscribe();
  }
}
