import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./modules/shared/shared.module";
import {ActionReducer, MetaReducer, StoreModule} from "@ngrx/store";
import {localStorageSync} from 'ngrx-store-localstorage';
import {EffectsModule} from '@ngrx/effects';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ApiInterceptor} from "./modules/shared/services/api.interceptor";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {AuthenticationModule} from "./modules/authentication/authentication.module";
import {CartDetailsModule} from "./modules/cart-details/cart-details.module";
import {TeaDetailsModule} from "./modules/tea-details/tea-details.module";
import {PackageDetailsModule} from "./modules/package-details/package-details.module";
import {UserDetailsModule} from "./modules/user-details/user-details.module";
import {DeleteAccountModule} from "./modules/delete-account/delete-account.module";
import {ResetPasswordModule} from "./modules/reset-password/reset-password.module";

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'shared',
      'landingPage',
      'authentication',
      'cartDetails',
      'packageDetails',
      'teaDetails',
      'userDetails',
      'resetPassword',
      'deleteAccount'
    ],
    rehydrate: true,
    storage: sessionStorage
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AuthenticationModule,
    CartDetailsModule,
    TeaDetailsModule,
    PackageDetailsModule,
    UserDetailsModule,
    DeleteAccountModule,
    ResetPasswordModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot([]),
    NgxSkeletonLoaderModule.forRoot({animation: 'pulse'}),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
