import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../../shared/core/shared.selectors";
import * as fromRoot from '../../../../../../state/app.state';
import {Translations} from "../../../../../translations/user-details/personal-information/account-information-form.translation";
import {CommonService} from "../../../../../shared/services/common.service";
import {UpdateAccountInformationService} from "../services/update-account-information.service";
import {getLoadingFlags, profile} from "../../../../../authentication/core/authentication.selectors";
import * as Validations from "../../../../../shared/constants/validation.constants";

@Component({
  selector: 'app-update-account-information-form',
  templateUrl: './update-account-information-form.component.html',
  styleUrls: ['./update-account-information-form.component.css']
})
export class UpdateAccountInformationFormComponent implements OnInit, OnDestroy {
  language: number = 1;
  validations = Validations;
  translations: any;
  loading: boolean = false;
  updateAccountLoading: boolean = false;
  profileInfo: any;

  accountForm: FormGroup;
  errorMessages = {};
  errorMessageMap;

  // Get language
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe((selectedLanguage) => {
    this.language = selectedLanguage;
  });

  // Get profile information
  profileInfoSubscription: Subscription = this.store.pipe(select(profile)).subscribe((profileData) => {
    this.profileInfo = profileData.profile;
    this.loading = profileData.loading;
  });

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.updateAccountLoading = loadingFlags.updateAccount?.loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private fb: FormBuilder,
              private updateAccountInformationService: UpdateAccountInformationService) {

  }

  ngOnInit(): void {
    this.translations = Translations[this.language];
    this.errorMessageMap = this.translations.errorMessageMap;

    this.accountForm = this.fb.group({
      fullName: new FormControl(this.profileInfo.fullName || '', {
          validators: [
            Validators.required,
            Validators.pattern(this.commonService.alphabetsRegex)
          ],
        }
      ),
      age: new FormControl(this.profileInfo.age, {
          validators: [
            Validators.required,
            Validators.min(this.validations.user.age.min),
            Validators.max(this.validations.user.age.max)
          ],
        }
      ),
    });

  }

  restrictZero(event: any) {
    if (event.target.value.length === 0 && event.key === '0') {
      event.preventDefault();
    }
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.accountForm, this.errorMessageMap, currentField))
    };
  }

  updateAccountInformation() {
    if (this.accountForm.invalid) {
      this.checkForErrors();
      return;
    }
    const {fullName, age, gender} = this.accountForm.getRawValue();

    this.updateAccountInformationService.updateAccountInfo.emit(
      {
        fullName,
        age: Number(age),
        gender
      });
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.profileInfoSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
  }
}
