import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/deleteAccountPage/delete-account-page.translation";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../../shared/services/common.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as DeleteAccountActions from './../../core/delete-account.actions'
import {getLoading} from "../../core/delete-account.selectors";

@Component({
  selector: 'app-delete-account-confirmation-dialog',
  templateUrl: './delete-account-confirmation-dialog.component.html',
  styleUrls: ['./delete-account-confirmation-dialog.component.css']
})
export class DeleteAccountConfirmationDialogComponent implements OnInit, OnDestroy {
  language: number = 1;
  translations: any = Translations;
  loading: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoading)).subscribe((loading) => {
    this.loading = loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private route: ActivatedRoute,
              private router: Router,) {
  }

  ngOnInit(): void {
  }

  onConfirm() {
    this.store.dispatch(DeleteAccountActions.DeleteAccountRequest());
  }

  backToHome() {
    this.router.navigate(['/']).then(() => {
      this.commonService.closeDialog();
    });
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
  }

}
