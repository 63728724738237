interface TeaDetailsStoryInfoTranslation {
  heading: string
}

const en: TeaDetailsStoryInfoTranslation = {
  heading: `A Little More About the Tea`
}

const ja: TeaDetailsStoryInfoTranslation = {
  heading: `商品の説明`
}

export const Translations = {
  1: en,
  2: ja
}
