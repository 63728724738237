interface TeaDetailsBrewTasteInfoTranslation {
  heading: string
}

const en: TeaDetailsBrewTasteInfoTranslation = {
  heading: `Brew Taste`
}

const ja: TeaDetailsBrewTasteInfoTranslation = {
  heading: `味わい`
}

export const Translations = {
  1: en,
  2: ja
}
