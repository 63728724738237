import {Component, ContentChildren, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import SwiperCore, {Autoplay, Navigation, Pagination, SwiperOptions} from 'swiper';
import * as uuid from 'uuid';

SwiperCore.use([Navigation, Pagination, Autoplay]);

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit, OnChanges {
  @ContentChildren(TemplateRef)

  slideTemplate;
  className;
  breakpoints;
  defaultOptions: SwiperOptions;
  navigation;
  sliderOptions: SwiperOptions;

  @Input() options: SwiperOptions;
  @Input() slidesData = [];
  @Input() xlComponentCount: number = 3;
  @Input() lgComponentCount: number = 3;
  @Input() mdComponentCount: number = 3;
  @Input() smComponentCount: number = 2;
  @Input() xsComponentCount: number = 1;
  @Input() spacing: number = 40;
  @Input() pagination: boolean = false;
  @Input() autoPlay: number = 0;
  @Input() arrowSize: string = 'md';
  @Input() loop: boolean = false;


  showNavigationArrows = false;
  screenSize = 'lg';

  constructor(
    public breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XLarge]) {
        this.screenSize = 'xl';
      }
      if (state.breakpoints[Breakpoints.Large]) {
        this.screenSize = 'lg';
      }
      if (state.breakpoints[Breakpoints.Medium]) {
        this.screenSize = 'md';
      }
      if (state.breakpoints[Breakpoints.Small]) {
        this.screenSize = 'sm';
      }
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.screenSize = 'xs';
      }

      this.checkNavigationArrows(this.screenSize, this.slidesData);
    });

    this.className = `carousel_${uuid.v4()}`;

    this.navigation = {
      nextEl: `.${this.className} .carousel-arrow-next`,
      prevEl: `.${this.className} .carousel-arrow-prev`,
      disabledClass: 'carousel-arrow-disabled',
    };

    this.breakpoints = {
      0: {
        slidesPerView: this.xsComponentCount,
        spaceBetween: this.spacing,
      },
      600: {
        slidesPerView: this.smComponentCount,
        spaceBetween: this.spacing,
      },
      960: {
        slidesPerView: this.mdComponentCount,
        spaceBetween: this.spacing,
      },
      1280: {
        slidesPerView: this.lgComponentCount,
        spaceBetween: this.spacing,
      },
      1920: {
        slidesPerView: this.lgComponentCount,
        spaceBetween: this.spacing,
      },
    };

    this.defaultOptions = {
      ...(this.loop ? {
        loop: this.loop,
        loopedSlides: this.slidesData.length
      } : {}),
      breakpoints: this.breakpoints,
      slidesPerView: 1,
      spaceBetween: 10,
      centerInsufficientSlides: true,
      navigation: this.navigation,
      ...(this.pagination ? {
        pagination: {
          clickable: true
        }
      } : {}),
      ...(this.autoPlay ? {
        autoplay: {
          delay: this.autoPlay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }
      } : {})
    };

    this.sliderOptions = {
      ...this.defaultOptions,
      ...this.options,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.slidesData) {
      const slidesData = changes.slidesData?.currentValue || [];

      this.checkNavigationArrows(this.screenSize, slidesData);
    }
  }

  checkNavigationArrows(screenSize, slidesData = []) {
    if (this.pagination) {
      this.showNavigationArrows = false;
      return;
    }
    switch (screenSize) {
      case 'xl':
        this.showNavigationArrows = slidesData?.length > this.xlComponentCount;
        break;
      case 'lg':
        this.showNavigationArrows = slidesData?.length > this.lgComponentCount;
        break;
      case 'md':
        this.showNavigationArrows = slidesData?.length > this.mdComponentCount;
        break;
      case 'sm':
        this.showNavigationArrows = slidesData?.length > this.smComponentCount;
        break;
      case 'xs':
        this.showNavigationArrows = slidesData?.length > this.xsComponentCount;
        break;
      default:
        break;
    }
  }
}
