import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/deleteAccountPage/delete-account-page.translation";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../../shared/services/common.service";
import {Subscription} from "rxjs";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import {profile} from "../../../authentication/core/authentication.selectors";
import {
  DeleteAccountConfirmationDialogComponent
} from "../delete-account-confirmation-dialog/delete-account-confirmation-dialog.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as Validations from "../../../shared/constants/validation.constants";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-delete-account-form',
  templateUrl: './delete-account-form.component.html',
  styleUrls: ['./delete-account-form.component.css']
})
export class DeleteAccountFormComponent implements OnInit, OnDestroy {

  language: number = 1;
  translations = Translations;
  errorMessages = {};
  errorMessageMap = {};
  loading: boolean = false;
  focus: any = {};
  fieldBackgroundColor = "#D7D7D7";
  fieldTextColor = "#121315";
  labelTextColor = "#000000";
  email: string = '';

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });
  validations = Validations;

  profileSubscription: Subscription = this.store.pipe(select(profile)).subscribe((profileData: any) => {
    this.email = profileData.profile.email;
    this.loading = profileData.loading;
  });

  form: FormGroup = this.fb.group({
    email: new FormControl({value: this.email, disabled: true},
      {
        validators: [
          Validators.pattern(this.validations.email.regex)
        ],
      })
  });

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  onDeleteAccount() {
    this.commonService.openDialog({
      data: {
        component: DeleteAccountConfirmationDialogComponent
      }
    });
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.profileSubscription?.unsubscribe();
  }

}
