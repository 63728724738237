interface UnauthenticatedCartEditableRowTranslations {
  quantity: string,
  removeItem: string,
  notAllowedToAddItem: string,
  notAllowedToRemoveItem: string,
}

const en: UnauthenticatedCartEditableRowTranslations = {
  quantity: `Quantity`,
  removeItem: `Remove Item`,
  notAllowedToAddItem: `Can not add more quantity. Maximum Limit Reached.`,
  notAllowedToRemoveItem: `Can not remove more quantity. Minimum Limit Reached.`
}

const ja: UnauthenticatedCartEditableRowTranslations = {
  quantity: `数量`,
  removeItem: `商品を削除`,
  notAllowedToAddItem: `注文できる個数の上限です`,
  notAllowedToRemoveItem: `最小注文数です`
}

export const Translations = {
  1: en,
  2: ja
}
