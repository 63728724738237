<app-tea-details-loader
  *ngIf="teaDetails?.success === null || teaDetails.loading; else teaDetailsSection"></app-tea-details-loader>
<ng-template #teaDetailsSection>
  <div *ngIf="teaDetails?.success !== null">
    <div
      *ngIf="teaDetails?.id && teaDetails?.id === productId;
       else
      noData"
    >
      <div fxFlex="100">
        <div
          class="product-details-outer-div"
          [class.mobile]="isMobileView"
          fxLayout="column"
        >
          <div class="product-heading-div"
               fxLayout="row"
               fxLayoutAlign="center none">
            <div class="heading-section">
              <span>{{name1}}</span>
              <span *ngIf="name2">|</span>
              <span *ngIf="name2"
                    class="ff-lang-ja-text-en">{{name2}}</span>
            </div>
          </div>
          <div
            class="details-product-heading-div"
            fxLayout="row"
            fxLayoutAlign="center none">
            <div class="details-heading-section">
              {{ teaDetails?.shortDescription}}
            </div>
          </div>
          <div class="product-details-content-section"
               fxFlex="0 0 0"
               fxLayout="column"
               fxLayout.gt-sm="row"
               fxLayoutGap="10rem">
            <div class="carousel-image"
                 fxFlex="50"
                 fxLayout="column">
              <div fxFlex="0 0 0"
                   fxLayoutAlign="center none">
                <app-carousel
                  [pagination]="true"
                  [slidesData]="teaDetails?.images"
                  class="image-carousel swiper"
                  [class.mobile-image-carousel]="isMobileView"
                  lgComponentCount="1"
                  mdComponentCount="1"
                  smComponentCount="1"
                  xsComponentCount="1"
                  [loop]="true">
                  <ng-template let-image>
                    <div class="carousel-item"
                         fxLayoutAlign="center center">
                      <app-image [url]="image"
                                 class="carousel-image"></app-image>
                    </div>
                  </ng-template>
                </app-carousel>
              </div>
              <div class="start-brew-section">
                <app-primary-button
                  *ngIf="showStartBrewButton"
                  id="start_brew"
                  [prefixIcon]="'brew-icon'"
                  [text]="rightSectionTranslations[language].startBrew"
                  (handleClick)="startBrew()"
                >
                </app-primary-button>
              </div>
            </div>
            <div fxFlex="50"
                 fxLayout="column"
                 fxLayoutGap="3rem">
              <div
                class="bread-crumb-section"
                fxLayout="column"
                fxLayoutGap="1.5rem"
              >
                <div
                  class="bread-crumb-section"
                  *ngIf="teaDetails?.vendor?.name"
                  fxFlex="0 0 0"
                  fxLayout="row"
                  fxLayoutAlign="none center"
                  fxLayoutGap="10">
                  <div class="bread-crumb-logo"
                       fxLayoutAlign="center center">
                    <img alt=""
                         class="bread-crumb-image"
                         src="assets/images/svg/vendor.svg"/>
                  </div>
                  <div class="bread-crumb-text">
                    {{ rightSectionTranslations[language].vendor + " - " }}
                    <span class="ff-lang-ja-text-en">{{ teaDetails?.vendor?.name }}</span>
                  </div>
                </div>
                <div
                  class="bread-crumb-location-section"
                  fxFlex="0 0 0"
                  fxLayout="row"
                  fxLayoutAlign="none center"
                  fxLayoutGap="10">
                  <div class="bread-crumb-logo"
                       fxLayoutAlign="center center">
                    <img alt=""
                         class="bread-crumb-location-image"
                         src="assets/images/png/location.png"/>
                  </div>
                  <div class="bread-crumb-text">
                    {{ rightSectionTranslations[language].location + " - " }}
                    {{ teaDetails?.region + "," + teaDetails?.country }}
                  </div>
                </div>
                <div
                  class="bread-crumb-quantity-description-section"
                  fxFlex="0 0 0"
                  fxLayout="row"
                  fxLayoutAlign="none center"
                  fxLayoutGap="10">
                  <div class="bread-crumb-logo"
                       fxLayoutAlign="center center">
                    <img alt=""
                         class="bread-crumb-quantity-description-image"
                         src="assets/images/png/breadcrumb-logo-package.png"/>
                  </div>
                  <div class="bread-crumb-text">
                    {{rightSectionTranslations[language].quantityDescription + ":"}}
                    {{ teaDetails?.quantityDescription}}
                  </div>
                </div>
                <div
                  class="product-details-actions-section"
                  fxLayout.lt-sm="column"
                  fxLayout="row"
                  fxLayoutGap="10"
                  fxLayoutAlign="center center"
                >
                  <div
                    [ngClass]="isMobileView?'mobile-button':''"
                    class="drop-down-section"
                    fxFlex="50"
                    fxFlex.lt-sm="100"
                  >
                    <div *ngIf="message.length === 0"
                         class="block-layout light price"
                         fxFlex="100"
                         fxLayoutAlign="center center">
                      <app-primary-clickable-text
                        class="pricing-value"
                        [text]="teaDetails?.pricingPlans[0]?.value"
                      >
                      </app-primary-clickable-text>
                    </div>
                    <div
                      *ngIf="message.length > 0"
                      class="block-layout light unavailable"
                      fxFlex="100"
                      [matTooltip]="message"
                    >
                      <!--                      <app-primary-clickable-text [text]="message" [toolTip]="message"></app-primary-clickable-text>-->
                      {{message}}
                    </div>
                  </div>
                  <div
                    class="add-to-cart-section"
                    [class.mobile-button]="isMobileView"
                    fxFlex="50"
                    fxFlex.lt-sm="100"
                  >
                    <app-primary-button
                      id="add_to_cart"
                      [matTooltip]="leftSectionTranslations[language].addToCart"
                      [isDisabled]="message.length > 0"
                      [text]="leftSectionTranslations[language].addToCart"
                      (handleClick)="!message && addToCart()"
                    >
                    </app-primary-button>
                  </div>
                </div>
              </div>

              <div class="exp-sec"
                   fxLayoutGap="2rem"
                   fxLayout="column">
                <div class="experience-outer">
                  <app-experience class="experience-section"></app-experience>
                </div>
                <div
                  class="tea-experience-params"
                  fxLayoutGap="2rem"
                  fxLayout="column"
                >
                  <div class="addition-section-details"
                       fxLayout="column"
                       fxLayoutGap="0.7rem">
                    <div class="addition-section-heading">
                      {{ aromaTranslations[language].heading }}
                    </div>

                    <div class="addition-section-content">
                      {{ teaDetails?.aromaDescription }}
                    </div>
                  </div>
                  <div class="addition-section-details"
                       fxLayout="column"
                       fxLayoutGap="0.7rem">
                    <div class="addition-section-heading">
                      {{ appearanceTranslations[language].heading }}
                    </div>
                    <div class="addition-section-content">
                      {{ teaDetails?.appearanceDescription }}
                    </div>
                  </div>
                  <div class="addition-section-details"
                       fxLayout="column"
                       fxLayoutGap="0.7rem">
                    <div class="addition-section-heading">
                      {{ brewTasteInfoTranslations[language].heading }}
                    </div>
                    <div class="addition-section-content">
                      {{ teaDetails?.brewTasteDescription }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <app-pairings
            *ngIf="teaDetails?.pairings?.length>0"
            fxFlex="100">
          </app-pairings>
          <hr
            *ngIf=" teaDetails?.pairings?.length > 0"
            class="divider"
          />
          <app-tea-story
            class="tea-story-section"
            fxFlex="100"
            fxLayoutAlign="center center"
          ></app-tea-story>

          <app-reviews
            class="review-section"
            fxFlex="100"
            *ngIf="reviewSummary.totalReviews > 0">
          </app-reviews>
        </div>

      </div>
    </div>
    <ng-template #noData>
      <app-half-content-half-image>
        <div
          class="no-data"
          fxLayoutAlign="center center"
        >
          {{translations[language].noData}}
        </div>
      </app-half-content-half-image>
    </ng-template>
  </div>
</ng-template>
