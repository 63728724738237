<div
  class="container confirm-dialog"
  fxLayoutAlign="space-between none"
  fxLayout="column"
>
  <div
    class="heading"
  >
    {{translations.heading}}
  </div>
  <div
    class="action"
    fxLayout="column"
  >
    <div>
      <app-primary-button
        id="yes"
        (handleClick)="onConfirm()"
        [isLoading]="loading"
        class="confirm-button"
        [isSlim]="true"
        text="{{translations.confirmButtonLabel}}"
      >
      </app-primary-button>
    </div>

  </div>
</div>

