/* NgRx */
import {createAction, props} from '@ngrx/store';
import {LoginRequestModel} from "../../models/login-request.model";
import {ProfileModel} from "../../models/profile.model";
import {SignupRequestModel} from "../../models/signup-request.model";

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[authentication] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** LOGIN ***********/
const LOGIN_REQUEST = '[authentication] login requested';
export const LoginRequest = createAction(LOGIN_REQUEST, props<{ payload: LoginRequestModel }>());

const LOGIN_SUCCESS = '[authentication] login successful';
export const LoginSuccess = createAction(LOGIN_SUCCESS, props<{ profile: ProfileModel, message: string }>());

const LOGIN_FAILURE = '[authentication] login failure';
export const LoginFailure = createAction(LOGIN_FAILURE);

/******** LOGOUT ***********/
const LOGOUT_REQUEST = '[authentication] logout requested';
export const LogoutRequest = createAction(LOGOUT_REQUEST);

const LOGOUT_SUCCESS = '[authentication] logout successful';
export const LogoutSuccess = createAction(LOGOUT_SUCCESS, props<{ message: string }>());

const LOGOUT_FAILURE = '[authentication] logout failure';
export const LogoutFailure = createAction(LOGOUT_FAILURE, props<{ message: string }>());

/******** CREATE ACCOUNT ***********/
const CREATE_ACCOUNT_REQUEST = '[authentication] create account requested';
export const CreateAccountRequest = createAction(CREATE_ACCOUNT_REQUEST, props<{ payload: SignupRequestModel }>());

const CREATE_ACCOUNT_SUCCESS = '[authentication] create account successful';
export const CreateAccountSuccess = createAction(CREATE_ACCOUNT_SUCCESS, props<{ profile: ProfileModel, message: string }>());

const CREATE_ACCOUNT_FAILURE = '[authentication] create account failure';
export const CreateAccountFailure = createAction(CREATE_ACCOUNT_FAILURE);

/******** UPDATE ACCOUNT ***********/
const UPDATE_ACCOUNT_REQUEST = '[authentication] update account requested';
export const UpdateAccountRequest = createAction(UPDATE_ACCOUNT_REQUEST, props<{ payload: any }>());

const UPDATE_ACCOUNT_SUCCESS = '[authentication] update account successful';
export const UpdateAccountSuccess = createAction(UPDATE_ACCOUNT_SUCCESS, props<{ profile: ProfileModel, message: string }>());

const UPDATE_ACCOUNT_FAILURE = '[authentication] update account failure';
export const UpdateAccountFailure = createAction(UPDATE_ACCOUNT_FAILURE, props<{ message: string }>());

/******** FORGOT PASSWORD ***********/
const FORGOT_PASSWORD_REQUEST = '[authentication] forgot password requested';
export const ForgotPasswordRequest = createAction(FORGOT_PASSWORD_REQUEST, props<{ payload: { email: string } }>());

const FORGOT_PASSWORD_SUCCESS = '[authentication] forgot password successful';
export const ForgotPasswordSuccess = createAction(FORGOT_PASSWORD_SUCCESS, props<{ message: string }>());

const FORGOT_PASSWORD_FAILURE = '[authentication] forgot password failure';
export const ForgotPasswordFailure = createAction(FORGOT_PASSWORD_FAILURE);

/******** FETCH PROFILE ***********/
const FETCH_PROFILE_REQUEST = '[authentication] fetch profile requested';
export const FetchProfileRequest = createAction(FETCH_PROFILE_REQUEST);

const FETCH_PROFILE_SUCCESS = '[authentication] fetch profile successful';
export const FetchProfileSuccess = createAction(FETCH_PROFILE_SUCCESS, props<{ profile: ProfileModel, message: string }>());

const FETCH_PROFILE_FAILURE = '[authentication] fetch profile failure';
export const FetchProfileFailure = createAction(FETCH_PROFILE_FAILURE, props<{ message: string }>());

/******** SET PROFILE EMAIL ***********/
const SET_PROFILE_EMAIL = '[authentication] set profile email';
export const SetProfileEmail = createAction(SET_PROFILE_EMAIL, props<{ email: string }>());

/******** SET FORGOT PASSWORD STATUS ***********/
const SET_FORGOT_PASSWORD_STATUS = '[authentication] set forgot password status ';
export const SetForgotPasswordStatus = createAction(SET_FORGOT_PASSWORD_STATUS, props<any>());

