/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[landing page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** CLEAR ALL FILTERS ***********/
const CLEAR_ALL_FILTERS = '[landing page] clear all filters';
export const ClearAllFilters = createAction(CLEAR_ALL_FILTERS);

/******** SET PRODUCT FILTERS ***********/
const SET_PRODUCT_FILTERS = '[landing page] set product filters';
export const SetProductFilters = createAction(SET_PRODUCT_FILTERS, props<{ productFilters: string[] }>());

/******** SET VENDOR FILTERS ***********/
const SET_VENDOR_FILTERS = '[landing page] set vendor filters';
export const SetVendorFilters = createAction(SET_VENDOR_FILTERS, props<{ vendorFilters: string }>());

/******** SET VENDOR FILTERS ***********/
const SET_FILTERS = '[landing page] set filters';
export const SetFilters = createAction(SET_FILTERS, props<{productFilters: string[], vendorFilters: string }>());

/******** FETCH CATALOG FILTERS ***********/
const FETCH_CATALOG_FILTERS_REQUEST = '[landing page] fetch catalog filters requested';
export const FetchCatalogFiltersRequest = createAction(FETCH_CATALOG_FILTERS_REQUEST);

const FETCH_CATALOG_FILTERS_SUCCESS = '[landing page] fetch catalog filters success';
export const FetchCatalogFiltersSuccess = createAction(FETCH_CATALOG_FILTERS_SUCCESS, props<any>());

const FETCH_CATALOG_FILTERS_FAILURE = '[landing page] fetch catalog filters failure';
export const FetchCatalogFiltersFailure = createAction(FETCH_CATALOG_FILTERS_FAILURE, props<any>());

/******** FETCH PRODUCTS ***********/
const FETCH_PRODUCTS_REQUEST = '[landing page] fetch products requested';
export const FetchProductsRequest = createAction(FETCH_PRODUCTS_REQUEST, props<any>());

const FETCH_PRODUCTS_SUCCESS = '[landing page] fetch products success';
export const FetchProductsSuccess = createAction(FETCH_PRODUCTS_SUCCESS, props<any>());

const FETCH_PRODUCTS_FAILURE = '[landing page] fetch products failure';
export const FetchProductsFailure = createAction(FETCH_PRODUCTS_FAILURE, props<any>());

/******** FETCH VENDORS LIST ***********/
const FETCH_VENDORS_LIST_REQUEST = '[landing page] fetch vendors list requested';
export const FetchVendorsListRequest = createAction(FETCH_VENDORS_LIST_REQUEST);

const FETCH_VENDORS_LIST_SUCCESS = '[landing page] fetch vendors list success';
export const FetchVendorsListSuccess = createAction(FETCH_VENDORS_LIST_SUCCESS, props<any>());

const FETCH_VENDORS_LIST_FAILURE = '[landing page] fetch vendors list failure';
export const FetchVendorsListFailure = createAction(FETCH_VENDORS_LIST_FAILURE, props<any>());
