<app-subscription-list-loader
  *ngIf="loading; else subscriptionsListTemplate"
  [translations]="translations[language]"></app-subscription-list-loader>
<ng-template #subscriptionsListTemplate>
  <div
    class="container"
    ngClass.lg="lg"
    ngClass.md="md"
    ngClass.sm="sm"
    ngClass.xl="xl"
    ngClass.xs="xs"
  >
    <div
      class="cards-container"
      [fxLayoutAlign]="(subscriptionsList?.length < 4 ? 'center' : 'flex-start') + ' center'"
      fxLayout="row wrap"
    >
      <div
        *ngFor="let eachSubscription of subscriptionsList"
        fxFlex.lg="0 0 calc(25% - 13.5rem/4)"
        fxFlex.md="0 0 calc(33.3333% - 9rem/3)"
        fxFlex.sm="0 0 calc(50% - 4.5rem/2)"
        fxFlex.xl="0 0 calc(20% - 22.5rem/5)"
        fxFlex.xs="100"
      >
        <app-subscription-card
          [subscriptionDetails]="eachSubscription"
        ></app-subscription-card>
      </div>
    </div>
    <div *ngIf="!subscriptionsList?.length">
      <div
        class="no-data"
        fxLayoutAlign="center center"
      >
        {{translations[language].noData}}
      </div>
      <div
        fxLayoutAlign="center"
      >
        <app-secondary-button
          class="back-to-home"
          (handleClick)="backToHome()"
          fxFlex="48rem"
          [text]="translations[language].goToShopPage"
        ></app-secondary-button>
      </div>
    </div>
  </div>

</ng-template>







