import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { Translations } from '../../../translations/tea-details-page/cold-carafe-temperature-info.translation';
import { TeaDetailsService } from '../../core/tea-details.service';

@Component({
  selector: 'app-cold-carafe-temperature-info',
  templateUrl: './cold-carafe-temperature-info.component.html',
  styleUrls: ['./cold-carafe-temperature-info.component.css'],
})
export class ColdCarafeTemperatureInfoComponent
  implements OnInit, OnChanges, OnDestroy {
  language: number;
  @Input() selectedBrewConfiguration: any;
  @Input() externalBrewDetails: any;
  @Input() profileData: any;
  @Input() teaDetails: any;
  @Output() handleClick = new EventEmitter();

  translations = Translations;
  brewTemperature: string;
  productQuantity: string;
  brewDuration: string;
  waterQuantity: string;

  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  constructor(
    private commonService: CommonService,
    private TeaDetailsService: TeaDetailsService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.brewTemperature = this.selectedBrewConfiguration.brewTemperature;
    this.productQuantity = this.selectedBrewConfiguration.productQuantity;
    this.brewDuration = this.selectedBrewConfiguration.brewDuration;
    this.waterQuantity = this.selectedBrewConfiguration.waterQuantity;
  }

  ngOnChanges() { }

  handleStartBrewClick() {
    const payload = {
      email: this.profileData.email,
      productId: this.teaDetails?.id,
    };
    if (this.commonService.isAuthenticated()) {
      this.handleClick.emit(5);
      this.TeaDetailsService.notify.emit(payload);
    } else {
      this.handleClick.emit(6);
    }
  }

  handlePreviousClick() {
    this.handleClick.emit(0);
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }
}
