import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {CommonService} from "./common.service";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../state/app.state";
import {isAppInMaintenanceMode} from "../core/shared.selectors";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuardService implements CanActivate {

  constructor(
    private commonService: CommonService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let enableMaintenancePage = false;
    this.store.pipe(select(isAppInMaintenanceMode)).subscribe((data) => {
      enableMaintenancePage = data;
    });

    if (enableMaintenancePage) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
