<div ngClass.lg="lg" ngClass.md="md" ngClass.sm="sm" ngClass.xs="xs">
  <app-brew-strength-info
    (StrengthHandleClick)="updateBrewStrength($event)"
    (handleClick)="updateStepIndex($event)"
    *ngIf="stepIndex === 0"
    [ngClass]="stepIndex === 0 ? 'active' : ''"
    [selectedBrewStrength]="selectedBrewStrength"
  ></app-brew-strength-info>

  <app-brew-temperature-info
    (handleClick)="updateStepIndex($event)"
    *ngIf="stepIndex === 1"
    [ngClass]="stepIndex === 1 ? 'active' : ''"
    [selectedBrewTemperature]="selectedBrewTemperature"
  ></app-brew-temperature-info>

  <app-brew-processing-info
    (handleClick)="updateStepIndex($event)"
    *ngIf="stepIndex === 2"
    [brewDuration]="selectedBrewDuration"
    [ngClass]="stepIndex === 2 ? 'active' : ''"
  ></app-brew-processing-info>

  <app-brew-ready-serve-info
    *ngIf="stepIndex === 3"
    [ngClass]="stepIndex === 3 ? 'active' : ''"
    [teaName]="externalBrewDetails.name"
  ></app-brew-ready-serve-info>
  <app-cold-carafe-temperature-info
    (handleClick)="updateStepIndex($event)"
    *ngIf="stepIndex === 4"
    [selectedBrewConfiguration]="selectedBrewConfiguration"
    [profileData]="profileData"
    [teaDetails]="teaDetailsPageData?.teaDetails"
    [ngClass]="stepIndex === 4 ? 'active' : ''"
  ></app-cold-carafe-temperature-info>
  <app-authenticated-brew-notification-info
    (handleClick)="updateStepIndex($event)"
    *ngIf="stepIndex === 5"
    [ngClass]="stepIndex === 5 ? 'active' : ''"
  ></app-authenticated-brew-notification-info>
  <app-unauthenticated-brew-notification-info
    (handleClick)="updateStepIndex($event)"
    *ngIf="stepIndex === 6"
    [teaDetails]="teaDetailsPageData?.teaDetails"
    [ngClass]="stepIndex === 6 ? 'active' : ''"
  ></app-unauthenticated-brew-notification-info>
</div>
