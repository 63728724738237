import {Component, OnDestroy, OnInit} from '@angular/core';
import * as UserDetailsActions from "../../core/user-details.actions";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from './../../../../state/app.state';
import {PaymentOptionService} from "../services/payment-option.service";
import {AddressService} from "../services/address.service";

@Component({
  selector: 'app-personal-information-container',
  templateUrl: './personal-information-container.component.html',
  styleUrls: ['./personal-information-container.component.css']
})
export class PersonalInformationContainerComponent implements OnInit, OnDestroy {

  language: number = 1;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  // Address operations
  removeAddressSubscription: Subscription = this.addressService.removeAddress.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.RemoveAddressRequest(payload));
  });

  makeDefaultAddressSubscription: Subscription = this.addressService.makeDefaultAddress.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.MakeDefaultAddressRequest(payload));
  });

  // Payment option operations
  removePaymentOptionSubscription: Subscription = this.paymentOptionService.removePaymentOption.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.RemovePaymentOptionRequest(payload));
  });

  makeDefaultPaymentOptionSubscription: Subscription = this.paymentOptionService.makeDefaultPaymentOption.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.PaymentOptionMakeDefaultRequest(payload));
  });

  constructor(private store: Store<fromRoot.State>,
              private addressService: AddressService,
              private paymentOptionService: PaymentOptionService) { }

  ngOnInit(): void {
    this.store.dispatch(UserDetailsActions.FetchAddressListRequest());
    this.store.dispatch(UserDetailsActions.FetchPaymentOptionListRequest());
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.removeAddressSubscription?.unsubscribe();
    this.makeDefaultAddressSubscription?.unsubscribe();
    this.makeDefaultPaymentOptionSubscription?.unsubscribe();
    this.removePaymentOptionSubscription?.unsubscribe();
  }

}
