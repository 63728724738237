<style
  id="order-details-dialog-styling"
>
  .cdk-overlay-pane.dialog {
    width: 50vw !important;
  }
</style>
<div
  class="container"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div
    class="content"
    fxLayoutGap="5rem"
    fxLayout="column"
  >
    <div
      class="row items-row"
      fxLayout="column"
      fxLayoutAlign.lt-lg="center center"
    >
      <div class="heading">{{translations.itemsHeading}}</div>
      <div
        class="items-list"
        fxLayout="row wrap"
        fxLayout.lt-lg="column"
        fxLayoutGap="1rem grid"
      >
        <div
          *ngFor="let eachItem of itemList"
          class="each-item"
          fxFlex="50"
          fxFlex.lt-lg="100"
          fxLayout.gt-xs="row"
          fxLayout.xs="column"
          fxLayoutGap="1rem"
        >
          <div class="image-panel"
               fxFlex.gt-md="25"
               fxFlex.gt-xs="30"
               fxFlex.xs="100"
            fxLayoutAlign="center start">
            <app-shimmer-loader
              class="image"
              [theme]="{
                  'background-color': '#e5e5e5',
                  'width': '100%',
                  'height': '100%',
                  'margin-bottom': '0',
                  'border-radius': '1.125rem'
                }"
            ></app-shimmer-loader>
          </div>
          <div
            class="item-details"
            fxFlex.gt-md="75"
            fxFlex.gt-xs="70"
            fxFlex.xs="100"
            fxLayout="column"
          >
            <app-shimmer-loader
              [count]="4"
              [theme]="{
              'background-color': '#e5e5e5',
              'width': '20rem',
              'height': '1.8rem',
              'margin-bottom': '1.3rem'
            }"
            ></app-shimmer-loader>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      fxLayout="column"
    >
      <div
        class="each-section basic-information"
        fxFlex="100"
      >
        <div
          class="order-id"
          fxLayoutGap="3rem"
        >
          <div
            class="label"
            fxFlex="50"
          >
            {{translations.orderNumber}}
          </div>
          <div
            class="value"
            fxFlex="50"
          >
            <app-shimmer-loader
              [theme]="{
              'background-color': '#e5e5e5',
              'width': '13rem',
              'height': '2rem',
              'margin-bottom': '0rem'
            }"
            ></app-shimmer-loader>
          </div>
        </div>
        <div
          class="date"
          fxLayoutGap="3rem">
          <div
            class="label"
            fxFlex="50"
          >{{translations.orderDate}}
          </div>
          <div
            class="value"
            fxFlex="50"
          >
            <app-shimmer-loader
              [theme]="{
              'background-color': '#e5e5e5',
              'width': '10rem',
              'height': '2rem',
              'margin-bottom': '0rem'
            }"
            ></app-shimmer-loader>
          </div>
        </div>
        <div
          class="price"
          fxLayoutGap="3rem">
          <div
            class="label"
            fxFlex="50"
          >
            {{translations.orderTotal}}
          </div>
          <div
            class="value"
            fxFlex="50"
            fxLayout="row"
          >
            <app-shimmer-loader
              [theme]="{
              'background-color': '#e5e5e5',
              'width': '10rem',
              'height': '2rem',
              'margin-bottom': '0rem'
            }"
            ></app-shimmer-loader>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      fxLayout.gt-xs="row"
      fxLayout.xs="column"
      fxLayoutGap.gt-sm="1.5rem"
    >
      <div
        class="each-section payment-details"
        fxFlex.gt-sm="50"
        fxFlex.lt-md="100"
      >
        <div class="heading">{{translations.paymentDetailsHeading}}</div>
        <div class="payment-detail-rows">
          <div class="value payment-detail card-number">
            <app-shimmer-loader
              [count]="2"
            [theme]="{
              'background-color': '#e5e5e5',
              'height': '1.7rem',
              'width': '60%',
              'margin-bottom': '0.62rem'
            }"
          ></app-shimmer-loader></div>
        </div>
      </div>
      <div
        class="each-section discount-details"
        fxFlex.gt-sm="50"
        fxFlex.lt-md="100"
      >
        <div class="heading">{{translations.discountCouponDetailsHeading}}</div>
        <div class="discount-detail-rows">
          <div
            class="value discount-detail no-coupon-applied"
          >
            <app-shimmer-loader
              [theme]="{
              'background-color': '#e5e5e5',
              'height': '2.08rem',
              'width': '25rem',
              'margin-bottom': '0rem'
            }"
            ></app-shimmer-loader>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      fxLayout.gt-xs="row"
      fxLayout.xs="column"
      fxLayoutGap.gt-sm="1.5rem"
    >
      <div
        class="each-section shipping-address"
        fxLayout="column"
        fxFlex.gt-sm="50"
        fxFlex.lt-md="100"
      >
        <div class="heading">{{translations.shippingAddressHeading}}</div>
        <div class="address-rows"
          fxFlex="100">
          <app-shimmer-loader
            [count]="5"
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '100%',
              'height': '1.7rem',
              'margin-bottom': '0.78rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>

    </div>
    <div
      class="row"
      fxLayout="column"
    >
      <div class="value contact-us-text"
           fxFlex="100">
        {{translations.contactUsText}}
      </div>
      <div class="action-cta"
           fxLayoutAlign="center center"
      >
        <app-secondary-button
          [text]="translations.contactUsButton"
          fxFlex="45rem"
        ></app-secondary-button>
      </div>
    </div>
  </div>
</div>

