<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutAlign.xs="center center"
  fxLayoutGap="20px"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div class="strength-container" fxLayout="column">
    <p>{{translations[language].heading}}</p>
  </div>
  <div class="timer-container" fxLayout="column">
    <div
      (value)="handleValueChange($event)"
      [interval]="interval"
      [startTime]="brewDuration"
      class="counter"
      countdownTimer
    >
      <div class="value">{{ value | secondsToMinutes}}</div>
      <div class="label">{{translations[language].timeRemaining}}</div>
    </div>
    <mat-progress-spinner
      [color]="color"
      [mode]="mode"
      [value]="((brewDuration - value)/brewDuration)*100"
      class="spinner">
    </mat-progress-spinner>
  </div>
</div>

