import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { profile } from 'src/app/modules/authentication/core/authentication.selectors';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { Translations } from '../../../translations/tea-details-page/authenticated-brew-notification-info.translation';

@Component({
  selector: 'app-authenticated-brew-notification-info',
  templateUrl: './authenticated-brew-notification-info.component.html',
  styleUrls: ['./authenticated-brew-notification-info.component.css']
})
export class AuthenticatedBrewNotificationInfoComponent implements OnInit, OnDestroy {

  language: number;
  profileData: any;
  @Output() handleClick = new EventEmitter();
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  profileSubscription: Subscription = this.store.select(profile).subscribe(profileData => {
    this.profileData = profileData.profile;
  });

  translations: any = Translations;
  brewTemperature: string;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe()
    this.profileSubscription?.unsubscribe()
  }

}
