interface ForgotPasswordFormTranslation {
  heading: string,
  successHeading: string,
  successMessage: string,
  content: string,
  emailLabel: string,
  confirmButton: string,
  goHomeButton: string
  errorMessageMap: object
}

const en: ForgotPasswordFormTranslation = {
  heading: `Forgot your password?`,
  successHeading: `Reset password requested.`,
  successMessage: `The instruction for obtaining a new password were sent to`,
  content: `The link to reset your password will be sent to this provided email address.`,
  emailLabel: 'Email',
  confirmButton: 'Confirm',
  goHomeButton: 'Back to Home',
  errorMessageMap: {
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email Id.'
    },
  }
}

const ja: ForgotPasswordFormTranslation = {
  heading: `パスワードをお忘れの場合`,
  successHeading: `パスワードリセットを受け付けました`,
  successMessage: `新しいパスワードの設定手順を下記のアドレスに送りました`,
  content: `登録いただいたメールアドレスにパスワードリセットのリンクをお送りいたします`,
  emailLabel: `Eメール`,
  confirmButton: `メールを送信`,
  goHomeButton: 'ホームに戻る',
  errorMessageMap: {
    email: {
      required: '入力が必須です',
      pattern: 'Eメールが無効です'
    },
  }
}

export const Translations = {
  1: en,
  2: ja
}
