<div
  class="container"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <app-orders-list-loader
    *ngIf="loading; else ordersListTemplate"></app-orders-list-loader>
  <ng-template #ordersListTemplate>
    <div
      class="cards-container"
      *ngIf="ordersList?.length"
      [fxLayoutAlign]="(ordersList.length < 4 ? 'center' : 'flex-start') + ' center'"
      fxLayout="row wrap"
    >
      <div
        *ngFor="let eachOrder of ordersList"
        fxFlex.gt-lg="0 0 calc(20% - 22.5rem/5)"
        fxFlex.lg="0 0 calc(25% - 13.5rem/4)"
        fxFlex.md="0 0 calc(33.3333% - 9rem/3)"
        fxFlex.sm="0 0 calc(50% - 4.5rem/2)"
        fxFlex.xs="100"
      >
        <app-order-card
          [order]="eachOrder"
          [language]="language"
        ></app-order-card>
      </div>

    </div>

    <div *ngIf="!ordersList?.length">
      <div
        class="no-data"
        fxLayoutAlign="center center"
      >
        {{translations[language].noData}}
      </div>
      <div
        fxLayoutAlign="center">
        <app-secondary-button
          id="back_to_home"
          class="back-to-home"
          (handleClick)="backToHome()"
          fxFlex="48rem"
          [text]="translations[language].goToShopPage"
        ></app-secondary-button>
      </div>
    </div>
  </ng-template>

</div>

