interface TeaDetailsExperienceInfoTranslation {
  heading: string,
  brewTemperature: string,
  waterQuantity: string,
  brewDuration: string,
  productQuantity: string
}

const en: TeaDetailsExperienceInfoTranslation = {
  heading: `Experience`,
  brewTemperature: `Temperature`,
  waterQuantity: `Water Quantity`,
  brewDuration: `Brew Time`,
  productQuantity: `Tea Quantity`
}

const ja: TeaDetailsExperienceInfoTranslation = {
  heading: `お茶の特徴`,
  brewTemperature: `抽出温度`,
  waterQuantity: `抽出水量`,
  brewDuration: `抽出時間`,
  productQuantity: `茶葉量`
}

export const Translations = {
  1: en,
  2: ja
}
