import {password} from "../../shared/constants/validation.constants";

interface ResetPasswordFormTranslation {
  heading: string,
  passwordLabel: string,
  confirmPasswordLabel: string,
  confirmButton: string,
  passwordInfo: string,
  successMessage: string,
  errorMessageMap: {
    password: {
      required: string,
      minlength: string,
      maxlength: string,
      lowerCaseCheckFailed: string,
      upperCaseCheckFailed: string,
      numberCheckFailed: string,
      specialCharCheckFailed: string,
      hint: string
    },
    confirmPassword: {
      required: string,
      passwordMatchFailed: string
    }
  }
}

const en: ResetPasswordFormTranslation = {
  heading: `Reset Password`,
  passwordLabel: `New Password`,
  confirmPasswordLabel: `Confirm Password`,
  confirmButton: 'Confirm',
  passwordInfo: 'Password should be in English A-Z .Password needs at least 1 digit , 1 special character, 1 uppercase & 1 lower case' +
    ' letter. It should be at least 8 characters long.',
  successMessage: 'The password has been reset',
  errorMessageMap: {
    password: {
      required: 'This field is required.',
      minlength: `Password must contain at least ${password.length.min} characters`,
      maxlength: `Password must contain only ${password.length.max} characters`,
      lowerCaseCheckFailed: 'Password must contain at least 1 Lowercase character',
      upperCaseCheckFailed: 'Password must contain at least 1 Uppercase character',
      numberCheckFailed: 'Password must contain at least 1 numeric character',
      specialCharCheckFailed: 'Password must contain a Special character',
      hint: 'Password should be in English A-Z. Password needs at-least 1 digit, 1 special character, 1 uppercase & 1' +
        ' lower case letter. It should be at-least 8 characters long. '
    },
    confirmPassword: {
      required: 'This field is required.',
      passwordMatchFailed: 'Confirm Password should be same as Password.'
    }
  }
}

const ja: ResetPasswordFormTranslation = {
  heading: `ログイン`,
  passwordLabel: `パスワード`,
  confirmPasswordLabel: `パスワードを確認`,
  confirmButton: '確認',
  passwordInfo: 'パスワードは半角で、少なくとも1つの数字、1つの大文字、1つの小文字、1つの特殊文字を使用して、8文字以上で入力してください',
  successMessage: 'パスワードをリセットしました',
  errorMessageMap: {
    password: {
      required: '入力が必須です',
      minlength: `パスワードは半角で${password.length.min}文字以上で設定してください`,
      maxlength: `パスワードは半角で${password.length.max}文字以内で設定してください`,
      lowerCaseCheckFailed: 'パスワードは1文字以上の半角小文字の英語を入れてください',
      upperCaseCheckFailed: 'パスワードは1文字以上の半角大文字の英語を入れてください',
      numberCheckFailed: 'パスワードは1文字以上の半角数字を入れてください',
      specialCharCheckFailed: 'パスワードは1文字以上の半角特殊文字(&や@等)を入れてください',
      hint: 'パスワードは半角で、少なくとも1つの数字、1つの大文字、1つの小文字、1つの特殊文字を使用して、8文字以上で入力してください'
    },
    confirmPassword: {
      required: '入力が必須です',
      passwordMatchFailed: 'パスワードが一致していません'
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
