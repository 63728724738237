import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../../shared/services/common.service";
import {Translations} from "../../../translations/user-details/orders/order-screen.translations";
import {FetchOrdersListRequest} from "../../core/user-details.actions";
import {ordersList} from "../../core/user-details.selectors";
import {Router} from "@angular/router";

@Component({
  selector: 'app-orders-screen',
  templateUrl: './orders-screen.component.html',
  styleUrls: ['./orders-screen.component.css']
})
export class OrdersScreenComponent implements OnInit, OnDestroy {
  language: number = 1;
  ordersList: any[];
  loading: boolean = false;
  payload: any;
  translations = Translations

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  ordersListSubscription: Subscription = this.store.pipe(select(ordersList)).subscribe(ordersListData => {
    this.ordersList = ordersListData.ordersList;
    this.loading = ordersListData.loading;
  });

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.store.dispatch(FetchOrdersListRequest(
      this.payload = {
        pageNumber: 1,
        limit: 100
      }));
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.ordersListSubscription?.unsubscribe();
  }
}
