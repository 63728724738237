import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../../../state/app.state";
import {Observable} from "rxjs";
import * as UserDetailsActions from "../../../../core/user-details.actions";

@Component({
  selector: 'app-subscription-detail-popup-container',
  templateUrl: './subscription-detail-popup-container.component.html',
  styleUrls: ['./subscription-detail-popup-container.component.css']
})
export class SubscriptionDetailPopupContainerComponent implements OnInit {

  @Input() data;
  language: number;

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit(): void {
    this.language = this.data.language;
    if (this.data.subscriptionId) {
      this.store.dispatch(
        UserDetailsActions.FetchUserSubscriptionDetailsRequest({
          payload: {
            subscriptionId: this.data.subscriptionId,
          },
        })
      );
    }

  }

}
