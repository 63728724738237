import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../../state/app.state";
import {CommonService} from "../../../../../shared/services/common.service";
import * as UserDetailsSelector from "../../../../core/user-details.selectors";
import {isMobileView, selectedLanguage} from "../../../../../shared/core/shared.selectors";
import {Translations} from "../../../../../translations/user-details/my-subscriptions/subscription-details.translation";
import {SubscriptionsService} from "../../../services/subscriptions.service";

@Component({
  selector: 'app-subscription-detail-popup-screen',
  templateUrl: './subscription-detail-popup-screen.component.html',
  styleUrls: ['./subscription-detail-popup-screen.component.css']
})
export class SubscriptionDetailPopupScreenComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: any;

  language: number;
  subscriptionDetails: any;
  addressDetails: any;
  paymentDetails: any;
  translations: any = Translations;
  loading: boolean = false;

  subscriptionDetailsSubscription: Subscription;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  mobileViewSubscription: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private subscriptionsService: SubscriptionsService) {
  }

  ngOnInit(): void {
    this.subscriptionDetailsSubscription = this.store.pipe(select(UserDetailsSelector.getUserSubscriptionDetails)).subscribe(
      (subscriptionDetailsData) => {
        this.subscriptionDetails = subscriptionDetailsData.subscriptionDetails;
        this.addressDetails = this.subscriptionDetails?.shipping?.address
        this.paymentDetails = this.subscriptionDetails?.paymentOption
        this.loading = subscriptionDetailsData.loading;
      }
    );
  }

  handleContactUs(subscriptionId): void {
    window.open(
      `mailto:orders@load-road.com?subject=Regarding subscription Id: ${subscriptionId}`
    );
  }

  handleCancelSubscription(subscriptionId): void {
    const payload = {
      subscriptionId,
    };
    this.subscriptionsService.emitCancelSubscription.emit(payload);
  }

  ngAfterViewInit() {
    this.mobileViewSubscription = this.store.pipe(select(isMobileView)).subscribe((isMobileView) => {
      if (!isMobileView) {
        document.querySelectorAll<HTMLElement>('.cdk-overlay-pane.dialog')[0].classList.add('w60');
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionDetailsSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.mobileViewSubscription?.unsubscribe();
  }

}
