import {user} from '../../../shared/constants/validation.constants';
interface AccountInformationFormTranslation {
  heading: string,
  fullNameLabel: string,
  ageLabel: string,
  genderLabel: string,
  confirmButton: string,
  errorMessageMap: object
}

const en: AccountInformationFormTranslation = {
  heading: `We’d like to know you a bit more.`,
  fullNameLabel: `Full Name`,
  ageLabel: 'Age',
  genderLabel: 'Gender',
  confirmButton: `Confirm`,
  errorMessageMap: {
    fullName: {
      required: 'This field is required.',
      pattern: 'The full name should be alphabetical only.'
    },
    age: {
      required: `This field is required.`,
      min: `Age should not be below ${user.age.min} years. `,
      max: `Age should not be above ${user.age.max} years, `
    },
    gender: {
      required: 'This field is required.'
    }
  }
}

const ja: AccountInformationFormTranslation = {
  heading: `もう少しお聞きしたいです。`,
  fullNameLabel: `フルネーム`,
  ageLabel: '年齢',
  genderLabel: '性別',
  confirmButton: `保存`,
  errorMessageMap: {
    fullName: {
      required: '入力が必須です',
      pattern: '名前は日本語かアルファベットを使用してください'
    },
    age: {
      required: '入力が必須です',
      min: `年齢は${user.age.min}歳以上で入力してください`,
      max: `年齢は${user.age.max}歳以下で入力してください `
    },
    gender: {
      required: '入力が必須です'
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
