<div
  class="each-item"
  fxFlex="50"
  fxFlex.lt-lg="100"
  fxLayout.gt-xs="row"
  fxLayout.xs="column"
  fxLayoutGap="1rem"
>
  <div
    fxFlex.gt-md="25"
    fxFlex.gt-xs="30"
    fxFlex.xs="100"
  >
    <app-image
      [loaderTheme]="{
        'height': '100%',
        'width': '100%',
        'border-radius': '1.125rem'
      }"
      [url]="images[0] || '../../../../assets/images/default-tea.png'"
      class="image-container"
    ></app-image>
  </div>

  <div
    class="item-details"
    fxFlex.gt-md="75"
    fxFlex.gt-xs="70"
    fxFlex.xs="100"
    fxLayout="column"
  >
    <div class="value item-detail item-name ff-lang-ja-text-en">{{itemDetails.name}}</div>
    <div
      *ngIf="itemDetails.subscriptionCode"
      class="item-detail subscription-code">
      <span class="label">{{translations.subscriptionsCodeLabel}} - </span>
      <span class="value">{{itemDetails.subscriptionCode}}</span>
    </div>
    <div
      class="item-detail quantity-details">
      <span class="label">{{translations.quantity}} - </span>
      <span class="value ff-lang-ja-text-en">{{itemDetails.quantity}}</span>
    </div>
    <div
      class="value"
      fxFlex.gt-sm="67"
      fxFlex.lt-md="50"
      fxLayout="row">
      <div
        [ngClass]="{'strike-text': itemDetails?.isDiscountApplied}"
        class="original-amount ff-lang-ja-text-en">
        {{itemDetails?.total | currency: currencySymbol : currencySymbol : '1.0-2' }}
      </div>
      <div
        *ngIf="itemDetails?.isDiscountApplied"
        class="discounted-amount ff-lang-ja-text-en">
        {{itemDetails?.afterDiscount?.total | currency: currencySymbol : currencySymbol : '1.0-2' }}
      </div>
    </div>
    <div class="value item-detail item-purchase-type">
      {{itemDetails.purchaseType}}
    </div>
  </div>
</div>
