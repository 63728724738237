import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { profile } from 'src/app/modules/authentication/core/authentication.selectors';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { NotifyUserRequest } from '../../core/tea-details.actions';
import { getExternalBrewDetails, teaDetails } from '../../core/tea-details.selectors';
import { TeaDetailsService } from '../../core/tea-details.service';

@Component({
  selector: 'app-brew-details-popup',
  templateUrl: './brew-details-popup.component.html',
  styleUrls: ['./brew-details-popup.component.css']
})
export class BrewDetailsPopupComponent implements OnInit {
  selectedBrewStrength = 'ideal';
  selectedBrewDuration: number;
  selectedBrewConfiguration: string;
  selectedBrewTemperature: string;
  stepIndex = 0;
  externalBrewDetails: any;
  profileData: any;
  teaDetailsPageData: any;
  language: number = 1;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  externalBrewDetailsSubscription: Subscription = this.store.select(getExternalBrewDetails).subscribe(data => {
    this.externalBrewDetails = data;
    this.updateBrewStrength(this.selectedBrewStrength);
  });

  teaDetailsSubscription: Subscription = this.store.select(teaDetails).subscribe(data => {
    this.teaDetailsPageData = data;
  });

  profileSubscription: Subscription = this.store.select(profile).subscribe(profileData => {
    this.profileData = profileData.profile;
  });

  notifySubscription = this.teaDetailsService.notify.subscribe((payload) => {
    this.store.dispatch(NotifyUserRequest({ payload }));
  });

  constructor(private store: Store, private teaDetailsService: TeaDetailsService) { }

  ngOnInit(): void {

  }

  updateStepIndex(index) {
    this.stepIndex = index;
  }

  updateBrewStrength(strength) {
    this.selectedBrewStrength = strength;
    const selectedBrewConfiguration = this.externalBrewDetails.brewConfigurations.filter(eachConfiguration => {
      if (eachConfiguration.brewStrength === this.selectedBrewStrength) {
        return eachConfiguration;
      }
    })[0];

    this.selectedBrewConfiguration = selectedBrewConfiguration;
    this.selectedBrewDuration = selectedBrewConfiguration?.brewDuration.split(' ')[0];
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.externalBrewDetailsSubscription?.unsubscribe();
    this.profileSubscription?.unsubscribe();
    this.teaDetailsSubscription?.unsubscribe();
    this.notifySubscription?.unsubscribe();
  }

}
