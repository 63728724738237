import {Component, Input, OnInit} from '@angular/core';
import {Translations} from "../../../../../translations/user-details/personal-information/account-information.translation";

@Component({
  selector: 'app-account-information-loader',
  templateUrl: './account-information-loader.component.html',
  styleUrls: ['./account-information-loader.component.css']
})
export class AccountInformationLoaderComponent implements OnInit {
  @Input() language: number = 1;
  translations: any;

  constructor() { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

}
