import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService} from "../../shared/services/common.service";
import {Translations} from "../../translations/reset-password/reset-password-screen.translations";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../shared/core/shared.selectors";
import * as fromRoot from "../../../state/app.state";

@Component({
  selector: 'app-reset-password-screen',
  templateUrl: './reset-password-screen.component.html',
  styleUrls: ['./reset-password-screen.component.css']
})
export class ResetPasswordScreenComponent implements OnInit, OnDestroy {
  translations: any = Translations;
  language: number = 1;
  resetPasswordSuccessful: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  queryParamSubscription: Subscription = this.route.queryParams.subscribe(params => {
    if (params.passwordReset === '1') {
      this.resetPasswordSuccessful = true;
    }
  })

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
  ) {
    this.commonService.removeAuthenticationToken();
  }

  ngOnInit(): void {
    document.addEventListener('keypress', (e) => {
      if (e.code === 'Enter') {
        this.backToHome();
      }
    });
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.queryParamSubscription?.unsubscribe();
    document.removeEventListener('keypress', (e) => {
    })
  }

}
