<div
  fxLayout="column"
  fxFlexAlign="center">
  <app-shimmer-loader
    fxFlex="100"
    [theme]="{
                'height': '4.6rem',
                'margin-bottom': '3rem'
              }"
  ></app-shimmer-loader>
  <app-shimmer-loader
    fxFlex="100"
    [theme]="{'height': '6rem'}"
  ></app-shimmer-loader>
</div>
