import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {Translations} from "../../../translations/user-details/my-subscriptions/subscriptions-page-screen.translation";
import * as UserDetailsSelector from "../../core/user-details.selectors";
import {Router} from "@angular/router";

@Component({
  selector: 'app-subscriptions-screen',
  templateUrl: './subscriptions-screen.component.html',
  styleUrls: ['./subscriptions-screen.component.css']
})
export class SubscriptionsScreenComponent implements OnInit, OnDestroy {

  language: number;
  subscriptionsSubscription: Subscription;
  subscriptionsList: any = [];
  translations: any = Translations;
  loading: boolean = false;
  hasMoreSubscriptions: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(private store: Store<fromRoot.State>,
              private router: Router) { }

  ngOnInit(): void {
    this.subscriptionsSubscription = this.store.pipe(select(UserDetailsSelector.getSubscriptions)).subscribe((subscriptionsListData) => {
        this.subscriptionsList = subscriptionsListData.subscriptionsList;
        this.loading = subscriptionsListData.loading;
        this.hasMoreSubscriptions = subscriptionsListData.hasMoreSubscriptions;
      }
    );
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this.subscriptionsSubscription.unsubscribe();
    this.languageSubscription?.unsubscribe();
  }

}
