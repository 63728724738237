<div
  class="address-card"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="5px"
  style="height: 100%; word-break: break-all;"
>
  <div
    class="card-header"
    fxLayoutAlign="space-between center">
    <div class="shipping-name">{{ address?.location }}</div>
    <div
      class="actions"
      fxLayout="row">
      <a *ngIf="editButtonVisible" (click)="handleEditButtonClick.emit(address)"
         class="mat-icon-link"><mat-icon class="small">edit</mat-icon></a>
      <ng-container *ngIf="!address?.isDefault && deleteButtonVisible">
        <a (click)="handleDeleteButtonClick.emit(address)"
           class="mat-icon-link"><app-icon [cssClass]="'small'" [icon]="'delete'"></app-icon></a>
      </ng-container>

    </div>
  </div>
  <div class="address-details">
    {{ address?.recipientName }}, {{ address?.addressLine1 }}, {{ address?.addressLine2 }}, {{ address?.city }}

    <div class="address-details">
      {{ address?.state }}, {{ address?.countryName }} - {{ address?.zip }}
    </div>
  </div>

  <!-- </div> -->
  <div class="card-footer" fxLayout="row" fxLayoutAlign="start center" *ngIf="makeDefaultButtonVisible">
    <div fxFlex="100">
      <ng-container *ngIf="address?.isDefault; else elseTemplate">
        <span class="default-label">{{ defaultLabel }}</span>
      </ng-container>
      <ng-template #elseTemplate>
        <div
          fxLayout="row"
          fxLayoutGap="15px"
        >
          <div *ngIf="makeDefaultButtonVisible">
            <app-primary-clickable-text
              (handleClick)="handleMakeDefaultButtonClick.emit(address)"
              [text]="makeDefaultButtonLabel"
              [isLoading]="makeDefaultLoading"
              size="small"
            ></app-primary-clickable-text>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
