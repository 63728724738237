interface AddressSectionTranslation {
  sectionHeading: string,
  defaultLabel: string,
  makeDefaultButtonLabel: string
}

const en: AddressSectionTranslation = {
  sectionHeading: `Shipping Address`,
  defaultLabel: 'Default',
  makeDefaultButtonLabel: 'Make Default'
}

const ja: AddressSectionTranslation = {
  sectionHeading: `お届先住所`,
  defaultLabel: '既定のお届先',
  makeDefaultButtonLabel: '既定のお届先に設定'
}

export const Translations = {
  1: en,
  2: ja
}
