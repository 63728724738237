import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.css']
})
export class OrderItemComponent implements OnInit {
  @Input() itemDetails: any;
  @Input() currencySymbol: string = '';
  @Input() translations: any;

  cdnUrl = environment.cdnURL;
  images: any;

  constructor() {
  }

  ngOnInit(): void {
    this.images = this.itemDetails?.cdnImages?.map((imageUrl) => {
      if (imageUrl) {
        return this.cdnUrl + '/' + imageUrl;
      }
    });
  }

}
