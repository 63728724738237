export interface PaymentOptionFormTranslation {
  heading: string,
  nameLabel: string,
  numberLabel: string,
  expiryLabel: string,
  cvcLabel: string,
  addButton: string,
  updateButton: string,
  errorMessageMap: object
}

const en: PaymentOptionFormTranslation = {
  heading: `Add Card`,
  nameLabel: `Card Holder Name`,
  numberLabel: `Card Number`,
  expiryLabel: `Expiry`,
  cvcLabel: `CVC`,
  addButton: `Add Card`,
  updateButton: `Update Card`,
  errorMessageMap: {
    cardHolderName: {
      required: 'This field is required.',
      pattern: 'Card holder name should be alphabetical only.'
    },
    cardNumber: {
      incomplete_number: 'Your card number is incomplete.',
      invalid_number: `Your card number is invalid.`
    },
    cardExpiry: {
      incomplete_expiry: `Your card's expiration date is incomplete.`,
      invalid_expiry_year: `Your card's expiration year is invalid.`,
      invalid_expiry_year_past: `Your card's expiration year is in the past.`,
      invalid_expiry_month_past: `Your card's expiration date is in the past.`
    },
    cardCvc: {
      incomplete_cvc: `Your card's security code is incomplete.`,
      invalid_cvc: `Your card's security code is invalid.`
    }
  }
}

const ja: PaymentOptionFormTranslation = {
  heading: `カードを追加`,
  nameLabel: `カード名義`,
  numberLabel: `カード番号`,
  expiryLabel: `カード有効期限`,
  cvcLabel: `セキュリティ番号`,
  addButton: `カードを追加`,
  updateButton: `カードを更新`,
  errorMessageMap: {
    cardHolderName: {
      required: '入力が必須です',
      pattern: '名前には日本語かアルファベットを使用してください'
    },
    cardNumber: {
      incomplete_number: 'カード番号を再度ご確認ください',
      invalid_number: `カード番号を再度ご確認ください`
    },
    cardExpiry: {
      incomplete_expiry: `カード有効期限を再度ご確認ください`,
      invalid_expiry_year: `カード有効期限を再度ご確認ください`,
      invalid_expiry_year_past: `カード有効期限を再度ご確認ください`,
      invalid_expiry_month_past: `カード有効期限を再度ご確認ください`
    },
    cardCvc: {
      incomplete_cvc: `カードセキュリティー番号を再度ご確認ください`,
      invalid_cvc: `カードセキュリティー番号を再度ご確認ください`
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
