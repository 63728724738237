<app-half-content-half-image>
  <div
    class="reset-password-form-container"
    ngClass.xs="xs">
    <div
      *ngIf="!resetPasswordSuccessful"
      class="reset-password-form"
    >
      <app-reset-password-form></app-reset-password-form>
    </div>
    <div
      *ngIf="resetPasswordSuccessful"
      class="reset-password-success"
      fxLayout="column"
      fxLayout.xs="column"
      fxLayoutAlign.xs="center center"
      fxLayoutGap="5rem"
    >
      <div class="message">
        {{translations[language].successMessage}}
      </div>
      <div class="back-to-home-btn">
        <app-secondary-button
          id="back_to_home"
          (handleClick)="backToHome()"
          text="{{translations[language].backToHome}}"
        >
        </app-secondary-button>
      </div>
    </div>
  </div>
</app-half-content-half-image>
