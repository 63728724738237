interface ResetPasswordScreenTranslations {
  successMessage: string
  backToHome: string
}

const en: ResetPasswordScreenTranslations = {
  successMessage: 'The password has been reset',
  backToHome: 'Back To Home',
}

const ja: ResetPasswordScreenTranslations = {
  successMessage: 'パスワードをリセットしました',
  backToHome: 'ホームに戻る',
}

export const Translations = {
  1: en,
  2: ja
}
