interface TeaDetailsPairingInfoTranslation {
  heading: string
}

const en: TeaDetailsPairingInfoTranslation = {
  heading: `Pairings`
}

const ja: TeaDetailsPairingInfoTranslation = {
  heading: `ティーマリアージュ`
}

export const Translations = {
  1: en,
  2: ja
}
