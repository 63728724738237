<div
  class="subscription-card"
  fxLayout="column"
  fxLayoutGap="0.5rem"
>
  <div
    fxLayoutAlign="center center"
    class="card-header"
  >
    <app-shimmer-loader
      [theme]="{
              'background-color': '#e5e5e5',
              'height': '2.16rem',
              'width': '100%',
              'margin-bottom': '1.08rem'
            }"
    ></app-shimmer-loader>
  </div>
  <div
    *ngFor="let eachInfo of[1, 2, 3]"
    class="info-row"
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <app-shimmer-loader
      [theme]="{
                'background-color': '#e5e5e5',
                'height': '1.8rem',
                'width': '8rem',
                'margin-bottom': '0.2rem'
              }"
    ></app-shimmer-loader>
    <app-shimmer-loader
      [theme]="{
                'background-color': '#e5e5e5',
                'height': '1.8rem',
                'width': '10rem',
                'margin-bottom': '0.2rem'
              }"
    ></app-shimmer-loader>
  </div>
  <div fxLayoutAlign="center center">
    <div class="image-panel">
      <app-shimmer-loader
        class="image"
        [theme]="{
                  'background-color': '#e5e5e5',
                  'height': '16rem',
                  'width': '17rem',
                  'margin': '1rem',
                  'border-radius': '1rem'
                }"
      ></app-shimmer-loader>
  </div>
</div>











