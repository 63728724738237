import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TeaDetailsService} from './tea-details.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../../shared/services/common.service';
import {catchError, map, mergeMap, of, repeat, tap} from 'rxjs';
import * as TeaDetailsActions from '../core/tea-details.actions';
import {units} from '../../shared/constants/common.constants';
import * as SharedSelectors from '../../shared/core/shared.selectors';
import {profile} from '../../authentication/core/authentication.selectors';
import {environment} from "../../../../environments/environment";

@Injectable()
export class TeaDetailsEffects {
  cdnUrl = environment.cdnURL;
  fetchTeaDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeaDetailsActions.FetchTeaDetailsRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.fetchProductDetails(payload).pipe(
          map((response: any) => {
            const data = response.data;
            const experienceObj =
              (data?.brewConfigurations?.filter(
                (i) => i.brewStrength === 2
              ))[0] || {};
            let unitSystem: number = 1;
            let language: number = 1;
            this.store.select(profile).subscribe((profileData) => {
              unitSystem = profileData.profile.unitSystem;
            }).unsubscribe();

            this.store.select(SharedSelectors.selectedLanguage).subscribe((data) => {
              language = data;
            }).unsubscribe();

            const experience = [];

            if (experienceObj.brewTemperature) {
              experience.push({
                title: 'brewTemperature',
                value:
                  `<span class="ff-lang-ja-text-en">${this.commonService.formatByUnitSystem(
                    experienceObj.brewTemperature,
                    'temperature',
                    unitSystem
                  )}</span>` +
                  ' ' +
                  `<span class="ff-lang-ja-text-en">${units[unitSystem][language]?.temperature}</span>`,
                icon: 'temperature',
              })
            }

            if (experienceObj.waterQuantity) {
              experience.push({
                title: 'waterQuantity',
                value:
                  `<span class="ff-lang-ja-text-en">${this.commonService.formatByUnitSystem(
                    experienceObj.waterQuantity,
                    'volume',
                    unitSystem
                  )}</span>` +
                  ' ' +
                  `<span class="ff-lang-ja-text-en">${units[unitSystem][language]?.volume}</span>`,
                icon: 'water',
              })
            }

            if (experienceObj.brewDuration) {
              experience.push({
                title: 'brewDuration',
                value:
                  `<span class="ff-lang-ja-text-en">${this.commonService.formatByUnitSystem(
                    experienceObj.brewDuration,
                    'time',
                    unitSystem
                  )}</span>` +
                  ' ' +
                  units[unitSystem][language]?.time,
                icon: 'time',
              },)
            }

            if (experienceObj.productQuantity) {
              experience.push({
                title: 'productQuantity',
                value:
                  `<span class="ff-lang-ja-text-en">${this.commonService.formatByUnitSystem(
                    experienceObj.productQuantity,
                    'mass',
                    unitSystem
                  )}</span>` +
                  ' ' +
                  `<span class="ff-lang-ja-text-en">${units[unitSystem][language]?.mass}</span>`,
                icon: 'tea',
              })
            }

            const teaDetails = {
              id: data?._id,
              images: data?.cdnImageUrls?.filter((eachImageObj) => {
                if (eachImageObj.type === 'webImage') {
                  return eachImageObj;
                }
              })[0].urls.map(eachUrl => {
                if (eachUrl) {
                  return this.cdnUrl + '/' + eachUrl;
                }
              }),
              brewConfigurations: data?.brewConfigurations
                ?.filter((eachConfiguration) => {
                  if (
                    (eachConfiguration.brewMode === 3 &&
                      eachConfiguration.brewStrength !== 4) ||
                    (eachConfiguration.brewMode === 2 &&
                      eachConfiguration.brewStrength === 6)
                  ) {
                    return eachConfiguration;
                  }
                })
                .map((eachConfiguration) => {
                  const brewStrengthMap = {
                    1: {
                      key: 'mild',
                      order: 1,
                    },
                    2: {
                      key: 'ideal',
                      order: 2,
                    },
                    3: {
                      key: 'strong',
                      order: 3,
                    },
                    6: {
                      key: 'coldCarafe',
                      order: 4,
                    },
                  };
                  let brewDuration = eachConfiguration.brewDuration;

                  if (eachConfiguration.brewStrength === 6) {
                    brewDuration = this.commonService.secondsToHms(
                      eachConfiguration.brewDuration,
                      language,
                      unitSystem
                    );
                  }
                  return {
                    order:
                    brewStrengthMap[eachConfiguration.brewStrength].order,
                    brewStrength:
                    brewStrengthMap[eachConfiguration.brewStrength].key,
                    brewDuration:
                      this.commonService.formatByUnitSystem(
                        brewDuration,
                        'time',
                        unitSystem
                      ) +
                      (eachConfiguration.brewStrength !== 6
                        ? ' ' + units[unitSystem][language]?.time
                        : ''),
                    brewTemperature:
                      this.commonService.formatByUnitSystem(
                        eachConfiguration.brewTemperature,
                        'temperature',
                        unitSystem
                      ) +
                      ' ' +
                      units[unitSystem][language]?.temperature,
                    productQuantity:
                      this.commonService.formatByUnitSystem(
                        eachConfiguration.productQuantity,
                        'mass',
                        unitSystem
                      ) +
                      ' ' +
                      units[unitSystem][language]?.mass,
                    waterQuantity:
                      this.commonService.formatByUnitSystem(
                        eachConfiguration.waterQuantity,
                        'volume',
                        unitSystem
                      ) +
                      ' ' +
                      units[unitSystem][language]?.volume,
                  };
                })
                .sort((a, b) => {
                  return a.order - b.order;
                }),
              name: data?.eCommerceDisplayName,
              key: data?.key,
              shortDescription: data?.productInfo?.shortDescription,
              region: data?.productInfo?.region,
              country: data?.productInfo?.country,
              pairings: data?.brewPairing.map(
                (eachBrewParing) => {
                  if (eachBrewParing?.cdnImageUrl) {
                    eachBrewParing.cdnImageUrl = this.cdnUrl + '/' + eachBrewParing.cdnImageUrl;
                  }
                  return eachBrewParing;
                }
              ),
              storyInfo: {
                title: data?.productInfo?.storyTitle,
                description: data?.productInfo?.infoDescription,
                imageUrl:
                  data?.cdnImageUrls?.filter((imageObj) => {
                    if (imageObj.type === 'storyImage') {
                      return imageObj;
                    }
                  })[0].urls.map(eachUrl => {
                    if (eachUrl) {
                      return this.cdnUrl + '/' + eachUrl;
                    }
                  }),
              },
              aromaDescription: data?.productInfo?.aromaDescription,
              appearanceDescription: data?.productInfo?.appearanceDescription,
              brewTasteDescription: data?.productInfo?.brewTasteDescription,
              vendor: data.vendor,
              experience,
              pricingPlans: data?.pricingPlans?.map((eachPricingPlan) => {
                return {
                  key: eachPricingPlan._id,
                  value: `${eachPricingPlan.currencySymbol} ${eachPricingPlan.price}`,
                };
              }),
              isAvailableInCountry: data?.isAvailableInCountry,
              availableQuantity: data?.availableQuantity,
              quantityDescription: data?.productInfo?.quantityDescription,
            };
            return TeaDetailsActions.FetchTeaDetailsSuccess({teaDetails});
          })
        )
      ),
      catchError((error) => {
        return of(
          TeaDetailsActions.FetchTeaDetailsFailure({message: error.message})
        );
      }),
      tap((action) => {
        if (action.type === TeaDetailsActions.FetchTeaDetailsSuccess.type) {
          this.store.dispatch(
            TeaDetailsActions.FetchTeaReviewSummaryRequest({
              payload: {
                teaId: action.teaDetails.id,
              },
            })
          );
          this.store.dispatch(
            TeaDetailsActions.FetchTeaReviewRequest({
              payload: {
                teaId: action.teaDetails.id,
              },
            })
          );
        }
      }),
      repeat()
    )
  );

  fetchTeaReviewSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeaDetailsActions.FetchTeaReviewSummaryRequest),
      map((payload) => {
        return payload.payload;
      }),
      mergeMap((payload) =>
        this.cartService
          .fetchProductReviewsSummary({
            productId: payload.teaId,
            isSummary: true,
          })
          .pipe(
            map((teaDetails) => {
              return TeaDetailsActions.FetchTeaReviewSummarySuccess({
                userReviewsSummary: teaDetails.data,
              });
            }),
            catchError((error) => {
              return of(
                TeaDetailsActions.FetchTeaReviewSummaryFail({
                  message: error.message,
                })
              );
            })
          )
      ),
    )
  );

  fetchTeaReview$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeaDetailsActions.FetchTeaReviewRequest),
      map((payload) => {
        return payload.payload;
      }),
      mergeMap((payload) => {
        const currentpage = this.cartService.userReviewsCurrentPage.value;
        return this.cartService
          .fetchProductReviewsSummary({
            productId: payload.teaId,
            isSummary: false,
            pageNumber: currentpage, limit: 100
          })
          .pipe(
            map((teaDetails) => {
              return TeaDetailsActions.FetchTeaReviewSuccess({
                userReviews: teaDetails.data,
              });
            }),
            catchError((error) => {
              return of(
                TeaDetailsActions.FetchTeaReviewFail({
                  message: error.message,
                })
              );
            })
          )
      }),
      tap((action) => {
        if (action.type === TeaDetailsActions.FetchTeaReviewSuccess.type) {
          // Code to execute on API Success Action dispatch
          this.cartService.setNextUserReviewPage()
        } else if (action.type === TeaDetailsActions.FetchTeaReviewFail.type) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );

  notifyUser$ = createEffect(
    () => this.actions.pipe(
      ofType(TeaDetailsActions.NotifyUserRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.notifyUser(payload).pipe(
          map((response: any) => {
            return TeaDetailsActions.NotifyUserSuccess({
              message: response.message
            });
          }),
          catchError((error) => {
            return of(TeaDetailsActions.NotifyUserFailure(error.message));
          }),
          tap((action) => {
            if (action.type === TeaDetailsActions.NotifyUserSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === TeaDetailsActions.NotifyUserFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );


  constructor(
    private store: Store<fromRoot.State>,
    private cartService: TeaDetailsService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {
  }
}
