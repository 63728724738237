import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-user-reviews',
  templateUrl: './user-reviews.component.html',
  styleUrls: ['./user-reviews.component.css'],
})
export class UserReviewsComponent implements OnInit {
  @Input() userReviews;
  @Input() loading = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  getRatingImages() {
    let imageUrl = '';
    switch (this.userReviews.value) {
      case 0:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/poor.webp`;
        break;
      case 1:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/normal.webp`;
        break;
      case 2:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/good.webp`;
        break;
      case 3:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/great.webp`;
        break;
    }
    return imageUrl;
  }
}
