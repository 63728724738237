<app-half-content-half-image>
  <div class="message-container">
    <div class="error-message">
      {{translations[language].errorMessage}}
    </div>
    <div class="message">
      {{translations[language].message}}
    </div>
  </div>
</app-half-content-half-image>

