<style
  id="order-details-dialog-styling"
>
  .cdk-overlay-pane.dialog {
    width: 50vw !important;
  }
</style>
<app-order-details-loader
  *ngIf="loading; else orderDetailsTemplate"
  [translations]="translations[language]"></app-order-details-loader>

<ng-template #orderDetailsTemplate>
  <div
    class="container"
    ngClass.md="md"
    ngClass.sm="sm"
    ngClass.xs="xs"
  >
    <div
      class="content"
      fxLayoutGap="5rem"
      fxLayout="column"
    >
      <div
        class="row items-row"
        fxLayout="column"
        fxLayoutAlign.lt-lg="center center"
      >
        <div
          class="heading">{{orderDetails?.items?.length > 1 ? translations[language].items : translations[language].item}}</div>
        <div
          class="items-list"
          fxLayout="row wrap"
          fxLayout.lt-lg="column"
          fxLayoutGap="1rem grid"
        >
          <div
            *ngFor="let eachItem of orderDetails.items"
            fxFlex="100"
          >
            <app-order-item
              fxFlex="100"
              [itemDetails]="eachItem"
              [translations]="translations[language]"
              [currencySymbol]="orderDetails.currencySymbol"
            ></app-order-item>
          </div>
        </div>
      </div>
      <div
        class="row"
        fxLayout="column"
      >
        <div
          class="each-section basic-information"
          fxFlex="100"
        >
          <div
            class="order-id"
          >
            <div
              class="label"
              fxFlex="20"
            >
              {{translations[language].orderNumber}}
            </div>
            <div
              class="value ff-lang-ja-text-en"
              fxFlex="50"
            >
              {{orderDetails.orderCode}}
            </div>
          </div>
          <div
            class="date">
            <div
              class="label"
              fxFlex="20"
            >{{translations[language].orderDate}}
            </div>
            <div
              *ngIf="language !== 2"
              class="value ff-lang-ja-text-en"
              fxFlex="50"
            >
              {{orderDetails.createdAt | date}}
            </div>
            <div
              *ngIf="language === 2"
              class="value ff-lang-ja-text-en"
              fxFlex="50"
            >
              {{orderDetails.createdAt | date:dateFormat}}
            </div>
          </div>
          <div
            class="price">
            <div
              class="label"
              fxFlex="20"
            >
              {{translations[language].orderTotal}}
            </div>
            <div
              class="value"
              fxFlex="50"
              fxLayout="row"
            >
              <div
                [ngClass]="{'strike-text': orderDetails.isDiscountApplied}"
                class="original-amount ff-lang-ja-text-en"
              >
                {{orderDetails.total | currency: orderDetails.currencySymbol : orderDetails.currencySymbol : '1.0-2' }}
              </div>
              <div
                *ngIf="orderDetails.isDiscountApplied"
                class="discounted-amount ff-lang-ja-text-en"
              >
                {{orderDetails.afterDiscount.total | currency: orderDetails.currencySymbol : orderDetails.currencySymbol : '1.0-2' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        fxLayout.gt-xs="row"
        fxLayout.xs="column"
        fxLayoutGap.gt-sm="1.5rem"
      >
        <div
          class="each-section payment-details"
          fxFlex.gt-sm="50"
          fxFlex.lt-md="100"
        >
          <div class="heading">{{translations[language].paymentDetailsHeading}}</div>
          <div class="payment-detail-rows">
            <div class="value payment-detail name">{{orderDetails?.paymentOption?.details?.name}}</div>
            <div class="value payment-detail card-number ff-lang-ja-text-en">
              XXXX-XXXX-XXXX-{{orderDetails?.paymentOption?.details?.last4Digits}}</div>
          </div>
        </div>
        <div
          class="each-section discount-details"
          fxFlex.gt-sm="50"
          fxFlex.lt-md="100"
        >
          <div class="heading">{{translations[language].discountCouponDetailsHeading}}</div>
          <div class="discount-detail-rows">
            <div
              *ngIf="orderDetails?.discountCouponDetails?.couponCode"
              class="value discount-detail coupon-code"
            >
              {{orderDetails?.discountCouponDetails?.couponCode}}
            </div>
            <div
              *ngIf="!orderDetails?.discountCouponDetails?.couponCode"
              class="value discount-detail no-coupon-applied"
            >
              {{translations[language].noCouponAppliedText}}
            </div>
          </div>
        </div>
      </div>

      <!--		commenting out the code Need to be reviewed later-->
      <!--		<div-->
      <!--			fxLayout="column"-->
      <!--			class="row"-->
      <!--		>-->
      <!--			<div fxFlex="100" class="heading">{{translations[language].taxesHeading}}</div>-->
      <!--			<div fxLayout="row">-->
      <!--				<div-->
      <!--					fxFlex.lg="50"-->
      <!--					fxFlex.md="50"-->
      <!--					fxFlex.sm="50"-->
      <!--					fxFlex.xs="100"-->
      <!--					class="tax-details"-->
      <!--				>-->
      <!--					<div fxFlex="50" class="label">{{translations[language].taxAmount}}</div>-->
      <!--					<div fxFlex="50" class="value">{{orderDetails?.tax | currency: orderDetails?.currencySymbol}}</div>-->
      <!--				</div>-->
      <!--			</div>-->
      <!--		</div>-->
      <div
        class="row"
        fxLayout.gt-xs="row"
        fxLayout.xs="column"
        fxLayoutGap.gt-sm="1.5rem"
      >
        <div
          class="each-section shipping-address"
          fxFlex.gt-sm="50"
          fxFlex.lt-md="100"
        >
          <div class="heading">{{translations[language].shippingAddressHeading}}</div>
          <div class="address-rows">
            <div class="value address-row address-location">{{ orderDetails?.shipping?.address['location'] }}</div>
            <div
              class="value address-row address-recipient-name">{{ orderDetails?.shipping?.address['recipientName'] }}</div>
            <div class="value address-row address-line-1">{{orderDetails?.shipping?.address['addressLine1']}}</div>
            <div class="value address-row address-line-2">{{orderDetails?.shipping?.address['addressLine2']}}</div>
            <div class="value address-row address-city-state-zip">
            <span *ngIf="orderDetails?.shipping?.address['city']">
              {{orderDetails?.shipping?.address['city']}}
            </span>
              <span *ngIf="orderDetails?.shipping?.address['state']">
              {{', ' + orderDetails?.shipping?.address['state']}}
            </span>
              <span *ngIf="orderDetails?.shipping?.address['zip']">
              {{', ' + orderDetails?.shipping?.address['zip']}}
            </span>
            </div>
            <div class="value address-row country">{{orderDetails?.shipping?.address['country']}}</div>
          </div>
        </div>
        <!--			<div-->
        <!--				*ngIf="!orderDetails?.trackingDetails?.id"-->
        <!--				class="each-section tracking-details"-->
        <!--				fxFlex.lg="50"-->
        <!--				fxFlex.md="50"-->
        <!--				fxFlex.sm="100"-->
        <!--				fxFlex.xs="100"-->
        <!--			>-->
        <!--				<div class="heading">{{translations[language].trackingDetailsHeading}}</div>-->
        <!--				<div class="tracking-detail-rows">-->
        <!--					<div class="value tracking-detail tracking-id">{{orderDetails?.trackingDetails?.id}}</div>-->
        <!--					<div class="value tracking-detail tracking-status">{{orderDetails?.trackingDetails?.status}}</div>-->
        <!--				</div>-->
        <!--			</div>-->
      </div>
      <div
        class="row"
        fxLayout="column"
      >
        <div class="value contact-us-text"
             fxFlex="100">
          <span [innerHtml]="translations[language].contactUsText"></span>
        </div>
        <app-secondary-button
          fxLayoutAlign="center center"
          class="value contact-us-button"
          (handleClick)="handleContactUs(orderDetails.orderCode)"
          [text]="translations[language].contactUsButton"
        ></app-secondary-button>
      </div>
    </div>
  </div>
</ng-template>
