<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="center none"
  ngClass.xs="xs"
>
  <div class="heading">
    {{translations.heading}}
  </div>
  <form
    [formGroup]="accountForm"
    fxLayout="column"
    fxLayoutAlign="space-around none"
  >
    <div class="form-fields">
      <app-text-input
        id="fullname"
        (checkForErrors)="checkForErrors($event)"
        [error]="errorMessages['fullName']"
        [parentFormGroup]="accountForm"
        class="input-control full-name-input"
        controlName="fullName"
        [isSlim]="true"
        label="{{translations.fullNameLabel}}"
        [maxLength]="validations.user.fullName.length.max"
      ></app-text-input>
      <app-number-input
        id="age"
        (checkForErrors)="checkForErrors($event)"
        [error]="errorMessages['age']"
        [parentFormGroup]="accountForm"
        class="input-control age-input"
        controlName="age"
        [isSlim]="true"
        label="{{translations.ageLabel}}"
        (keydown)="restrictZero($event)"
        [maxLength]="validations.user.age.length.max"
      ></app-number-input>
    </div>
    <div class="form-actions">
      <app-primary-button
        id="update"
        (handleClick)="updateAccountInformation()"
        [isLoading]="updateAccountLoading"
        class="update-button"
        [isSlim]="true"
        text="{{translations.confirmButton}}"
      >
      </app-primary-button>
    </div>
  </form>
</div>
