import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../../shared/services/common.service";
import {Subscription} from "rxjs";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {ForgotPasswordRequest, SetForgotPasswordStatus} from "../../core/authentication.actions";
import {Translations} from "../../../translations/authentication/forms/forgot-password-form.translation";
import * as Validations from "../../../shared/constants/validation.constants";
import {isForgotPasswordSuccessful} from "../../core/authentication.selectors";

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.css']
})
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {
  focus: any = {};
  language: number = 1;
  validations = Validations;
  forgotPasswordSuccess: boolean;
  maskedEmailID: string;

  form: FormGroup = this.fb.group({
    email: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.pattern(this.validations.email.regex)
        ],
      })
  });

  translations = Translations;
  errorMessages = {};
  errorMessageMap = {};

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
    this.errorMessageMap = this.translations[this.language].errorMessageMap;
  });

  forgotPasswordSubscription: Subscription = this.store.pipe(select(isForgotPasswordSuccessful)).subscribe(data => {
    this.forgotPasswordSuccess = data.isForgotPasswordSuccessful;
    const email = data.emailId;
    const maskEmail = (email = '') => {
      const [name, domain] = email.split('@');
      const {length: len} = name;
      const maskedName = name[0] + '*'.repeat(len - 1);
      return maskedName + '@' + domain;
    };
    this.maskedEmailID = (maskEmail(email));
  })


  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {

  }

  ngOnInit(): void {
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  sendEmail() {
    if (this.form.invalid) {
      this.initializeFocus();
      this.checkForErrors();
      this.commonService.setFocus(this.form, this.errorMessages, this.focus);
      return;
    }

    const {email} = this.form.getRawValue();
    const payload = {
      email
    };

    this.store.dispatch(ForgotPasswordRequest({payload}));
  }

  backToHome() {
    const isForgotPasswordSuccessful = {
      isForgotPasswordSuccessful: false,
      emailId: ''
    }
    this.store.dispatch(SetForgotPasswordStatus(isForgotPasswordSuccessful));
    this.commonService.closeDialog();
  }

  initializeFocus() {
    this.focus = {
      email: false
    }
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.forgotPasswordSubscription?.unsubscribe();
  }
}
