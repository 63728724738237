interface UserDetailsHeaderTranslation {
  logoutButton: string,
  welcomeMessage: string,
  personalInformation: string,
  myOrders: string,
  mySubscriptions: string,
}

const en: UserDetailsHeaderTranslation = {
  logoutButton: `LOGOUT`,
  welcomeMessage: `Welcome to Your Account`,
  personalInformation: `Personal Information`,
  myOrders: `My Orders`,
  mySubscriptions: `My Subscriptions`
}

const ja: UserDetailsHeaderTranslation = {
  logoutButton: `ログアウト`,
  welcomeMessage: `アカウント設定`,
  personalInformation: `お名前`,
  myOrders: `注文履歴`,
  mySubscriptions: `サブスクリプション`
}

export const Translations = {
  1: en,
  2: ja
}
