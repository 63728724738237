import {Component, OnDestroy, OnInit} from '@angular/core';
import {isMobileView} from "../../../shared/core/shared.selectors";
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-pakage-details-loader',
  templateUrl: './pakage-details-loader.component.html',
  styleUrls: ['./pakage-details-loader.component.css']
})
export class PakageDetailsLoaderComponent implements OnInit, OnDestroy {

  isMobileView: boolean;
  mobileSubscription: Subscription = this.store
    .pipe(select(isMobileView))
    .subscribe((data) => {
      this.isMobileView = data;
    });

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.mobileSubscription?.unsubscribe();
  }

}
