<div
  class="container"
  fxLayoutAlign="center"
>
  <app-carousel
    [arrowSize]="'sm'"
    [slidesData]="itemList"
    fxFlexAlign="center"
    ngClass.xs="xs"
    fxFlex="80"
  >
    <ng-template let-item>
      <div
        fxLayoutAlign="space-between">
        <app-shimmer-loader
          fxFlex="60"
          [theme]="{
              'background-color': '#e5e5e5',
              'height': '2.25rem',
              'margin-bottom': '0.5rem'
            }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          fxFlex="10"
          [theme]="{
              'background-color': '#e5e5e5',
              'height': '2.25rem',
              'width':'1.6rem',
              'margin-bottom': '0.5rem',
              'margin-left': '1.1rem'
            }"
        ></app-shimmer-loader>
      </div>
      <app-shimmer-loader
        [count]="2"
        [theme]="{
                'background-color': '#e5e5e5',
                'height': '1.5rem',
                'margin-bottom': '0.66rem'
              }"
      ></app-shimmer-loader>
      <app-shimmer-loader
        [theme]="{
                'background-color': '#e5e5e5',
                'height': '2.36rem',
                'width': '60%',
                'margin-top': '0.55rem',
                'margin-bottom': '0'
              }"
      ></app-shimmer-loader>
    </ng-template>
  </app-carousel>
</div>
