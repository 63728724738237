<div
  class="container"
  ngClass.xs="xs"
>
  <div
    class="cart-row"
    fxLayout="row"
    fxLayout.lt-sm="row"
    fxLayoutAlign="start start"
    fxLayoutGap="1rem"
    [ngClass.lt-sm]="'grey-background'"
  >
    <div
      class="image-box"
      fxFlex="15"
      fxFlex.xs="40"
      fxFlex.sm="30"
      fxFlex.md="20"
    >
      <div
        class="image-container"
        fxFlex="100"
      >
        <img
          [src]="images[0]"
          alt=""
          class="image">
      </div>
    </div>
    <div
      class="details-box"
      fxFlex="70"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-around start"
      fxLayoutAlign.lt-md="space-around strech"
      fxLayoutGap="1rem"
    >
      <div
        class="item-details"
        fxLayout="column"
        fxFlex="50"
        fxFlex.lt-md="100"
        fxFlex.gt-sm="45"
        fxLayoutGap="0.6rem"
        fxLayoutAlign="space-evenly start"
      >
        <div class="name ff-lang-ja-text-en">{{data?.name}}</div>
        <div
          class="price"
          fxLayoutAlign="space-between center"
          fxLayoutGap="1rem"
        >
          <div
            class="ff-lang-ja-text-en"
            [class.strike-text]="data?.isDiscountApplied">
            {{data?.total | currency: currencySymbol:currencySymbol:'1.0'}}
          </div>
          <div *ngIf="data?.isDiscountApplied"
               class="discounted-value discounted-total ff-lang-ja-text-en">
            {{data?.afterDiscount?.total | currency: currencySymbol:currencySymbol:'1.0'}}
          </div>
        </div>
        <div class="purchase-type">{{data?.purchaseType}}</div>
      </div>
      <div
        class="quantity-editor"
        fxFlex="50"
        fxFlex.lt-sm="100"
        fxFlex.md="60"
        fxFlex.gt-md="65"
        fxLayout="row"
      >
        <div
          class="quantity-row"
          fxLayout="column"
          fxFlex="60"
          fxFlex.lt-md="100"
          fxFlex.md="100"
          fxFlex.gt-md="70"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="start start"
        >
          <div class="quantity-label">
            {{translations[language].quantity}}
          </div>
          <div
            class="value"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="0.5rem"
          >
            <div
              (click)="data?.quantity == 1 ? null : decreaseQuantity(data._id)"
              class="quantity-counter-element"
            >
              <div
                [class.disabled]="data?.quantity === 1"
                class="decrement">
                -
              </div>
            </div>
            <div class="quantity-counter-element">
              <div class="quantity ff-lang-ja-text-en">{{data.quantity}}</div>
            </div>
            <div
              (click)="data?.quantity == 5 ? null : increaseQuantity(data._id)"
              class="quantity-counter-element"
            >
              <div
                [class.disabled]="data?.quantity === 5"
                class="increment">
                +
              </div>
            </div>
            <div
              class="remove-item"
              fxFlex="40"
              fxFlex.lt-sm="25"
              fxFlex.md="40"
              fxFlex.gt-md="30"
              fxLayout="column"
              fxLayoutAlign="center start"
            >
              <app-icon
                class="delete-button"
                [icon]="'delete'"
                (click)="removeItem(data._id)"
              >
              </app-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
