import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../translations/user-details/personal-information/delete-payment-option-confirm-dialog.translation";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../state/app.state";
import {CommonService} from "../../../../shared/services/common.service";
import {ActivatedRoute} from "@angular/router";
import {AddressService} from "../../services/address.service";
import {UserPaymentOptionModel} from "../../../../models/user-payment-option.model";
import {PaymentOptionService} from "../../services/payment-option.service";
import {getLoadingFlags} from "../../../core/user-details.selectors";

@Component({
  selector: 'app-delete-payment-option-confirm-dialog',
  templateUrl: './delete-payment-option-confirm-dialog.component.html',
  styleUrls: ['./delete-payment-option-confirm-dialog.component.css']
})
export class DeletePaymentOptionConfirmDialogComponent implements OnInit, OnDestroy {
  @Input() data: any;
  paymentOption: UserPaymentOptionModel;

  language: number = 1;
  translations: any = Translations;
  loading: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.loading = loadingFlags.removePaymentOption?.loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private route: ActivatedRoute,
              private paymentOptionService: PaymentOptionService) { }

  ngOnInit(): void {
    this.paymentOption = this.data?.paymentOption;
    this.translations = Translations[this.language];
  }

  onConfirm() {
    this.paymentOptionService.removePaymentOption.emit({
      payload: {
        paymentOptionId: this.paymentOption._id
      }
    });
  }

  onCancel() {
    this.commonService.closeDialog();
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
  }

}
