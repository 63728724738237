import {address, phoneNumber} from '../../../shared/constants/validation.constants';

interface AddressFormTranslation {
  addHeading: string,
  editHeading: string,
  nameLabel: string,
  recipientNameLabel: string,
  addressLine1Label: string,
  addressLine2Label: string,
  cityLabel: string,
  stateLabel: string,
  zipLabel: string,
  countryLabel: string,
  phoneCodeLabel: string,
  phoneNumberLabel: string,
  addAddressButton: string,
  updateAddressButton: string,
  removeAddressButton: string,
  errorMessageMap: object
}

const en: AddressFormTranslation = {
  addHeading: `Add Address`,
  editHeading: `Edit Address`,
  nameLabel: `Location Name`,
  recipientNameLabel: `Shipping Name`,
  addressLine1Label: `Address Line 1`,
  addressLine2Label: `Address Line 2`,
  cityLabel: `City`,
  stateLabel: `State`,
  zipLabel: `Zip`,
  countryLabel: `Country`,
  phoneCodeLabel: `Code`,
  phoneNumberLabel: `Phone Number`,
  addAddressButton: `Add Address`,
  updateAddressButton: `Update Address`,
  removeAddressButton: `Remove Address`,
  errorMessageMap: {
    location: {
      required: 'This field is required.',
      whitespace: 'Only spaces not allowed.',
    },
    recipientName: {
      required: 'This field is required.',
      pattern: 'The shipping name should be alphabetical only.'
    },
    country: {
      required: 'This field is required.',
    },
    zip: {
      required: 'This field is required.',
      minlength: `Must be atleast ${address.zipCode.length.min} digits.`,
      maxlength: `Must be atleast ${address.zipCode.length.max} digits.`,
      pattern: 'Should contain only Numbers and Single (-) in between.'
    },
    state: {
      required: 'This field is required.',
      whitespace: 'Only spaces not allowed.',
    },
    city: {
      required: 'This field is required.',
      whitespace: 'Only spaces not allowed.',
    },
    addressLine1: {
      required: 'This field is required.',
      minlength: `Please enter at least ${address.addressLine1.length.min} characters`,
      whitespace: 'Only spaces not allowed.',
    },
    addressLine2: {
      minlength: `Please enter at least ${address.addressLine2.length.min} characters`
    },
    phoneNumber: {
      required: 'This field is required.',
      minlength: `Must be atleast ${phoneNumber.length.min} digits.`,
      maxlength: `Must be atleast ${phoneNumber.length.max} digits.`
    },
    phoneCode: {
      required: 'This field is required.'
    },
  }
}

const ja: AddressFormTranslation = {
  addHeading: `お届け先住所を追加`,
  editHeading: `住所を編集`,
  nameLabel: `お届け先名`,
  recipientNameLabel: `お届け先氏名`,
  addressLine1Label: `番地以降`,
  addressLine2Label: `マンション名等`,
  cityLabel: `市区町村`,
  stateLabel: `都道府県`,
  zipLabel: `郵便番号`,
  countryLabel: `国`,
  phoneCodeLabel: `電話番号コード`,
  phoneNumberLabel: `電話番号`,
  addAddressButton: `お届け先住所を追加`,
  updateAddressButton: `お届け先住所を変更`,
  removeAddressButton: `お届け先住所を削除`,
  errorMessageMap: {
    location: {
      required: '入力が必須です',
    },
    recipientName: {
      required: '入力が必須です',
      pattern: '名前は日本語かアルファベットを使用してください'
    },
    country: {
      required: '入力が必須です',
    },
    zip: {
      required: '入力が必須です',
      minlength: `${address.zipCode.length.min}桁以上で入力してください`,
      maxlength: `${address.zipCode.length.max}桁以上で入力してください`,
      pattern: '郵便番号は半角数字と半角ハイフン(-)で入力してください'
    },
    state: {
      required: '入力が必須です',
      whitespace: '文字を入力してください',
    },
    city: {
      required: '入力が必須です',
      whitespace: '文字を入力してください',
    },
    addressLine1: {
      required: '入力が必須です',
      minlength: `${address.addressLine1.length.min}文字以上で入力してください`,
      whitespace: '文字を入力してください',
    },
    addressLine2: {
      minlength: `${address.addressLine2.length.min}文字以上で入力してください`
    },
    phoneNumber: {
      required: '入力が必須です',
      minlength: `${phoneNumber.length.min}桁以上で入力してください`,
      maxlength: `${phoneNumber.length.max}桁以下で入力してください`
    },
    phoneCode: {
      required: '入力が必須です'
    },
  }
}

export const Translations = {
  1: en,
  2: ja
}
