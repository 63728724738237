<div
  class="container"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutAlign.sm="start center"
  fxLayoutAlign.xs="start center"
>
  <div class="image-container" fxLayoutAlign="center center">
    <img alt="" class="image" src="./assets/images/gif/brew-complete.gif" />
  </div>
  <div class="message">
    {{ teaName }} {{ translations[language].readyToServer }}
  </div>

</div>
