import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../../translations/cart-details/shipping-address-selection-screen.translation";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../../state/app.state";
import {SetShippingAddressRequest} from "../../../../core/cart.actions";

@Component({
  selector: 'app-shipping-address-selection-screen',
  templateUrl: './shipping-address-selection-screen.component.html',
  styleUrls: ['./shipping-address-selection-screen.component.css']
})
export class ShippingAddressSelectionScreenComponent implements OnInit, OnDestroy {
  @Input() data: any;
  language: number = 1;
  selectedAddressId: string;
  orderId: string;
  addressList: any;
  translations: any = Translations;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit(): void {
    this.selectedAddressId = this.data.selectedAddressId;
    this.orderId = this.data.orderId;
    this.addressList = this.data.addressList;
  }

  selectAddress(selectedAddressId) {
    this.selectedAddressId = selectedAddressId;
  }

  setShippingAddress() {
    const payload = {
      orderId: this.orderId,
      addressId: this.selectedAddressId
    }

    this.store.dispatch(SetShippingAddressRequest({payload}));
  }

  addAddress() {
    // this.router.navigate(['/user/accountInfo'])
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
