export const languages = {
  english: 1,
  japanese: 2
};

export const productTypes = {
  brewable: 1,
  equipment: 2,
  package: 3
}

export const units = {
  1: {
    1: {
      temperature: '°C',
      volume: 'ml',
      mass: 'gm',
      time: 'secs',
      hour: `hour`,
      hours: `hours`,
      minute: `minute`,
      minutes: `minutes`,
      second: `second`,
      seconds: `seconds`
    },
    2: {
      temperature: '°C',
      volume: 'ml',
      mass: 'gm',
      time: '秒',
      hour: `時間`,
      hours: `時間`,
      minute: `分`,
      minutes: `分`,
      second: `秒`,
      seconds: `秒`
    }
  },
  2: {
    1: {
      temperature: '°F',
      volume: 'oz',
      mass: 'oz',
      time: 'secs',
      hour: `hour`,
      hours: `hours`,
      minute: `minute`,
      minutes: `minutes`,
      second: `second`,
      seconds: `seconds`
    },
    2: {
      temperature: '°F',
      volume: 'oz',
      mass: 'oz',
      time: '秒',
      hour: `時間`,
      hours: `時間`,
      minute: `分`,
      minutes: `分`,
      second: `秒`,
      seconds: `秒`
    }
  },
}
