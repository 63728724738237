import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../translations/deleteAccountPage/delete-account-page.translation";
import {select, Store} from "@ngrx/store";
import * as fromRoot from './../../../state/app.state';
import {CommonService} from "../../shared/services/common.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {selectedLanguage} from "../../shared/core/shared.selectors";

@Component({
  selector: 'app-delete-account-screen',
  templateUrl: './delete-account-screen.component.html',
  styleUrls: ['./delete-account-screen.component.css']
})
export class DeleteAccountScreenComponent implements OnInit, OnDestroy {

  language: number = 1;
  translations: any = Translations;
  isAccountDeleted: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  queryParamSubscription: Subscription = this.route.queryParams.subscribe(params => {
    if (params.accountDeleted === '1') {
      this.isAccountDeleted = true;
    }
  })

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.queryParamSubscription?.unsubscribe();
  }
}
