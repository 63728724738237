interface PageNotFoundTranslation {
  heading: string
  backToHome: string
}

const en: PageNotFoundTranslation = {
  heading: `The page you are looking for doesn't exist.`,
  backToHome: 'Back To Home',
}

const ja: PageNotFoundTranslation = {
  heading: `お探しのページがみつかりません`,
  backToHome: 'ホームに戻る',
}

export const Translations = {
  1: en,
  2: ja
}
