import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../core/shared.selectors";
import {Subscription} from "rxjs";
import {Translations} from "../../../translations/shared/pages/maintenance-page.translation";
import * as fromRoot from "../../../../state/app.state";
import {FetchCountriesListRequest} from "../../core/shared.actions";

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.css']
})
export class MaintenancePageComponent implements OnInit {

  translations: any = Translations;
  language: number = 1;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private store: Store<fromRoot.State>,
  ) {
    this.store.dispatch(FetchCountriesListRequest());
  }

  ngOnInit(): void {

  }
}
