<div
  class="container"
  fxLayoutAlign="center none"
>
  <div
    class="confirm-dialog"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div
      class="heading">
      {{translations[language].dialogConfirmationMessage}}
    </div>
    <app-primary-button
      id="confirm"
      (handleClick)="onConfirm()"
      [isLoading]="loading"
      class="confirm-button"
      text="{{translations[language].dialogConfirmButtonLabel}}"
    >
    </app-primary-button>
    <div (click)="backToHome()"
         class="back-to-home"
         fxLayoutAlign="center">
      {{translations[language].backToHomePage}}
    </div>
  </div>
</div>

