<div
  class="container"
>
  <div
    class="header"
    fxLayoutAlign="start center"
  >
    <div
      class="logo-section"
      fxFlex.gt-sm="20"
      fxFlex.sm="22.5"
    >
      <img
        (click)="goToHome()"
        alt=""
        class="logo"
        src="../../../../../../assets/images/png/logo.png"
      >
    </div>
    <div
      class="search-box"
      fxFlex.gt-sm="50"
      fxFlex.sm="35"
      fxLayout="row"
      fxLayoutAlign="start center"
      [formGroup]="form"
    >
      <input
        [placeholder]="translations[language].searchPlaceholder"
        class="search-input"
        type="text"
        formControlName="searchText"
        (keydown.enter)="search()"
      >
      <app-icon
        *ngIf="!searchIconClicked"
        (click)="search()"
        class="search-icon"
        icon="search"
      ></app-icon>
      <app-icon
        *ngIf="searchIconClicked"
        (click)="close()"
        class="search-icon"
        icon="close"
      ></app-icon>
    </div>
    <div
      class="options-section"
      fxFlex.gt-sm="30"
      fxFlex.sm="42.5"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <div class="country-selector-section">
        <button
          [disableRipple]="true"
          [matMenuTriggerFor]="menu"
          class="country-selector"
          mat-button
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>
              {{selectedCountryText}}
            </div>
          </div>
        </button>
        <mat-menu #menu="matMenu" class="header-country-list" xPosition="before">
          <button
            (click)="setSelectedCountry(eachCountry.key)"
            *ngFor="let eachCountry of countriesList"
            [class.selected]="eachCountry.key === selectedCountryCode"
            class="header-country-item"
            mat-menu-item
          >
            {{eachCountry.value}}
          </button>
        </mat-menu>
      </div>
      <app-icon
        (click)="handleCartClick()"
        class="options-icon cart-icon"
        icon="cart"
        [badgeValue]="cartCount"
      ></app-icon>
      <app-icon
        (click)="handleProfileClick()"
        class="options-icon profile-icon"
        icon="profile"
      ></app-icon>
    </div>
  </div>
  <div class="sub-header">
    <div
      class="sub-header-label-list"
      fxLayout="row"
      fxLayoutAlign="space-around center"
    >
      <div
        (click)="eachOption.handleClick()"
        *ngFor="let eachOption of subHeaderOptions"
        class="sub-header-item"
      >
        {{translations[language][eachOption.key]}}
      </div>
    </div>
  </div>
</div>
