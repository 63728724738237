<div class="payment-option-section" fxLayout="column" fxLayoutGap="2.5rem">
  <div
    fxLayoutAlign="center center"
    fxLayout="row"
    class="payment-option-heading"
  >
    <h1 class="heading">{{ translations.sectionHeading }}</h1>
    <a (click)="openAddDialog()"
       class="mat-icon-link">
      <mat-icon class="medium">add</mat-icon>
    </a>
  </div>
  <app-payment-option-list-loader
    *ngIf="loading; else paymentOptionCarousel"></app-payment-option-list-loader>
  <ng-template #paymentOptionCarousel>
    <app-carousel
      [arrowSize]="'sm'"
      [slidesData]="paymentOptionList"
      fxFlexAlign="center"
      ngClass.xs="xs"
      style="width: 80%"
    >
      <ng-template let-paymentOption>
        <app-payment-option-card
          (handleMakeDefaultButtonClick)="onMakeDefault($event)"
          (handleDeleteButtonClick)="openDeleteDialog($event)"
          [paymentOption]="paymentOption"
          [defaultLabel]="translations.defaultLabel"
          [expiredLabel]="translations.expiredLabel"
          [makeDefaultButtonLabel]="translations.makeDefaultButtonLabel"
        ></app-payment-option-card>
      </ng-template>
    </app-carousel>
  </ng-template>
</div>
