interface OrderSuccessDialogTranslations {
  backToHomeButtonLabel: string,
  successMessage: string,
}

const en: OrderSuccessDialogTranslations = {
  backToHomeButtonLabel: 'Back to Home',
  successMessage: 'Your Order has been placed successfully.',
}

const ja: OrderSuccessDialogTranslations = {
  backToHomeButtonLabel: 'ホームに戻る',
  successMessage: '正常にご注文しました',

}

export const Translations = {
  1: en,
  2: ja
}
