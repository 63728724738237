<div
  class="header-row"
  fxLayout="column"
  fxLayoutAlign="none center">
  <div
    class="logout-section"
    fxLayout="column"
    fxLayoutAlign="none center"
    fxHide.xs>
    <app-icon class='large profile-icon' [cssClass]="'large'" [icon]="'profile'"></app-icon>
    <div
      class="logout-button-container"
    >
      <app-secondary-button
        id="logout"
        (handleClick)="openLogoutDialogBox()"
        class="logout-button"
        text="{{translations.logoutButton}}"
      >
      </app-secondary-button>
    </div>
  </div>
  <div class="heading">{{translations.welcomeMessage}}</div>
  <app-shimmer-loader
    *ngIf="loading"
    [theme]="{
              'background-color': '#e5e5e5',
              'height': '3.5rem',
              'width': '20rem',
              'margin-top': '1rem',
              'margin-bottom': '0'
            }"
  ></app-shimmer-loader>
  <div class="user-name" *ngIf="!loading">{{ profileInfo?.fullName }}</div>
</div>
<div
  class="route-container"
  fxLayoutAlign="center center"
>
  <div
    class="routes-list"
    fxFlex="60"
    fxFlex.lt-md="100"
    fxLayout.lg="row"
    fxLayout.md="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
  >
    <div
      *ngFor="let eachUrl of urls"
      fxFlex.lg="33"
      fxFlex.md="33"
      fxFlex.sm="33"
      fxFlex.xs="100"
      fxLayoutAlign="center center"
    >
        <span
          [routerLinkActive]="eachUrl.disabled ? 'disabled' : 'active'"
          [routerLink]="eachUrl.disabled ? null : eachUrl.path"
          class="each-route"
          ngClass.xs="xs"
        >
        {{ translations[eachUrl.translationKey] }}
      </span>
    </div>

  </div>
</div>
