import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { Translations } from '../../../translations/tea-details-page/brew-temperature-info.translation';

@Component({
  selector: 'app-brew-temperature-info',
  templateUrl: './brew-temperature-info.component.html',
  styleUrls: ['./brew-temperature-info.component.css']
})
export class BrewTemperatureInfoComponent implements OnInit, OnChanges, OnDestroy {
  language: number;
  @Input() selectedBrewTemperature: any;
  @Output() handleClick = new EventEmitter();

  translations: any = Translations;
  brewTemperature: string;

  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.brewTemperature = this.selectedBrewTemperature;
  }

  ngOnChanges() {
    this.brewTemperature = this.selectedBrewTemperature;
  }

  handleNextClick() {
    this.handleClick.emit(2);
  }

  handlePreviousClick() {
    this.handleClick.emit(0);
  }
  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }
}
