import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserAddressModel} from "../../../../../models/user-address.model";
import * as UserDetailsActions from "../../../../core/user-details.actions";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../../../state/app.state";
import {Subscription} from "rxjs";
import {AddressService} from "../../../services/address.service";

@Component({
  selector: 'app-add-update-address-container',
  templateUrl: './add-update-address-container.component.html',
  styleUrls: ['./add-update-address-container.component.css']
})
export class AddUpdateAddressContainerComponent implements OnInit, OnDestroy {

  @Input() data: any;
  address: UserAddressModel;

  saveAddressSubscription: Subscription = this.addressService.saveAddress.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.SaveAddressRequest({payload}));
  });

  updateAddressSubscription: Subscription = this.addressService.updateAddress.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.UpdateAddressRequest({payload}));
  });

  constructor(private store: Store<fromRoot.State>,
              private addressService: AddressService) { }

  ngOnInit(): void {
    this.address = this.data?.address;
    this.store.dispatch(UserDetailsActions.FetchCountriesListRequest());
  }


  ngOnDestroy() {
    this.saveAddressSubscription?.unsubscribe();
    this.updateAddressSubscription?.unsubscribe();
  }
}
