import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {SetCountry, SetIsMobileSearchOpen, SetIsMobileSidenavOpen} from "../../../core/shared.actions";
import {Subscription} from "rxjs";
import {CommonService} from "../../../services/common.service";
import {
  countriesList,
  isMobileSearchOpen,
  isMobileSidenavOpen,
  selectedCountry,
  selectedLanguage
} from "../../../core/shared.selectors";
import {Translations} from 'src/app/modules/translations/shared/components/headers/desktop-header.translations'
import {FilterCountryModel} from "../../../../models/filter-country.model";
import {ActivatedRoute, Router} from "@angular/router";
import {cartCount} from 'src/app/modules/cart-details/core/cart.selectors';
import {LazyLoaderService} from "../../../services/lazy-loader.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.css']
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
  cartCount: string;
  cartIcon: string;
  isMobileSidenavOpen = false;
  countriesList: FilterCountryModel[] = [];
  selectedCountryCode: string | undefined;
  selectedCountryText: string = '';
  searchIconClicked: boolean;

  translations = Translations;

  language: number = 1;

  cartCountSubscription: Subscription;

  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe((data) => this.language = data);

  isMobileSearchOpen = false;

  isSearchSectionOpenSubscription: Subscription = this.store.pipe(select(isMobileSearchOpen)).subscribe((data) => {
    this.isMobileSearchOpen = data;

    if (this.isMobileSearchOpen) {
      this.store.dispatch(SetIsMobileSidenavOpen({isMobileSidenavOpen: false}));
    }
  })

  countrySubscription: Subscription = this.store.pipe(select(selectedCountry)).subscribe((data) => {
    this.selectedCountryCode = data;
    this.selectedCountryText = this.getSelectedCountryName();
  });

  isMobileSidenavOpenSubscription: Subscription = this.store.pipe(select(isMobileSidenavOpen)).subscribe((data) => {
    this.isMobileSidenavOpen = data;

    if (this.isMobileSidenavOpen) {
      this.store.dispatch(SetIsMobileSearchOpen({isMobileSearchOpen: false}));
    }
  })

  countriesListSubscription: Subscription = this.store.pipe(select(countriesList)).subscribe((data) => {
    this.countriesList = data;
    if (this.selectedCountryCode) {
      this.selectedCountryText = this.getSelectedCountryName();
    }
  });

  form: FormGroup = this.fb.group({
    searchText: new FormControl('')
  });

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router,
    private lazyLoaderService: LazyLoaderService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
  ) {
    this.lazyLoaderService.loadModule(
      () => import('src/app/modules/cart-details/cart-details.module').then(m => m.CartDetailsModule),
      () => {
        this.cartCountSubscription = this.store.pipe(select(cartCount)).subscribe((count) => {
          this.cartCount = count === 0 ? '' : count.toString();
          this.cartIcon = this.cartCount ? 'cart_filled' : 'cart_empty'
        })
      }
    );
  }

  ngOnInit(): void {
  }

  toggleSidenav() {
    this.store.dispatch(SetIsMobileSidenavOpen({isMobileSidenavOpen: !this.isMobileSidenavOpen}));
  }

  setSelectedCountry(countryCode: string) {
    this.store.dispatch(SetCountry({selectedCountry: countryCode}));

    let queryParams = {};

    this.activatedRoute.queryParams.subscribe((params) => {
      queryParams = params;
    }).unsubscribe();

    const route = new URL(window.location.href).pathname;
    const fragment = this.activatedRoute.snapshot.fragment;

    this.router.navigateByUrl('/dummy-url', {skipLocationChange: true}).then(() => {
      this.router.navigate([`/${route}`], {
        queryParams,
        ...(fragment ? {fragment} : {})
      })
    })
  }

  getSelectedCountryName() {
    return (this.countriesList.filter((o) => o.key === this.selectedCountryCode))[0]?.isoCode || '';
  }

  toggleSearchSection() {
    this.store.dispatch(SetIsMobileSearchOpen({isMobileSearchOpen: !this.isMobileSearchOpen}))
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  handleCartClick() {
    this.router.navigate(['/cart']);
  }

  search() {
    const formData = this.form.getRawValue();
    const searchText = formData?.searchText;
    const currentUrl = this.router.url.split('?')[0];

    let options: any = {
      fragment: 'tea-leaves',
      queryParams: {
        search: searchText || ''
      }
    };

    if (currentUrl === '/') {
      this.router.navigate([], options).then(() => {
        this.close();
      });
    } else {
      this.router.navigate(['/'], options).then(() => {
        this.close();
      });
    }
  }

  close() {
    this.searchIconClicked = false;
    this.form.reset();
    this.toggleSearchSection();
  }

  ngOnDestroy() {
    this.isMobileSidenavOpenSubscription?.unsubscribe();
    this.isSearchSectionOpenSubscription?.unsubscribe();
    this.countriesListSubscription?.unsubscribe();
    this.countrySubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.cartCountSubscription?.unsubscribe();
  }
}
