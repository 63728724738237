/* NgRx */
import {createAction, props} from '@ngrx/store';
import {ResetPasswordSetModel} from "../../models/reset-password-set.model";

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[reset password] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** RESET PASSWORD SET ***********/
const RESET_PASSWORD_SET_REQUEST = '[reset password] reset password requested';
export const ResetPasswordSetRequest = createAction(RESET_PASSWORD_SET_REQUEST, props<{ payload: ResetPasswordSetModel }>());

const RESET_PASSWORD_SET_SUCCESS = '[reset password] reset password successful';
export const ResetPasswordSetSuccess = createAction(RESET_PASSWORD_SET_SUCCESS, props<{ message: string }>());

const RESET_PASSWORD_SET_FAILURE = '[reset password] reset password failure';
export const ResetPasswordSetFailure = createAction(RESET_PASSWORD_SET_FAILURE, props<any>());
