interface OrderScreenTranslations {
  noData: string,
  goToShopPage: string,
}

const en: OrderScreenTranslations = {
  noData: `No Orders Placed`,
  goToShopPage: ' Go To Shop Page'
}

const ja: OrderScreenTranslations = {
  noData: `ご注文履歴がありません`,
  goToShopPage: 'ホームに戻る',
}

export const Translations = {
  1: en,
  2: ja
}
