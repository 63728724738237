import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-subscription-detail-loader',
  templateUrl: './subscription-detail-loader.component.html',
  styleUrls: ['./subscription-detail-loader.component.css']
})
export class SubscriptionDetailLoaderComponent implements OnInit {
  @Input() translations: any;
  constructor() { }

  ngOnInit(): void {
  }

}
