import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/shared/components/footer.translations";
import {select, Store} from "@ngrx/store";
import * as fromRoot from '../../../../state/app.state';
import {isMobileView, languagesList, selectedLanguage} from '../../core/shared.selectors';
import {CommonService} from '../../services/common.service';
import {FilterModel} from "../../../models/filter.model";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  translations = Translations;
  language: number = 1;
  languagesList: FilterModel[] = [];
  selectedLanguageText: string;

  isMobileView$: Observable<boolean> = this.store.pipe(select(isMobileView));

  languageSubscription = this.store.pipe(select(selectedLanguage)).subscribe(data => {
    this.language = data;
    this.selectedLanguageText = this.getSelectedLanguageName();
  });

  languagesListSubscription: Subscription = this.store.pipe(select(languagesList)).subscribe((data) => {
    this.languagesList = data;
    if (this.language) {
      this.selectedLanguageText = this.getSelectedLanguageName();
    }
  });

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>
  ) {
  }

  ngOnInit(): void {
  }

  privacyClickHandler() {
    if (this.language === 1) {
      this.commonService.openInNewTab(
        'https://teplotea.com/en/privacy_policy/'
      )
    } else {
      this.commonService.openInNewTab(
        'https://teplotea.com/プライバシーポリシー/'
      )
    }
  }

  termsClickHandler() {
    if (this.language === 1) {
      this.commonService.openInNewTab(
        'https://teplotea.com/en/terms-and-conditions/'
      )
    } else {
      this.commonService.openInNewTab(
        'https://teplotea.com/terms-and-conditions/'
      )
    }
  }

  noticeClickHandler() {
    this.commonService.openInNewTab('https://s3.us-east-2.amazonaws.com/teplo2.1-uploads/documents/sale_and_refund_JP.pdf');
  }

  setSelectedLanguage(languageCode: number) {
    this.commonService.setLanguage(Number(languageCode));
  }

  getSelectedLanguageName() {
    return (this.languagesList.filter((o) => Number(o.key) === this.language))[0]?.value || '';
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.languagesListSubscription?.unsubscribe();
  }

}
