import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentOptionService {
  savePaymentOption = new EventEmitter();
  makeDefaultPaymentOption = new EventEmitter();
  removePaymentOption = new EventEmitter();

  constructor() { }
}
