import {Injectable} from '@angular/core';
import {CommonService} from '../services/common.service';
import {Observable} from "rxjs";
import {v1URL} from "../constants/urls.constants";

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private commonServices: CommonService) {
  }

  getCountries(): Observable<any> {
    const urlConfig = v1URL.filter.countries.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  getGenders(): Observable<any> {
    const urlConfig = v1URL.filter.gender.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

}
