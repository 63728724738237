import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DeleteAccountState} from "./delete-account.reducer";

// Select feature
const deleteAccountData = createFeatureSelector<DeleteAccountState>('deleteAccount');

// Selector functions
export const getLoading = createSelector(
  deleteAccountData,
  (state: DeleteAccountState) => state.loading
)
