import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserPaymentOptionModel} from "../../../../models/user-payment-option.model";
import {select, Store} from "@ngrx/store";
import * as fromRoot from '../../../../../state/app.state';
import {Subscription} from "rxjs";
import * as UserDetailsSelectors from '../../../core/user-details.selectors';
import {Translations} from "../../../../translations/user-details/personal-information/payment-option-section.translation";
import {CommonService} from "../../../../shared/services/common.service";
import {AddPaymentOptionContainerComponent} from "../add-payment-option/add-payment-option-container/add-payment-option-container.component";
import {PaymentOptionService} from "../../services/payment-option.service";
import {DeletePaymentOptionConfirmDialogComponent} from "../delete-payment-option-confirm-dialog/delete-payment-option-confirm-dialog.component";
import {getLoadingFlags} from "../../../core/user-details.selectors";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-payment-option-section',
  templateUrl: './payment-option-section.component.html',
  styleUrls: ['./payment-option-section.component.css']
})
export class PaymentOptionSectionComponent implements OnInit, OnDestroy {
  @Input() language: number = 1;
  paymentOptionList: UserPaymentOptionModel[];
  translations: any;
  loading: boolean = false;
  cellsToShow = 3;

  // Get payment option list
  paymentOptionListSubscription: Subscription = this.store.pipe(select(UserDetailsSelectors.getPaymentOptionList)).subscribe((paymentOptionListData) => {
    this.paymentOptionList = paymentOptionListData.paymentOptionList;
    this.loading = paymentOptionListData.loading;
  })

  // Open add address dialog
  routeQueryParams$: Subscription = this.route.queryParams.subscribe(params => {
    if (params['addPaymentOption']) {
      this.openAddDialog();
    }
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private paymentOptionService: PaymentOptionService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

  openAddDialog(){
    this.commonService.openDialog({
      data: {
        component: AddPaymentOptionContainerComponent
      }
    })
  }

  onMakeDefault(event){
    this.paymentOptionService.makeDefaultPaymentOption.emit({
      payload: {
        paymentOptionId: event._id,
        returnAll: true
      }
    });
  }

  onDelete(event){
    this.paymentOptionService.removePaymentOption.emit({
      payload: {
        paymentOptionId: event._id
      }
    });
  }
  openDeleteDialog(event){
    this.commonService.openDialog({
      data: {
        component: DeletePaymentOptionConfirmDialogComponent,
        paymentOption: event
      }
    })
  }

  ngOnDestroy() {
    this.paymentOptionListSubscription?.unsubscribe();
    this.routeQueryParams$?.unsubscribe();
  }
}
