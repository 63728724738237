<div
  class="container"
>
  <div
    class="cart-row"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="2rem"
    [ngClass.lt-sm]="'grey-background'"

  >
    <div
      class="image-box"
      fxFlex="30"
    >
      <img
        [src]="images[0]"
        alt=""
        class="image">
    </div>
    <div
      class="details-box"
      fxFlex="70"
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-around start"
      fxLayoutAlign.lt-sm="space-around strech"
      fxLayoutGap="1rem"
    >
      <div
        class="item-details"
        fxLayout="column"
        fxFlex.lt-sm="100"
        fxFlex.sm="30"
        fxFlex.gt-sm="50"
        fxLayoutGap="0.6rem"
        fxLayoutAlign="space-evenly start"
      >
        <div class="name ff-lang-ja-text-en">{{data?.name}}</div>
        <div class="price ff-lang-ja-text-en">
          <div
            fxFlex="50"
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <div
              class="ff-lang-ja-text-en"
              [class.strike-text]="data.isDiscountApplied">
              {{data?.total | currency: currencySymbol:currencySymbol:'1.0'}}
            </div>
            <div *ngIf="data?.isDiscountApplied"
                 class="discounted-value discounted-total ff-lang-ja-text-en">
              {{data?.afterDiscount?.total | currency: currencySymbol:currencySymbol:'1.0'}}
            </div>
          </div>
        </div>
        <div class="purchase-type">{{data?.purchaseType}}</div>
      </div>
      <div
        class="quantity-editor"
        fxFlex.lt-sm="100"
        fxFlex.sm="70"
        fxFlex.gt-sm="50"
        fxLayout="row"
        fxLayoutAlign.xs="space-between center"
        ngClass.xs="xs"
      >
        <div
          class="quantity-row"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="start start"
        >
          <div class="quantity-label">
            {{translations[language].quantity}}
          </div>
          <div
            class="value"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="0.5rem"
          >
            <div
              (click)="data?.quantity == 1 ? null : decreaseQuantity(data.productId , data.pricingPlanId, data.quantity)"
              class="quantity-counter-element"
            >
              <div
                [class.disabled]="data?.quantity === 1"
                class="decrement"
              >
                -
              </div>
            </div>
            <div class="quantity-counter-element">
              <div class="quantity ff-lang-ja-text-en">{{data.quantity}}</div>
            </div>
            <div
              (click)="data?.quantity == 5 ? null : increaseQuantity(data.productId , data.pricingPlanId, data.quantity)"
              class="quantity-counter-element"
            >
              <div
                [class.disabled]="data?.quantity === 5"
                class="increment"
              >
                +
              </div>
            </div>
            <div
              class="remove-item"
            >
              <app-icon
                class="delete-button"
                [icon]="'delete'"
                (click)="removeItem(data.productId , data.pricingPlanId, data.quantity)"
              >
              </app-icon>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
