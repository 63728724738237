interface OrderDetailsPopupScreenTranslation {
  orderNumber: string,
  orderDate: string,
  orderTotal: string,
  shippingAddressHeading: string,
  trackingDetailsHeading: string,
  paymentDetailsHeading: string,
  discountCouponDetailsHeading: string,
  noCouponAppliedText: string,
  items: string,
  item: string,
  subscriptionsCodeLabel: string,
  quantity: string,
  taxesHeading: string,
  taxAmount: string,
  contactUsButton: string,
  contactUsText: string,
  dateFormatJP: string
}

const en: OrderDetailsPopupScreenTranslation = {
  orderNumber: `Order #`,
  orderDate: `Order Date`,
  orderTotal: `Order Total`,
  shippingAddressHeading: `Shipping Address`,
  trackingDetailsHeading: `Tracking Details`,
  paymentDetailsHeading: `Payment Details`,
  discountCouponDetailsHeading: `Discount Coupon Details`,
  noCouponAppliedText: `No Coupon Applied`,
  items: `Items`,
  item: `Item`,
  subscriptionsCodeLabel: `Subscription #`,
  quantity: `Quantity`,
  taxesHeading: `Taxes`,
  taxAmount: 'Amount',
  contactUsButton: `CONTACT US`,
  contactUsText: `* If you have any questions about the order contact us at order.japan@load-road.com`,
  dateFormatJP: ''
}

const ja: OrderDetailsPopupScreenTranslation = {
  orderNumber: `注文番号`,
  orderDate: `注文日`,
  orderTotal: `注文金額`,
  shippingAddressHeading: `お届け先住所`,
  trackingDetailsHeading: `追跡番号`,
  paymentDetailsHeading: `お支払い情報`,
  discountCouponDetailsHeading: `クーポン`,
  noCouponAppliedText: `クーポン使用なし`,
  items: `ご注文商品`,
  item: `注文した製品`,
  subscriptionsCodeLabel: `サブスクリプション番号`,
  quantity: `数量`,
  taxesHeading: `消費税`,
  taxAmount: '小計',
  contactUsButton: `お問合せ`,
  contactUsText: `※ ご質問等は<span class="ff-lang-ja-text-en">order.japan@load-road.com</span>までお気軽にお問い合わせください`,
  dateFormatJP: 'yyyy年M月d日'
}

export const Translations = {
  1: en,
  2: ja
}
