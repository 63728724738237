import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from "../../../../shared/services/common.service";
import {Translations} from "../../../../translations/user-details/orders/order-item.translations";
import {OrderDetailsPopupScreenComponent} from "../order-details-popup-screen/order-details-popup-screen.component";

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.css']
})
export class OrderCardComponent implements OnInit {
  @Input() order: any;
  @Input() language: number = 1;
  dateFormat: string;
  translations = Translations;

  constructor(
    private commonService: CommonService,
  ) {

  }

  ngOnInit(): void {
    if (this.language === 2) {
      this.dateFormat = this.translations[this.language].dateFormatJP
    }
  }

  openOrderDetailDialogBox(orderId): any {
    this.commonService.openDialog({data: {component: OrderDetailsPopupScreenComponent, orderId}});
  }

}
