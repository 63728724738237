interface ShippingAddressSelectionScreenTranslation {
  heading: string,
  noData: string,
  confirmButton: string,
  addAddress: string
}

const en: ShippingAddressSelectionScreenTranslation = {
  heading: `Select Shipping Address`,
  noData: `Please add shipping address`,
  confirmButton: `CONFIRM`,
  addAddress: `Add Address`
}

const ja: ShippingAddressSelectionScreenTranslation = {
  heading: `お届け先を選択`,
  noData: `お届け先を指定してください`,
  confirmButton: `保存`,
  addAddress: `住所を追加`
}

export const Translations = {
  1: en,
  2: ja
}
