import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CommonService} from "./common.service";
import {catchError, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {isAppInMaintenanceMode} from "../core/shared.actions";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../state/app.state";
import {v1URL} from "../constants/urls.constants";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  messageQueue = [];
  processingMessage = false;

  excludedUrlsRegex: RegExp[];
  excludedUrls = ['.svg'];

  constructor(
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
    this.excludedUrlsRegex =
      this.excludedUrls.map(urlPattern => new RegExp(urlPattern, 'i')) || [];
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const passThrough: boolean =
      !!this.excludedUrlsRegex.find(regex => regex.test(req.url));

    if (passThrough) {
      return next.handle(req);
    }


    let headers = req.headers;
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append(
      'language',
      this.commonService.getLanguage().toString()
    );

    headers = headers.append('app-version', '1.1.1');
    headers = headers.append('country-code', this.commonService.getCountry());
    const body = req.body;
    let requestToSend;

    if (this.commonService.isAuthenticated()) {
      const authenticationToken = this.commonService.getAuthenticationToken();
      headers = headers.append('Authorization', 'Bearer ' + authenticationToken);
    } else if (this.commonService.getFromLocalStorage('token')) {
      const resetPasswordToken = this.commonService.getFromLocalStorage('token');
      headers = headers.append('Authorization', 'Bearer ' + resetPasswordToken);
    }
    headers = headers.append('platform', 'e-commerce' );
    requestToSend = req.clone({headers, body});
    return next.handle(requestToSend).pipe(
      map((response) => {
        if (
          (response['body'] && !response['body'].success) ||
          ('success' in response && response['success'] === false)
        ) {
          throw response;
        }

        return response;
      }),
      catchError((errorResponse) => this.handleError(errorResponse))
    );
  }

  handleError(response) {
    if (response.status === 503) {
      this.store.dispatch(isAppInMaintenanceMode({isAppInMaintenanceMode: true}));
      let currentUrl = this.router.url;
      currentUrl = currentUrl.split('/').pop();
      if (currentUrl !== 'maintenance') {
        this.router.navigate(['/maintenance']);
      }
      return;
    }
    if (response.status === 401) {
      if (response.url.split('/').pop() === 'count') {
        return;
      }

      if (this.commonService.isAuthenticated()) {
        if (response.url.split('/').pop() !== 'home') {
          this.router.navigate(['/']).then(() => {
            this.commonService.clearAppState();
            this.commonService.clearStorage();
          })
        } else {
          this.commonService.clearAppState();
          this.commonService.clearStorage();
        }
      } else {
        if (response.url !== v1URL.user.password.reset.set.url) {
          return;
        }
      }
    }

    const errorObject = (response && response.error) || (response && response.body) || {
      message: 'Oops! Something went wrong.',
    };

    if (errorObject.validationErrorStack) {
      errorObject.validationErrorStack.forEach(eachValidationError => {
        this.addMessageToQueue(eachValidationError.message);
      })
    } else {
      this.addMessageToQueue(errorObject.message);
    }

    return throwError(errorObject);
  }

  addMessageToQueue(message) {
    this.messageQueue.push(message);

    if (!this.processingMessage) {
      this.displaySnackbar();
    }
  }

  displaySnackbar() {
    const nextMessage = this.getNextMessage();

    if (!nextMessage) {
      this.processingMessage = false; // No message in the queue, set flag false
      return;
    }

    this.processingMessage = true; // A message was dequeue and is being processed

    this.snackBar
      .open(nextMessage, undefined, {
        duration: 1500,
        verticalPosition: 'bottom', // 'top' | 'bottom'
        horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: 'danger',
      })
      .afterDismissed()
      .subscribe(() => {
        this.displaySnackbar();
      });
  }

  getNextMessage(): string | undefined {
    return this.messageQueue.length ? this.messageQueue.shift() : null;
  }
}
