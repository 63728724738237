import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-personal-information-screen',
  templateUrl: './personal-information-screen.component.html',
  styleUrls: ['./personal-information-screen.component.css']
})
export class PersonalInformationScreenComponent implements OnInit, OnDestroy {
  @Input() language: number = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {

  }

}
