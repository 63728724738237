<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && !isLoading && handleClick.emit()"
  [disableRipple]="true"
  [ngClass]="{disabled: isDisabled, loading: isLoading}"
  [throttleTime]="1000"
  class="button primary-clickable-text"
  mat-button
  preventMultipleClick
>
  <span class="button-label">
    {{ text }}
  </span>
  <mat-icon class="loading-icon"><mat-spinner color="accent" diameter="20" strokeWidth="2"></mat-spinner></mat-icon>
</button>
