import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { Translations } from '../../../translations/tea-details-page/brew-ready-serve-info.translation';

@Component({
  selector: 'app-brew-ready-serve-info',
  templateUrl: './brew-ready-serve-info.component.html',
  styleUrls: ['./brew-ready-serve-info.component.css']
})
export class BrewReadyServeInfoComponent implements OnInit, OnDestroy {
  language: number;
  @Input() teaName: string;

  translations: any = Translations;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  constructor(private store: Store) {
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe()
  }
}
