import {createAction, props} from "@ngrx/store";

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[delete account page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** Delete Account ***********/
const DELETE_ACCOUNT_REQUEST = '[delete account page] Delete account request';
export const DeleteAccountRequest = createAction(DELETE_ACCOUNT_REQUEST);

const DELETE_ACCOUNT_SUCCESS = '[delete account page] Delete account success';
export const DeleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS, props<{ message: string }>());

const DELETE_ACCOUNT_FAILURE = '[delete account page] Delete account failure';
export const DeleteAccountFailure = createAction(DELETE_ACCOUNT_FAILURE);
