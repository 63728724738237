/* NgRx */
import {createAction, props} from '@ngrx/store';
import {TeaDetailsActionState, TeaDetailsState, UserReview} from '../../models/tea-details.model';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[tea details] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** RESET SUCCESS STATE ***********/
const RESET_SUCCESS_STATE = '[tea details] reset success state';
export const ResetSuccessState = createAction(RESET_SUCCESS_STATE);

/******** FETCH TEA DETAILS ***********/
const FETCH_TEA_DETAILS_REQUEST = '[tea details] fetch tea details request';
export const FetchTeaDetailsRequest = createAction(
  FETCH_TEA_DETAILS_REQUEST,
  props<{ payload: { productId?: string, productKey?: string } }>()
);
const FETCH_TEA_DETAILS_SUCCESS = '[tea details] fetch tea details success';
export const FetchTeaDetailsSuccess = createAction(
  FETCH_TEA_DETAILS_SUCCESS,
  props<{ teaDetails: TeaDetailsState }>()
);
const FETCH_TEA_DETAILS_FAILURE = '[tea details] fetch tea details failure';
export const FetchTeaDetailsFailure = createAction(
  FETCH_TEA_DETAILS_FAILURE,
  props<any>()
);

/******** FETCH TEA REVIEW SUMMARY ***********/
const FETCH_TEA_REVIEW_SUMMARY_REQUEST =
  '[tea details] fetch tea review summary request';
export const FetchTeaReviewSummaryRequest = createAction(
  FETCH_TEA_REVIEW_SUMMARY_REQUEST,
  props<{
    payload: {
      teaId: string,
    },
  }>()
);

const FETCH_TEA_REVIEW_SUMMARY_SUCCESS =
  '[tea details] fetch tea review summary success';
export const FetchTeaReviewSummarySuccess = createAction(
  FETCH_TEA_REVIEW_SUMMARY_SUCCESS,
  props<{ userReviewsSummary: UserReview }>()
);

const FETCH_TEA_REVIEW_SUMMARY_FAIL =
  '[tea details] fetch tea review summary fail';
export const FetchTeaReviewSummaryFail = createAction(
  FETCH_TEA_REVIEW_SUMMARY_FAIL,
  props<any>()
);

/******** FETCH TEA REVIEW ***********/
const FETCH_TEA_REVIEW_REQUEST = '[tea details] fetch tea review request';
export const FetchTeaReviewRequest = createAction(
  FETCH_TEA_REVIEW_REQUEST,
  props<{
    payload: {
      teaId: string,
    },
  }>()
);

const FETCH_TEA_REVIEW_SUCCESS = '[tea details] fetch tea review  success';
export const FetchTeaReviewSuccess = createAction(
  FETCH_TEA_REVIEW_SUCCESS,
  props<{ userReviews: UserReview }>()
);

const FETCH_TEA_REVIEW_FAIL = '[tea details] fetch tea review  fail';
export const FetchTeaReviewFail = createAction(
  FETCH_TEA_REVIEW_FAIL,
  props<any>()
);


/******** NOTIFY USER ***********/
const NOTIFY_USER_REQUEST = '[tea details] notify user request';
export const NotifyUserRequest = createAction(NOTIFY_USER_REQUEST, props<any>());
const NOTIFY_USER_SUCCESS = '[tea details] notify user success';
export const NotifyUserSuccess = createAction(NOTIFY_USER_SUCCESS, props<any>());
const NOTIFY_USER_FAILURE = '[tea details] notify user failure';
export const NotifyUserFailure = createAction(NOTIFY_USER_FAILURE, props<any>());

