<div
  class="container confirm-dialog"
  fxLayoutAlign="center none"
  fxLayout="column"
>
  <div
    class="heading"
  >
    {{translations.successMessage}}
  </div>
  <app-primary-button
    id="back_to_home"
    (handleClick)="backToHome()"
    class="confirm-button"
    [isSlim]="true"
    text="{{translations.backToHomeButtonLabel}}"
  >
  </app-primary-button>
</div>

