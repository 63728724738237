import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {lastValueFrom, Observable} from 'rxjs';
import {v1URL} from "../constants/urls.constants";
import {HttpClient} from "@angular/common/http";
import {CommonService} from "./common.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../state/app.state";

@Injectable({
  providedIn: 'root'
})
export class ProductGuard implements CanActivate {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private http?: HttpClient,
  ) {
  }

  // @ts-ignore
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {

    try {
      const productId = route.paramMap.get('id');


      const objectIdRegex = /^[0-9a-fA-F]{24}$/;
      const isBSON = objectIdRegex.test(productId);

      if (isBSON) {
        const url = v1URL.product.fetch.url;
        const method = v1URL.product.fetch.method;

        this.commonService.startLoading();

        let response: any = await lastValueFrom(this.http[method](url, {
          productId
        }));

        this.commonService.stopLoading();

        if (response['success']) {
          const {data} = response;
          const productKey = data.key;

          const url = `${data.type === 1 ? 'tea/' : 'package/'}${productKey}`
          this.router.navigate([url]);
        }

        return false;
      }


      return true;
    } catch(error) {
      this.commonService.stopLoading();
    }

    return false;
  }
}
