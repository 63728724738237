interface TeaDetailsAppearanceInfoTranslation {
  heading: string
}

const en: TeaDetailsAppearanceInfoTranslation = {
  heading: `Appearance`
}

const ja: TeaDetailsAppearanceInfoTranslation = {
  heading: `色`
}

export const Translations = {
  1: en,
  2: ja
}
