<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <div
    [ngClass]="labelFontWeight"
    [class.slim]="isSlim"
    [ngStyle]="{
        color: labelTextColor,
        textAlign: labelAlignment
      }"
    class="form-field-label"
  >
    <span
      *ngIf="isRequired"
      class="required-mark"
    >*</span>
    {{label}}
  </div>
  <mat-form-field
    [formGroup]="parentFormGroup"
    [ngClass]="{
      'has-prefix': !!prefixIcon,
      'has-suffix': !!suffixIcon,
      slim: isSlim
    }"
    [ngStyle]="{
        color: fieldTextColor,
        backgroundColor: fieldBackgroundColor
      }"
    appearance="outline"
    class="form-field"
    hideRequiredMarker
  >
    <input
      #input
      [formControlName]="controlName"
      [maxlength]="maxLength"
      matInput
      placeholder="{{placeholder}}"
      type="text"
      [appInputRestriction]="inputRestriction"
    >
    <app-icon
      *ngIf="prefixIcon"
      [icon]="prefixIcon"
      class="form-field-icon prefix-icon"
      matPrefix
    >
    </app-icon>
    <app-icon
      *ngIf="suffixIcon"
      [icon]="suffixIcon"
      class="form-field-icon suffix-icon"
      matSuffix
    >
    </app-icon>
  </mat-form-field>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
