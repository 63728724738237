interface BrewStrengthInfoTranslation {
  mild: string,
  ideal: string,
  strong: string,
  coldCarafe: string,
  labelBrewTemp: string,
  labelBrewTime: string,
  btnNext: string,
  C: string,
  F: string,
  secs: string,
}

const en: BrewStrengthInfoTranslation = {
  mild: `Mild`,
  ideal: `Ideal`,
  strong: `Strong`,
  coldCarafe: `Cold Brew`,
  labelBrewTemp: `Brew temperature`,
  labelBrewTime: `Brew time`,
  btnNext: `Next`,
  C: `C`,
  F: `F`,
  secs: 'secs'
}

const ja: BrewStrengthInfoTranslation = {
  mild: `マイルド`,
  ideal: `スタンダード`,
  strong: `ストロング`,
  coldCarafe: `水出し抽出`,
  labelBrewTemp: `抽出温度`,
  labelBrewTime: `抽出時間`,
  btnNext: `次に進む`,
  C: `C`,
  F: `F`,
  secs: '秒'
}

export const Translations = {
  1: en,
  2: ja
}
