import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  emitCancelSubscription: EventEmitter<any> = new EventEmitter();
  constructor() { }
}
