/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CartDetailsState} from './cart.reducer';

// Selector functions
const cartData =
  createFeatureSelector<CartDetailsState>('cartDetails');

// Create Selectors
export const guestCart = createSelector(
  cartData,
  (state: CartDetailsState) => state?.guestCart
);

export const cart = createSelector(
  cartData,
  (state: CartDetailsState) => state?.cartData
);

export const isGuestPurchaseFormEnabled = createSelector(
  cartData,
  (state) => state?.enableGuestPurchaseForm
);

export const guestPurchaseInfo = createSelector(
  cartData,
  (state) => state?.guestPurchaseForm
);

export const getAddressList = createSelector(
  cartData,
  (state) => state.addressList
);

export const cardsList = createSelector(
  cartData,
  (state) => state.paymentCards
);

export const cartCount = createSelector(
  cartData,
  (state) => state.cartCount
);
