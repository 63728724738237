import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {Translations} from '../../../../translations/user-details/personal-information/account-information.translation';
import * as fromRoot from '../../../../../state/app.state';
import {profile} from "../../../../authentication/core/authentication.selectors";
import {AddUpdateAddressContainerComponent} from "../add-update-address/add-update-address-container/add-update-address-container.component";
import {CommonService} from "../../../../shared/services/common.service";
import {UpdateAccountInformationContainerComponent} from "../update-account-information/update-account-information-container/update-account-information-container.component";

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.css']
})
export class AccountInformationComponent implements OnInit, OnDestroy {
  @Input() language: number = 1;
  translations: any;
  loading: boolean = false;
  profileInfo: any;

  // Get profile information
  profileInfoSubscription: Subscription = this.store.pipe(select(profile)).subscribe((profileData) => {
    this.profileInfo = profileData.profile;
    this.loading = profileData.loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

  openEditDialog(){
    this.commonService.openDialog({
      data: {
        component: UpdateAccountInformationContainerComponent
      }
    })
  }

  ngOnDestroy() {
    this.profileInfoSubscription?.unsubscribe();
  }

}
