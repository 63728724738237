interface AccountInformationTranslation {
  accountInformationHeading: string,
  nameHeading: string,
  emailAddressHeading: string,
  editButton: string
}

const en: AccountInformationTranslation = {
  accountInformationHeading: `Account Information`,
  nameHeading: `Name`,
  emailAddressHeading: `Email`,
  editButton: `EDIT`
}

const ja: AccountInformationTranslation = {
  accountInformationHeading: `お名前`,
  nameHeading: `フルネーム`,
  emailAddressHeading: `メールアドレス`,
  editButton: `編集`
}

export const Translations = {
  1: en,
  2: ja
}
