import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {Subscription} from "rxjs";
import {getLoadingFlags} from "../../../core/user-details.selectors";

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.css']
})
export class AddressCardComponent implements OnInit, OnDestroy {

  @Input() address: any;
  @Input() defaultLabel: string = 'Default';
  @Input() makeDefaultButtonLabel = 'Make Default';
  @Input() makeDefaultButtonVisible: boolean = true;
  @Input() makeDefaultButtonDisabled: boolean = false;
  @Input() editButtonVisible: boolean = true;
  @Input() editButtonDisabled: boolean = false;
  @Input() deleteButtonVisible: boolean = true;
  @Input() deleteButtonDisabled: boolean = false;

  @Output() handleMakeDefaultButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() handleEditButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() handleDeleteButtonClick: EventEmitter<any> = new EventEmitter();

  makeDefaultLoading: boolean = false;

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.makeDefaultLoading = (loadingFlags.makeDefaultAddress?.addressId === this.address?._id ) && loadingFlags.makeDefaultAddress?.loading;
  });

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
  }


  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }
}
