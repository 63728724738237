<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && !isLoading && handleClick.emit()"
  [class.slim]="isSlim"
  [disableRipple]="isDisabled || isLoading"
  [ngClass]="{disabled: isDisabled, loading: isLoading}"
  [throttleTime]="1000"
  class="button primary-button font-base"
  mat-button
  preventMultipleClick
>
  <div class="button-content" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="prefixIcon" class="prefix-icon" fxLayoutAlign="center center">
      <app-icon [icon]="prefixIcon">
      </app-icon>
    </div>
    <div class="button-label font-medium">
      {{ text }}
    </div>
    <mat-icon class="loading-icon"><mat-spinner color="accent" diameter="20" strokeWidth="2"></mat-spinner></mat-icon>
    <div *ngIf="suffixIcon" class="suffix-icon" fxLayoutAlign="center center">
      <mat-icon>
        {{suffixIcon}}
      </mat-icon>
    </div>
  </div>
  <div [id]="buttonId" class="overlay"></div>
</button>

