import {Component, OnDestroy, OnInit} from '@angular/core';
import * as SharedActions from './../../../../../shared/core/shared.actions';
import * as AuthenticationActions from './../../../../../authentication/core/authentication.actions';
import * as fromRoot from '../../../../../../state/app.state';
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {UpdateAccountInformationService} from "../services/update-account-information.service";

@Component({
  selector: 'app-update-account-information-container',
  templateUrl: './update-account-information-container.component.html',
  styleUrls: ['./update-account-information-container.component.css']
})
export class UpdateAccountInformationContainerComponent implements OnInit, OnDestroy {

  // // Update profile information
  updateAccountInformationSubscription: Subscription = this.updateAccountInformationService.updateAccountInfo.subscribe((payload) => {
    this.store.dispatch(AuthenticationActions.UpdateAccountRequest({payload}));
  });

  constructor(private store: Store<fromRoot.State>,
              private updateAccountInformationService: UpdateAccountInformationService) { }


  ngOnInit(): void {
    this.store.dispatch(SharedActions.FetchGenderListRequest());
  }

  ngOnDestroy() {
    this.updateAccountInformationSubscription?.unsubscribe();
  }
}
