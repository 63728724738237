interface BrewReadyServeInfoTranslation {
  readyToServer: string,
}

const en: BrewReadyServeInfoTranslation = {
  readyToServer: `is Ready To Serve`,
}

const ja: BrewReadyServeInfoTranslation = {
  readyToServer: `の抽出が完了しました！`,
}

export const Translations = {
  1: en,
  2: ja
}
