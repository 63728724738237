import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import * as UserDetailsActions from "../../../user-details/core/user-details.actions";
import {Subscription} from "rxjs";
import {SubscriptionsService} from "../services/subscriptions.service";

@Component({
  selector: 'app-subscriptions-container',
  templateUrl: './subscriptions-container.component.html',
  styleUrls: ['./subscriptions-container.component.css']
})
export class SubscriptionsContainerComponent implements OnInit, OnDestroy {

  cancelSubscription: Subscription;
  constructor(private store: Store<fromRoot.State>,
              private subscriptionsService: SubscriptionsService) {
    this.cancelSubscription =
      this.subscriptionsService.emitCancelSubscription.subscribe(
        (payload) => {
          this.store.dispatch(
            UserDetailsActions.CancelUserSubscriptionRequest({payload})
          );
        }
      );
  }

  ngOnInit(): void {
    this.store.dispatch(UserDetailsActions.FetchUserSubscriptionsRequest({
      payload: {
        pageNumber: 1,
        limit: 100
      }
    }));
  }

  ngOnDestroy(): void {
  }

}
