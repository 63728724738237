<app-half-content-half-image>
  <div
    class="page-not-found-container"
    fxLayout="column"
    fxLayout.xs="column"
    fxLayoutGap="10rem"
  >
    <div class="heading"
         fxLayoutAlign="center">
      {{translations[language].heading}}
    </div>
    <div class="back-to-home-btn">
      <app-secondary-button
        id="back_to_home"
        (handleClick)="backToHome()"
        text="{{translations[language].backToHome}}"
      >
      </app-secondary-button>
    </div>
  </div>
</app-half-content-half-image>
