<div class="container" fxLayout="column" fxLayoutGap="2rem">
  <div class="heading">
    {{translations[language].heading}}
  </div>
  <div class="experience-details-section" fxLayout="row" fxLayoutGap="5rem">
    <div
      *ngFor="let experience of experience"
      class="bread-crumb-section"
      fxLayoutAlign="start start"
      fxLayoutAlign.lt-sm="start center"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="1rem"
      >
        <div class="bread-crumb-logo" fxLayoutAlign="center center">
          <img
            [src]="getImagesForExperience(experience.icon)"
            alt=""
            class="bread-crumb-image"
          />
        </div>
        <div
          class="bread-crumb-text"
          fxLayoutAlign="center center"
        >
          <span [innerHtml]="experience.value "></span>
        </div>
        <div
          class="sub-bread-crumb-text"
          fxLayoutAlign="center center"
        >
          {{ translations[language][experience.title] }}
        </div>
      </div>
    </div>
  </div>
</div>
