<app-account-information-loader
  *ngIf="loading; else accountInformation"
  [language]="language"></app-account-information-loader>
<ng-template #accountInformation>
  <div class="account-info-card text-center" fxLayout="column" fxLayoutGap="0.75rem">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div
        fxFlex="55"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div
          flexLayout="row"
          fxLayoutAlign="center center"
        >
          <h1 class="heading">{{translations.accountInformationHeading}}</h1>
          <a (click)="openEditDialog()"
             class="mat-icon-link">
            <mat-icon class="medium">edit</mat-icon>
          </a>
        </div>
        <h2 class="field-heading">{{translations.nameHeading}}</h2>
        <div fxLayout="column">
          <div class="field-data">{{ profileInfo?.fullName }}</div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2 class="field-heading">{{translations.emailAddressHeading}}</h2>
        <div fxLayout="column" fxLayoutGap="15px">
          <div class="field-data">{{ profileInfo?.email }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
