<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="center"
  fxLayoutGap="1.5rem"
>
  <div
    class="heading"
    fxLayoutAlign="center"
  >
    {{ translations[language].heading }}
  </div>
  <app-delete-account-form-loader
    *ngIf="loading; else deleteAccountForm"></app-delete-account-form-loader>
  <ng-template #deleteAccountForm>
    <app-text-input
      id="email"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['email']"
      [focus]="focus['email']"
      [parentFormGroup]="form"
      class="email-text"
      [controlName]="'email'"
      [fieldBackgroundColor]=fieldBackgroundColor
      [fieldTextColor]=fieldTextColor
      [labelTextColor]=labelTextColor
    ></app-text-input>

    <div class="actions">
      <app-secondary-button
        id=""
        (handleClick)="onDeleteAccount()"
        class="login-button"
        text="{{translations[language].submitButtonLabel}}"
      >
      </app-secondary-button>
    </div>
  </ng-template>
</div>
