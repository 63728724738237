interface PaymentOptionSectionTranslation {
  sectionHeading: string,
  defaultLabel: string,
  expiredLabel: string,
  makeDefaultButtonLabel: string
}

const en: PaymentOptionSectionTranslation = {
  sectionHeading: `Saved Cards`,
  defaultLabel: 'Default',
  expiredLabel: 'Expired',
  makeDefaultButtonLabel: 'Make Default'
}

const ja: PaymentOptionSectionTranslation = {
  sectionHeading: `カード一覧`,
  defaultLabel: '既定のカード',
  expiredLabel: '有効期限切れ',
  makeDefaultButtonLabel: '既定のカードに設定'
}

export const Translations = {
  1: en,
  2: ja
}
