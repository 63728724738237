<div
  class="container"
  fxLayoutAlign="center none"
  ngClass.xs="xs"
>
  <div
    class="create-account-form"
    fxLayout="column"
    fxLayoutAlign="center none"
  >
    <div class="heading">
      {{translations[language].heading}}
    </div>
    <form
      [formGroup]="form"
      fxLayout="column"
      fxLayoutAlign="space-around none"
    >
      <div class="form-fields">
        <app-text-input
          id="fullname"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['fullName']"
          [focus]="focus['fullName']"
          [maxLength]="validations.user.fullName.length.max"
          [parentFormGroup]="form"
          class="input-control full-name-input"
          controlName="fullName"
          [isSlim]="true"
          label="{{translations[language].fullNameLabel}}"
        ></app-text-input>
      </div>
      <div class="form-fields">
        <app-text-input
          id="email"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['email']"
          [focus]="focus['email']"
          [maxLength]="validations.email.length.max"
          [parentFormGroup]="form"
          class="input-control email-input"
          controlName="email"
          [isSlim]="true"
          label="{{translations[language].emailLabel}}"
        ></app-text-input>
      </div>
      <div class="form-fields">
        <app-password-input
          id="password"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['password']"
          [focus]="focus['password']"
          [maxLength]="validations.password.length.max"
          [parentFormGroup]="form"
          class="input-control password-input"
          controlName="password"
          [isSlim]="true"
          [hint]="translations[language].errorMessageMap.password.hint"
          label="{{translations[language].passwordLabel}}"
        ></app-password-input>
      </div>
      <div class="form-fields">
        <app-password-input
          id="confirmpassword"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['confirmPassword']"
          [focus]="focus['confirmPassword']"
          [maxLength]="validations.password.length.max"
          [parentFormGroup]="form"
          class="input-control confirm-password-input"
          controlName="confirmPassword"
          [isSlim]="true"
          label="{{translations[language].confirmPasswordLabel}}"
        ></app-password-input>
      </div>
      <div class="form-actions">
        <app-primary-button
          id="create_account"
          (handleClick)="createAccount()"
          class="signup-button"
          text="{{translations[language].createAccountButton}}"
          [isSlim]="true"
        >
        </app-primary-button>
        <app-primary-clickable-text
          id="login"
          (handleClick)="openLoginDialog()"
          class="login-button"
          fxLayoutAlign="center none"
          text="{{translations[language].loginButton}}"
        ></app-primary-clickable-text>
      </div>
    </form>
  </div>
</div>

