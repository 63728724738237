<div
  class="basic-dialog"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div
    (click)="close()"
    class="close-icon-container"
    fxLayoutAlign="center center"
  >
    <app-icon
      class="close-icon"
      icon="close"
    ></app-icon>
  </div>
  <div mat-dialog-content>
    <template #anchor></template>
  </div>
</div>
