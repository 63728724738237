import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/shared/components/mobile-sidenav.translations";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {languagesList, selectedLanguage} from "../../core/shared.selectors";
import {MobileSidenavItemModel} from "../../../models/mobile-sidenav-item.model";
import {CommonService} from "../../services/common.service";
import {EnableScrolling, SetIsMobileSidenavOpen} from "../../core/shared.actions";
import {Router} from "@angular/router";
import {LazyLoaderService} from "../../services/lazy-loader.service";
import {LoginFormComponent} from "../../../authentication/forms/login-form/login-form.component";
import {FilterModel} from "../../../models/filter.model";
import {Subscription} from "rxjs";
import {languages} from "../../constants/common.constants";

@Component({
  selector: 'app-mobile-sidenav',
  templateUrl: './mobile-sidenav.component.html',
  styleUrls: ['./mobile-sidenav.component.css']
})
export class MobileSidenavComponent implements OnInit, OnDestroy {
  @Input() isAuthenticated = false;

  options: MobileSidenavItemModel[] = []

  translations = Translations;

  language: number = 1;
  languagesList: FilterModel[] = [];
  selectedLanguageText: string;

  languageSubscription = this.store.pipe(select(selectedLanguage)).subscribe(data => {
    this.language = data;
    this.selectedLanguageText = this.getSelectedLanguageName();
  });

  languagesListSubscription: Subscription = this.store.pipe(select(languagesList)).subscribe((data) => {
    this.languagesList = data;
    if (this.language) {
      this.selectedLanguageText = this.getSelectedLanguageName();
    }
  });

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router,
    private lazyLoaderService: LazyLoaderService
  ) {
  }

  ngOnInit(): void {
    this.options = [
      {
        key: 'selections',
        handleClick: () => this.redirectTo('/', {fragment: "tea-leaves"}),
        isVisible: true
      },
      {
        key: 'cart',
        handleClick: () => this.redirectTo('/cart'),
        isVisible: true
      },
      {
        key: 'accountInformation',
        handleClick: () => this.redirectTo('/user/personal-information'),
        isVisible: this.commonService.isAuthenticated()
      },
      {
        key: 'myOrders',
        handleClick: () => this.redirectTo('/user/orders'),
        isVisible: this.commonService.isAuthenticated()

      },
      {
        key: 'mySubscriptions',
        handleClick: () => this.redirectTo('/user/subscriptions'),
        isVisible: this.commonService.isAuthenticated()
      },
      {
        key: 'teploTeapot',
        handleClick: () => {
          if (Number(this.language) === languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/')
          }
          if (Number(this.language) === languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/')
          }
        },
        isVisible: true
      },
      {
        key: 'teploCarafe',
        handleClick: () => {
          if (Number(this.language) === languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/carafe')
          }
          if (Number(this.language) === languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/carafe')
          }
        },
        isVisible: true
      },
      {
        key: 'teploForBusiness',
        handleClick: () => {
          if (this.language == languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/business/')
          }
          if (this.language == languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/business/')
          }
        },
        isVisible: true
      },
      {
        key: 'blog',
        handleClick: () => {
          if (this.language == languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/blogs/')
          }
          if (this.language == languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/blogs/')
          }
        },
        isVisible: true
      },
      {
        key: 'support',
        handleClick: () => {
          if (this.language == languages.english) {
            this.commonService.openInNewTab('https://teplotea.com/en/support/')
          }
          if (this.language == languages.japanese) {
            this.commonService.openInNewTab('https://teplotea.com/support/')
          }
        },
        isVisible: true
      },

      ...(
        this.isAuthenticated ?
          [{
            key: 'logout',
            handleClick: () => {
              this.commonService.logout();
            },
            isVisible: true
          }] :
          [{
            key: 'login',
            handleClick: () => {
              this.store.dispatch(SetIsMobileSidenavOpen({isMobileSidenavOpen: false}));
              this.commonService.openDialog({data: {component: LoginFormComponent}});
            },
            isVisible: true
          }]
      )
    ]
  }

  redirectTo(path: String, options: any = {}) {
    this.router.navigate([path], options).then(() => {
      this.store.dispatch(SetIsMobileSidenavOpen({isMobileSidenavOpen: false}));
    });
  }

  setSelectedLanguage(languageCode: number) {
    this.commonService.setLanguage(Number(languageCode));
  }

  getSelectedLanguageName() {
    return (this.languagesList.filter((o) => o.key == this.language))[0]?.value || '';
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.languagesListSubscription?.unsubscribe();
    this.store.dispatch(EnableScrolling());
  }
}
