import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {FetchOrdersDetailsRequest} from "../../../core/user-details.actions";
import {Translations} from "../../../../translations/user-details/orders/order-details-popup-screen.translations";
import {Subscription} from "rxjs";
import {isMobileView, selectedLanguage} from "../../../../shared/core/shared.selectors";
import {selectedOrderData} from "../../../core/user-details.selectors";

@Component({
  selector: 'app-order-details-popup-screen',
  templateUrl: './order-details-popup-screen.component.html',
  styleUrls: ['./order-details-popup-screen.component.css']
})
export class OrderDetailsPopupScreenComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data;

  language: number = 1;
  dateFormat: string;
  orderDetails: any;
  translations = Translations;
  loading: boolean = false;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  ordersDetailsSubscription: Subscription = this.store.pipe(select(selectedOrderData)).subscribe(selectedOrderData => {
    this.orderDetails = selectedOrderData.orderDetails;
    this.loading = selectedOrderData.loading;
  });

  mobileViewSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(FetchOrdersDetailsRequest({payload: this.data}));
  }

  handleContactUs(orderCode): void {
    window.open(
      `mailto:orders@load-road.com?subject=Regarding orderDetails Id: ${orderCode}`
    );
  }

  ngAfterViewInit() {
    this.mobileViewSubscription = this.store.pipe(select(isMobileView)).subscribe((isMobileView) => {
      if (!isMobileView) {
        document.querySelectorAll<HTMLElement>('.cdk-overlay-pane.dialog')[0].classList.add('w70');
      }
    });
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.ordersDetailsSubscription?.unsubscribe();
    this.mobileViewSubscription?.unsubscribe();
  }

}
