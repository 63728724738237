import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { Translations } from '../../../translations/tea-details-page/brew-strength-info.translation';
import { getExternalBrewDetails } from '../../core/tea-details.selectors';

@Component({
  selector: 'app-brew-strength-info',
  templateUrl: './brew-strength-info.component.html',
  styleUrls: ['./brew-strength-info.component.css'],
})
export class BrewStrengthInfoComponent implements OnInit, OnChanges, OnDestroy {
  language: number;
  externalBrewDetails: any;
  @Input() selectedBrewStrength = 'ideal';
  @Output() StrengthHandleClick = new EventEmitter();
  @Output() handleClick = new EventEmitter();
  translations: any = Translations;

  unitSystem: string;
  brewStrength: string;

  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  externalBrewDetailsSubscription: Subscription = this.store
    .select(getExternalBrewDetails)
    .subscribe((data) => {
      this.externalBrewDetails = data;
    });

  constructor(private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {}

  brewStrengthSelect(strength) {
    this.selectedBrewStrength = strength;
    this.StrengthHandleClick.emit(strength);
  }

  handleNextClick() {
    if (this.selectedBrewStrength === 'coldCarafe') {
      this.handleClick.emit(4);
    } else {
      this.handleClick.emit(1);
    }
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe()
    this.externalBrewDetailsSubscription?.unsubscribe()
  }
}
