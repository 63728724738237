interface MaintenancePageTranslation {
  errorMessage: string;
  message: string;
}

const en: MaintenancePageTranslation = {
  errorMessage: 'WE’RE DOWN FOR MAINTENANCE',
  message: 'This page is undergoing maintenance and will be back soon',
}

const ja: MaintenancePageTranslation = {
  errorMessage: 'メンテナンス中です',
  message: 'メンテナンス中です',
}

export const Translations = {
  1: en,
  2: ja
}
