interface UnauthenticatedBrewNotificationInfoComponent {
  labelText1: string,
  labelText2: string,
  submit: string,
  placeholderText: string,
  errorMessageMap: object,
}

const en: UnauthenticatedBrewNotificationInfoComponent = {
  labelText1: `We will notify you when your cold brew is ready`,
  labelText2: `Please provide your Email Id, for us to send the notification`,
  submit: `Submit`,
  placeholderText: `Please Enter Your Email Address`,
  errorMessageMap: {
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email Id.'
    }
  }
}

const ja: UnauthenticatedBrewNotificationInfoComponent = {
  labelText1: `飲みごろになりましたら通知でお知らせします`,
  labelText2: `飲み物の通知を受け取るには、メールアドレスを入力してください`,
  submit: `抽出を開始`,
  placeholderText: `メールアドレスを入力してください`,
  errorMessageMap: {
    email: {
      required: '入力が必須です',
      pattern: 'Eメールが無効です'
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
