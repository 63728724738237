<div class="container"
     ngClass.sm="sm"
     ngClass.xs="xs">
  <div
    class="heading-section"
    fxLayoutAlign="center center"
    fxLayout="column"
  >
    <app-shimmer-loader
      [theme]="isMobileView?{
            'height': '3rem',
            'width': '25rem'
      }:{
            'height': '3.3rem',
            'width': '40rem'
          }"
    ></app-shimmer-loader>
    <app-shimmer-loader
      [theme]="isMobileView?{
            'height': '2rem',
            'width': '40rem',
            'margin-top': '1rem',
            'margin-bottom': '7rem'
      }:{
            'height': '2rem',
            'width': '60rem',
            'margin-top': '1rem',
            'margin-bottom': '7rem'
          }"
    ></app-shimmer-loader>
  </div>
  <div class="tea-details-section"
       fxLayout="row"
       fxLayout.xs="column"
       fxLayout.sm="column"
       fxLayoutAlign="center center">
    <div class="left">
      <app-shimmer-loader
        [theme]="isMobileView?{
            'height': '35rem',
            'width': '35rem',
            'margin-left': '1rem'
        }:{
            'height': '66rem',
            'width': '68rem'
          }"
      ></app-shimmer-loader>
      <div fxLayout="row"
           fxLayoutAlign="center center"
      >
        <div *ngFor="let each of [1, 2, 3]">
          <app-shimmer-loader
            appearance="circle"
            [theme]="{
            'height': '1rem',
            'width': '1rem'
          }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutGap="2rem"
      fxLayout.xs="column"
      fxLayoutAlign="center center">
        <app-shimmer-loader
          [theme]="isMobileView?{
          'height': '6rem',
          'width': '20rem'
          }:{
            'height': '6rem',
            'width': '25rem',
            'margin-top': '3rem'
          }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          [theme]="isMobileView?{
            'height': '6rem',
            'width': '20rem',
            'margin-bottom': '5rem'
          }:{
            'height': '6rem',
            'width': '25rem',
            'margin-top': '3rem'
          }"
        ></app-shimmer-loader>
      </div>
    </div>

    <div class="right">

      <div *ngFor="let each of [1, 2]"
           fxLayout="column">
        <div fxLayout="row">
          <app-shimmer-loader
            appearance="circle"
            [theme]="isMobileView?{
            'height': '3.3rem',
            'width': '3.3rem'
            }:{
            'height': '3.3rem',
            'width': '3.3rem',
            'margin-right': '1.5rem'
          }"
          ></app-shimmer-loader>
          <app-shimmer-loader
            [theme]="{
            'height': '2rem',
            'width': '18rem',
            'margin-top': '1.2rem'
          }"
          ></app-shimmer-loader>
        </div>
      </div>

      <div>
        <app-shimmer-loader
          [theme]="{
            'height': '3rem',
            'width': '15rem',
            'margin-top': '2rem',
            'margin-left': '1rem'
          }"
        ></app-shimmer-loader>
      </div>

      <div fxLayout="row"
           fxLayoutGap="4rem"
           fxLayoutGap.xs="1rem"
      >
        <div *ngFor="let each of [1, 2, 3, 4]"
             fxLayout="column"
             fxLayoutAlign="center center">
          <app-shimmer-loader
            appearance="circle"
            [theme]="{
            'height': '6rem',
            'width': '6rem'
          }"
          ></app-shimmer-loader>
          <app-shimmer-loader
            [theme]="isMobileView?{
            'height': '1.5rem',
            'width': '4rem'
            }:{
            'height': '1.5rem',
            'width': '6rem'
          }"
          ></app-shimmer-loader>
          <app-shimmer-loader
            [theme]="isMobileView?{
            'height': '1rem',
            'width': '6rem'
            }:{
            'height': '1rem',
            'width': '10rem'
          }"
          ></app-shimmer-loader>
        </div>
      </div>

      <div fxLayout="column"
           *ngFor="let each of [1, 2, 3]">
        <app-shimmer-loader
          [theme]="{
            'height': '2.5rem',
            'width': '10rem',
            'margin-top': '1rem'
          }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          [theme]="{
            'height': '2rem',
            'width': '20rem',
            'margin-bottom': '2rem'
          }"
        ></app-shimmer-loader>
      </div>

      <div>
        <app-shimmer-loader
          [theme]="{
            'height': '6rem',
            'width': '30rem',
            'margin-top': '1rem'
          }"
        ></app-shimmer-loader>
      </div>

    </div>
  </div>
  <div class="pairing">
    <app-shimmer-loader
      [theme]="{
            'height': '3rem',
            'width': '15rem',
            'margin-top': '3rem'
          }"
    ></app-shimmer-loader>
    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutGap="5rem"
    >
      <div *ngFor="let each of [1, 2, 3]"
           fxLayout="column">
        <app-shimmer-loader
          [theme]="isMobileView?{
            'height': '15rem',
            'width': '25rem'
          }:{
            'height': '15rem',
            'width': '15rem',
            'margin-top': '2rem'
          }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          [theme]="{
            'height': '1.5rem',
            'width': '12rem'
          }"
        ></app-shimmer-loader>
      </div>
    </div>
      <app-shimmer-loader
        [theme]="isMobileView?{
            'height': '2rem',
            'width': '30rem',
            'margin-top': '3rem'
        }:{
            'height': '1.5rem',
            'width': '80rem',
            'margin-top': '3rem'
          }"
      ></app-shimmer-loader>
  </div>
  <div class="horizontal-divider"></div>
  <div class="story"
  fxLayoutAlign="center center"
  fxLayout="column">
    <app-shimmer-loader
      [theme]="{
            'height': '3rem',
            'width': '25rem',
            'margin-top': '3rem'
          }"
    ></app-shimmer-loader>
    <app-shimmer-loader
      [theme]="isMobileView?{
            'height': '30rem',
            'width': '40rem',
            'margin-top': '2rem',
            'border-radius': '1.5rem'
      }:{
            'height': '60rem',
            'width': '100rem',
            'margin-top': '2rem'
          }"
    ></app-shimmer-loader>
  </div>
  <div class="story-description"
       fxLayout="column">
    <app-shimmer-loader
      [theme]="{
            'height': '2rem',
            'width': '25rem',
            'margin-top': '2rem'
          }"
    ></app-shimmer-loader>
    <app-shimmer-loader
      [theme]="isMobileView?{
            'height': '5rem',
            'width': '30rem'
      }:{
            'height': '5rem',
            'width': '130rem'
          }"
    ></app-shimmer-loader>
  </div>
</div>
