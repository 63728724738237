<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && !isLoading && handleClick.emit()"
  [disableRipple]="isDisabled || isLoading"
  [ngClass]="{ disabled: isDisabled, loading: isLoading}"
  [throttleTime]="1000"
  class="button secondary-button font-base"
  mat-button
  preventMultipleClick
>
  <div class="button-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="button-label">
      {{ text }}
    </div>
    <mat-icon class="loading-icon"><mat-spinner color="accent" diameter="20" strokeWidth="2"></mat-spinner></mat-icon>
  </div>
</button>

