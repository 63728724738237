import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "../shared/pages/page-not-found/page-not-found.component";
import {ResetPasswordScreenComponent} from "./reset-password-screen/reset-password-screen.component";

const routes: Routes = [
  {
    path: '',
    component: ResetPasswordScreenComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResetPasswordRoutingModule {
}
