interface FooterTranslations {
  privacy: string;
  terms: string;
  notice: string;
}

const en: FooterTranslations = {
  privacy: 'Privacy policy',
  terms: 'Terms of use',
  notice: 'Sales and Refund'
}

const ja: FooterTranslations = {
  privacy: 'プライバシーポリシー',
  terms: '利⽤規約',
  notice: '特定商取引法に基づく表記'
}

export const Translations = {
  1: en,
  2: ja
};
