<div class="account-info-card text-center" fxLayout="column" fxLayoutGap="30px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div
      fxFlex="40"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div
        fxLayoutAlign="center flex-start">
        <h1 class="heading">{{translations.accountInformationHeading}}</h1>
        <app-shimmer-loader
          [theme]="{
              'background-color': '#e5e5e5',
              'height': '1.8rem',
              'width': '1.8rem',
              'margin': '0 0 0 1.1rem'
            }"
        ></app-shimmer-loader>
      </div>
      <h2 class="field-heading">{{translations.nameHeading}}</h2>
      <div fxLayout="column" fxLayoutGap="15px">
        <app-shimmer-loader
          [theme]="{
              'background-color': '#e5e5e5',
              'height': '2rem',
              'width': '15rem',
              'margin-bottom': '0'
            }"
        ></app-shimmer-loader>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h2 class="field-heading">{{translations.emailAddressHeading}}</h2>
      <div fxLayout="column" fxLayoutGap="15px">
        <app-shimmer-loader
          [theme]="{
              'background-color': '#e5e5e5',
              'height': '2rem',
              'width': '30rem',
              'margin-bottom': '0'
            }"
        ></app-shimmer-loader>
      </div>
    </div>
  </div>
</div>
