import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {v1URL} from '../../shared/constants/urls.constants';
import {CommonService} from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root',
})

export class ResetPasswordService {
  resetPasswordSuccess: Subject<any> = new Subject();

  constructor(private commonServices: CommonService) {
  }

  resetPasswordSet(payload): Observable<any> {
    const urlConfig = v1URL.user.password.reset.set
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }
}
