<div
  class="container"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xl="xl"
  ngClass.xs="xs"
>
  <div
    class="cards-container"
    [fxLayoutAlign]="(itemList?.length < 4 ? 'center' : 'flex-start') + ' center'"
    fxLayout="row wrap"
  >
    <div
      *ngFor="let eachItem of itemList"
      fxFlex.lg="0 0 calc(25% - 13.5rem/4)"
      fxFlex.md="0 0 calc(33.3333% - 9rem/3)"
      fxFlex.sm="0 0 calc(50% - 4.5rem/2)"
      fxFlex.xl="0 0 calc(20% - 22.5rem/5)"
      fxFlex.xs="100"
    >
      <app-subscription-card-loader
        [translations]="translations"
      ></app-subscription-card-loader>
    </div>
  </div>
</div>









