/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthenticationState} from './authentication.reducer';

// Selector functions
const authenticationData =
  createFeatureSelector<AuthenticationState>('authentication');

// Create Selectors
export const profile = createSelector(
  authenticationData,
  (state: AuthenticationState) => state.profileData
);

export const getLoadingFlags = createSelector(
  authenticationData,
  (state) => ({
    logout: state.logout,
    updateAccount: state.updateAccount,
  })
);

export const isForgotPasswordSuccessful = createSelector(
  authenticationData,
  (state) => state.isForgotPasswordSuccessful
);
