import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as fromRoot from '../../../../../../state/app.state';
import {select, Store} from "@ngrx/store";
import {CommonService} from "../../../../../shared/services/common.service";
import {Subscription} from "rxjs";
import {selectedLanguage} from "../../../../../shared/core/shared.selectors";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Translations} from '../../../../../translations/user-details/personal-information/address-form.translation';
import {UserAddressModel} from "../../../../../models/user-address.model";
import {getCountriesList, getLoadingFlags} from "../../../../core/user-details.selectors";
import {AddressService} from "../../../services/address.service";
import * as Validations from "../../../../../shared/constants/validation.constants";
import * as Constants from "../../../../../shared/constants/common.constants";
import {ValidationService} from "../../../../../shared/services/validation.service";

@Component({
  selector: 'app-add-update-address-form',
  templateUrl: './add-update-address-form.component.html',
  styleUrls: ['./add-update-address-form.component.css']
})
export class AddUpdateAddressFormComponent implements OnInit, OnDestroy {
  focus: any = {};
  @Input() address: UserAddressModel;
  language: number = 1;
  countryList: any;
  validations = Validations;
  constants = Constants;
  translations: any;
  loading: boolean = false;

  form: FormGroup;
  errorMessages = {};
  errorMessageMap;

  // Get language
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe((selectedLanguage) => {
    this.language = selectedLanguage;
  });

  // Get country list
  countryListSubscription: Subscription = this.store.pipe(select(getCountriesList)).subscribe((countryList) => {
    this.countryList = countryList;
  });

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.loading = loadingFlags.saveAddress?.loading;
  });

  countryChangeSubscription: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private fb: FormBuilder,
              private addressService: AddressService,
              private validationService: ValidationService) {
  }

  ngOnInit(): void {
    this.translations = Translations[this.language];
    this.errorMessageMap = this.translations.errorMessageMap;

    this.form = this.fb.group({
      location: new FormControl(this.address?.location || '', {
        validators: [
          Validators.required,
          this.validationService.noWhitespaceValidator
        ],
      }),
      recipientName: new FormControl(this.address?.recipientName || '', {
        validators: [
          Validators.required,
          Validators.pattern(this.commonService.alphabetsRegex)
        ],
      }),
      country: new FormControl(this.address?.countryId || '', [
        Validators.required,
      ]),
      zip: new FormControl(this.address?.zip || '', {
        validators: [
          Validators.required,
          Validators.minLength(this.validations.address.zipCode.length.min),
          Validators.maxLength(this.validations.address.zipCode.length.max),
          Validators.pattern(this.commonService.zipCodeRegex)
        ],
      }),
      state: new FormControl(this.address?.state || '', {
        validators: [
          Validators.required,
          this.validationService.noWhitespaceValidator
        ],
      }),
      city: new FormControl(this.address?.city || '', {
        validators: [
          Validators.required,
          this.validationService.noWhitespaceValidator
        ],
      }),
      addressLine1: new FormControl(this.address?.addressLine1 || '', {
        validators: [
          Validators.required,
          Validators.minLength(this.validations.address.addressLine1.length.min),
          this.validationService.noWhitespaceValidator
        ],
      }),
      addressLine2: new FormControl(this.address?.addressLine2 || '', {
        validators: [
          Validators.minLength(this.validations.address.addressLine2.length.min)
        ],
      }),
      phoneCode: new FormControl({value: '', disabled: true}, []),
      phoneNumber: new FormControl(this.address?.phoneNumber || '', {
        validators: [
          Validators.required,
          Validators.minLength(this.validations.phoneNumber.length.min),
          Validators.maxLength(this.validations.phoneNumber.length.max)
        ],
      }),
    });

    // Logic for countryCode
    const selectedCountry = this.address?.countryId;
    if (selectedCountry && this.countryList.length) {
      const countryOption = this.countryList.find(
        (eachCountry) => eachCountry.key.split('_')[1] === selectedCountry
      );
      this.form.patchValue(
        {
          country: countryOption.key,
          phoneCode: countryOption.key.split('_')[0]
        },
        {emitEvent: false},
      );
    }

    // Country change subscription
    this.countryChangeSubscription = this.form.controls.country.valueChanges.subscribe((data) => {
      this.form.patchValue({phoneCode: data.split('_')[0]});
    });
  }

  updateAddress() {

    if (this.form.invalid) {
      this.initializeFocus();
      this.checkForErrors();
      this.commonService.setFocus(this.form, this.errorMessages, this.focus);
      return;
    }
    const {
      location,
      recipientName,
      country,
      zip,
      state,
      city,
      addressLine1,
      addressLine2,
      phoneNumber,
    } = this.form.getRawValue();

    const payload = {
      location,
      recipientName,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      phoneNumber,
      returnAll: true,
      countryId: country.split('_')[1],
      ...(this.address ? {addressId: this.address._id} : {}),
    };
    if (this.address) {
      this.addressService.updateAddress.emit(payload);
      return;
    }

    this.addressService.saveAddress.emit(payload);
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  initializeFocus() {
    this.focus = {
      location: false,
      recipientName: false,
      country: false,
      zip: false,
      state: false,
      city: false,
      addressLine1: false,
      addressLine2: false,
      phoneCode: false,
      phoneNumber: false,
    }
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.countryListSubscription?.unsubscribe();
    this.countryChangeSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
  }

}
