import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "../shared/pages/page-not-found/page-not-found.component";
import {PackageDetailsScreenComponent} from "./package-details-screen/package-details-screen.component";
import {ProductGuard} from "../shared/services/product.guard";

const routes: Routes = [
  {
    path: ':id',
    canActivate: [ProductGuard],
    component: PackageDetailsScreenComponent
  },
  {
    path: ':vendor/:id',
    canActivate: [ProductGuard],
    component: PackageDetailsScreenComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackageDetailsRoutingModule {
}
