<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <div
    *ngIf="label"
    [ngClass]="labelFontWeight"
    [class.slim]="isSlim"
    [ngStyle]="{
        color: labelTextColor,
        textAlign: labelAlignment
      }"
    class="form-field-label"
  >
    <span
      *ngIf="isRequired"
      class="required-mark"
    >*</span>
    {{label}}
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-form-field
      [formGroup]="parentFormGroup"
      [ngClass]="{
        'enabled-clear-selection': enableClearSelection &&
                                   parentFormGroup.controls[this.controlName].value &&
                                   parentFormGroup.controls[this.controlName].status !== 'DISABLED',
        'show-border': showBorder,
        slim: isSlim
      }"
      [ngStyle]="{
        color: fieldTextColor,
        backgroundColor: fieldBackgroundColor
      }"
      appearance="outline"
      class="form-field"
      hideRequiredMarker
    >
      <mat-select
        #select
        [formControlName]="controlName"
        [multiple]="enableMultiple"
        [ngClass]="{
            'enabled-clear-selections': (parentFormGroup.controls[this.controlName].status !== 'DISABLED') &&
            (enableClearSelection && parentFormGroup.getRawValue()[controlName])
          }"
        [panelClass]="optionsPanelClassName"
        [placeholder]="placeholder"
      >
        <mat-option
          *ngFor="let option of options"
          [ngStyle]="{
              color: fieldTextColor,
              backgroundColor: fieldBackgroundColor
            }"
          [value]="option.key"
          class="single-select-option"
        >
          <span [innerHtml]="option.value"></span>
        </mat-option>
      </mat-select>
      <mat-icon
        (click)="clearSelection($event)"
        *ngIf="(parentFormGroup.controls[this.controlName].status !== 'DISABLED') &&
            (enableClearSelection && parentFormGroup.getRawValue()[controlName])"
        class="clear-selection"
        matSuffix
        matTooltip="Clear"
      >close
      </mat-icon>
    </mat-form-field>
  </div>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
