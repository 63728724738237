import {createReducer, on} from "@ngrx/store";
import * as Actions from './delete-account.actions';

export interface DeleteAccountState {
  loading: boolean;
}

const initialState: DeleteAccountState = {
  loading: false
}

export const reducer = createReducer(
  initialState,
  on(Actions.ClearState, (state) => initialState),
  on(Actions.DeleteAccountRequest, (state) => ({
    ...state,
    loading: true
  })),
  on(Actions.DeleteAccountSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(Actions.DeleteAccountFailure, (state) => ({
    ...state,
    loading: false
  })),
)
