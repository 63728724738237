import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/cart-details/authenticated-cart-screen.translations";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import {CommonService} from "../../../shared/services/common.service";
import {Router} from "@angular/router";
import * as fromRoot from "../../../../state/app.state";
import {
  FetchAddressListRequest,
  FetchCartRequest,
  PlaceOrderRequest,
  UserPaymentCardsRequest
} from "../../core/cart.actions";
import {cardsList, cart, getAddressList} from "../../core/cart.selectors";
import {profile} from "../../../authentication/core/authentication.selectors";
import {
  ShippingAddressSelectionScreenComponent
} from "../components/shipping-address-selection/shipping-address-selection-screen/shipping-address-selection-screen.component";
import {
  CardSelectionScreenComponent
} from "../components/card-selection/card-selection-screen/card-selection-screen.component";
import {LazyLoaderService} from "../../../shared/services/lazy-loader.service";

@Component({
  selector: 'app-authenticated-cart-screen',
  templateUrl: './authenticated-cart-screen.component.html',
  styleUrls: ['./authenticated-cart-screen.component.css']
})
export class AuthenticatedCartScreenComponent implements OnInit, OnDestroy {
  language: number = 1;
  cartData: any;
  addressList: any;
  cardsList: any;
  profileData: any;
  translations: any = Translations;

  profileDataSubscription: Subscription = this.store.pipe(select(profile)).subscribe(profileData => {
    this.profileData = profileData.profile;
  });

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  cartDataSubscription: Subscription = this.store.pipe(select(cart)).subscribe(data => {
    this.cartData = data
  });

  addressListSubscription: Subscription = this.store.pipe(select(getAddressList)).subscribe(data => {
    this.addressList = data;
  });

  cardsListSubscription: Subscription = this.store.pipe(select(cardsList)).subscribe(data => {
    this.cardsList = data;
  });

  constructor(
    private commonService: CommonService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private lazyLoaderService: LazyLoaderService,
  ) {
    this.store.dispatch(FetchCartRequest());
    this.store.dispatch(FetchAddressListRequest({}));
    this.store.dispatch(UserPaymentCardsRequest({}));
  }

  ngOnInit(): void {

  }

  openAddressSelectionDialog(selectedAddressId?) {
    this.commonService.openDialog({
      data: {
        component: ShippingAddressSelectionScreenComponent,
        selectedAddressId,
        orderId: this.cartData.orderId,
        addressList: this.addressList
      }
    })
  }

  openCardSelectionDialog(selectedCardId?) {
    this.commonService.openDialog({
      data: {
        component: CardSelectionScreenComponent,
        selectedCardId,
        orderId: this.cartData.orderId,
        cardsList: this.cardsList
      }
    })
  }

  openDocument() {
    let language = 'EN';
    if (this.language === 2) {
      language = 'JA';
    }
    this.commonService.openInNewTab('https://teplo-files-bucket.s3.us-east-2.amazonaws.com/documents/policies/subscription_contract_and_cancellation_policy_' + language + '.pdf?v=' + new Date().getTime())
  }

  addAddress() {
    this.router.navigate(['/user/personal-information'], {queryParams: {addAddress: true}});
  }

  addCard() {
    this.router.navigate(['/user/personal-information'], {queryParams: {addPaymentOption: true}});
  }

  placeOrder() {
    this.store.dispatch(PlaceOrderRequest({}));
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.cartDataSubscription?.unsubscribe();
    this.addressListSubscription?.unsubscribe();
    this.cardsListSubscription?.unsubscribe();
    this.profileDataSubscription?.unsubscribe();
  }

}
