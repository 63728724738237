<div
  fxLayout="column"
  *ngIf="packageDetail$ | async as packageDetails"
>
  <app-pakage-details-loader
    *ngIf="packageDetails.success === null || packageDetails.loading; else packageLoaded">
  </app-pakage-details-loader>
  <ng-template #packageLoaded>
    <div *ngIf="packageDetails.success !== null">
      <div
        *ngIf="packageDetails?.success &&
        (packageDetails?.id === productId || packageDetails?.key === productId);
        else noData">
        <div
          fxFlex="100"
          fxLayout="column">
          <div class="package-heading-div" fxLayout="row" fxLayoutAlign="center none">
            <div class="heading-section">{{ packageDetails?.name }}</div>
          </div>
          <div
            fxFlex="100"
            fxLayout.gt-sm="row"
            fxLayout="column"
            fxLayoutGap="30"
            [ngClass]="
      !isMobileView
        ? 'package-details-outer-div'
        : 'mobile-package-details-outer-div'
    "
          >
            <div fxFlex="50" fxLayout="column" fxLayoutGap="20">
              <div fxLayoutAlign="center">
                <app-carousel
                  [slidesData]="packageDetails?.imageList"
                  lgComponentCount="1"
                  mdComponentCount="1"
                  smComponentCount="1"
                  xsComponentCount="1"
                  [pagination]="true"
                  class="image-carousel"
                  [autoPlay]="2000"
                  [loop]="true"
                >
                  <ng-template let-image>
                    <div class="carousel-item" fxLayoutAlign="center center">
                      <img class="carousel-image" [src]="image" alt=""/>
                    </div>
                  </ng-template>
                </app-carousel>
              </div>
              <div
                class="package-details-actions-section"
                fxFlex="0 0 0"
                fxLayoutAlign="center center"
                fxLayoutGap="10"
                fxLayout.lt-sm="column"
                fxFlex.lt-sm="100"
              >
                <div
                  class="drop-down-section"

                  fxFlex.gt-md="40"
                  fxFlex.lt-sm="40"
                  [class.mobile-button]="isMobileView">
                  <app-single-select
                    class="price-selection"
                    *ngIf="message?.length === 0"
                    [options]="packageDetails?.priceList"
                    [parentFormGroup]="quantitySelectionForm"
                    [showBorder]="true"
                    controlName="quantity"
                    fxFlex="100"
                  ></app-single-select>
                  <div class="block-layout light" fxFlex="100" *ngIf="message?.length>0 ">
                    <app-primary-clickable-text
                      [text]="message"
                    ></app-primary-clickable-text>
                  </div>
                </div>
                <div class="add-to-cart-section"
                     fxFlex.gt-md="40"
                     fxFlex.lt-sm="40"
                     [class.mobile-button]="isMobileView">
                  <app-primary-button
                    id="add_to_cart"
                    [isDisabled]="message?.length>0 "
                    [text]="translations[language].addToCart"
                    (handleClick)="!message && addToCart()"
                  ></app-primary-button>
                </div>
              </div>
            </div>
            <div
              fxFlex="50"
              fxFlex.lt-sm="100"
              fxLayout="column"
              fxLayoutGap="2rem"
            >
              <div
                class="bread-crumb-section"
                fxLayout="row"
                fxLayoutAlign="none center"
                fxLayoutGap="5"
              >
                <div class="bread-crumb-logo" fxLayoutAlign="center center">
                  <img class="bread-crumb-image" src="assets/images/png/breadcrumb-logo-package.png" alt=""/>
                </div>
                <div class="bread-crumb-text">
                  {{ translations[language].vendor + " - " }}
                  {{ packageDetails?.vendor?.name }}
                </div>
              </div>
              <div class="sub-heading-section">
                {{ packageDetails?.description }}
              </div>
              <div
                class="qna-section"
                *ngFor="let eachQuestion of packageDetails?.questionList"
              >
                <app-package-details-qna
                  [question]="eachQuestion?.question"
                  [answer]="eachQuestion?.answer"
                ></app-package-details-qna>
              </div>
              <div class="contact-us-note">
                {{translations[language].contactUsText}}
              </div>
            </div>
          </div>
          <div
            class="contact-us-section"
            fxFlex="40"
            fxLayoutAlign="center none"
            fxFlex.lt-sm="100"
          >
            <app-secondary-button
              id="contact_us"
              (handleClick)="handleContactUs(packageDetails?.name)"
              fxFlex.lt-sm="90"
              fxFlex="50"
              [text]="translations[language].contactUsButton"
            ></app-secondary-button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noData>
      <app-half-content-half-image>
        <div
          class="no-data"
          fxLayoutAlign="center center"
        >
          {{translations[language].noData}}
        </div>
      </app-half-content-half-image>
    </ng-template>
  </ng-template>
</div>
