interface BrewProcessingInfoTranslation {
  heading: string,
  timeRemaining: string,
  btnNext: string,
}

const en: BrewProcessingInfoTranslation = {
  heading: `Brew is Processing`,
  timeRemaining: `Time Remaining`,
  btnNext: `Next`,
}

const ja: BrewProcessingInfoTranslation = {
  heading: `カウントダウンが終了するまで抽出してください。`,
  timeRemaining: `抽出完了までの時間`,
  btnNext: `次に進む`,
}

export const Translations = {
  1: en,
  2: ja
}
