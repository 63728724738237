import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../translations/cart-details/unauthenticated-cart-non-editable-row.translations";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../state/app.state";

@Component({
  selector: 'app-unauthenticated-cart-non-editable-row',
  templateUrl: './unauthenticated-cart-non-editable-row.component.html',
  styleUrls: ['./unauthenticated-cart-non-editable-row.component.css']
})
export class UnauthenticatedCartNonEditableRowComponent implements OnInit, OnDestroy {
  language: number = 1;
  @Input() data: any;
  @Input() currencySymbol: string;
  translations: any = Translations;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private store: Store<fromRoot.State>,
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
