/* NgRx */
import {createAction, props} from '@ngrx/store';
import {PackageDetailsModel} from '../../models/package-details.model';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[package details] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** RESET SUCCESS STATE ***********/
const RESET_SUCCESS_STATE = '[package details] reset success state';
export const ResetSuccessState = createAction(RESET_SUCCESS_STATE);

/******** Package Details Actions ***********/
const FETCH_PACKAGE_DETAILS_REQUEST =
  '[package details] fetch package details request';
export const FetchPackageDetailsRequest = createAction(
  FETCH_PACKAGE_DETAILS_REQUEST,
  props<{ payload: { productId?: string, productKey?: string } }>()
);

const FETCH_PACKAGE_DETAILS_SUCCESS =
  '[package details] fetch package details success';
export const FetchPackageDetailsSuccess = createAction(
  FETCH_PACKAGE_DETAILS_SUCCESS,
  props<{ payload: PackageDetailsModel }>()
);

const FETCH_PACKAGE_DETAILS_FAIL =
  '[package details] fetch package details fail';
export const FetchPackageDetailsFail = createAction(
  FETCH_PACKAGE_DETAILS_FAIL,
  props<any>()
);
