interface OrderItemTranslation {
  heading: string,
  orderDate: string,
  orderNumber: string,
  orderTotal: string,
  paymentStatus: string,
  dateFormatJP: string
}

const en: OrderItemTranslation = {
  heading: `ORDER DETAILS`,
  orderDate: `Order Date`,
  orderNumber: `Order #`,
  orderTotal: `Order Total`,
  paymentStatus: `Payment Status`,
  dateFormatJP: ''
}

const ja: OrderItemTranslation = {
  heading: `注文詳細`,
  orderDate: `注文日`,
  orderNumber: `注文番号`,
  orderTotal: `注文総額`,
  paymentStatus: `支払い状況`,
  dateFormatJP: 'yyyy年M月d日'
}

export const Translations = {
  1: en,
  2: ja
}
