import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {PackageDetailsService} from './package-details.service';
import * as fromRoot from '../../../state/app.state';
import {
  FetchPackageDetailsFail,
  FetchPackageDetailsRequest,
  FetchPackageDetailsSuccess
} from './package-details.actions';
import {catchError, map, mergeMap, of, repeat, tap} from 'rxjs';
import {PackageDetailsModel} from '../../models/package-details.model';
import {environment} from "../../../../environments/environment";

@Injectable()
export class PackageDetailsEffects {
  cdnUrl = environment.cdnURL;
  fetchPackageDetailsEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPackageDetailsRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.packageService.fetchPackageDetails(payload).pipe(
          map((response: any) => {
            const data = response.data;
            const packageDetails: PackageDetailsModel = {
              id: data?._id,
              name: data?.displayName,
              imageList: data?.cdnImageUrls?.filter((eachImageObj) => {
                if (eachImageObj.type === 'webImage') {
                  return eachImageObj;
                }
              })[0].urls.map(eachUrl => {
                if (eachUrl) {
                  return this.cdnUrl + '/' + eachUrl;
                }
              }) || [],

              priceList: data?.pricingPlans?.map(eachPricingPlan => {
                return {
                  key: eachPricingPlan._id,
                  value: `${eachPricingPlan.currencySymbol} ${eachPricingPlan.price} / ${eachPricingPlan.planName}`
                }
              }),
              key: data?.key,
              vendor: data?.vendor,
              description: data?.productInfo?.infoDescription,
              questionList: data?.productInfo?.infoQueries,
              isAvailableInCountry: data?.isAvailableInCountry,
              availableQuantity: data?.availableQuantity,
              loading: data?.loading,
            }
            return FetchPackageDetailsSuccess({payload: packageDetails});
          })
        )
      ),
      catchError((error) => {
        return of(
          FetchPackageDetailsFail({message: error.message})
        );
      }),
      tap((action) => {
        if (action.type === FetchPackageDetailsSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (action.type === FetchPackageDetailsFail.type) {
          // Code to execute on API Failure Action dispatch
        }
      }),
      repeat()
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private packageService: PackageDetailsService,
    private actions: Actions,
  ) {
  }
}
