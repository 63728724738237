import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['../styles.css', './primary-button.component.css'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() text: string;
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() isSlim: boolean = false;
  @Input() buttonId: string = '';
  @Output() handleClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }
}
