import * as SharedActions from './shared.actions';
import {createReducer, on} from '@ngrx/store';
import {FilterModel} from "../../models/filter.model";
import {FilterCountryModel} from "../../models/filter-country.model";

export interface SharedState {
  disableScrolling: number[];
  loading: number[];
  selectedLanguage: number;
  languagesList: FilterModel[];
  selectedCountry: string;
  countriesList: FilterCountryModel[];
  isMobileView: boolean;
  isMobileSidenavOpen: boolean;
  isMobileSearchOpen: boolean;
  gendersList: FilterModel[];
  isAppInMaintenanceMode: boolean;
}

const initialState: SharedState = {
  disableScrolling: [],
  loading: [],
  selectedLanguage: 2,
  languagesList: [
    {
      key: '1',
      value: 'English'
    },
    {
      key: '2',
      value: '日本語'
    }
  ],
  selectedCountry: '6246e28b473185d4cde7a98c',
  countriesList: [
    {
      key: '6246e28b473185d4cde7a98c',
      value: 'Japan',
      isoCode: 'JP',
      phoneCode: '+81'
    }
  ],
  isMobileView: false,
  isMobileSidenavOpen: false,
  isMobileSearchOpen: false,
  gendersList: [],
  isAppInMaintenanceMode: false,
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(SharedActions.ClearState, (state) => ({
    ...initialState,
    selectedLanguage: state.selectedLanguage,
    selectedCountry: state.selectedCountry,
    countriesList: state.countriesList,
    isMobileView: state.isMobileView,
    isMobileSidenavOpen: state.isMobileSidenavOpen,
    isMobileSearchOpen: state.isMobileSearchOpen,
  })),

  // SET LANGUAGE
  on(SharedActions.SetLanguage, (state, props) => ({
    ...state,
    selectedLanguage: props.selectedLanguage,
  })),

  // SET COUNTRY
  on(SharedActions.SetCountry, (state, props) => ({
    ...state,
    selectedCountry: props.selectedCountry,
  })),

  // SET IS MOBILE VIEW
  on(SharedActions.SetIsMobileView, (state, props) => ({
    ...state,
    isMobileView: props.isMobileView,
  })),

  // SET IS MOBILE SIDENAV OPEN
  on(SharedActions.SetIsMobileSidenavOpen, (state, props) => ({
    ...state,
    isMobileSidenavOpen: props.isMobileSidenavOpen,
  })),

  // SET IS MOBILE SEARCH OPEN
  on(SharedActions.SetIsMobileSearchOpen, (state, props) => ({
    ...state,
    isMobileSearchOpen: props.isMobileSearchOpen,
  })),

  // START LOADING
  on(SharedActions.StartLoading, (state) => ({
    ...state,
    loading: [...state.loading, 1],
  })),

  // STOP LOADING
  on(SharedActions.StopLoading, (state) => ({
    ...state,
    loading: [...state.loading].slice(1),
  })),

  // CLEAR LOADING
  on(SharedActions.ClearLoading, (state) => ({
    ...state,
    loading: [],
  })),

  // DISABLE SCROLLING
  on(SharedActions.DisableScrolling, (state) => ({
    ...state,
    disableScrolling: [...state.disableScrolling, 1],
  })),

  // ENABLE LOADING
  on(SharedActions.EnableScrolling, (state) => ({
    ...state,
    disableScrolling: [...state.disableScrolling].slice(1),
  })),

  // FETCH COUNTRIES LIST
  on(SharedActions.FetchCountriesListRequest, (state) => state),
  on(SharedActions.FetchCountriesListSuccess, (state, props) => ({
    ...state,
    countriesList: props.countriesList
  })),
  on(SharedActions.FetchCountriesListFailure, (state) => state),

  // FETCH GENDER LIST
  on(SharedActions.FetchGenderListRequest, (state) => state),
  on(SharedActions.FetchGenderListSuccess, (state, props) => ({
    ...state,
    gendersList: props.gendersList
  })),
  on(SharedActions.FetchGenderListFailure, (state) => state),

  // ENABLE MAINTENANCE OVERLAY
  on(SharedActions.isAppInMaintenanceMode, (state, props) => ({
    ...state,
    isAppInMaintenanceMode: props.isAppInMaintenanceMode
  })),
);
