<div
  class="container"
  ngClass.xs="xs"
  ngClass.sm="sm"
  fxLayout.xs="column"
  fxLayout.sm="column"
>
  <div *ngIf="cartData?.loading && cartData?.success === null; else showData"></div>
  <ng-template #showData>
    <div *ngIf="cartData?.items?.length; else noData">
      <div
        class="guest-purchase-form"
        *ngIf="isGuestPurchaseFormEnabled"
      >
        <div
          class="heading"
          fxLayoutAlign.xs="center center"
          fxLayoutAlign="start"
        >
          {{translations[language].guestFormHeading}}
        </div>
        <form
          fxLayout="column">
          <div
            class="row"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutAlign.lt-md="center"
            fxLayoutGap="0.75rem"
            fxLayoutGap.lt-lg="0"
          >
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="50"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['fullName']"
                [parentFormGroup]="form"
                [prefixIcon]="'name'"
                class="full-name"
                [controlName]="'fullName'"
                [fieldBackgroundColor]="fieldBackgroundColor"
                [fieldTextColor]="fieldTextColor"
                [labelTextColor]="labelTextColor"
                label="{{translations[language].fullName}}"
                [focus]="focus['fullName']"
                maxLength="25"
              ></app-text-input>
            </div>
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="50"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['email']"
                [focus]="focus['email']"
                [parentFormGroup]="form"
                [prefixIcon]="'email'"
                class="email"
                [controlName]="'email'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
                label="{{translations[language].email}}"
                maxLength="100"
              ></app-text-input>
            </div>
          </div>
          <div
            class="sub-heading"
            fxLayoutAlign.xs="center center"
            fxLayoutAlign.sm="start"
          >
            {{translations[language].shippingAddressDetailsLabel}}
          </div>
          <div
            class="row"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="0.75rem"
          >
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="50"
            >
              <app-single-select
                class="country hide-arrow"
                [parentFormGroup]="form"
                [showBorder]="true"
                [options]="countriesList"
                [controlName]="'country'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [labelTextColor]=labelTextColor
                [fieldTextColor]=fieldTextColor
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['country']"
                [focus]="focus['country']"
                [label]="translations[language].country"
              ></app-single-select>
            </div>
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="34"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['zip']"
                [focus]="focus['zip']"
                [parentFormGroup]="form"
                class="zip"
                [controlName]="'zip'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
                label="{{translations[language].zip}}"
                maxLength="10"
              ></app-text-input>
            </div>
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="34"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['state']"
                [focus]="focus['state']"
                [parentFormGroup]="form"
                class="state"
                [controlName]="'state'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
                label="{{translations[language].state}}"
                maxLength="20"
              ></app-text-input>
            </div>
          </div>
          <div
            class="row"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="0.75rem"
          >
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="34"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['city']"
                [focus]="focus['city']"
                [parentFormGroup]="form"
                class="city"
                [controlName]="'city'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
                label="{{translations[language].city}}"
                maxLength="20"
              ></app-text-input>
            </div>
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="34"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['addressLine1']"
                [focus]="focus['addressLine1']"
                [parentFormGroup]="form"
                [prefixIcon]="'address'"
                class="addressLine1"
                [controlName]="'addressLine1'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
                label="{{translations[language].addressLine1}}"
                maxLength="35"
              ></app-text-input>
            </div>
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="34"
            >
              <app-text-input
                (checkForErrors)="checkForErrors($event)"
                [error]="errorMessages['addressLine2']"
                [focus]="focus['addressLine2']"
                [parentFormGroup]="form"
                [prefixIcon]="'address'"
                class="addressLine2"
                [controlName]="'addressLine2'"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
                label="{{translations[language].addressLine2}}"
                maxLength="35"
              ></app-text-input>
            </div>
          </div>
          <div
            class="row"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="0.75rem"
          >
            <div
              class="input-control"
              fxFlex="30"
              fxFlex.md="50"
            >
              <app-number-input
                (checkForErrors)="checkForErrors($event)"
                (keydown)="restrictZero($event)"
                [error]="errorMessages['phone']"
                [focus]="focus['phone']"
                [parentFormGroup]="form"
                [controlName]="'phone'"
                [prefixIcon]="'phone'"
                class="phone"
                label="{{translations[language].phone}}"
                [maxLength]="validations.phoneNumber.length.max"
                [fieldBackgroundColor]=fieldBackgroundColor
                [fieldTextColor]=fieldTextColor
                [labelTextColor]=labelTextColor
              ></app-number-input>
            </div>
          </div>
          <div
            class="sub-heading"
            fxLayoutAlign.xs="center center"
            fxLayoutAlign.sm="start"
          >
            {{translations[language].paymentCardDetailsLabel}}
          </div>
          <div
            class="row"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="0.75rem"
            fxLayoutGap.lt-lg="0"
          >
            <app-text-input
              (checkForErrors)="checkForErrors($event)"
              [error]="errorMessages['nameOnCard']"
              [focus]="focus['nameOnCard']"
              [parentFormGroup]="form"
              [prefixIcon]="'name-on-card'"
              class="input-control"
              [controlName]="'nameOnCard'"
              [fieldBackgroundColor]=fieldBackgroundColor
              [fieldTextColor]=fieldTextColor
              [labelTextColor]=labelTextColor
              label="{{translations[language].nameOnCard}}"
              maxLength="35"
              fxFlex="30"
            ></app-text-input>
            <div
              class="card-details"
              fxFlex="70"
              fxFlex.lt-lg="100"
              fxLayoutGap="0.75rem"
              fxLayoutGap.lt-md="25px"
              fxLayout.lt-md="column"
              fxLayout.gt-sm="row"
            >
              <div
                class="input-control"
                fxFlex="43.5"
                fxFlex.md="50"
              >
                <div
                  class="card-number"
                  fxLayout="column"
                >
                  <div class="card-input-label numberLabel">
                  <span
                    class="required-mark"
                  >*</span>
                    <span>
								{{translations[language].numberLabel}}
							</span>
                  </div>
                  <div
                    class="stripe-control"
                    fxLayoutAlign="none center"
                    id="card-number"
                  >
                  </div>
                  <div class="stripe-element-error" *ngIf="cardNumberError">
                    {{ cardNumberError }}
                  </div>
                </div>
              </div>
              <div
                fxFlex="43.5"
                fxFlex.md="50"
                fxLayoutGap="0.75rem"
                fxLayout="row"
              >
                <div
                  class="input-control"
                  fxFlex="50"
                >
                  <div
                    class="expire-date"
                    fxLayout="column"
                  >
                    <div
                      class="card-input-label expiryLabel"
                    >
                  <span
                    class="required-mark"
                  >*</span>
                      <span>
									{{translations[language].expiryLabel}}
								</span>
                    </div>
                    <div
                      class="stripe-control"
                      fxLayoutAlign="none center"
                      id="card-expiry"
                    >
                    </div>
                    <div class="stripe-element-error" *ngIf="cardExpiryError">
                      {{ cardExpiryError }}
                    </div>
                  </div>
                </div>
                <div
                  class="input-control"
                  fxFlex="50"
                >
                  <div
                    class="cvv"
                    fxLayout="column"
                  >
                    <div class="card-input-label cvcLabel">
                  <span
                    class="required-mark"
                  >*</span>
                      <span>
									{{translations[language].cvcLabel}}
								</span>
                    </div>
                    <div
                      class="stripe-control"
                      fxLayoutAlign="none center"
                      id="card-cvv"
                    >
                    </div>
                    <div class="stripe-element-error" *ngIf="cardCvcError">
                      {{ cardCvcError }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class="cart-heading"
        fxLayoutAlign="start center"
        fxLayoutAlign.xs="center center"
        *ngIf="isGuestPurchaseFormEnabled"
      >
        {{translations[language].cartHeading}}
      </div>
      <div
        class="cart-heading"
        fxLayoutAlign="center center"
        *ngIf="!isGuestPurchaseFormEnabled"
      >
        {{translations[language].myCartHeading}}
      </div>
      <div
        class="cart"
        fxLayout="row"
        fxLayout.lt-lg="column"
      >
        <div
          class="left-panel"
          fxFlex="75"
        >
          <div
            class="editable-cart"
            *ngIf="!isGuestPurchaseFormEnabled"
          >
            <app-unauthenticated-cart-editable-row
              class="cart"
              *ngFor="let eachCartItem of cartData?.items"
              [currencySymbol]="cartData.currencySymbol"
              [data]="eachCartItem"
            ></app-unauthenticated-cart-editable-row>
          </div>
          <div
            class="non-editable-cart"
            *ngIf="isGuestPurchaseFormEnabled"
          >
            <app-unauthenticated-cart-non-editable-row
              class="cart"
              *ngFor="let eachCartItem of cartData?.items"
              [currencySymbol]="cartData.currencySymbol"
              [data]="eachCartItem"
            ></app-unauthenticated-cart-non-editable-row>
          </div>

        </div>
        <div
          class="right-panel"
          fxFlex="25"
        >
          <div class="cart-total">
            <div
              *ngIf="cartData?.items?.length"
              class="total"
              fxLayout="row-wrap"
              fxLayoutAlign="end center"
              fxLayoutAlign.lt-lg="center center"
            >
              <div
                class="label"
                fxFlex="20rem"
              >
                {{translations[language].total}}
              </div>
              <div
                class="value ff-lang-ja-text-en"
              >
                {{cartData.total | currency: cartData.currencySymbol:cartData.currencySymbol:'1.0'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="productDeliveryPolicy"
           *ngIf="isGuestPurchaseFormEnabled"
      >
        <span
          [innerHTML]="translations[language].productDeliveryPolicyPart1"
        >
        </span>
        <span
          (click)="openDocument()"
          class="document"
        >
      {{translations[language].productDeliveryPolicyPart2}}
    </span>
        {{translations[language].productDeliveryPolicyPart3}}
      </div>
      <div
        class="action-btn"
        fxLayoutAlign="center center"
        *ngIf="!isGuestPurchaseFormEnabled"
      >
        <app-secondary-button
          *ngIf=" cartData?.items?.length"
          (handleClick)="onNext()"
          class="submit-order"
          fxFlex="48rem"
          fxFlex.xs="42rem"
          [text]="translations[language].next"
        ></app-secondary-button>
        <app-secondary-button
          *ngIf="!cartData?.items?.length"
          (handleClick)="goToCartPage()"
          class="submit-order"
          fxFlex="48rem"
          fxFlex.xs="42rem"
          [text]="translations[language].goToCatalogPage"
        ></app-secondary-button>
      </div>
      <div
        class="action-btn"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-around center"
        *ngIf="isGuestPurchaseFormEnabled"
      >
        <app-secondary-button
          (handleClick)="confirmOrder()"
          class="confirm-order"
          [text]="translations[language].confirmOrder"
          fxFlex="48rem"
          fxFlex.lt-md="12rem"
        ></app-secondary-button>
        <app-primary-button
          (handleClick)="onBack()"
          class="submit-order"
          [text]="translations[language].back"
          fxFlex="48rem"
          fxFlex.lt-md="12rem"
        ></app-primary-button>

      </div>
    </div>

    <ng-template #noData>
      <div
        fxLayoutAlign="center center"
        fxLayout="column"
        ngClass.xs="xs"
      >
        <div
          class="empty-cart-heading"
        >
          {{translations[language].myCartHeading}}
        </div>
        <div class="empty-cart-text">
          {{translations[language].noData}}
        </div>
        <div class="back-to-home-btn">
          <app-secondary-button
            (handleClick)="goToCartPage()"
            fxFlex="48rem"
            fxFlex.xs="42rem"
            [text]="translations[language].goToCatalogPage"
          >
          </app-secondary-button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>

