interface BrewTemperatureInfoTranslation {
  labelText1: string,
  labelText2: string,
  btnNext: string,
  btnPrevious: string,
}

const en: BrewTemperatureInfoTranslation = {
  labelText1: `Have you heated your water to the brewing temperature of `,
  labelText2: `in your normal teapot?`,
  btnNext: `Next`,
  btnPrevious: `Previous`,
}

const ja: BrewTemperatureInfoTranslation = {
  labelText1: `茶葉を入れたティーポットに`,
  labelText2: `のお湯を注ぎ、次に進むボタンを押してください。`,
  btnNext: `次に進む`,
  btnPrevious: `戻る`,
}

export const Translations = {
  1: en,
  2: ja
}
