import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import { selectedLanguage, } from 'src/app/modules/shared/core/shared.selectors';
import { environment } from 'src/environments/environment';
import {
  Translations as ReviewTranslation
} from '../../../translations/tea-details-page/tea-details-review-info.translation';
import { reviewSummary, userReviews } from '../../core/tea-details.selectors';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css'],
})
export class ReviewsComponent implements OnInit, OnDestroy {
  reviews;
  language: number = 1;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });
  userReviews;
  productId;
  ReviewTranslation = ReviewTranslation;
  current = 0;
  reviewSubscription: Subscription = this.store
    .select(reviewSummary)
    .pipe(
      tap((reviews) => {
        this.reviews = reviews;
      })
    )
    .subscribe();

  userReviewsSubscription: Subscription = this.store
    .select(userReviews)
    .pipe(
      tap((userReviews) => {
        this.userReviews = userReviews;
      })
    )
    .subscribe();

  paramsSubscription: Subscription = this.activatedRoute.paramMap.subscribe(
    (params: any) => {
      if (params) {
        this.productId = params.params.id;
      }
    }
  );

  constructor(private store: Store, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  getRatingImages() {
    let imageUrl = '';
    switch (this.reviews.averageRatingKey) {
      case 0:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/ezgif.com-gif-maker_2.webp`;
        break;
      case 1:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/ezgif.com-gif-maker.webp`;
        break;
      case 2:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/animation_500_la253tdd.webp`;
        break;
      case 3:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/animation.webp`;
        break;
    }
    return imageUrl;
  }

  getSummaryRatingImages(review) {
    let imageUrl = '';
    switch (review.ratingKey) {
      case 0:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/poor.webp`;
        break;
      case 1:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/normal.webp`;
        break;
      case 2:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/good.webp`;
        break;
      case 3:
        imageUrl = `${environment.cdnURL}/others/emoji_webps/great.webp`;
        break;
    }
    return imageUrl;
  }

  ngOnDestroy(): void {
    this.reviewSubscription?.unsubscribe();
    this.userReviewsSubscription?.unsubscribe();
    this.paramsSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe()
  }
}
