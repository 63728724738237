interface LoginFormTranslation {
  heading: string,
  left: {
    usernameLabel: string,
    passwordLabel: string,
    signInButton: string,
    forgotPasswordButton: string
  },
  right: {
    heading: string,
    contents: string,
    createAccountButton: string
    guestPurchaseButton: string
    or: string
  }
  errorMessageMap: {
    email: {
      required: string,
      pattern: string
    },
    password: {
      required: string,
      hint: string
    }
  }
}

const en: LoginFormTranslation = {
  heading: `Please Sign In`,
  left: {
    usernameLabel: `Email`,
    passwordLabel: `Password`,
    signInButton: 'Sign In',
    forgotPasswordButton: 'FORGOT PASSWORD'
  },
  right: {
    heading: `Don't Have an Account`,
    contents: `Enjoy more connected, personalized, and richer experience by creating your personal Teplo account`,
    createAccountButton: 'Create my Teplo account',
    guestPurchaseButton: 'Guest Purchase',
    or: 'OR'
  },
  errorMessageMap: {
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email Id.'
    },
    password: {
      required: `This field is required.`,
      hint: 'Password should be in English A-Z. Password needs at-least 1 digit, 1 special character, 1 uppercase & 1' +
        ' lower case letter. It should be at-least 8 characters long. '
    }
  }
}

const ja: LoginFormTranslation = {
  heading: `ログイン`,
  left: {
    usernameLabel: `メールアドレス`,
    passwordLabel: `パスワード`,
    signInButton: `ログイン`,
    forgotPasswordButton: `パスワードをお忘れの場合`
  },
  right: {
    heading: `アカウントをつくる`,
    contents: `<span class="ff-lang-ja-text-en">teplo</span>のアカウントをつくることで便利に<span class="ff-lang-ja-text-en">teplo</span>をご利用いただけます`,
    createAccountButton: 'アカウントをつくる',
    guestPurchaseButton: 'ユーザー登録せずに購入',
    or: 'または'
  },
  errorMessageMap: {
    email: {
      required: '入力が必須です',
      pattern: 'Eメールが無効です'
    },
    password: {
      required: '入力が必須です',
      hint: 'パスワードは半角で、少なくとも1つの数字、1つの大文字、1つの小文字、1つの特殊文字を使用して、8文字以上で入力してください'
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
