import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {select, Store} from "@ngrx/store";
import {CommonService} from "./modules/shared/services/common.service";
import * as fromRoot from './state/app.state';
import * as SharedSelectors from '../app/modules/shared/core/shared.selectors';
import {isMobileView} from '../app/modules/shared/core/shared.selectors';
import {LoadingScreenComponent} from "./modules/shared/components/loading-screen/loading-screen.component";
import {ClearLoading} from "./modules/shared/core/shared.actions";
import {SetProfileEmail} from "./modules/authentication/core/authentication.actions";
import {ViewportScroller} from "@angular/common";
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'Teplo';

  isLoadingSubscription: Subscription | undefined;
  isLoadingScreenOpenSubscription: Subscription | undefined;
  isMaintenanceOverlayEnabledSubscription: Subscription;
  routeParamsSubscription: Subscription;

  isLoadingScreenOpen: boolean = false;
  isMaintenanceOverlayEnabled: boolean;

  isMobileView: boolean = false;


  mobileViewSubscription: Subscription = this.store.select(isMobileView).subscribe((data) => {
    this.isMobileView = data;
  })

  routeChangeSubscription = this.router.events.subscribe((event: any) => {
    const clientAreaAnchor = $("#client_area");

    if (event instanceof NavigationEnd) {
      const anchor = this.router.url.split('#')[1];
      const hasScroll = !!anchor;

      if (!hasScroll) {
        clientAreaAnchor.animate({scrollTop: clientAreaAnchor.offset()?.top - 100}, 500);
      } else {
        setTimeout(() => {
          const clientAreaPosition = this.isMobileView ? 0 : clientAreaAnchor.position().top;
          const pageHeight = $('#router_outlet').children().eq(1)?.height() || 0;
          const anchorHeight = $('#' + anchor)?.height() || 0;

          const scrollPosition = pageHeight - anchorHeight + clientAreaPosition;

          clientAreaAnchor.animate({scrollTop: scrollPosition, behaviour: 'smooth'}, 500);
        }, 500);
      }
    }
  });

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.store.dispatch(ClearLoading());

    this.isLoadingScreenOpenSubscription = this.commonService.isLoadingScreenOpen.subscribe(data => {
      this.isLoadingScreenOpen = data;
    });

    this.isLoadingSubscription = this.store.pipe(select(SharedSelectors.isLoading)).subscribe(data => {
      if (!!data.length && !this.isLoadingScreenOpen) {
        this.commonService.isLoadingScreenOpen.next(true);
        this.dialog.open(LoadingScreenComponent, {
          backdropClass: 'spinner-backdrop',
          panelClass: 'spinner-panel'
        });
      }

      if (!data.length) {
        this.commonService.isLoadingScreenOpen.next(false);
      }
    });

    this.routeParamsSubscription = this.route.queryParams.subscribe(params => {
      this.commonService.handleQueryParamsChange(params);
    });

    this.isMaintenanceOverlayEnabledSubscription = this.store.pipe(select(SharedSelectors.isAppInMaintenanceMode)).subscribe(data => {
      this.isMaintenanceOverlayEnabled = data;
    })
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  ngOnDestroy() {
    this.isLoadingScreenOpenSubscription?.unsubscribe();
    this.isLoadingSubscription?.unsubscribe();
    this.routeChangeSubscription?.unsubscribe();
    this.mobileViewSubscription?.unsubscribe();
    this.routeParamsSubscription?.unsubscribe();
  }
}
