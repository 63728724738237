<div
  class="container confirm-dialog"
  fxLayout="column"
  fxLayoutAlign="center none"
>

    <div
      class="heading">
      {{translations.heading}}
    </div>
  <div
    class="action"
    fxLayout="column"
  >
    <div>
      <app-primary-button
        id="yes"
        (handleClick)="onConfirm()"
        [isLoading]="loading"
        class="confirm-button"
        text="{{translations.confirmButtonLabel}}"
        [isSlim]="true"
      >
      </app-primary-button>
    </div>
<!--    <div>-->
<!--      <app-primary-clickable-text-->
<!--        fxFlex="100"-->
<!--        (handleClick)="onCancel()"-->
<!--        [text]="translations.cancelButtonLabel"-->
<!--      >-->
<!--      </app-primary-clickable-text>-->
<!--    </div>-->
  </div>
</div>

