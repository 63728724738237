import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "./material-module";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {reducer} from "./core/shared.reducer";
import {SharedEffects} from "./core/shared.effects";
import {GlobalWrapperComponent} from './components/global-wrapper/global-wrapper.component';
import {DesktopHeaderComponent} from './components/headers/desktop-header/desktop-header.component';
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {IconComponent} from './components/icon/icon.component';
import {MobileHeaderComponent} from './components/headers/mobile-header/mobile-header.component';
import {RouterModule} from "@angular/router";
import {MobileSidenavComponent} from './components/mobile-sidenav/mobile-sidenav.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {DesktopBasicDialogComponent} from './components/dialog/desktop-basic-dialog/desktop-basic-dialog.component';
import {DummyComponent} from './components/dummy/dummy.component';
import {SingleSelectComponent} from "./components/form-fields/dropdown/single-select/single-select.component";
import {NumberInputComponent} from "./components/form-fields/input/number-input/number-input.component";
import {PasswordInputComponent} from "./components/form-fields/input/password-input/password-input.component";
import {TextInputComponent} from "./components/form-fields/input/text-input/text-input.component";
import {SharedRoutingModule} from "./shared-routing.module";
import {ReactiveFormsModule} from "@angular/forms";
import {OnlyDigitsDirective} from "./directives/only-digits.directive";
import {PreventMultipleClickDirective} from "./directives/prevent-multiple-click.directive";
import {SafeHTMLPipe} from "./pipes/safe-html.pipe";
import {SafeUrlPipe} from "./pipes/safe-url.pipe";
import {PrimaryButtonComponent} from "./components/buttons/primary-button/primary-button.component";
import {
  PrimaryClickableTextComponent
} from "./components/buttons/primary-clickable-text/primary-clickable-text.component";
import {SecondaryButtonComponent} from "./components/buttons/secondary-button/secondary-button.component";
import {LoadingScreenComponent} from './components/loading-screen/loading-screen.component';
import {FooterComponent} from './components/footer/footer.component';
import {CarouselComponent} from './components/carousel/carousel.component';
import {SwiperModule} from "swiper/angular";
import {ImageComponent} from './components/image/image.component';
import {ShimmerLoaderComponent} from './components/shimmer-loader/shimmer-loader.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {LogoutConfirmDialogComponent} from './components/logout-confirm-dialog/logout-confirm-dialog.component';
import {HalfContentHalfImageComponent} from './pages/half-content-half-image/half-content-half-image.component';
import {MaintenancePageComponent} from "./pages/maintenance-page/maintenance-page.component";
import {InputRestrictionDirective} from './directives/input-restriction.directive';

@NgModule({
  declarations: [
    GlobalWrapperComponent,
    DesktopHeaderComponent,
    IconComponent,
    MobileHeaderComponent,
    MobileSidenavComponent,
    PageNotFoundComponent,
    DesktopBasicDialogComponent,
    DummyComponent,
    SingleSelectComponent,
    NumberInputComponent,
    PasswordInputComponent,
    TextInputComponent,
    OnlyDigitsDirective,
    PreventMultipleClickDirective,
    SafeHTMLPipe,
    SafeUrlPipe,
    PrimaryButtonComponent,
    PrimaryClickableTextComponent,
    SecondaryButtonComponent,
    LoadingScreenComponent,
    FooterComponent,
    CarouselComponent,
    ImageComponent,
    ShimmerLoaderComponent,
    LogoutConfirmDialogComponent,
    HalfContentHalfImageComponent,
    MaintenancePageComponent,
    InputRestrictionDirective,
  ],
  exports: [
    GlobalWrapperComponent,
    MaterialModule,
    SharedRoutingModule,
    TextInputComponent,
    PasswordInputComponent,
    NumberInputComponent,
    SingleSelectComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    PrimaryClickableTextComponent,
    LoadingScreenComponent,
    CarouselComponent,
    ImageComponent,
    ShimmerLoaderComponent,
    IconComponent,
    HalfContentHalfImageComponent,
    SafeHTMLPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedRoutingModule,
    StoreModule.forFeature('shared', reducer),
    EffectsModule.forFeature(
      [SharedEffects]
    ),
    FlexModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxSkeletonLoaderModule
  ],
  entryComponents: [DummyComponent],
})
export class SharedModule {
}
