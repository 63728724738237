import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../../shared/services/common.service";
import {cart, guestCart, guestPurchaseInfo, isGuestPurchaseFormEnabled} from '../../core/cart.selectors';
import * as SharedSelectors from '../../../shared/core/shared.selectors';
import {isMobileView, selectedLanguage} from '../../../shared/core/shared.selectors';
import {Subscription} from "rxjs";
import {Translations} from "../../../translations/cart-details/unauthenticated-cart-screen.translations";
import {
  Translations as PaymentOptionTranslations
} from '../../../translations/user-details/personal-information/payment-option-form.translation';
import {LoginFormComponent} from "../../../authentication/forms/login-form/login-form.component";
import {LazyLoaderService} from "../../../shared/services/lazy-loader.service";
import {CartDetailsService} from "../../services/cart-details.service";
import * as _ from 'lodash';
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import * as Validations from "../../../shared/constants/validation.constants";
import {
  AddToItems,
  CalculateCartRequest,
  EnableGuestPurchaseForm,
  PlaceGuestOrderRequest,
  ResetCart,
  SaveGuestPurchaseForm
} from "../../core/cart.actions";
import {ValidationService} from "../../../shared/services/validation.service";

declare let Stripe: any;

@Component({
  selector: 'app-unauthenticated-cart-screen',
  templateUrl: './unauthenticated-cart-screen.component.html',
  styleUrls: ['./unauthenticated-cart-screen.component.css']
})
export class UnauthenticatedCartScreenComponent implements OnInit, AfterViewInit, OnDestroy {
  focus: any = {};
  cartData: any;
  language: number = 1;
  validations = Validations;
  translations: any = Translations;
  isGuestPurchaseFormEnabled: boolean;
  items: any = [];
  errorMessages: any = {};
  errorMessageMap: any = {};
  paymentOptionErrorMessageMap: any = {};
  countriesList: any;
  country: any;
  paymentOptionToken;
  guestCountryId: any;
  isMobileView: boolean = false;
  fieldBackgroundColor = "#D7D7D7"
  fieldTextColor = "#121315"
  labelTextColor = "#000000"
  cardNumberError: string = '';
  cardExpiryError: string = '';
  cardCvcError: string = '';

  stripInputStyleGuide = {
    base: {
      color: '#121315',
      fontSize: '16px',
      height: '6rem',
      backgroundColor: 'transparent',
      padding: '0 10px'
    },
    invalid: {
      iconColor: '#FF0000',
      color: '#121315',
    }
  }
  stripe = Stripe(environment.stripePublishableKey);
  elements = this.stripe.elements();
  cardNumberElement = this.elements.create('cardNumber', {showIcon: true, style: this.stripInputStyleGuide});
  cardExpiryElement = this.elements.create('cardExpiry', {style: this.stripInputStyleGuide});
  cardCVCElement = this.elements.create('cardCvc', {style: this.stripInputStyleGuide});

  form: FormGroup = this.fb.group({
    fullName: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(this.commonService.alphabetsRegex)
        ],
      }
    ),
    email: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.pattern(this.commonService.emailRegex)
        ],
      }
    ),
    country: new FormControl({value: '', disabled: true},
      {
        validators: [
          Validators.required,
        ],
      }
    ),
    zip: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(this.commonService.zipCodeRegex)
        ],
      }
    ),
    state: new FormControl('',
      {
        validators: [
          Validators.required,
          this.validationService.noWhitespaceValidator
        ],
      }
    ),
    city: new FormControl('',
      {
        validators: [
          Validators.required,
          this.validationService.noWhitespaceValidator
        ],
      }
    ),
    addressLine1: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.minLength(1),
          this.validationService.noWhitespaceValidator
        ],
      }
    ),
    addressLine2: new FormControl('',
      {
        validators: [
          Validators.minLength(1)
        ],
      }
    ),
    phone: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(18)
        ],
      }
    ),
    nameOnCard: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.pattern(this.commonService.alphabetsRegex)
        ],
      }
    ),
  });

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
    this.errorMessageMap = this.translations[this.language].errorMessageMap;
    this.paymentOptionErrorMessageMap = PaymentOptionTranslations[this.language].errorMessageMap;
  });

  isMobileViewSubscription: Subscription = this.store.pipe(select(isMobileView)).subscribe((data) => {
    this.isMobileView = data;
    if (this.isMobileView) {
      this.fieldBackgroundColor = "#FFFFFF"
    }
  });

  getCartItemsSubscriptions: Subscription = this.store.pipe(select(guestCart)).subscribe(data => {
    if (!this.commonService.isAuthenticated() && (data.countryId && data.items.length)) {
      this.store.dispatch(CalculateCartRequest({
        payload: {
          countryId: data.countryId,
          items: data.items
        }
      }))
    }
    if (!data.countryId || !data.items.length) {
      this.store.dispatch(ResetCart())
    }

  });

  enableGuestPurchaseFormSubscriptions: Subscription = this.store.pipe(select(isGuestPurchaseFormEnabled)).subscribe(data => {
    this.isGuestPurchaseFormEnabled = data;

    if (this.isGuestPurchaseFormEnabled) {
      setTimeout(() => {
        this.cardNumberElement.mount('#card-number');
        this.cardExpiryElement.mount('#card-expiry');
        this.cardCVCElement.mount('#card-cvv');

        // Custom errors for stripe elements
        let _self = this;
        this.cardNumberElement.addEventListener('change', function (event) {
          _self.cardNumberError = _self.getStripeErrorMessage(event, _self.paymentOptionErrorMessageMap.cardNumber);
        });

        this.cardExpiryElement.addEventListener('change', function (event) {
          _self.cardExpiryError = _self.getStripeErrorMessage(event, _self.paymentOptionErrorMessageMap.cardExpiry);
        });

        this.cardCVCElement.addEventListener('change', function (event) {
          _self.cardCvcError = _self.getStripeErrorMessage(event, _self.paymentOptionErrorMessageMap.cardCvc);
        });
      }, 0);
    }
  });

  guestPurchaseInfoSubscriptions: Subscription = this.store.pipe(select(guestPurchaseInfo)).subscribe(data => {
    this.form.patchValue({
      fullName: data?.fullName,
      email: data?.email,
      addressLine1: data?.addressLine1,
      addressLine2: data?.addressLine2,
      city: data?.city,
      state: data?.state,
      zip: data?.zip,
      country: data?.country,
      phone: data?.phone
    })
  });

  cartDataSubscription: Subscription = this.store.pipe(select(cart)).subscribe(data => {
    this.cartData = data;
  });

  productQuantityIncrementSubscription: Subscription = this.cartDetailsService.productQuantityIncrement.subscribe((data: any) => {
    const items = _.cloneDeep(this.items);
    const index = items.findIndex(object => {
      if (object?.productId === data?.productId && object?.pricingPlanId === data?.pricingPlanId) {
        return object;
      }
    });

    if (index > -1) {
      if (items[index].quantity < 5) {
        items[index].quantity = items[index].quantity + 1;
      } else {
        this.commonService.notification(this.translations[this.language].maxQuantityMessage, 'danger');
      }
    }
    this.store.dispatch(AddToItems({items}));
  });

  productQuantityDecrementSubscription: Subscription = this.cartDetailsService.productQuantityDecrement.subscribe((data: any) => {
    const items = _.cloneDeep(this.items);
    const index = items.findIndex(object => {
      if (object?.productId === data?.productId && object?.pricingPlanId === data?.pricingPlanId) {
        return object;
      }
    });

    if (index > -1) {
      if (items[index].quantity > 1) {
        items[index].quantity = items[index].quantity - 1;
      } else {
        this.commonService.notification(this.translations[this.language].minQuantityMessage, 'danger');
      }
    }
    this.store.dispatch(AddToItems({items}));
  });

  productDeleteSubscription: Subscription = this.cartDetailsService.productDelete.subscribe((data: any) => {
    const items = _.cloneDeep(this.items);
    const index = items.findIndex(object => {
      if (object?.productId === data?.productId && object?.pricingPlanId === data?.pricingPlanId) {
        return object;
      }
    });

    if (index > -1) {
      items.splice(index, 1)
      this.commonService.notification(this.translations[this.language].productDeleteSuccessMessage, 'success');
    }

    const payload = {
      items,
      ...(items.length ? {} : {countryId: ''})
    }
    this.store.dispatch(AddToItems(payload));
  });

  guestCartItemsSubscription: Subscription = this.store.pipe(select(guestCart)).subscribe((data) => {
    this.guestCountryId = data.countryId;
    this.items = data.items;
  });

  countriesListSubscription: Subscription = this.store.pipe(select(SharedSelectors.countriesList)).subscribe((data) => {
      this.countriesList = data;

      const selectedCountry = this.guestCountryId;
      if (selectedCountry && data.length) {
        const index = this.countriesList.findIndex(object => {
          if (object?.key === selectedCountry) {
            return object;
          }
        });
        if (index > -1) {
          this.country = this.countriesList[index];
        }
        this.form.patchValue({
          country: this.country.key,
        });
      }
    }
  );
  guestCartSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private lazyLoaderService: LazyLoaderService,
    private cartDetailsService: CartDetailsService,
    private router: Router,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
  }

  getStripeErrorMessage(event, errorMessagesMap) {
    if (event.error) {
      return errorMessagesMap[event.error.code] || event.error.message;
    }
    return '';
  }

  restrictZero(event: any) {
    if (event.target.value.length === 0 && event.key === '0') {
      event.preventDefault();
    }
  }

  onNext() {
    this.commonService.openDialog({data: {component: LoginFormComponent, guestPurchase: true}});
  }

//Routing The User to Home

  goToCartPage() {
    this.router.navigate(['/'])
  }

  onBack() {
    // Creating Payload
    const {
      fullName,
      email,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      country,
      phone
    } = this.form.getRawValue();

    const payload = {
      fullName,
      email,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      country,
      phone
    }
    this.store.dispatch(EnableGuestPurchaseForm(false));
    this.store.dispatch(SaveGuestPurchaseForm({payload}));
  }

  async confirmOrder() {
    // checking for errors on form
    if (this.form.invalid) {
      this.initializeFocus();
      this.checkForErrors();
      this.commonService.setFocus(this.form, this.errorMessages, this.focus);
      return;
    }

// Creating Payload
    const {
      fullName,
      email,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      country,
      phone,
      nameOnCard
    } = this.form.getRawValue();

    this.commonService.startLoading();
    const {token, error} = await this.stripe.createToken(this.cardNumberElement, {card: {name: nameOnCard}});
    this.commonService.stopLoading();
    if (error) {
      this.commonService.notification(error.message, 'danger');
    } else {
      this.paymentOptionToken = token.id
    }
    const payload = {
      email,
      address: {
        recipientName: fullName,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        countryId: country,
        phoneNumber: phone,

        // SENDING THIS CURRENTLY AS THE GUEST ORDER PLACE API NEED THE LOCATION FIELD AS MANDATORY
        location: 'guest-location'
      },
      paymentOptionToken: this.paymentOptionToken,
      items: this.items
    };
    this.store.dispatch(PlaceGuestOrderRequest({payload}));
  }

  openDocument() {
    let language = 'EN';
    if (this.language === 2) {
      language = 'JA';
    }
    this.commonService.openInNewTab('https://teplo-files-bucket.s3.us-east-2.amazonaws.com/documents/policies/subscription_contract_and_cancellation_policy_' + language + '.pdf?v=' + new Date().getTime())
  }

  checkForErrors(currentField ?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  initializeFocus() {
    this.focus = {
      fullName: false,
      email: false,
      country: false,
      zip: false,
      state: false,
      city: false,
      addressLine1: false,
      addressLine2: false,
      phone: false,
      nameOnCard: false
    }
    this.cdRef.detectChanges()
  }

  ngAfterViewInit() {
    this.initializeFocus();
  }

  ngOnDestroy() {
    this.getCartItemsSubscriptions?.unsubscribe();
    this.enableGuestPurchaseFormSubscriptions?.unsubscribe();
    this.cartDataSubscription?.unsubscribe();
    this.productQuantityIncrementSubscription?.unsubscribe();
    this.productQuantityDecrementSubscription?.unsubscribe();
    this.productDeleteSubscription?.unsubscribe();
    this.guestCartItemsSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.countriesListSubscription?.unsubscribe();
    this.guestCartSubscription?.unsubscribe();
    this.isMobileViewSubscription?.unsubscribe();
    this.guestPurchaseInfoSubscriptions?.unsubscribe();
  }

}
