<div class="reviews-main-section" fxFlex="100" fxLayout="column">
  <div class="reviews-heading-section" fxFlex="0 0 0" fxLayout="row" fxLayoutAlign="center none">
    {{ ReviewTranslation[language].heading }}
  </div>
  <div class="reviews-emoji-section" fxFlex="0 0 0" fxLayout="row" fxLayoutAlign="center none">
    <img *ngIf="reviews.averageRatingValue" [src]="getRatingImages()" alt="" class="average-review-image"/>
  </div>
  <div class="avearge-reviews-heading-section" fxFlex="0 0 0" fxLayout="row" fxLayoutAlign="center none">
    {{ reviews.averageRatingValue }}
  </div>
  <div class="user-review-section" ngClass.xs="xs" fxLayout="column" fxLayoutGap="3.75rem">
    <div class="user-review-emoji-section" fxFlex="100" fxLayout="row" fxLayoutAlign="center none" fxLayoutGap="2rem">
      <div *ngFor="let review of reviews.ratingSummary" class="user-review-emoji" fxFlex="0 0 0" fxLayout="column"
           fxLayoutAlign="center center"
           fxLayoutGap="1rem">
        <img [src]="getSummaryRatingImages(review)" alt="" class="user-review-emoji-image"/>
        <div class="user-review-number ff-lang-ja-text-en">{{ review.percentage + "%" }}</div>
      </div>
    </div>
    <div class="user-total-review" fxFlex="0 0 0" fxLayout="row" fxLayoutAlign="center none">
      {{ReviewTranslation[language].totalReview + " : " }}
      <span class="ff-lang-ja-text-en">{{reviews.totalReviews}}</span>
    </div>
    <div fxFlex="0 0 0" fxLayout="column" fxLayoutGap="2rem">
      <div fxFlex="0 0 0" fxLayout="row"
           fxLayoutGap="1rem">
        <app-carousel [pagination]="false" [slidesData]="userReviews.reviews" class="image-carousel"
                      lgComponentCount="3"
                      mdComponentCount="3" smComponentCount="1" xsComponentCount="1">
          <ng-template let-reviews>
            <app-user-reviews [loading]="userReviews.loading" [userReviews]="reviews"></app-user-reviews>
          </ng-template>
        </app-carousel>
      </div>
    </div>
  </div>
</div>
