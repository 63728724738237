interface TeaDetailsPageScreenTranslation {
  noData: string
  addToCartMessage: string,
  maximumQuantityReached: string,
  CartErrorProductFromDifferentCountryAdded: string,
  errorMessage: string
}

const en: TeaDetailsPageScreenTranslation = {
  noData: `Information for selected tea is not available currently`,
  addToCartMessage: 'Item Added to the Cart',
  maximumQuantityReached: 'Can not add more quantity. Maximum Limit Reached.',
  CartErrorProductFromDifferentCountryAdded: 'The selected product can not be added to the cart as other products in the cart belong to a different country',
  errorMessage: 'Please login or Signup if you like to subscribe for our teas-packages'
}

const ja: TeaDetailsPageScreenTranslation = {
  noData: `選択された商品の詳細情報は現在準備中です`,
  addToCartMessage: '商品がカートに追加されました',
  maximumQuantityReached: '注文できる個数の上限です',
  CartErrorProductFromDifferentCountryAdded: 'カート内に他国へ発送する商品があるため、商品をカートに追加することができません',
  errorMessage: 'お茶のパッケージを購読したい場合は、ログインまたはサインアップしてください'
}

export const Translations = {
  1: en,
  2: ja
}
