import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {Subscription} from "rxjs";
import {getLoadingFlags} from "../../../core/user-details.selectors";

@Component({
  selector: 'app-payment-option-card',
  templateUrl: './payment-option-card.component.html',
  styleUrls: ['./payment-option-card.component.css']
})
export class PaymentOptionCardComponent implements OnInit, OnDestroy {

  @Input() paymentOption: any;
  @Input() defaultLabel: string;
  @Input() expiredLabel: string;
  @Input() makeDefaultButtonLabel = 'Make Default';
  @Input() makeDefaultButtonVisible: boolean = true;
  @Input() makeDefaultButtonDisabled: boolean = false;
  @Input() editButtonVisible: boolean = true;
  @Input() editButtonDisabled: boolean = false;
  @Input() deleteButtonVisible: boolean = true;
  @Input() deleteButtonDisabled: boolean = false;
  @Input() expiryDateVisible: boolean = true;

  @Output() handleMakeDefaultButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() handleEditButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() handleDeleteButtonClick: EventEmitter<any> = new EventEmitter();

  expDate: string;
  makeDefaultLoading: boolean = false;

  // Get loading
  loadingSubscription: Subscription = this.store.pipe(select(getLoadingFlags)).subscribe((loadingFlags) => {
    this.makeDefaultLoading = (loadingFlags.makeDefaultPaymentOption?.paymentOptionId === this.paymentOption?._id ) && loadingFlags.makeDefaultPaymentOption?.loading;
  });

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit(): void {
    if(this.paymentOption?.details?.expiryMonth && this.paymentOption?.details?.expiryYear){
      this.expDate = `${(parseInt(this.paymentOption.details.expiryMonth) <= 9 ? '0' : '')}${this.paymentOption.details.expiryMonth}/${this.paymentOption.details.expiryYear.substring(2)}`;
    }
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }
}
