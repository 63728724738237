import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../shared/core/shared.selectors";
import * as fromRoot from './../../../state/app.state';

@Component({
  selector: 'app-user-details-wrapper',
  templateUrl: './user-details-wrapper.component.html',
  styleUrls: ['./user-details-wrapper.component.css']
})
export class UserDetailsWrapperComponent implements OnInit, OnDestroy {

  language: number = 1;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
