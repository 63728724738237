import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  saveAddress = new EventEmitter();
  updateAddress = new EventEmitter();
  makeDefaultAddress = new EventEmitter();
  removeAddress = new EventEmitter();

  constructor() { }
}
