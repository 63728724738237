import {ConfirmDialogModel} from "../../../models/confirm-dialog.model";

const en: ConfirmDialogModel = {
  heading: 'Are you sure, you want to delete payment card?',
  subHeading: '',
  confirmButtonLabel: 'Yes',
  cancelButtonLabel: 'Cancel',
}

const ja: ConfirmDialogModel = {
  heading: '支払いカードを削除してもよろしいですか?',
  subHeading: '',
  confirmButtonLabel: 'はい',
  cancelButtonLabel: 'キャンセル',
}

export const Translations = {
  1: en,
  2: ja
};
