import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {v1URL} from '../../shared/constants/urls.constants';
import {CommonService} from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root',
})

export class CartService {
  constructor(private commonServices: CommonService) {
  }

  calculateCart(payload): Observable<any> {
    const urlConfig = v1URL.guest.calculateCart
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  placeGuestOrder(payload): Observable<any> {
    const urlConfig = v1URL.guest.placeOrder
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchCart(): Observable<any> {
    const urlConfig = v1URL.cart.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  addToCart(payload): Observable<any> {
    const urlConfig = v1URL.cart.item.add
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchCartCount(): Observable<any> {
    const urlConfig = v1URL.cart.item.quantity.count
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  increment(payload): Observable<any> {
    const urlConfig = v1URL.cart.item.quantity.increment
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  decrement(payload): Observable<any> {
    const urlConfig = v1URL.cart.item.quantity.decrement
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  removeItem(payload): Observable<any> {
    const urlConfig = v1URL.cart.item.remove
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  getUserAddress(): Observable<any> {
    const urlConfig = v1URL.address.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  getUserPaymentCards(): Observable<any> {
    const urlConfig = v1URL.paymentOption.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  setShippingAddress(payload): Observable<any> {
    const urlConfig = v1URL.cart.update
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  updatePaymentOption(payload): Observable<any> {
    const urlConfig = v1URL.cart.update
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  placeOrder(payload): Observable<any> {
    const urlConfig = v1URL.order.place
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  cartSet(payload): Observable<any> {
    const urlConfig = v1URL.cart.set
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }
}
