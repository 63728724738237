import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DeleteAccountScreenComponent} from "./delete-account-screen/delete-account-screen.component";
import {PageNotFoundComponent} from "../shared/pages/page-not-found/page-not-found.component";

const routes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    component: DeleteAccountScreenComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DeleteAccountRoutingModule {
}
