import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CartDetailsService {

  productQuantityIncrement = new Subject()
  productQuantityDecrement = new Subject()
  productDelete = new Subject()
  cancelGuestPurchase = new Subject()

  constructor() {
  }
}
