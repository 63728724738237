<div
  class="payment-option-card"
  fxLayout="column"
  fxLayoutGap="5px"
  style="word-break: break-all;">
  <div
    class="card-header"
    fxLayoutAlign="space-between center">
    <div class="name">{{ paymentOption?.details?.name }}</div>
    <div
      class="actions"
      fxLayout="row">
      <ng-container *ngIf="!paymentOption?.isDefault && deleteButtonVisible">
        <a class="mat-icon-link"
          (click)="handleDeleteButtonClick.emit(paymentOption)"><app-icon [cssClass]="'small'" [icon]="'delete'"></app-icon></a>
      </ng-container>
    </div>
  </div>
  <div class="payment-option-details">
    <div class="card-number ff-lang-ja-text-en">
      XXXX-XXXX-XXXX-{{ paymentOption?.details?.last4Digits}}
    </div>
    <div
      *ngIf="expiryDateVisible"
      fxLayoutAlign="space-between center">
      <div class="exp-date">
        {{ expDate }}
      </div>
      <div *ngIf="!paymentOption?.isValid"
           class="expired">
        | <span class="expiredLabel">{{expiredLabel}}</span>
      </div>
    </div>
  </div>
  <div class="card-footer" fxLayout="row" fxLayoutAlign="start center" *ngIf="makeDefaultButtonVisible">
    <ng-container *ngIf="paymentOption?.isDefault; else elseTemplate">
      <span class="default-label">{{ defaultLabel }}</span>
    </ng-container>
    <ng-template #elseTemplate>
      <div
        class="actions-section"
      >
        <app-primary-clickable-text
          id="make_default"
          (handleClick)="handleMakeDefaultButtonClick.emit(paymentOption)"
          *ngIf="makeDefaultButtonVisible"
          [text]="makeDefaultButtonLabel"
          [isLoading]="makeDefaultLoading"
          class="primary-action"
          size="small"
        ></app-primary-clickable-text>
      </div>
    </ng-template>
  </div>
</div>
