<div
  class="order-card"
  fxLayout="column"
  ngClass.sm="sm"
  fxLayoutGap="0.5rem"
>
  <div
    fxLayoutAlign="center center"
  >
    <div
      (click)="openOrderDetailDialogBox(order['_id'])"
      class="order-details-button"
    >
      {{translations[language].heading}}
    </div>
  </div>
  <div
    class="order-info date"
    fxLayout="row"
  >
    <div class="label" fxFlex="50">{{translations[language].orderDate}}</div>
    <div *ngIf="language !== 2"
         class="value" fxFlex="50">
      {{order['createdAt'] | date}}
    </div>
    <div *ngIf="language === 2"
         class="value" fxFlex="50">
      {{order['createdAt'] | date:dateFormat}}
    </div>
  </div>
  <div
    class="order-info order-id"
    fxLayout="row"
  >
    <div class="label" fxFlex="50">{{translations[language].orderNumber}}</div>
    <div class="value ff-lang-ja-text-en" fxFlex="50">{{order.orderCode}}</div>
  </div>
  <div
    class="order-info price"
    fxLayout="row"
  >
    <div class="label" fxFlex="50">{{translations[language].orderTotal}}</div>
    <div
      class="value ff-lang-ja-text-en"
      fxFlex="50">
      {{(order?.afterDiscount ? order?.afterDiscount?.total : order?.total) | currency: order.currencySymbol : order.currencySymbol : '1.0-2'}}
    </div>
  </div>
  <div
    class="order-info status"
    fxLayout="row"
  >
    <div class="label" fxFlex="50">{{translations[language].paymentStatus}}</div>
    <div class="value" fxFlex="50"> {{order.orderStatus}}</div>
  </div>
  <app-image
    [loaderTheme]="{
        'height': '100%',
        'width': '100%'
      }"
    [url]="order['image'] || '../../../assets/images/png/order-list.png'"
    class="image-container"
  ></app-image>
</div>











