import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Translations} from "../../../../../translations/cart-details/card-selection-screen.translation";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../../state/app.state";
import {UpdatePaymentOptionRequest} from "../../../../core/cart.actions";

@Component({
  selector: 'app-card-selection-screen',
  templateUrl: './card-selection-screen.component.html',
  styleUrls: ['./card-selection-screen.component.css']
})
export class CardSelectionScreenComponent implements OnInit, OnDestroy {
  @Input() data: any;
  language: number = 1;
  cardsList: any;
  selectedCardId: string;
  orderId: string;

  translations: any = Translations;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit(): void {
    this.selectedCardId = this.data.selectedCardId;
    this.orderId = this.data.orderId;
    this.cardsList = this.data.cardsList;
  }

  selectCard(selectedCardId) {
    this.selectedCardId = selectedCardId;
  }

  setCard() {
    const payload = {
      orderId: this.orderId,
      paymentOptionId: this.selectedCardId
    }

    this.store.dispatch(UpdatePaymentOptionRequest({payload}));
  }

  addCard() {
    this.router.navigate(['/user/accountInfo'])
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }
}
