<div
  class="container"
  fxLayoutAlign="center none"
  ngClass.xs="xs"
>
  <div
    class="update-profile-form"
    fxLayout="column"
  >
    <div class="heading">
      {{translations[language].heading}}
    </div>
    <form
      [formGroup]="form"
      fxLayout="column"
      fxLayoutAlign="space-around none"
    >
      <div class="form-fields">
        <app-number-input
          id="age"
          (checkForErrors)="checkForErrors($event)"
          (keydown)="restrictZero($event)"
          [error]="errorMessages['age']"
          [maxLength]="validations.user.age.length.max"
          [parentFormGroup]="form"
          class="input-control age-input"
          controlName="age"
          [isSlim]="true"
          label="{{translations[language].ageLabel}}"
        ></app-number-input>
      </div>
      <div class="form-fields">
        <app-single-select
          id="gender"
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['gender']"
          [options]="gendersList$ | async"
          [parentFormGroup]="form"
          class="input-control gender-dropdown"
          controlName="gender"
          [isSlim]="true"
          label="{{translations[language].genderLabel}}"
        ></app-single-select>
      </div>
      <div class="form-actions">
        <app-primary-button
          id="update_account"
          (handleClick)="updateAccount()"
          class="update-account-button"
          [isSlim]="true"
          text="{{translations[language].updateAccountButton}}"
        >
        </app-primary-button>
        <app-primary-clickable-text
          id="login"
          (handleClick)="openLoginDialog()"
          class="login-button"
          fxLayoutAlign="center none"
          text="{{translations[language].loginButton}}"
        ></app-primary-clickable-text>
      </div>
    </form>
  </div>
</div>

