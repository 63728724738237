import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "../shared/pages/page-not-found/page-not-found.component";
import {UserDetailsWrapperComponent} from "./user-details-wrapper/user-details-wrapper.component";
import {OrdersScreenComponent} from "./orders/orders-screen/orders-screen.component";
import {SubscriptionsScreenComponent} from "./subscriptions/subscriptions-screen/subscriptions-screen.component";
import {
  PersonalInformationContainerComponent
} from "./personal-information/personal-information-container/personal-information-container.component";
import {
  SubscriptionsContainerComponent
} from "./subscriptions/subscriptions-container/subscriptions-container.component";
import {HalfContentHalfImageComponent} from "../shared/pages/half-content-half-image/half-content-half-image.component";

const routes: Routes = [
  {
    path: '',
    component: UserDetailsWrapperComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'personal-information'
      },
      {
        path: 'personal-information',
        component: PersonalInformationContainerComponent
      },
      {
        path: 'orders',
        component: OrdersScreenComponent
      },
      {
        path: 'subscriptions',
        component: SubscriptionsContainerComponent
      }
    ]
  },
  {
    path: '**',
    component: HalfContentHalfImageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PageNotFoundComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserDetailsRoutingModule {
}
