import {Component, OnInit} from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {CommonService} from "../../../shared/services/common.service";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {ClientDeviceModel} from "../../../models/login-request.model";
import {Translations} from "../../../translations/authentication/forms/signup-form.translation";
import {LoginFormComponent} from "../login-form/login-form.component";
import {CreateAccountRequest} from "../../core/authentication.actions";
import * as Validations from "../../../shared/constants/validation.constants";

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {
  focus: any = {};
  language: number;
  translations = Translations;
  errorMessages: any = {};
  errorMessageMap = {};

  validations = Validations;

  clientDevice: ClientDeviceModel = {
    uuid: uuidv4(),
    os: this.commonService.OSName(),
    version: this.commonService.browserDetails().browserName,
    model: this.commonService.browserDetails().browserName,
    appVersion: '0.0.0',
  };

  form: FormGroup = this.fb.group({
    fullName: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(this.commonService.alphabetsRegex)
        ],
      }
    ),
    email: new FormControl('',
      {
        validators: [
          Validators.required,
          Validators.pattern(this.validations.email.regex)
        ],
      }
    ),
    password: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(this.validations.password.length.min),
        Validators.maxLength(this.validations.password.length.max),
        this.commonService.validatePassword,
      ],
    }),
    confirmPassword: new FormControl('', {
      validators: [
        Validators.required
      ],
    })
  }, {validators: [this.validatePassword]});

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
    this.errorMessageMap = this.translations[this.language].errorMessageMap;
  });

  passwordSubscription: Subscription = this.form.controls.password.valueChanges.subscribe(() => {
    this.form.controls.confirmPassword.setErrors(null);
    this.errorMessages.confirmPassword = null;
  });

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private commonService: CommonService,
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {

  }

  createAccount() {
    if (this.form.invalid) {
      this.initializeFocus();
      this.checkForErrors();
      this.commonService.setFocus(this.form, this.errorMessages, this.focus);
      return;
    }
    const {fullName, email, password, confirmPassword} = this.form.getRawValue();
    const payload = {
      fullName,
      email,
      password,
      confirmPassword,
      clientDevice: this.clientDevice
    };

    this.store.dispatch(CreateAccountRequest({payload}));
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  initializeFocus() {
    this.focus = {
      fullName: false,
      email: false,
      password: false,
      confirmPassword: false
    }
  }

  openLoginDialog() {
    this.commonService.closeDialog();
    this.commonService.openDialog({data: {component: LoginFormComponent}});
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('confirmPassword')) {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;

      if (password && confirmPassword && password !== confirmPassword) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors ? control.get('confirmPassword').errors : {}),
          passwordMatchFailed: true
        })
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.passwordSubscription?.unsubscribe();
  }
}
