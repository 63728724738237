import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "../shared/pages/page-not-found/page-not-found.component";
import {CartWrapperComponent} from "./cart-wrapper/cart-wrapper.component";
import {HalfContentHalfImageComponent} from "../shared/pages/half-content-half-image/half-content-half-image.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CartWrapperComponent
  },
  {
    path: '**',
    component: HalfContentHalfImageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PageNotFoundComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartDetailsRoutingModule {
}
