interface TeaDetailsReviewInfoTranslation {
  heading: string,
  loadMore: string,
  noData: string,
  totalReview: string
}

const en: TeaDetailsReviewInfoTranslation = {
  heading: `Reviews`,
  loadMore: `LOAD MORE`,
  noData: `No reviews available`,
  totalReview: `Total Reviews`
}

const ja: TeaDetailsReviewInfoTranslation = {
  heading: `レビュー`,
  loadMore: `もっと見る`,
  noData: `レビューがありません`,
  totalReview: `総レビュー`
}

export const Translations = {
  1: en,
  2: ja
}
