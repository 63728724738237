<div
  class="subscription-card"
  fxLayout="column"
  fxLayoutGap="0.5rem"
>
  <div
    fxLayoutAlign="center center"
  >
    <app-primary-clickable-text
      (handleClick)="handleUserSubscription(subscriptionDetails._id)"
      [text]="translations[language].subscriptionsDetailsLabel"
      class="subscription-details-button"
    ></app-primary-clickable-text>
  </div>
  <div
    class="subscription-code "
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="label">{{ translations[language].subscriptionsCodeLabel }}</div>
    <div class="value ff-lang-ja-text-en">{{ subscriptionDetails?.subscriptionCode }}</div>
  </div>
  <div
    class="price"
    fxLayout="row"
  >
    <div class="label" fxFlex="40">  {{ translations[language].totalAmount }}</div>
    <div class="value ff-lang-ja-text-en" fxFlex="60">
      {{subscriptionDetails?.total | currency: subscriptionDetails?.currencySymbol : subscriptionDetails?.currencySymbol : '1.0-2'}}</div>
  </div>
  <div
    class="interval"
    fxLayout="row"
  >
    <div class="label" fxFlex="40">{{ translations[language].subscriptionLabel }} </div>
    <div class="value" fxFlex="60">{{ subscriptionDetails?.status | uppercase }} </div>
  </div>
  <div fxLayoutAlign="center center">
    <img [src]="image" class="image"/>
  </div>
</div>











