import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {CartService} from './cart.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../../shared/services/common.service';
import {Observable, of} from "rxjs";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {
  AddToCartFailure,
  AddToCartRequest,
  AddToCartSuccess,
  AddToItems,
  CalculateCartFailure,
  CalculateCartRequest,
  CalculateCartSuccess,
  CartSetFailure,
  CartSetRequest,
  CartSetSuccess,
  ClearCartCount,
  ClearCartData,
  EnableGuestPurchaseForm,
  FetchAddressListFailure,
  FetchAddressListRequest,
  FetchAddressListSuccess,
  FetchCartCountFailure,
  FetchCartCountRequest,
  FetchCartCountSuccess,
  FetchCartFailure,
  FetchCartRequest,
  FetchCartSuccess,
  PlaceGuestOrderFailure,
  PlaceGuestOrderRequest,
  PlaceGuestOrderSuccess,
  PlaceOrderFailure,
  PlaceOrderRequest,
  PlaceOrderSuccess,
  QuantityDecrementFailure,
  QuantityDecrementRequest,
  QuantityDecrementSuccess,
  QuantityIncrementFailure,
  QuantityIncrementRequest,
  QuantityIncrementSuccess,
  RemoveItemFailure,
  RemoveItemRequest,
  RemoveItemSuccess,
  SetShippingAddressFailure,
  SetShippingAddressRequest,
  SetShippingAddressSuccess,
  UpdatePaymentOptionFailure,
  UpdatePaymentOptionRequest,
  UpdatePaymentOptionSuccess,
  UserPaymentCardsFailure,
  UserPaymentCardsRequest,
  UserPaymentCardsSuccess
} from './cart.actions';
import {OrderSuccessDialogComponent} from "../common/components/order-success-dialog/order-success-dialog.component";
import {environment} from "../../../../environments/environment";


@Injectable()
export class CartEffects {
  cdnUrl = environment.cdnURL;
  calculateCart$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(CalculateCartRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.calculateCart(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return CalculateCartSuccess({
              cartData: data
            });
          }),
          catchError((error) => {
            return of(CalculateCartFailure(error.message));
          }),
          tap((action) => {
            if (action.type === CalculateCartSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === CalculateCartFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  placeGuestOrder$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(PlaceGuestOrderRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.placeGuestOrder(payload).pipe(
          map((response: any) => {
            return PlaceGuestOrderSuccess({message: response.message});
          }),
          catchError((error) => {
            return of(PlaceGuestOrderFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === PlaceGuestOrderSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.stopLoading();
              this.store.dispatch(EnableGuestPurchaseForm(false));
              this.store.dispatch(AddToItems({countryId: '', items: []}));
              this.commonService.openDialog({data: {component: OrderSuccessDialogComponent}});
            } else if (action.type === PlaceGuestOrderFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  fetchCart$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(FetchCartRequest),
      map((action) => {
        this.commonService.startLoading();
        return action;
      }),
      mergeMap(() =>
        this.cartService.fetchCart().pipe(
          map((response: any) => {
            const {data} = response;
            return FetchCartSuccess({
              cartData: data
            });
          }),
          catchError((error) => {
            return of(FetchCartFailure(error.message));
          }),
          tap((action) => {
            if (action.type === FetchCartSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchCartFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  addToCart$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(AddToCartRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.addToCart(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return AddToCartSuccess({
              cartData: data,
              message: response.message
            });
          }),
          catchError((error) => {
            return of(AddToCartFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === AddToCartSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
              this.store.dispatch(FetchCartCountRequest({}));
            } else if (action.type === AddToCartFailure.type) {
              // Code to execute on API Failure Action dispatch
              this.commonService.notification(action.message, 'error');
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  fetchCartCount$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(FetchCartCountRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(() =>
        this.cartService.fetchCartCount().pipe(
          map((response: any) => {
            return FetchCartCountSuccess({cartCount: response.data.itemsCount});
          }),
          catchError((error) => {
            return of(FetchCartCountFailure(error.message));
          }),
          tap((action) => {
            if (action.type === FetchCartCountSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchCartCountFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  quantityIncrement$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(QuantityIncrementRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.increment(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return QuantityIncrementSuccess({
              cartData: data,
              message: response.message
            });
          }),
          catchError((error) => {
            return of(QuantityIncrementFailure(error.message));
          }),
          tap((action) => {
            if (action.type === QuantityIncrementSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === QuantityIncrementFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  quantityDecrement$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(QuantityDecrementRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.decrement(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return QuantityDecrementSuccess({
              cartData: data,
              message: response.message
            });
          }),
          catchError((error) => {
            return of(QuantityDecrementFailure(error.message));
          }),
          tap((action) => {
            if (action.type === QuantityDecrementSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === QuantityDecrementFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  removeItem$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(RemoveItemRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.removeItem(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return RemoveItemSuccess({
              cartData: data,
              message: response.message
            });
          }),
          catchError((error) => {
            return of(RemoveItemFailure(error.message));
          }),
          tap((action) => {
            if (action.type === RemoveItemSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(
                action.message,
                'success'
              );
              this.store.dispatch(FetchCartCountRequest({}));
            } else if (action.type === RemoveItemFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  fetchAddressList: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchAddressListRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(() =>
        this.cartService.getUserAddress().pipe(
          map((response: any) => {
            const {data} = response;
            return FetchAddressListSuccess({addressList: data});
          }),
          catchError((error) => {
            return of(FetchAddressListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === FetchAddressListRequest.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (
              action.type === FetchAddressListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  userPaymentCards$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UserPaymentCardsRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(() =>
        this.cartService.getUserPaymentCards().pipe(
          map((response: any) => {
            const {data} = response;
            return UserPaymentCardsSuccess({
              message: 'Got User Payment Cards Successful.',
              cardsList: data,
            });
          }),
          catchError((error) => {
            return of(UserPaymentCardsFailure(error.message));
          }),
          tap((action) => {
            if (action.type === UserPaymentCardsSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (action.type === UserPaymentCardsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  setShippingAddress$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(SetShippingAddressRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.setShippingAddress(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const cartData = {
              address: data.address
            }
            return SetShippingAddressSuccess({
              cartData,
              message: response.message
            });
          }),
          catchError((error) => {
            return of(SetShippingAddressFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SetShippingAddressSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (action.type === SetShippingAddressFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  updatePaymentOption$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(UpdatePaymentOptionRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.updatePaymentOption(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return UpdatePaymentOptionSuccess({
              cartData: data,
              message: response.message
            });
          }),
          catchError((error) => {
            return of(UpdatePaymentOptionFailure(error.message));
          }),
          tap((action) => {
            if (action.type === UpdatePaymentOptionSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
            } else if (action.type === UpdatePaymentOptionFailure.type) {
            } else if (action.type === PlaceOrderFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  placeOrder$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(PlaceOrderRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.placeOrder(payload).pipe(
          map((response: any) => {
            const {data, message} = response;
            return PlaceOrderSuccess({
              cartData: data,
              message
            });
          }),
          catchError((error) => {
            return of(PlaceOrderFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === PlaceOrderSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.stopLoading();
              this.store.dispatch(ClearCartData());
              this.store.dispatch(ClearCartCount());
              this.commonService.openDialog({data: {component: OrderSuccessDialogComponent}});
            } else if (action.type === PlaceOrderFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  cartSet$: Observable<Action> = createEffect(
    () => this.actions.pipe(
      ofType(CartSetRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.cartService.cartSet(payload).pipe(
          map((response: any) => {
            const {data} = response;
            return CartSetSuccess({cartData: data});
          }),
          catchError((error) => {
            return of(CartSetFailure(error.message));
          }),
          tap((action) => {
            if (action.type === CartSetSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.store.dispatch(AddToItems({countryId: '', items: []}));
              if (action.data?.errorMessage) {
                this.commonService.notification(action.data.errorMessage, 'danger');
              }
              this.router.navigate(['/cart']);
            } else if (action.type === CartSetFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private cartService: CartService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService
  ) {
  }
}
