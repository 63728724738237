import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-package-details-qna',
  templateUrl: './package-details-qna.component.html',
  styleUrls: ['./package-details-qna.component.css'],
})
export class PackageDetailsQnaComponent implements OnInit {
  @Input() question: string = '';
  @Input() answer: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }
}
