import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {v1URL} from '../../shared/constants/urls.constants';
import {CommonService} from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root',
})

export class PackageDetailsService {
  constructor(private commonServices: CommonService) {
  }

  fetchPackageDetails(payload): Observable<any> {
    const urlConfig = v1URL.product.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }
}
