<div
  class="container"
  fxLayoutAlign="center center"
>
  <ng-container *ngIf="!forgotPasswordSuccess; else successMessage">
    <div
      class="forgot-password-form"
      fxLayout="column"
    >
      <div class="heading">
        {{translations[language].heading}}
      </div>
      <div>
        <div class="content">
          {{translations[language].content}}
        </div>
        <form
          [formGroup]="form"
          fxLayout="column"
          fxLayoutAlign="space-around none"
        >
          <div class="form-fields">
            <app-text-input
              id="email"
              (checkForErrors)="checkForErrors($event)"
              [error]="errorMessages['email']"
              [focus]="focus['email']"
              [maxLength]="validations.email.length.max"
              [parentFormGroup]="form"
              class="input-control email-input"
              controlName="email"
              label="{{translations[language].emailLabel}}"
            ></app-text-input>
          </div>
          <div class="form-actions">
            <app-primary-button
              id="confirm"
              (handleClick)="sendEmail()"
              class="create-account-button"
              text="{{translations[language].confirmButton}}"
            >
            </app-primary-button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-template #successMessage>
    <div class="successContainer"
         fxLayoutAlign="center center"
         fxLayout="column"
    >
      <div class="heading">
        {{translations[language].successHeading}}
      </div>
      <div class="content">
        {{translations[language].successMessage}} {{maskedEmailID}}
      </div>
      <app-primary-button
        id="to-home"
        (handleClick)="backToHome()"
        class="back-to-home-button"
        text="{{translations[language].goHomeButton}}"
      >
      </app-primary-button>
    </div>
  </ng-template>
</div>

