import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeaDetailsScreenComponent } from './tea-details-screen/tea-details-screen.component';
import { TeaDetailsRoutingModule } from './tea-details-routing.module';
import { StoreModule } from '@ngrx/store';
import { reducer } from './core/tea-details.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TeaDetailsEffects } from './core/tea-details.effects';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExperienceComponent } from './components/experience/experience.component';
import { PairingsComponent } from './components/pairings/pairings.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { TeaStoryComponent } from './components/tea-story/tea-story.component';
import { UserReviewsComponent } from './components/user-reviews/user-reviews.component';
import { UserReviewTagsComponent } from './components/user-review-tags/user-review-tags.component';
import { BrewDetailsPopupComponent } from './components/brew-details-popup/brew-details-popup.component';
import { AuthenticatedBrewNotificationInfoComponent } from './components/authenticated-brew-notification-info/authenticated-brew-notification-info.component';
import { BrewProcessingInfoComponent } from './components/brew-processing-info/brew-processing-info.component';
import { BrewStrengthInfoComponent } from './components/brew-strength-info/brew-strength-info.component';
import { BrewTemperatureInfoComponent } from './components/brew-temperature-info/brew-temperature-info.component';
import { BrewReadyServeInfoComponent } from './components/brew-ready-serve-info/brew-ready-serve-info.component';
import { ColdCarafeTemperatureInfoComponent } from './components/cold-carafe-temperature-info/cold-carafe-temperature-info.component';
import { UnauthenticatedBrewNotificationInfoComponent } from './components/unauthenticated-brew-notification-info/unauthenticated-brew-notification-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CountdownTimerDirective } from './directives/coundown-timer.directive';
import { SecondsToMinutesPipe } from './pipes/seconds-to-minutes.pipe';
import { TeaDetailsLoaderComponent } from './components/tea-details-loader/tea-details-loader.component';
import { ReviewSummaryLoaderComponent } from './components/review-summary-loader/review-summary-loader.component';
import { UserReviewsLoaderComponent } from './components/user-reviews-loader/user-reviews-loader.component';

@NgModule({
  declarations: [
    TeaDetailsScreenComponent,
    ExperienceComponent,
    PairingsComponent,
    ReviewsComponent,
    TeaStoryComponent,
    UserReviewsComponent,
    UserReviewTagsComponent,
    BrewDetailsPopupComponent,
    AuthenticatedBrewNotificationInfoComponent,
    BrewStrengthInfoComponent,
    BrewTemperatureInfoComponent,
    BrewReadyServeInfoComponent,
    BrewProcessingInfoComponent,
    ColdCarafeTemperatureInfoComponent,
    UnauthenticatedBrewNotificationInfoComponent,
    CountdownTimerDirective,
    SecondsToMinutesPipe,
    TeaDetailsLoaderComponent,
    ReviewSummaryLoaderComponent,
    UserReviewsLoaderComponent
  ],
  imports: [
    CommonModule,
    TeaDetailsRoutingModule,
    StoreModule.forFeature('teaDetails', reducer),
    EffectsModule.forFeature([TeaDetailsEffects]),
    SharedModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
})
export class TeaDetailsModule {}
