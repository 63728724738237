import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-option-list-loader',
  templateUrl: './payment-option-list-loader.component.html',
  styleUrls: ['./payment-option-list-loader.component.css']
})
export class PaymentOptionListLoaderComponent implements OnInit {
  itemList: number[] = [1, 2, 3, 4, 5];
  constructor() { }

  ngOnInit(): void {
  }

}
