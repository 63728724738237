<style
  id="subscription-details-dialog-styling"
>
  .cdk-overlay-pane.dialog {
    width: unset !important;
  }
</style>
<div
  class="container"
  ngClass.gt-lg="gt-lg"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div class="content"
       fxLayout="column">
    <div
      class="each-section basic-information"
      fxFlex.lg="80"
      fxFlex.md="80"
      fxFlex.sm="80"
      fxFlex.xs="100"
    >
      <div class="subscription-code">
        <div class="label"
             fxFlex="35"
             fxFlex.md="45"
             fxFlex.xs="45">{{ translations.subscriptionLabel }} #
        </div>
        <div class="value"
             fxFlex="65"
             fxFlex.md="55"
             fxFlex.xs="55">
          <app-shimmer-loader
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.7rem',
              'margin-bottom': '0rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div class="name">
        <div class="label"
             fxFlex="35"
             fxFlex.md="45"
             fxFlex.xs="45"> {{translations.name}}</div>
        <div class="value"
             fxFlex="65"
             fxFlex.md="55"
             fxFlex.xs="55">
          <app-shimmer-loader
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.7rem',
              'margin-bottom': '0rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div class="nickname">
        <div class="label"
             fxFlex="35"
             fxFlex.md="45"
             fxFlex.xs="45">
          {{ translations.subscriptionLabel }}
        </div>
        <div class="value"
             fxFlex="65"
             fxFlex.md="55"
             fxFlex.xs="55">
          <app-shimmer-loader
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.7rem',
              'margin-bottom': '0rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div class="price">
        <div class="label"
             fxFlex="35"
             fxFlex.md="45"
             fxFlex.xs="45">
          {{translations.price}}
        </div>
        <div class="value"
             fxFlex="65"
             fxFlex.md="55"
             fxFlex.xs="55">
          <app-shimmer-loader
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.7rem',
              'margin-bottom': '0rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div class="subscription-status">
        <div class="label"
             fxFlex="35"
             fxFlex.md="45"
             fxFlex.xs="45">
          {{translations.subscriptionStatus}}
        </div>
        <div class="label primary-status-text"
             fxFlex="65"
             fxFlex.md="55"
             fxFlex.xs="55"
             fxLayoutAlign="start"
        >
          <app-shimmer-loader
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '30%',
              'height': '1.7rem',
              'margin-bottom': '0rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div
           class="next-payment-date">
        <div class="label"
             fxFlex="35"
             fxFlex.md="45"
             fxFlex.xs="45">
          {{ translations.nextPaymentDate }}
        </div>
        <div class="value"
             fxFlex="65"
             fxFlex.md="55"
             fxFlex.xs="55">
          <app-shimmer-loader
            [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.7rem',
              'margin-bottom': '0rem'
            }"
          ></app-shimmer-loader>
        </div>
      </div>
    </div>
    <div
      class="space"
      fxLayout.lg="row"
      fxLayout.md="row"
      fxLayout.sm="row"
      fxLayout.xs="column"
      fxLayoutGap.lg="20px"
      fxLayoutGap.md="20px"
    >
      <div
        class="each-section shipping-address"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.sm="100"
        fxFlex.xs="100"
      >
        <div class="heading"> {{ translations.shippingAddress }}</div>
        <app-shimmer-loader
          [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.85rem',
              'margin-bottom': '0.5rem'
            }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          [count]="4"
          [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.6rem',
              'margin-bottom': '0.71rem'
            }"
        ></app-shimmer-loader>
      </div>
      <div
        class="each-section payment-details"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.sm="100"
        fxFlex.xs="100"
      >
        <div class="heading"> {{ translations.paymentDetails }}</div>
        <app-shimmer-loader
          [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.85rem',
              'margin-bottom': '0.5rem'
            }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          [theme]="{
              'background-color': '#e5e5e5',
              'width': '60%',
              'height': '1.6rem',
              'margin-bottom': '0.71rem'
            }"
        ></app-shimmer-loader>
      </div>
    </div>
    <div
      class="space"
      fxLayout="column"
    >
      <div
        class="row"
        fxLayout="column"
      >
        <div  fxFlex="100" class="contact-description">{{ translations.contactUsDescription }}</div>
        <app-secondary-button
          fxLayoutAlign="center center"
          class="value contact-us-button"
          [isDisabled]="true"
          [text]="translations.contactUsLabel"
        ></app-secondary-button>

        <div
          class="text-center cancel"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <div fxFlex="100"
          >
            <app-primary-clickable-text
              [isDisabled]="true"
              [text]="translations.cancelSubscriptionLabel"
            ></app-primary-clickable-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



