<div
  class="container"
  ngClass.xs="xs"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div
    class="heading dialog-heading"
  >
    {{translations[language].heading}}
  </div>
  <div
    class="panels-section"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="6rem"
  >
    <div
      class="panel left-panel"
      fxFlex="100"
      fxFlex.gt-sm="50"
    >
      <form
        [formGroup]="form"
        fxLayout="column"
        fxLayoutAlign="space-around none"
      >
        <div class="input-field">
          <app-text-input
            id="email"
            (checkForErrors)="checkForErrors($event)"
            [controlName]="'email'"
            [error]="errorMessages['email']"
            [maxLength]="validations.email.length.max"
            [parentFormGroup]="form"
            class="username"
            [isSlim]="true"
            label="{{translations[language].left.usernameLabel}}"
          >
          </app-text-input>
        </div>
        <div class="input-field">
          <app-password-input
            id="password"
            (checkForErrors)="checkForErrors($event)"
            [controlName]="'password'"
            [error]="errorMessages['password']"
            [maxLength]="validations.password.length.max"
            [parentFormGroup]="form"
            class="password"
            [isSlim]="true"
            label="{{translations[language].left.passwordLabel}}"
          >
          </app-password-input>
        </div>
        <div class="actions">
          <app-primary-button
            id="login"
            (handleClick)="login()"
            class="login-button"
            text="{{translations[language].left.signInButton}}"
            [isSlim]="true"
          >
          </app-primary-button>
          <app-primary-clickable-text
            id="forgot_password"
            (handleClick)="openForgotPasswordDialog()"
            class="forgot-password-button"
            fxLayoutAlign="center none"
            text="{{translations[language].left.forgotPasswordButton}}"
          ></app-primary-clickable-text>
        </div>
      </form>
    </div>
    <div
      class="panel right-panel"
      fxFlex="100"
      fxFlex.gt-sm="50"
      fxLayout="column"
      fxLayoutGap="2rem"
      fxLayoutAlign="center center"
      [class.guest-purchase]="guestPurchase"
    >
      <div class="heading panel-heading">
        {{translations[language].right.heading}}
      </div>
      <div class="create-account-content">
        <span [innerHtml]="translations[language].right.contents"></span>
      </div>
      <div class="button"
           fxLayout="column"
           fxLayoutAlign="space-around center"
           fxLayoutGap="15px"
      >
        <div class="actions">
          <app-primary-button
            id="create_my_telpo_account"
            (handleClick)="openCreateTeploAccountDialog()"
            text="{{translations[language].right.createAccountButton}}"
            [isSlim]="true"
          >
          </app-primary-button>
        </div>
        <div
          *ngIf="guestPurchase"
          class="or-text"
        >
          {{translations[language].right.or}}
        </div>
        <div
          *ngIf="guestPurchase"
          class="guest-purchase"
        >
          <app-primary-button
            id="guest_purchase"
            (handleClick)="enableGuestPurchaseDetailsForm()"
            text="{{translations[language].right.guestPurchaseButton}}"
            [isSlim]="true"
          >
          </app-primary-button>
        </div>
      </div>
    </div>
  </div>
</div>
