import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Translations} from '../../../translations/tea-details-page/unauthenticated-brew-notification-info.translation';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { TeaDetailsService } from '../../core/tea-details.service';
import { selectedLanguage } from 'src/app/modules/shared/core/shared.selectors';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-unauthenticated-brew-notification-info',
  templateUrl: './unauthenticated-brew-notification-info.component.html',
  styleUrls: ['./unauthenticated-brew-notification-info.component.css']
})
export class UnauthenticatedBrewNotificationInfoComponent implements OnInit, OnDestroy {

  language: any;
  @Input() profileData: any;
  @Input() teaDetails: any;
  @Output() handleClick = new EventEmitter();

  translations: any;
  brewTemperature: string;
  UnauthenticatedBrewNotificationInfoForm: FormGroup;
  errorMessages = {};
  errorMessageMap;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });

  constructor(
    private TeaDetailsService: TeaDetailsService,
    private fb: FormBuilder,
    private commonService: CommonService,private store:Store
  ) {
    this.translations = Translations;
  }

  ngOnInit() {
    this.errorMessageMap = Translations[this.language].errorMessageMap;

    this.UnauthenticatedBrewNotificationInfoForm = this.fb.group({

      email: new FormControl('',
        {
          validators: [
            Validators.required,
            Validators.pattern(this.commonService.emailRegex)
          ]
        }
      )
    });
  }

  handleStartBrewClick(): void {
    if (this.UnauthenticatedBrewNotificationInfoForm.invalid) {
      this.checkForErrors();
      return;
    }

    const {email} = this.UnauthenticatedBrewNotificationInfoForm.getRawValue();
    const payload = {
      email,
      productId: this.teaDetails?.id
    };
    this.TeaDetailsService.notify.emit(payload);
    this.commonService.closeDialog()
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.UnauthenticatedBrewNotificationInfoForm, this.errorMessageMap, currentField))
    };
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }
}
