import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription, tap} from 'rxjs';
import {PackageDetailsModel} from '../../models/package-details.model';
import {
  isMobileView,
  selectedLanguage
} from '../../shared/core/shared.selectors';
import {
  Translations
} from '../../translations/package-details-page/package-details-screen.translation';
import {
  FetchPackageDetailsRequest,
  ResetSuccessState
} from '../core/package-details.actions';
import {fetchPackageDetails} from '../core/package-details.selectors';
import {AddToCartRequest} from "../../cart-details/core/cart.actions";
import {CommonService} from "../../shared/services/common.service";
import {ScriptService} from "../../shared/services/script.service";

@Component({
  selector: 'app-package-details-screen',
  templateUrl: './package-details-screen.component.html',
  styleUrls: ['./package-details-screen.component.css'],
})
export class PackageDetailsScreenComponent implements OnInit, OnDestroy {
  language: number = 1;
  productId: string = '';
  productKey: string = '';
  quantitySelectionForm: FormGroup = new FormGroup({
    quantity: new FormControl(``, []),
  });
  errorMessageMap: any = {};
  translations: any = Translations;
  message: string = '';
  isMobileView: boolean;
  packageDetails: PackageDetailsModel = null;

  mobileSubscription: Subscription = this.store
    .pipe(select(isMobileView))
    .subscribe((data) => {
      this.isMobileView = data;
    });
  packageDetail$: Observable<PackageDetailsModel> = this.store
    .select(fetchPackageDetails)
    .pipe(
      tap((details) => {
        this.packageDetails = details;
        this.productId = this.packageDetails?.id
        this.quantitySelectionForm.patchValue(
          {
            quantity: details.priceList[0]?.key,
          },
          {emitEvent: false}
        );
        this.message = '';
        if (details.isAvailableInCountry && details.availableQuantity <= 0 && details.priceList?.length) {
          this.message = this.translations[this.language].outOfStock;
        } else if (
          !details.isAvailableInCountry ||
          details.priceList?.length === 0
        ) {
          this.message = this.translations[this.language].outOfStock;
        }

        this.addScriptTag();
      })
    );
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
      this.errorMessageMap = this.translations[this.language].errorMessageMap;
    });

  paramsSubscription: Subscription = this.activatedRoute.params.subscribe(
    (params: any) => {
      if (params) {
        this.productKey = params.id;
        const vendorName = params.vendor;

        this.store.dispatch(
          FetchPackageDetailsRequest({
            payload: {
              productKey: `${vendorName}/${this.productKey}`
            }
          })
        );
      }
    }
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private scriptService: ScriptService,
    private renderer2: Renderer2
  ) {
    this.store.dispatch(ResetSuccessState());
  }

  ngOnInit(): void {
  }

  handleContactUs(name) {
    window.open(
      `mailto:hello.japan@load-road.com?subject=Need information regarding '${name}'`
    );
  }

  addScriptTag() {
    const ldJson = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": this.packageDetails?.name,
      "image": this.packageDetails?.imageList[0],
      "brand": {
        "@type": "Brand",
        "name": this.packageDetails?.vendor?.name
      },
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": "JPY",
        "price": this.packageDetails?.priceList[0]?.value,
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
      },
    }

    this.scriptService.setJsonLd(this.renderer2, ldJson)
  }

  addToCart() {
    const pricingPlanId = this.quantitySelectionForm.controls['quantity'].value;
    const payload = {
      productId: this.productId,
      pricingPlanId
    }
    if (this.commonService.isAuthenticated()) {
      this.store.dispatch(AddToCartRequest({payload}));
    } else {
      const message = this.translations[this.language].errorMessage
      this.commonService.notification(message, 'danger')
    }
  }


  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.mobileSubscription?.unsubscribe();

    const element = document.getElementById('ld_json');
    if (element) {
      element.parentElement.removeChild(element);
    }
  }
}
