/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SharedState} from './shared.reducer';

// Selector functions
const SharedState = createFeatureSelector<SharedState>('shared');

export const isLoading = createSelector(
  SharedState,
  state => state.loading
);

export const languagesList = createSelector(
  SharedState,
  state => state.languagesList
);

export const selectedLanguage = createSelector(
  SharedState,
  state => state.selectedLanguage
);

export const isMobileView = createSelector(
  SharedState,
  state => state.isMobileView
);

export const isMobileSidenavOpen = createSelector(
  SharedState,
  state => state.isMobileSidenavOpen
);

export const countriesList = createSelector(
  SharedState,
  state => state.countriesList
);

export const selectedCountry = createSelector(
  SharedState,
  state => state.selectedCountry
);

export const disableScrolling = createSelector(
  SharedState,
  state => !!state.disableScrolling.length
);

export const isMobileSearchOpen = createSelector(
  SharedState,
  state => state.isMobileSearchOpen
);

export const gendersList = createSelector(
  SharedState,
  state => state.gendersList
);

export const isAppInMaintenanceMode = createSelector(
  SharedState,
  (state) => state?.isAppInMaintenanceMode
);
