import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DeleteAccountRoutingModule} from './delete-account-routing.module';
import {DeleteAccountFormComponent} from './components/delete-account-form/delete-account-form.component';
import {DeleteAccountScreenComponent} from './delete-account-screen/delete-account-screen.component';
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import {reducer} from './core/delete-account.reducer';
import {EffectsModule} from "@ngrx/effects";
import {DeleteAccountEffects} from "./core/delete-account.effects";
import {
  DeleteAccountConfirmationDialogComponent
} from './components/delete-account-confirmation-dialog/delete-account-confirmation-dialog.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {AuthenticationModule} from "../authentication/authentication.module";
import { DeleteAccountFormLoaderComponent } from './components/delete-account-form-loader/delete-account-form-loader.component';


@NgModule({
  declarations: [
    DeleteAccountFormComponent,
    DeleteAccountScreenComponent,
    DeleteAccountConfirmationDialogComponent,
    DeleteAccountFormLoaderComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    AuthenticationModule,
    ReactiveFormsModule,
    DeleteAccountRoutingModule,
    StoreModule.forFeature('deleteAccount', reducer),
    EffectsModule.forFeature([DeleteAccountEffects])
  ]
})
export class DeleteAccountModule {}
