interface ColdCarafeTemperatureInfoTranslation {
  labelText1: string,
  startBrew: string,
  instructionText1A: string,
  instructionText2A: string,
  instructionText2B: string,
  instructionText3A: string,
  instructionText3B: string,
  btnPrevious: string,
}

const en: ColdCarafeTemperatureInfoTranslation = {
  labelText1: `A revered addition to our spring ensemble this Cold Brew is as velvety and smooth as the best of its class.`,
  instructionText1A: `Put one packet of teplo official tea leaves into the carafe. You can use your own tea as well.`,
  instructionText2A: `Keep the Carafe in a refrigerator for`,
  instructionText2B: `for the tea to brew`,
  instructionText3A: `Fill cold water with temperature less than`,
  instructionText3B: `upto the line in the carafe`,
  startBrew: `Start Brewing`,
  btnPrevious: `Previous`,
}

const ja: ColdCarafeTemperatureInfoTranslation = {
  labelText1: `美味しさ、美しさ、日常での使いやすさを追求して生まれたデザインが、いままでにない冷茶の体験をもたらします。`,
  instructionText1A: `公式茶葉を1袋をカラフェに入れます。公式茶葉以外も利用可能です。`,
  instructionText2A: `カラフェを`,
  instructionText2B: `冷蔵庫に入れて水出しを行います。`,
  instructionText3A: `カラフェの側面のマークまで、`,
  instructionText3B: `の室温以下の水を入れます。`,
  startBrew: `抽出開始`,
  btnPrevious: `戻る`,
}

export const Translations = {
  1: en,
  2: ja
}
