<div class="container" ngClass.lg="lg" ngClass.md="md" ngClass.sm="sm" ngClass.xs="xs" fxLayoutAlign="center center" fxLayoutAlign.xs="center start">
  <div fxFlex="90" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div class="image-container" fxLayoutAlign="start center">
      <img alt="" class="image" src="./assets/images/png/Carafe.png" />
    </div>
    <div class="message">
      {{ translations[language].labelText1 }}
    </div>
    <div fxLayout="row" fxLayout.xs="column"
      fxLayoutAlign.xs="center center">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="bread-crumb-logo" fxLayoutAlign="center center">
          <img class="bread-crumb-image" src="assets/images/svg/leaf.svg" alt="" />
        </div>
        <div class="instructions">
          {{ translations[language].instructionText1A }}
        </div>
      </div>
      <app-icon [icon]="'next'"></app-icon>
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="bread-crumb-logo" fxLayoutAlign="center center">
          <img class="bread-crumb-image" src="assets/images/svg/leaf.svg" alt="" />
        </div>
        <div class="instructions">
          {{ translations[language].instructionText2A }}
          {{ brewDuration }}
          {{ translations[language].instructionText2B }}
        </div>
      </div>
      <app-icon [icon]="'next'"></app-icon>
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="bread-crumb-logo" fxLayoutAlign="center center">
          <img class="bread-crumb-image" src="assets/images/svg/leaf.svg" alt="" />
        </div>
        <div class="instructions">
          {{ translations[language].instructionText3A }}
          {{ waterQuantity }}
          {{ translations[language].instructionText3B }}
        </div>
      </div>
    </div>
    <div>
      <div>
        <div></div>
      </div>
    </div>
    <div class="actions-section" fxLayout="row" fxLayoutAlign="space-between center">
      <app-primary-button id="previous" (handleClick)="handlePreviousClick()" [text]="translations[language].btnPrevious"
        [isSlim]="true" class="button"></app-primary-button>
      <app-primary-button
        buttonId="start_authenticated_cold_brew"
        (handleClick)="handleStartBrewClick()"
        [text]="translations[language].startBrew"
        [isSlim]="true" class="button start-authenticated-cold-brew"
      ></app-primary-button>
    </div>
  </div>
</div>
