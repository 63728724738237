<div
	class="container"
	ngClass.lg="lg"
	ngClass.md="md"
	ngClass.sm="sm"
	ngClass.xs="xs"
>
	<div
		fxFlex="100"
		fxLayout="column"
		fxLayoutAlign="center center"
		fxLayoutGap="20px"
	>
		<div class="image-container" fxLayoutAlign="start center">
      <img alt="" class="image" src="./assets/images/png/Carafe_Icon.png"/>
		</div>
		<div class="message-line1">
			{{translations[language].labelText1}}
		</div>
		<div class="message-line2">
			{{translations[language].labelText2}}
		</div>
		<div class="email-id">
			{{profileData.email }}
		</div>
	</div>
</div>

