import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-clickable-text',
  templateUrl: './primary-clickable-text.component.html',
  styleUrls: ['../styles.css', './primary-clickable-text.component.css']
})
export class PrimaryClickableTextComponent implements OnInit {
  @Input() text: string;
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Output() handleClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

}
