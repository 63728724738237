import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription, tap} from 'rxjs';
import {isMobileView, selectedLanguage,} from 'src/app/modules/shared/core/shared.selectors';
import {Translations} from '../../../translations/tea-details-page/tea-details-pairing-info.translation';
import {Pairings} from '../../../models/tea-details.model';
import {teaPairing} from '../../core/tea-details.selectors';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-pairings',
  templateUrl: './pairings.component.html',
  styleUrls: ['./pairings.component.css'],
})
export class PairingsComponent implements OnInit, OnDestroy {
  cdnUrl = environment.cdnURL;
  imageUrl: any;
  language: number = 1;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });
  isMobileView: boolean
  isMobileViewSubscription: Subscription = this.store.pipe(select(isMobileView), tap((mobileView) => {
    this.isMobileView = mobileView
  })).subscribe();
  pairing: Pairings[] = [];
  selectedPairing: Pairings;
  translations: any = Translations;
  pairingSubscription = this.store
    .select(teaPairing)
    .pipe(
      tap((pairing) => {
        this.pairing = pairing;
      })
    )
    .subscribe();

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    if (this.pairing.length) {
      this.selectedPairing = this.pairing[0];
    }
  }

  onChangeSelectedParing(pairing) {
    this.selectedPairing = pairing;
  }

  getSelectedRows(pairing) {
    if (this.selectedPairing) {
      return this.selectedPairing._id === pairing._id;
    } else {
      this.selectedPairing = pairing[0];
    }
  }

  ngOnDestroy(): void {
    this.pairingSubscription?.unsubscribe();
    this.isMobileViewSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
  }
}
