import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../translations/reset-password/reset-password-form.translation";
import * as Validations from "../../../shared/constants/validation.constants";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../state/app.state";
import {CommonService} from "../../../shared/services/common.service";
import {ResetPasswordSetRequest} from "../../core/reset-password.actions";

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {

  language: number = 1;
  validations = Validations;
  translations: any = Translations;
  errorMessages: any = {};
  errorMessageMap: any = {};
  form: FormGroup = this.fb.group({
    password: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(this.validations.password.length.min),
        Validators.maxLength(this.validations.password.length.max),
        this.commonService.validatePassword
      ],
    }),
    confirmPassword: new FormControl('', {
      validators: [
        Validators.required,
      ],
    })
  }, {validators: [this.validateConfirmPassword]});

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
    this.errorMessageMap = this.translations[this.language].errorMessageMap;
  });

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    document.addEventListener('keypress', (e) => {
      if (e.code === 'Enter') {
        this.resetPassword();
      }
    });
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  validateConfirmPassword(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('confirmPassword')) {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;

      if (password && confirmPassword && password !== confirmPassword) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors ? control.get('confirmPassword').errors : {}),
          passwordMatchFailed: true
        })
      }
    }
    return null;
  }

  resetPassword() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }
    const {password, confirmPassword} = this.form.getRawValue();
    const payload = {
      password,
      confirmPassword
    };
    this.store.dispatch(ResetPasswordSetRequest({payload}));
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    document.removeEventListener('keypress', (e) => {
    })
  }

}
