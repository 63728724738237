interface SubscriptionDetailsTranslation {
  subscriptionLabel: string;
  shippingAddress: string;
  paymentDetails: string;
  selectedLabel: string;
  contactUsDescription: string;
  contactUsLabel: string;
  cancelSubscriptionLabel: string;
  defaultLabel: string;
  name: string;
  price: string;
  subscriptionStatus: string;
  nextPaymentDate: string;
}

const en: SubscriptionDetailsTranslation = {
  subscriptionLabel: `Subscription`,
  shippingAddress: `Shipping Address`,
  paymentDetails: `Payment Details`,
  selectedLabel: `Selected`,
  contactUsDescription: `* If you have any questions about the order contact us at
  orders@load-road.com`,
  contactUsLabel: `Contact Us`,
  cancelSubscriptionLabel: `Cancel Subscription`,
  defaultLabel: `Default`,
  name: `Name`,
  price: `Price`,
  subscriptionStatus: 'Subscription Status',
  nextPaymentDate: 'Next Payment Date'
};

const ja: SubscriptionDetailsTranslation = {
  subscriptionLabel: `サブスクリプション番号`,
  shippingAddress: `お届け先住所`,
  paymentDetails: `お支払い情報`,
  selectedLabel: `選択中`,
  contactUsDescription: `※ご質問は<span class="ff-lang-ja-text-en">order.japan@load-road.com</span>までお気軽にお問い合わせください`,
  contactUsLabel: `お問合せ`,
  cancelSubscriptionLabel: `サブスクリプションを解約`,
  defaultLabel: `既定`,
  name: `名前`,
  price: `価格`,
  subscriptionStatus: 'サブスクリプションの状態',
  nextPaymentDate: '次回のお支払い予定日'
};

export const Translations = {
  1: en,
  2: ja
};
