import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from './../../../../../state/app.state';
import {Translations} from "../../../../translations/user-details/user-details-header.translation";
import {Subscription} from "rxjs";
import {profile} from "../../../../authentication/core/authentication.selectors";
import {CommonService} from "../../../../shared/services/common.service";
import {LogoutConfirmDialogComponent} from "../../../../shared/components/logout-confirm-dialog/logout-confirm-dialog.component";

@Component({
  selector: 'app-user-details-header',
  templateUrl: './user-details-header.component.html',
  styleUrls: ['./user-details-header.component.css']
})
export class UserDetailsHeaderComponent implements OnInit, OnDestroy {
  @Input() language: number;
  translations: any;
  loading: boolean = false;
  profileInfo: any;

  urls: any[] = [
    {
      path: './personal-information',
      translationKey: 'personalInformation',
      disabled: false,
    },
    {
      path: './orders',
      translationKey: 'myOrders',
      disabled: false,
    },
    {
      path: './subscriptions',
      translationKey: 'mySubscriptions',
      disabled: false,
    }
  ];

  // Get profile information
  profileInfoSubscription: Subscription = this.store.pipe(select(profile)).subscribe((profileData) => {
    this.profileInfo = profileData.profile;
    this.loading = profileData.loading;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService) {

  }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

  openLogoutDialogBox() {
    this.commonService.openDialog({
      data: {
        component: LogoutConfirmDialogComponent
      }
    })
  }


  ngOnDestroy() {
    this.profileInfoSubscription?.unsubscribe();
  }

}
