<div
  class="container"
  fxLayoutAlign="start center"
>
  <div
    class="logo-section"
    fxFlex="20"
  >
    <img
      (click)="goToHome()"
      alt=""
      class="logo"
      src="../../../../../../assets/images/png/logo.png"
    >
  </div>
  <div
    class="options-section"
    fxFlex="80"
    fxLayoutAlign="end center"
    fxLayoutGap="2rem"
  >

    <div
      (click)="toggleSearchSection()"
      class="search-icon-section"
      fxLayoutAlign="center center"
    >
      <app-icon
        *ngIf="!isMobileSearchOpen"
        class="header-search-icon"
        icon="search"
      ></app-icon>
      <app-icon
        *ngIf="isMobileSearchOpen"
        class="header-search-icon"
        icon="close"
      ></app-icon>
    </div>
    <div class="country-selector-section">
      <div
        [matMenuTriggerFor]="menu"
        class="country-selector"
        fxLayoutAlign="center center"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div>
            {{selectedCountryText}}
          </div>
        </div>
      </div>
      <mat-menu #menu="matMenu" class="header-country-list" xPosition="before">
        <button
          (click)="setSelectedCountry(eachCountry.key)"
          *ngFor="let eachCountry of countriesList"
          [class.selected]="eachCountry.key === selectedCountryCode"
          class="header-country-item"
          mat-menu-item
        >
          {{eachCountry.value}}
        </button>
      </mat-menu>
    </div>
    <app-icon
      (click)="handleCartClick()"
      class="options-icon cart-icon"
      icon="cart"
      [badgeValue]="cartCount"
    ></app-icon>
    <app-icon
      (click)="toggleSidenav()"
      *ngIf="!isMobileSidenavOpen"
      class="menu"
      icon="menu"
    ></app-icon>
    <app-icon
      (click)="toggleSidenav()"
      *ngIf="isMobileSidenavOpen"
      class="menu"
      icon="close"
    ></app-icon>
  </div>
</div>
<div
  *ngIf="isMobileSearchOpen"
  class="search-wrapper"
>
  <div class="search-block">
    <div
      class="search-box"
      fxLayout="row"
      [formGroup]="form"
    >
      <input
        [placeholder]="translations[language].searchPlaceholder"
        class="search-input"
        type="text"
        formControlName="searchText"
        (keydown.enter)="search()"
      >
      <div
        class="search-icon-section"
        fxLayoutAlign="center center"
      >
        <app-icon
          *ngIf="!searchIconClicked"
          class="search-icon"
          icon="search"
          (click)="search()"
        ></app-icon>
        <app-icon
          *ngIf="searchIconClicked"
          (click)="close()"
          class="search-icon"
          icon="close"
        ></app-icon>
      </div>
    </div>
  </div>
</div>
