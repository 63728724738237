<div class="container"
     ngClass.sm="sm"
     ngClass.xs="xs">
  <div
    class="heading-section"
    fxLayoutAlign="center center"
  >
    <app-shimmer-loader
      [theme]="isMobileView?{
            'height': '3rem',
            'width': '25rem'
      }:{
            'height': '3.3rem',
            'width': '50rem',
            'margin-bottom': '5rem'
          }"
    ></app-shimmer-loader>
  </div>
  <div class="tea-details-section"
       fxLayout="row"
       fxLayout.xs="column"
       fxLayout.sm="column">

    <div class="left">
      <app-shimmer-loader
        [theme]="isMobileView?{
            'height': '35rem',
            'width': '40rem',
            'margin-left': '2.5rem'
        }:{
            'height': '66rem',
            'width': '68rem'
          }"
      ></app-shimmer-loader>
      <div fxLayout="row"
           fxLayoutAlign="center center"
      >
        <div *ngFor="let each of [1, 2, 3]">
          <app-shimmer-loader
            appearance="circle"
            [theme]="{
            'height': '1rem',
            'width': '1rem'
          }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutGap="2rem"
           fxLayout.xs="column"
           fxLayoutAlign="center center">
        <app-shimmer-loader
          [theme]="isMobileView?{
          'height': '6rem',
          'width': '20rem'
          }:{
            'height': '6rem',
            'width': '25rem',
            'margin-top': '3rem'
          }"
        ></app-shimmer-loader>
        <app-shimmer-loader
          [theme]="isMobileView?{
            'height': '6rem',
            'width': '20rem',
            'margin-bottom': '5rem'
          }:{
            'height': '6rem',
            'width': '25rem',
            'margin-top': '3rem'
          }"
        ></app-shimmer-loader>
      </div>
    </div>

    <div class="right">

        <div fxLayout="row">
          <app-shimmer-loader
            appearance="circle"
            [theme]="isMobileView?{
            'height': '3.3rem',
            'width': '3.3rem'
            }:{
            'height': '3.3rem',
            'width': '3.3rem',
            'margin-right': '1rem'
          }"
          ></app-shimmer-loader>
          <app-shimmer-loader
            [theme]="{
            'height': '2rem',
            'width': '18rem',
            'margin-top': '1.2rem'
          }"
          ></app-shimmer-loader>
        </div>

      <div>
        <app-shimmer-loader
          [theme]="{
            'height': '1.5rem',
            'width': '30rem',
            'margin-top': '2rem',
            'margin-bottom': '3rem'
          }"
        ></app-shimmer-loader>
      </div>

      <div fxLayout="column"
           fxLayoutGap="2rem"
           fxLayoutGap.xs="1rem"
      >
        <div *ngFor="let each of [1, 2, 3, 4, 5, 6]"
             fxLayout="column">
          <app-shimmer-loader
            [theme]="isMobileView?{
            'height': '3rem',
            'width': '20rem',
            'margin-top': '1rem'
            }:{
            'height': '3rem',
            'width': '30rem',
            'margin-top': '1rem'
          }"
          ></app-shimmer-loader>
          <app-shimmer-loader
            [theme]="isMobileView?{
            'height': '1.5rem',
            'width': '38rem'
            }:{
            'height': '2rem',
            'width': '60rem'
          }"
          ></app-shimmer-loader>
        </div>
      </div>
      <div>
        <app-shimmer-loader
          [theme]="isMobileView?{
          'height': '1.5rem',
            'width': '38rem',
            'margin-top': '2rem'
          }:{
            'height': '1.5rem',
            'width': '60rem',
            'margin-top': '2rem'
          }"
        ></app-shimmer-loader>
      </div>
    </div>
  </div>
  <div fxLayoutAlign="center center">
    <app-shimmer-loader
      [theme]="isMobileView?{
          'height': '6rem',
          'width': '40rem'
          }:{
            'height': '6rem',
            'width': '70rem',
            'margin-top': '5rem',
            'margin-bottom': '7rem'
          }"
    ></app-shimmer-loader>
  </div>
</div>
