<div class="user-review-card" fxLayout="column">
  <div
    class="user-review-name-section"
    fxFlex="0 0 0"
    fxLayout="column"
    fxLayoutAlign="space-between none"
    fxLayoutGap="1rem"
  >
    <div
      class="user-name-section"
      fxFlex="0 0 0"
      fxLayout="row"
      fxLayoutAlign="space-between none"
    >
      <div fxLayoutAlign="center center" fxLayoutGap="1rem">
        <div class="bread-crumb-logo" fxLayoutAlign="center center">
          <img
            alt=""
            class="bread-crumb-image"
            src="assets/images/png/breadcrumb-logo-package.png"
          />
        </div>
        <div class="user-name ff-lang-ja-text-en">Teplo User</div>
      </div>

      <div class="review-date ff-lang-ja-text-en">{{userReviews.createdAt |date:"d MMM yy"}}</div>
    </div>
    <div fxLayoutAlign="space-around none">
      <div fxLayoutAlign="space-around none">
        <div
          class="user-review-emoji"
          fxFlex="0 0 20%"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="1rem"
        >
          <img
            [src]="getRatingImages()"
            class="user-review-emoji-image"
          />
          <div class="user-review-remark">{{userReviews.rating}}</div>
        </div>
      </div>
      <div
        *ngIf="userReviews.tags.length"
        fxFlex="0 0 65%"
        fxLayout="row wrap"
        fxLayoutGap="0.25rem grid"
        fxLayoutAlign="start center"
      >
        <app-user-review-tags
          *ngFor="let tag of userReviews.tags | slice:0:5"
          [tag]="tag"
        ></app-user-review-tags>
      </div>
    </div>
  </div>
</div>

