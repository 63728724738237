import {Component, Input, OnInit} from '@angular/core';
import {NgxSkeletonLoaderConfig} from "ngx-skeleton-loader";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  @Input() url: string = '';
  @Input() loaderAppearance: NgxSkeletonLoaderConfig["appearance"] = '';
  @Input() loaderTheme: NgxSkeletonLoaderConfig["theme"] = {};

  loading: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  hideLoader() {
    this.loading = false;
  }
}
