import {Injectable} from '@angular/core';
/* NgRx */
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SharedService} from './shared.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../services/common.service';
import {Router} from '@angular/router';
import {catchError, map, mergeMap, Observable, of, tap} from "rxjs";
import * as SharedActions from './shared.actions';
import {isAppInMaintenanceMode} from './shared.actions';

@Injectable()
export class SharedEffects {
  fetchCountriesList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchCountriesListRequest),
      map((action) => {
        return action;
      }),
      mergeMap(() =>
        this.sharedService.getCountries().pipe(
          map((response: any) => {
            const {data} = response;
            return SharedActions.FetchCountriesListSuccess({
              countriesList: data
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchCountriesListFailure());
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchCountriesListSuccess.type) {
              // Code to execute on API Success Action dispatch
              let currentUrl = this.router.url;
              if (currentUrl === '/maintenance') {
                // Redirecting the User to The Home Page instead of Previous URL
                this.router.navigate(['/'])
              }
              this.store.dispatch(isAppInMaintenanceMode({isAppInMaintenanceMode: false}));
            } else if (
              action.type === SharedActions.FetchCountriesListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );
  fetchGenderList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchGenderListRequest),
      map((action) => {
        return action;
      }),
      mergeMap(() =>
        this.sharedService.getGenders().pipe(
          map((response: any) => {
            const {data} = response;
            return SharedActions.FetchGenderListSuccess({
              gendersList: data
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchGenderListFailure());
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchGenderListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === SharedActions.FetchGenderListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private sharedService: SharedService,
              private actions: Actions,
              private router: Router,
  ) {
  }
}
