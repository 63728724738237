import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PackageDetailsScreenComponent} from './package-details-screen/package-details-screen.component';
import {PackageDetailsRoutingModule} from './package-details-routing.module';
import {StoreModule} from '@ngrx/store';
import {reducer} from './core/package-details.reducer';
import {EffectsModule} from '@ngrx/effects';
import {PackageDetailsEffects} from './core/package-details.effects';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PackageDetailsQnaComponent} from './components/package-details-qna/package-details-qna.component';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../shared/material-module';
import { PakageDetailsLoaderComponent } from './components/pakage-details-loader/pakage-details-loader.component';

@NgModule({
  declarations: [PackageDetailsScreenComponent, PackageDetailsQnaComponent, PakageDetailsLoaderComponent],
  imports: [
    CommonModule,
    PackageDetailsRoutingModule,
    StoreModule.forFeature('packageDetails', reducer),
    EffectsModule.forFeature([PackageDetailsEffects]),
    FlexLayoutModule,
    SharedModule,
    MaterialModule,
  ],
})
export class PackageDetailsModule {
}
