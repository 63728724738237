import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription, tap} from 'rxjs';
import {isMobileView, selectedLanguage,} from 'src/app/modules/shared/core/shared.selectors';
import {Experience} from '../../../models/tea-details.model';
import {Translations} from '../../../translations/tea-details-page/tea-details-experience-info.translation';
import {teaExperience} from '../../core/tea-details.selectors';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css'],
})
export class ExperienceComponent implements OnInit, OnDestroy {
  experience: Experience[] = [];
  language: number = 1;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });
  isMobileView$: Observable<boolean> = this.store.pipe(select(isMobileView));
  experienceSubscription: Subscription = this.store
    .select(teaExperience)
    .pipe(
      tap((experience) => {
        this.experience = experience;
      })
    )
    .subscribe();

  translations = Translations;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  getImagesForExperience(icon) {
    let imageUrl = '';
    switch (icon) {
      case 'temperature':
        imageUrl = 'assets/images/png/thermometer.png';
        break;
      case 'water':
        imageUrl = 'assets/images/png/coffee-pot.png';
        break;
      case 'time':
        imageUrl = 'assets/images/png/bell-time.png';
        break;
      case 'tea':
        imageUrl = 'assets/images/png/breadcrumb-logo-package.png';
        break;
    }
    return imageUrl;
  }

  ngOnDestroy() {
    this.experienceSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
  }
}
