import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../state/app.state";
import {Translations} from "../../../../translations/user-details/my-subscriptions/subscription-card.translation";
import {CommonService} from "../../../../shared/services/common.service";
import {
  SubscriptionDetailPopupContainerComponent
} from "../subscription-detail-popup/subscription-detail-popup-container/subscription-detail-popup-container.component";

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.css']
})
export class SubscriptionCardComponent implements OnInit {

  @Input() subscriptionDetails: any;
  image : string = '../../../../../../assets/images/png/default-subscription.png';
  language: number;
  translations: any = Translations;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService) { }

  ngOnInit(): void {
  }

  handleUserSubscription(subscriptionId) {
    this.commonService.openDialog({
      data: {
        component: SubscriptionDetailPopupContainerComponent,
        subscriptionId,
      },
    });
  }
}
