import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {
  isMobileView,
  selectedCountry,
  selectedLanguage,
} from '../../shared/core/shared.selectors';
import {
  Translations as RightSectionTranslations
} from '../../translations/tea-details-page/tea-details-basic-info.translation';
import {
  Translations as LeftSectionTranslations
} from '../../translations/tea-details-page/tea-details-left-panel.translation';
import {
  FetchTeaDetailsRequest,
  ResetSuccessState
} from '../core/tea-details.actions';
import {teaDetails} from '../core/tea-details.selectors';
import {
  ReviewSummary,
  TeaDetailsState,
  UserReview,
} from '../../models/tea-details.model';
import {
  Translations as AromaTranslation
} from '../../translations/tea-details-page/tea-details-aroma-info.translation';
import {
  Translations as AppearanceTranslation
} from '../../translations/tea-details-page/tea-details-appearance-info.translation';
import {
  Translations as BrewTasteInfoTranslations
} from '../../translations/tea-details-page/tea-details-brew-taste-info.translation';
import {CommonService} from "../../shared/services/common.service";
import {
  Translations
} from "../../translations/tea-details-page/tea-details-page-screen.translation";
import {
  AddToCartRequest,
  AddToItems
} from "../../cart-details/core/cart.actions";
import * as _ from "lodash";
import {guestCart} from "../../cart-details/core/cart.selectors";
import {
  BrewDetailsPopupComponent
} from "../components/brew-details-popup/brew-details-popup.component";
import {TeaDetailsService} from "../core/tea-details.service";
import {ScriptService} from "../../shared/services/script.service";

@Component({
  selector: 'app-tea-details-screen',
  templateUrl: './tea-details-screen.component.html',
  styleUrls: ['./tea-details-screen.component.css'],
})
export class TeaDetailsScreenComponent implements OnInit, OnDestroy {
  showStartBrewButton: boolean = false;
  translations = Translations;
  language: number = 1;
  cartItem: any;
  country: string;
  teaDetails: TeaDetailsState;
  reviewSummary: ReviewSummary;
  userReviews: UserReview;
  loading: boolean = false;
  languageSubscription: Subscription = this.store
    .pipe(select(selectedLanguage))
    .subscribe((selectedLanguage) => {
      this.language = selectedLanguage;
    });
  productId: string = '';
  productKey: string = '';
  message: string = '';
  isMobileView: boolean;
  name1: string = '';
  name2: string = '';
  rightSectionTranslations: any = RightSectionTranslations;
  leftSectionTranslations: any = LeftSectionTranslations;
  aromaTranslations = AromaTranslation;
  appearanceTranslations = AppearanceTranslation;
  brewTasteInfoTranslations = BrewTasteInfoTranslations;
  mobileSubscription: Subscription = this.store
    .pipe(select(isMobileView))
    .subscribe((data) => {
      this.isMobileView = data;
    });

  teaDetailsForm: FormGroup = this.fb.group({
    price: new FormControl(''),
  });

  teaDetailsSubscription: Subscription = this.store.select(teaDetails)
    .subscribe((teaDetailsPageData) => {
      const {teaDetails, reviewSummary, userReviews} = teaDetailsPageData;

      this.productId = teaDetails.id;

      this.teaDetailsForm.patchValue(
        {
          price: teaDetails?.pricingPlans[0]?.key || '',
        },
        {emitEvent: false}
      );
      this.message = '';

      if (
        teaDetails?.isAvailableInCountry &&
        teaDetails?.availableQuantity <= 0 &&
        teaDetails?.pricingPlans?.length
      ) {
        this.message = this.leftSectionTranslations[this.language].outOfStock;
      } else if (
        !teaDetails?.isAvailableInCountry ||
        teaDetails?.pricingPlans?.length === 0
      ) {
        this.message =
          this.leftSectionTranslations[this.language].outOfStock;
      }
      this.teaDetails = teaDetails;
      this.name1 = this.teaDetails.name.split(' | ')[0];
      this.name2 = this.teaDetails.name.split(' | ')[1];
      this.reviewSummary = reviewSummary;
      this.userReviews = userReviews;

      this.showStartBrewButton = !!this.teaDetails.experience?.length;

      this.addScriptTag();
    })

  paramsSubscription: Subscription = this.activatedRoute.paramMap.subscribe(
    (params: any) => {
      if (params) {
        this.productKey = params.params.id;
        const vendorName = params.params.vendor;

        this.store.dispatch(FetchTeaDetailsRequest({
          payload: {
            productKey: `${vendorName}/${this.productKey}`
          }
        }));
      }
    }
  );

  countrySubscription: Subscription = this.store.pipe(select(selectedCountry))
    .subscribe(data => {
      this.country = data;
    })

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    private commonService: CommonService,
    private teaDetailsService: TeaDetailsService,
    private scriptService: ScriptService,
    private renderer2: Renderer2,
  ) {
    this.store.dispatch(ResetSuccessState());

    this.scriptService.setCanonicalLink(this.renderer2, location.href);
  }

  ngOnInit(): void {
  }

  startBrew() {
    this.commonService.openDialog({
      data: {component: BrewDetailsPopupComponent, backgroundColor: '#2C2C2C'},
      panelClass: 'brew-dialog'
    })
  }

  addToCart() {
    const priceArr = this.teaDetailsForm.controls['price'].value.split('|');
    const pricingPlanId = priceArr[0];
    this.cartItem = {
      productId: this.productId,
      pricingPlanId
    }
    if (this.commonService.isAuthenticated()) {
      this.store.dispatch(AddToCartRequest({payload: this.cartItem}))
    } else {
      let guestCartCountryId = '';
      let items = [];
      this.store.pipe(select(guestCart)).subscribe(data => {
        guestCartCountryId = data?.countryId;
        items = data.items ? _.cloneDeep(data?.items) : [];
      }).unsubscribe();

      if (guestCartCountryId && this.country !== guestCartCountryId) {
        const message = this.translations[this.language].CartErrorProductFromDifferentCountryAdded;
        this.commonService.notification(message, 'danger');
        return;
      }
      const payload: any = {
        countryId: guestCartCountryId || this.country,
        items: _.cloneDeep(items || [])
      };
      if (!payload?.items?.length) {
        payload?.items.push({...this.cartItem, quantity: 1})
        const message = this.translations[this.language].addToCartMessage;
        this.commonService.notification(message, 'success');
      } else {
        const index = payload?.items?.findIndex(object => {
          if (object?.productId === this.cartItem?.productId && object?.pricingPlanId === this.cartItem?.pricingPlanId) {
            return object;
          }
        });

        if (index > -1) {
          if (items[index] && items[index].quantity < 5) {
            payload.items[index].quantity = payload.items[index].quantity + 1;
            const message = this.translations[this.language].addToCartMessage;
            this.commonService.notification(message, 'success');
          } else {
            const message = this.translations[this.language].maximumQuantityReached
            this.commonService.notification(message, 'danger');
          }
        } else {
          payload?.items.push({...this.cartItem, quantity: 1})
          const message = this.translations[this.language].addToCartMessage;
          this.commonService.notification(message, 'success');
        }
      }

      this.store.dispatch(AddToItems(payload))
    }
  }

  addScriptTag() {
    const ldJson = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": this.teaDetails?.name,
      "image": this.teaDetails?.images[0],
      "description": this.teaDetails?.shortDescription,
      "brand": {
        "@type": "Brand",
        "name": this.teaDetails?.vendor?.name
      },
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": "JPY",
        "price": this.teaDetails?.pricingPlans[0]?.value,
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": this.reviewSummary?.averageRatingKey,
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": this.reviewSummary?.totalReviews
      }
    }

    this.scriptService.setJsonLd(this.renderer2, ldJson)
  }

  ngOnDestroy() {
    this.teaDetailsService.userReviewsCurrentPage.next(1);
    this.languageSubscription?.unsubscribe();
    this.paramsSubscription?.unsubscribe();
    this.mobileSubscription?.unsubscribe();
    this.countrySubscription?.unsubscribe();
    this.teaDetailsSubscription?.unsubscribe();

    let element = document.getElementById('ld_json');
    if (element) {
      element.parentElement.removeChild(element);
    }

    element = document.getElementById('canonical_link');
    if (element) {
      element.parentElement.removeChild(element);
    }
  }
}
