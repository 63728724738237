<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="center"
  fxLayoutGap="1.5rem"
>
  <div
    class="heading"
    fxLayoutAlign="center"
  >
    {{translations[language].heading}}
  </div>
  <form
    [formGroup]="form"
    fxLayout="column"
    fxLayoutAlign="space-around none"
  >
    <div class="input-field">
      <app-password-input
        id="password"
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'password'"
        [error]="errorMessages['password']"
        [maxLength]="validations.password.length.max"
        [parentFormGroup]="form"
        class="password"
        fieldBackgroundColor="#D7D7D7"
        fieldTextColor="#FFFFFF"
        label="{{translations[language].passwordLabel}}"
        labelTextColor="#000000"
        labelFontWeight="bold"
        [hint]="translations[language].errorMessageMap.password.hint"
      >
      </app-password-input>
    </div>
    <div class="input-field">
      <app-password-input
        id="confirmpassword"
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'confirmPassword'"
        [error]="errorMessages['confirmPassword']"
        [maxLength]="validations.password.length.max"
        [parentFormGroup]="form"
        class="confirm-password"
        fieldBackgroundColor="#D7D7D7"
        fieldTextColor="#FFFFFF"
        label="{{translations[language].confirmPasswordLabel}}"
        labelTextColor="#000000"
        labelFontWeight="bold"
      >
      </app-password-input>
    </div>
    <div class="actions">
      <app-secondary-button
        id="confirm"
        (handleClick)="resetPassword()"
        class="login-button"
        text="{{translations[language].confirmButton}}"
      >
      </app-secondary-button>
    </div>
  </form>
</div>
