<div
  class="container"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div
    fxFlex="100"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.xs="center center"
    fxLayoutGap="20px"
  >
    <div class="image-container" fxLayoutAlign="start center">
      <img alt="" class="image" src="./assets/images/gif/kettle.gif"/>
    </div>
    <div class="message">
      {{translations[language].labelText1}} {{brewTemperature}} {{translations[language].labelText2}}
    </div>
    <div
      class="actions-section"
      fxLayout="row"
      fxLayoutAlign="space-around center"
    >
      <app-primary-button
        id="previous"
        (handleClick)="handlePreviousClick()"
        [text]="translations[language].btnPrevious"
        [isSlim]="true"
        class="button"
      ></app-primary-button>
      <app-primary-button
        buttonId="start_external_hot_brew"
        (handleClick)="handleNextClick()"
        [text]="translations[language].btnNext"
        [isSlim]="true"
        class="button start-external-hot-brew"
      ></app-primary-button>
    </div>
  </div>
</div>

