import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-list-loader',
  templateUrl: './address-list-loader.component.html',
  styleUrls: ['./address-list-loader.component.css']
})
export class AddressListLoaderComponent implements OnInit {
  itemList: number[] = [1, 2, 3, 4, 5];
  constructor() { }

  ngOnInit(): void {
  }

}
