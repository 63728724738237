import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../../../state/app.state";
import {PaymentOptionService} from "../../../services/payment-option.service";
import {Subscription} from "rxjs";
import * as UserDetailsActions from "../../../../core/user-details.actions";

@Component({
  selector: 'app-add-payment-option-container',
  templateUrl: './add-payment-option-container.component.html',
  styleUrls: ['./add-payment-option-container.component.css']
})
export class AddPaymentOptionContainerComponent implements OnInit, OnDestroy {
  @Input() data: any;
  paymentOptionId: string;

  savePaymentOptionSubscription: Subscription = this.paymentOptionService.savePaymentOption.subscribe((payload) => {
    this.store.dispatch(UserDetailsActions.SavePaymentOptionRequest({payload}));
  });

  constructor(private store: Store<fromRoot.State>,
              private paymentOptionService: PaymentOptionService) { }

  ngOnInit(): void {
    this.paymentOptionId = this.data?.paymentOptionId;
  }

  ngOnDestroy() {
    this.savePaymentOptionSubscription?.unsubscribe();
  }

}
