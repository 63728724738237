interface TeaDetailsAromaInfoTranslation {
  heading: string
}

const en: TeaDetailsAromaInfoTranslation = {
  heading: `Aroma`
}

const ja: TeaDetailsAromaInfoTranslation = {
  heading: `香り`
}

export const Translations = {
  1: en,
  2: ja
}
