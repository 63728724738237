/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {PackageDetailsModel} from '../../models/package-details.model';

// Selector functions
const packageDetailsData =
  createFeatureSelector<PackageDetailsModel>('packageDetails');

// Create Selectors

//fetch package details
export const fetchPackageDetails = createSelector(
  packageDetailsData,
  (state: PackageDetailsModel) => state
);
