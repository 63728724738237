/* NgRx */
import {createAction, props} from '@ngrx/store';
import {UserAddressModel} from "../../models/user-address.model";
import {UserPaymentOptionModel} from "../../models/user-payment-option.model";
import {FilterCountryModel} from "../../models/filter-country.model";
import {FilterModel} from "../../models/filter.model";

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[user details] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH COUNTRIES LIST ***********/
const FETCH_COUNTRIES_LIST_REQUEST = '[user details] fetch countries list requested';
export const FetchCountriesListRequest = createAction(FETCH_COUNTRIES_LIST_REQUEST);

const FETCH_COUNTRIES_LIST_SUCCESS =
  '[user details] fetch countries list successful';
export const FetchCountriesListSuccess = createAction(
  FETCH_COUNTRIES_LIST_SUCCESS,
  props<{ countriesList: FilterCountryModel[], phoneCodesList: FilterModel[] }>()
);

const FETCH_COUNTRIES_LIST_FAILURE = '[user details] fetch countries list failure';
export const FetchCountriesListFailure = createAction(
  FETCH_COUNTRIES_LIST_FAILURE,
  props<{ message: string }>()
);

/******** FETCH ADDRESS LIST ***********/
const FETCH_ADDRESS_LIST_REQUEST = '[user details] fetch address list requested';
export const FetchAddressListRequest = createAction(FETCH_ADDRESS_LIST_REQUEST);

const FETCH_ADDRESS_LIST_SUCCESS = '[user details] fetch address list successful';
export const FetchAddressListSuccess = createAction(
  FETCH_ADDRESS_LIST_SUCCESS,
  props<{ addressList: UserAddressModel[], message: string }>()
);

const FETCH_ADDRESS_LIST_FAILURE = '[user details] fetch address list failure';
export const FetchAddressListFailure = createAction(
  FETCH_ADDRESS_LIST_FAILURE,
  props<{ message: string }>()
);

/******** SAVE ADDRESS ***********/
const SAVE_ADDRESS_REQUEST = '[user details] save address requested';
export const SaveAddressRequest = createAction(
  SAVE_ADDRESS_REQUEST,
  props<{ payload: UserAddressModel }>()
);

const SAVE_ADDRESS_SUCCESS = '[user details] save address successful';
export const SaveAddressSuccess = createAction(
  SAVE_ADDRESS_SUCCESS,
  props<{ addressList: UserAddressModel[], message: string }>()
);

const SAVE_ADDRESS_FAILURE = '[user details] save address failure';
export const SaveAddressFailure = createAction(
  SAVE_ADDRESS_FAILURE,
  props<{ message: string }>()
);

/******** UPDATE ADDRESS ***********/
const UPDATE_ADDRESS_REQUEST = '[user details] update address requested';
export const UpdateAddressRequest = createAction(
  UPDATE_ADDRESS_REQUEST,
  props<{ payload: { addressId: string, isDefault: boolean, returnAll: boolean } }>()
);

const UPDATE_ADDRESS_SUCCESS = '[user details] update address successful';
export const UpdateAddressSuccess = createAction(
  UPDATE_ADDRESS_SUCCESS,
  props<{ addressList: UserAddressModel[], message: string }>()
);

const UPDATE_ADDRESS_FAILURE = '[user details] update address failure';
export const UpdateAddressFailure = createAction(
  UPDATE_ADDRESS_FAILURE,
  props<{ message: string }>()
);

/******** MAKE DEFAULT ADDRESS ***********/
const MAKE_DEFAULT_ADDRESS_REQUEST = '[user details] address make default requested';
export const MakeDefaultAddressRequest = createAction(
  MAKE_DEFAULT_ADDRESS_REQUEST,
  props<{ payload: {addressId: string, isDefault: boolean, returnAll: boolean} }>()
);

const MAKE_DEFAULT_ADDRESS_SUCCESS = '[user details] address make default successful';
export const MakeDefaultAddressSuccess = createAction(
  MAKE_DEFAULT_ADDRESS_SUCCESS,
  props<{ addressList: UserAddressModel[], addressId: string, message: string }>()
);

const MAKE_DEFAULT_ADDRESS_FAILURE = '[user details] address make default failure';
export const MakeDefaultAddressFailure = createAction(
  MAKE_DEFAULT_ADDRESS_FAILURE,
  props<{ addressId: string, message: string }>()
);

/************ REMOVE ADDRESS**************/
const REMOVE_ADDRESS_REQUEST = '[user details] remove address request';
export const RemoveAddressRequest = createAction(
  REMOVE_ADDRESS_REQUEST,
  props<{ payload: { addressId: string } }>()
);

const REMOVE_ADDRESS_SUCCESS = '[user details] remove address success';
export const RemoveAddressSuccess = createAction(
  REMOVE_ADDRESS_SUCCESS,
  props<{ addressList: UserAddressModel[], message: string }>()
);

const REMOVE_ADDRESS_FAILURE = '[user details] remove address failure';
export const RemoveAddressFailure = createAction(
  REMOVE_ADDRESS_FAILURE,
  props<{ message: string }>()
);

/******** FETCH PAYMENT OPTION LIST ***********/
const FETCH_PAYMENT_OPTION_LIST_REQUEST = '[user details] user payment options requested';
export const FetchPaymentOptionListRequest = createAction(FETCH_PAYMENT_OPTION_LIST_REQUEST);

const FETCH_PAYMENT_OPTION_LIST_SUCCESS = '[user details] user payment options successful';
export const FetchPaymentOptionListSuccess = createAction(
  FETCH_PAYMENT_OPTION_LIST_SUCCESS,
  props<{ paymentOptionList: UserPaymentOptionModel[], message: string }>()
);

const FETCH_PAYMENT_OPTION_LIST_FAILURE = '[user details] user payment options failure';
export const FetchPaymentOptionListFailure = createAction(
  FETCH_PAYMENT_OPTION_LIST_FAILURE,
  props<{ message: string }>()
);


/******** SAVE PAYMENT OPTION ***********/
const SAVE_PAYMENT_OPTION_REQUEST = '[user details] save payment option requested';
export const SavePaymentOptionRequest = createAction(
  SAVE_PAYMENT_OPTION_REQUEST,
  props<{ payload: { token: string, returnAll: boolean, type: number } }>()
);

const SAVE_PAYMENT_OPTION_SUCCESS = '[user details] save payment option successful';
export const SavePaymentOptionSuccess = createAction(SAVE_PAYMENT_OPTION_SUCCESS, props<{ paymentOptionList: UserPaymentOptionModel[], message: string }>());

const SAVE_PAYMENT_OPTION_FAILURE = '[user details] save payment option failure';
export const SavePaymentOptionFailure = createAction(SAVE_PAYMENT_OPTION_FAILURE, props<{ message: string }>());

/******** MAKE DEFAULT PAYMENT OPTION ***********/
const PAYMENT_OPTION_MAKE_DEFAULT_REQUEST = '[user details] payment option make default requested';
export const PaymentOptionMakeDefaultRequest = createAction(
  PAYMENT_OPTION_MAKE_DEFAULT_REQUEST,
  props<{ payload: { paymentOptionId: string, returnAll: boolean } }>()
);

const PAYMENT_OPTION_MAKE_DEFAULT_SUCCESS = '[user details] payment option make default successful';
export const PaymentOptionMakeDefaultSuccess = createAction(PAYMENT_OPTION_MAKE_DEFAULT_SUCCESS, props<{ paymentOptionList: UserPaymentOptionModel[], paymentOptionId: string, message: string }>());

const PAYMENT_OPTION_MAKE_DEFAULT_FAILURE = '[user details] payment option make default failure';
export const PaymentOptionMakeDefaultFailure = createAction(PAYMENT_OPTION_MAKE_DEFAULT_FAILURE, props<{ paymentOptionId: string, message: string }>());

/************ REMOVE PAYMENT OPTION **************/
const REMOVE_PAYMENT_OPTION_REQUEST = '[user details] remove payment option request';
export const RemovePaymentOptionRequest = createAction(
  REMOVE_PAYMENT_OPTION_REQUEST,
  props<{ payload: { paymentOptionId: string } }>()
);

const REMOVE_PAYMENT_OPTION_SUCCESS = '[user details] remove payment option success';
export const RemovePaymentOptionSuccess = createAction(
  REMOVE_PAYMENT_OPTION_SUCCESS,
  props<{ paymentOptionList: UserPaymentOptionModel[], message: string }>()
);

const REMOVE_PAYMENT_OPTION_FAILURE = '[user details] remove payment option failure';
export const RemovePaymentOptionFailure = createAction(
  REMOVE_PAYMENT_OPTION_FAILURE,
  props<{ message: string }>()
);

/************ LOADING ACTIONS **************/
const SET_ADD_PAYMENT_OPTION_LOADING_REQUEST = '[user details] set add payment option loading request';
export const SetAddPaymentOptionLoadingRequest = createAction(
  SET_ADD_PAYMENT_OPTION_LOADING_REQUEST,
  props<{loading: boolean }>()
);

/******** FETCH ORDERS LIST  ***********/
const FETCH_ORDERS_LIST_REQUEST = '[user details] fetch orders list requested';
export const FetchOrdersListRequest = createAction(FETCH_ORDERS_LIST_REQUEST, props<any>());

const FETCH_ORDERS_LIST_SUCCESS = '[user details] fetch orders list successful';
export const FetchOrdersListSuccess = createAction(FETCH_ORDERS_LIST_SUCCESS, props<any>());

const FETCH_ORDERS_LIST_FAILURE = '[user details] fetch orders list failure';
export const FetchOrdersListFailure = createAction(FETCH_ORDERS_LIST_FAILURE, props<any>());

/******** FETCH USER SUBSCRIPTION LIST ***********/
const FETCH_USER_SUBSCRIPTIONS_REQUEST = '[user details] fetch user subscriptions requested';
export const FetchUserSubscriptionsRequest = createAction(
  FETCH_USER_SUBSCRIPTIONS_REQUEST,
  props<any>()
);

const FETCH_USER_SUBSCRIPTIONS_SUCCESS = '[user details] fetch user subscriptions successful';
export const FetchUserSubscriptionsSuccess = createAction(
  FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  props<any>()
);

const FETCH_USER_SUBSCRIPTIONS_FAILURE = '[user details] fetch user subscriptions failure';
export const FetchUserSubscriptionsFailure = createAction(
  FETCH_USER_SUBSCRIPTIONS_FAILURE,
  props<any>()
);

/******** FETCH ORDERS DETAILS  ***********/
const FETCH_ORDERS_DETAILS_REQUEST = '[user details] fetch orders details requested';
export const FetchOrdersDetailsRequest = createAction(
  FETCH_ORDERS_DETAILS_REQUEST,
  props<{ payload: { orderId: string }}>()
);

const FETCH_ORDERS_DETAILS_SUCCESS =
  '[user details] fetch orders details successful';
export const FetchOrdersDetailsSuccess = createAction(
  FETCH_ORDERS_DETAILS_SUCCESS,
  props<{ orderDetails: any }>()
);

const FETCH_ORDERS_DETAILS_FAILURE = '[user details] fetch orders details failure';
export const FetchOrdersDetailsFailure = createAction(
  FETCH_ORDERS_DETAILS_FAILURE,
  props<{ message: string }>()
);

/******** FETCH SUBSCRIPTION DETAILS ***********/
const FETCH_USER_SUBSCRIPTION_DETAILS_REQUEST =
  '[user details] fetch user subscription details requested';
export const FetchUserSubscriptionDetailsRequest = createAction(
  FETCH_USER_SUBSCRIPTION_DETAILS_REQUEST,
  props<{ payload: { subscriptionId: string } }>()
);

const FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS =
  '[user details] fetch user subscription details successful';
export const FetchUserSubscriptionDetailsSuccess = createAction(
  FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS,
  props<{ subscriptionDetails: any }>()
);

const FETCH_USER_SUBSCRIPTION_DETAILS_FAILURE =
  '[user details] fetch user subscription details failure';
export const FetchUserSubscriptionDetailsFailure = createAction(
  FETCH_USER_SUBSCRIPTION_DETAILS_FAILURE,
  props<{ message: string }>()
);

/******** CANCEL USER SUBSCRIPTION ***********/
const CANCEL_USER_SUBSCRIPTION_REQUEST = '[user details] cancel user subscription requested';
export const CancelUserSubscriptionRequest = createAction(
  CANCEL_USER_SUBSCRIPTION_REQUEST,
  props<any>()
);

const CANCEL_USER_SUBSCRIPTION_SUCCESS = '[user details] cancel user subscription successful';
export const CancelUserSubscriptionSuccess = createAction(
  CANCEL_USER_SUBSCRIPTION_SUCCESS,
  props<any>()
);

const CANCEL_USER_SUBSCRIPTION_FAILURE = '[user details] cancel user subscription failure';
export const CancelUserSubscriptionFailure = createAction(
  CANCEL_USER_SUBSCRIPTION_FAILURE,
  props<any>()
);
