import {Renderer2, Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Service to automate creation of JSON-LD Microdata.
 */
@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  constructor(
    @Inject(DOCUMENT) private _document: Document
  ) { }

  /**
   * Set JSON-LD Microdata on the Document Body.
   *
   * @param renderer2 The Angular Renderer
   * @param data The data for the JSON-LD script
   * @param id Id of script tag
   * @returns Void
   */
  public setJsonLd(renderer2: Renderer2, data: any): void {
    const id = 'ld_json'


    const element = document.getElementById(id);
    if (element) {
      element.parentElement.removeChild(element);
    }

    let script = renderer2.createElement('script');
    script.id = id;
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;
    renderer2.appendChild(this._document.body, script);
  }

  public setCanonicalLink(renderer2: Renderer2, data: any): void {
    const id = 'canonical_link'


    const element = document.getElementById(id);
    if (element) {
      element.parentElement.removeChild(element);
    }

    let link = renderer2.createElement('link');
    link.id = id;
    link.rel = 'canonical';
    link.href = data;
    renderer2.appendChild(this._document.body, link);
  }
}
