import {user} from '../../../shared/constants/validation.constants';

interface UpdateAccountFormTranslation {
  heading: string,
  ageLabel: string,
  genderLabel: string,
  updateAccountButton: string,
  loginButton: string,
  errorMessageMap: object
}

const en: UpdateAccountFormTranslation = {
  heading: `We’d like to know you a bit more.`,
  ageLabel: 'Age',
  genderLabel: 'Gender',
  updateAccountButton: 'Update Account',
  loginButton: 'Login',
  errorMessageMap: {
    age: {
      required: 'This field is required.',
      min: `Age should not be below ${user.age.min} years.`,
      max: `Age should not be above ${user.age.max} years.`
    },
    gender: {
      required: 'This field is required.'
    }
  }
}

const ja: UpdateAccountFormTranslation = {
  heading: `アカウントをつくる Step2`,
  ageLabel: `年齢`,
  genderLabel: `性別`,
  updateAccountButton: `登録`,
  loginButton: `ログイン`,
  errorMessageMap: {
    age: {
      required: '入力が必須です',
      min: `年齢は${user.age.min}歳以上で入力してください`,
      max: `年齢は${user.age.max}歳以下で入力してください`
    },
    gender: {
      required: '入力が必須です'
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
