import * as CartActions from './package-details.actions';
import {createReducer, on} from '@ngrx/store';
import {FilterModel} from "../../models/filter.model";

// State for this feature (User)
export interface PackageDetailsState {
  loading: boolean;
  success: boolean;
  id: string;
  availableQuantity: number;
  imageList: string[];
  isAvailableInCountry: boolean;
  name: string;
  vendor: {
    logoUrl: string;
    name: string;
  };
  priceList: FilterModel[];
  questionList: PackageDetailsInfoQueries[];
  description: string,
  key: string
}

interface PackageDetailsInfoQueries {
  question: string;
  answer: string;
}

const initialState: PackageDetailsState = {
  loading: false,
  success: null,
  id: null,
  name: null,
  description: null,
  availableQuantity: 0,
  isAvailableInCountry: null,
  vendor: null,
  questionList: [],
  priceList: [],
  imageList: [],
  key: ''
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(CartActions.ClearState, (state) => initialState),

  //fetch package details
  on(CartActions.FetchPackageDetailsRequest, (state, props) => {
    return {
      ...state,
      ...initialState,
      loading: true,
      success: null
    };
  }),
  on(CartActions.FetchPackageDetailsSuccess, (state, props) => {
    return {
      ...state,
      ...props.payload,
      loading: false,
      success: true
    };
  }),
  on(CartActions.FetchPackageDetailsFail, (state, props) => {
    return {
      ...state,
      loading: false,
      success: false

    };
  }),

  // Reset Success State
  on(CartActions.ResetSuccessState, (state) => ({
    ...state,
      success: initialState.success,
      loading: initialState.loading
  }))
);
