<div
	class="container"
	fxLayout="column"
	fxLayoutGap="40px"
  ngClass.gt-sm="gt-sm"
  ngClass.sm="sm"
	ngClass.xs="xs"
>
	<div
		[ngClass]="brewStrength"
		fxLayout="row"
		fxLayout.xs="column"
		fxLayoutGap="20px"
    fxLayoutAlign="space-between none"
	>
		<div
			class="left"
			fxFlex.gt-xs="50"
			fxFlex.xs="100"
			fxLayoutAlign="center center"

		>
			<div class="circle-container">
				<div class="circle mild" [class]="selectedBrewStrength === 'mild'?'active':''"></div>
				<div class="circle ideal"  [class]="selectedBrewStrength === 'ideal'?'active':''"></div>
				<div class="circle strong"  [class]="selectedBrewStrength === 'strong'?'active':''"></div>
				<div class="circle coldCarafe"  [class]="selectedBrewStrength === 'coldCarafe'?'active':''"></div>
				<img class="image"  [class]="selectedBrewStrength" alt="" src="./assets/images/png/brew-{{selectedBrewStrength}}.png"/>
			</div>
		</div>

    <div
      class="right"
      fxFlex="45"
      fxFlex.xs="100"
      fxLayout="column"
      fxLayoutAlign="space-between none"
      fxLayoutAlign.xs="center center"
    >
      <div
        *ngFor="let eachStrength of externalBrewDetails?.brewConfigurations"
        class="strength-container"
        fxLayout="column"
        fxLayoutGap="15px"
      >
        <div
          (click)="brewStrengthSelect(eachStrength.brewStrength)"
          [ngClass]="selectedBrewStrength === eachStrength.brewStrength ? 'active' : ''"
          class="strength-box"
          fxLayout="column"
        >
          <div
            class="title"
            fxLayout="row"
            fxLayoutAlign.lg="start center"
            fxLayoutAlign.md="start center"
            fxLayoutAlign.sm="start center"
            fxLayoutAlign.xs="start center"
            fxLayoutGap="10px"
          >
            <div class="strength-name">{{translations[language][eachStrength.brewStrength]}}</div>
            <div [class]="'strength-badge ' + eachStrength.brewStrength"></div>
          </div>
          <div class="sub-title" fxLayout="column">
            <p>
              {{translations[language].labelBrewTemp}} - {{eachStrength.brewTemperature}}
            </p>
            <p>
              {{translations[language].labelBrewTime}} - {{eachStrength.brewDuration}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div fxLayoutAlign="center center" class="action-section" >
    <app-primary-button
      id="next"
      (handleClick)="handleNextClick()"
      [text]="translations[language].btnNext"
      [isSlim]="true"
      class="next-button"
    ></app-primary-button>
  </div>
</div>

