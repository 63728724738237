<div
  class="container"
>
  <div
    class="user-details-wrapper"
    ngClass.xs="xs"
  >
    <app-user-details-container [language]="language"></app-user-details-container>
  </div>
</div>
