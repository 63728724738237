interface AuthenticatedBrewNotificationInfoComponent {
  labelText1: string,
  labelText2: string,
}

const en: AuthenticatedBrewNotificationInfoComponent = {
  labelText1: `We will notify you when your cold brew is ready`,
  labelText2: `You will receive an Email on`,
}

const ja: AuthenticatedBrewNotificationInfoComponent = {
  labelText1: `飲みごろになりましたら通知でお知らせします`,
  labelText2: `あなたはに電子メールを受け取ります`,
}

export const Translations = {
  1: en,
  2: ja
}
